import axios, { AxiosError } from "axios"
import paymentApis from "../../utils/paymentApis"
import { ErrorResponse } from "./SignInAction"
import { showFeedbackModal } from "./UserFeedbackActions"
import { SET_EXTERNAL_ACCOUNTS } from "../types";

export const setExternalAccounts = (external_accounts: any[]) => {
    return {
        type: SET_EXTERNAL_ACCOUNTS,
        external_accounts,
    };
};


export const createConnectedAccount = (Token: string | undefined, Email: string, CustomerID: string) => {
    return async (dispatch: any) => {
        try {

            const resp = await paymentApis({
                method: 'POST',
                url: "stripe/createConnectedAccount",
                data: {
                    Token,
                    Email,
                    CustomerID
                }
            })

            if (resp && resp.status === 200) {
                return resp.data
            } else {
                if (axios.isAxiosError(resp)) {
                    const axiosError = resp as AxiosError;
                    if (axiosError.response && axiosError.response.data) {
                        let errorMessage = axiosError.response.data as ErrorResponse
                        dispatch(showFeedbackModal({
                            showModal: true,
                            message: (errorMessage.message ? errorMessage.message : errorMessage.error || 'An unknown error occurred'),
                            modalType: 'error',
                            duration: 3000
                        }));
                    } else {
                        dispatch(showFeedbackModal({
                            showModal: true,
                            message: ('Network error, please try again'),
                            modalType: 'error',
                            duration: 3000
                        }));
                    }
                }
            }
        } catch (err) {
            console.error(err)
        }
    }
}

export const listRecieversAccount = (CustomerID: string) => {
    return async (dispatch: any) => {
        try {

            const resp: any = await paymentApis({
                method: 'POST',
                url: "stripe/listAccounts",
                data: {
                    CustomerID: CustomerID,
                }
            })

            if (resp && resp.status === 200) {
                const accounts = resp.data.user_accounts
                if (accounts && Object.keys(accounts).length > 0 && accounts.external_accounts) {
                    dispatch(setExternalAccounts(accounts.external_accounts))
                }
                return resp.data
            } else {
                if (axios.isAxiosError(resp)) {
                    const axiosError = resp as AxiosError;
                    if (axiosError.response && axiosError.response.data) {
                        let errorMessage = axiosError.response.data as ErrorResponse
                        dispatch(showFeedbackModal({
                            showModal: true,
                            message: (errorMessage.message ? errorMessage.message : errorMessage.error || 'An unknown error occurred'),
                            modalType: 'error',
                            duration: 3000
                        }));
                    } else {
                        dispatch(showFeedbackModal({
                            showModal: true,
                            message: ('Network error, please try again'),
                            modalType: 'error',
                            duration: 3000
                        }));
                    }
                }
            }

        } catch (err) {
            console.error(err)
        }
    }
}

export const createVerificationSession = () => {
    return async (dispatch: any) => {
        try {

            const resp = await paymentApis({
                method: 'POST',
                url: "stripe/verifySession",
                data: {
                    user_id: "abcde"
                }
            })

            if (resp && resp.status === 200) {
                return resp.data
            }
            else {
                if (axios.isAxiosError(resp)) {
                    const axiosError = resp as AxiosError;
                    if (axiosError.response && axiosError.response.data) {
                        let errorMessage = axiosError.response.data as ErrorResponse
                        dispatch(showFeedbackModal({
                            showModal: true,
                            message: (errorMessage.message ? errorMessage.message : errorMessage.error || 'An unknown error occurred'),
                            modalType: 'error',
                            duration: 3000
                        }));
                    } else {
                        dispatch(showFeedbackModal({
                            showModal: true,
                            message: ('Network error, please try again'),
                            modalType: 'error',
                            duration: 3000
                        }));
                    }
                }
            }

        } catch (err) {
            console.error(err)
        }
    }
}

export const createCheckoutSession = (Payload: any) => {
    return async (dispatch: any) => {


        try {

            const formData = new FormData()

            Object.entries(Payload).forEach(([key, value]) => {
                if (value !== undefined && value !== null && value !== "") {
                    if (typeof value === 'boolean') {
                        formData.append(key, value ? 'yes' : 'no');
                    } else {
                        formData.append(key, value as string);
                    }
                }
            });
            console.log("gere");
            const resp = await paymentApis({
                method: 'POST',
                url: "stripe/createCheckoutSession",
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
                data: formData
            })

            if (resp && resp.status === 200) {
                return resp.data
            }
            else {
                if (axios.isAxiosError(resp)) {
                    const axiosError = resp as AxiosError;
                    if (axiosError.response && axiosError.response.data) {
                        let errorMessage = axiosError.response.data as ErrorResponse
                        dispatch(showFeedbackModal({
                            showModal: true,
                            message: (errorMessage.message ? errorMessage.message : errorMessage.error || 'An unknown error occurred'),
                            modalType: 'error',
                            duration: 3000
                        }));
                    } else {
                        dispatch(showFeedbackModal({
                            showModal: true,
                            message: ('Network error, please try again'),
                            modalType: 'error',
                            duration: 3000
                        }));
                    }
                }
            }

        } catch (err) {
            console.error(err)
        }
    }
}