
export const SET_ACTIVE_ROLE = 'SET_ACTIVE_ROLE';
export const SET_SLIDER_POSITION = 'SET_SLIDER_POSITION';

export const setActiveRole = (role: string) => ({
    type: SET_ACTIVE_ROLE,
    payload: role
});

export const setSliderPosition = (position: number) => ({
    type: SET_SLIDER_POSITION,
    payload: position
});
