import React, { useEffect, useCallback, useState, FormEvent, useRef } from "react";
import ReactPlayer from "react-player";
import { usePeer } from "../../contexts/Peer";
import SocketServices from "../../utils/SocketServices";
import { getUserType } from "../../store/actions/ChatAction";
import { Button, Dialog } from "@mui/material";
import "./VideoCallComponent.css"
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "../../store";
import { ChatMessage, MessageProps } from "./Client/ChatInterface";
import { updateMeetingStatus } from "../../store/actions/ScheduleMeetingActions";

interface CaseItem {
    CaseID: string;
}

const VideoCallComponent: React.FC<{ caseItem: CaseItem, isMeetingTime: boolean, setIsMeetingTime: (val: boolean) => void, meeting?: any }> = ({ caseItem, isMeetingTime, setIsMeetingTime, meeting }) => {
    const [myStream, setMyStream] = useState<MediaStream | null>(null);
    const [remoteStream, setRemoteStream] = useState<MediaStream | null>(null);
    const { peer, createOffer, createAnswer } = usePeer();
    const userType = getUserType();
    const recieverId =
        userType === "Client" ? `lawyer_${caseItem.CaseID}` : `client_${caseItem.CaseID}`;
    const caller =
        userType === "Client" ? `client_${caseItem.CaseID}` : `lawyer_${caseItem.CaseID}`;
    const [callAccepted, setCallAccepted] = useState(false)
    const [startCall, setStartCall] = useState(false)
    const userInfo = useSelector((state: RootState) => state.user.userInfo)
    const [mute, setMute] = useState(false)
    const [muted, setMuted] = useState(false)
    const [incomingCall, setIncomingCall] = useState(false)
    const [callId, setCallId] = useState("")
    const [isVideoEnabled, setIsVideoEnabled] = useState(true);
    const [isRemoteVideoEnabled, setIsRemoteVideoEnabled] = useState(true);

    const [note, setNote] = useState('');
    const [showNotes, setShowNotes] = useState(false);
    const [notes, setNotes] = useState<ChatMessage[]>([]);
    const messagesEndRef = useRef<HTMLDivElement | null>(null);
    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
    };
    const [unreadMessages, setUnreadMessages] = useState(0);
    const dispatch = useAppDispatch()

    const handleNotesChange = (event: any) => {
        setNote(event.target.value);
    };

    const toggleShowNotes = () => {
        setShowNotes(!showNotes);
        setUnreadMessages(0)
    };

    useEffect(() => {
        scrollToBottom();
    }, [notes]);





    const handleCallRequest = useCallback(async () => {
        const stream = await navigator.mediaDevices.getUserMedia({
            audio: true,
            video: true
        });
        const offer = await createOffer();

        SocketServices.emit("private_call_request", {
            username: caller,
            recipient_username: recieverId,
            caseid: caseItem.CaseID,
            offer
        });
        setMyStream(stream);
        setStartCall(true)
        SocketServices.emit("chat_list", { caseid: caseItem.CaseID, receiver_username: userType === "Client" ? `client_${caseItem.CaseID}` : `lawyer_${caseItem.CaseID}` })
    }, [caller, caseItem.CaseID, recieverId, createOffer, userType]);

    const handleIncommingCall = useCallback(
        async ({ callername, offer, callID }: { callername: string, offer: RTCSessionDescriptionInit, callID: string }) => {
            setCallId(callID)

            const stream = await navigator.mediaDevices.getUserMedia({
                audio: true,
                video: true
            });
            setMyStream(stream);
            const ans = await createAnswer(offer);
            SocketServices.emit('accept_call', { username: recieverId, recipient_username: caller, caseid: caseItem.CaseID, answer: ans, callID: callID });
            setIncomingCall(true)
            SocketServices.emit("chat_list", { caseid: caseItem.CaseID, receiver_username: userType === "Client" ? `client_${caseItem.CaseID}` : `lawyer_${caseItem.CaseID}` })
        },
        [recieverId, caller, caseItem.CaseID, createAnswer, userType]
    );

    const sendStreams = useCallback(async () => {
        if (myStream) {
            for (const track of myStream.getTracks()) {
                await peer.addTrack(track, myStream);
            }
        }
    }, [myStream, peer]);

    const handleCallAccepted = useCallback(
        async ({ ans }: { reciever_name: string, ans: RTCSessionDescriptionInit }) => {
            await peer.setRemoteDescription(ans);
            sendStreams();
        },
        [peer, sendStreams]
    );

    const handleNegoNeeded = useCallback(async () => {
        const offer = await createOffer();
        SocketServices.emit('negotiation_needed', { username: caller, recipient_username: recieverId, caseid: caseItem.CaseID, offer: offer });
    }, [caller, recieverId, caseItem.CaseID, createOffer]);

    useEffect(() => {
        peer.addEventListener("negotiationneeded", handleNegoNeeded);
        return () => {
            peer.removeEventListener("negotiationneeded", handleNegoNeeded);
        };
    }, [handleNegoNeeded, peer]);

    const handleNegoNeedIncomming = useCallback(
        async ({ from, offer }: { from: string, offer: RTCSessionDescriptionInit }) => {
            const ans = await createAnswer(offer);
            SocketServices.emit("negotiation_done", { username: caller, reciever_name: recieverId, caseid: caseItem.CaseID, ans: ans });
        },
        [caller, recieverId, caseItem.CaseID, createAnswer]
    );

    const handleNegoNeedFinal = useCallback(async ({ ans }: { ans: RTCSessionDescriptionInit }) => {
        await peer.setRemoteDescription(ans);
    }, [peer]);

    const createOrderedMessageString = (notes: any) => {
        return notes.map((note: any, index: number) => `${index + 1}. ${note.Message}`).join(',\n');
    }


    const handleRejectCall = useCallback(async () => {
        SocketServices.emit('reject_call', { username: caller, recipient_username: recieverId, caseid: caseItem.CaseID, callID: callId });
        setIncomingCall(false)
        setStartCall(false)
        setCallAccepted(false)
        setRemoteStream(null)
        setMute(false)
        setMuted(false)
        setNotes([])
        setShowNotes(false);
        setUnreadMessages(0)

        if (userType === "Lawyer" && notes.length > 0) {
            const orderedMessages = await createOrderedMessageString(notes)
            SocketServices.emit("send_message", {
                message: orderedMessages,
                receiver_username: `client_${caseItem.CaseID}`
            })
        }

        if (userType === "Lawyer" && Object.keys(meeting).length > 0) {
            dispatch(updateMeetingStatus(caseItem.CaseID,meeting.MeetingID))
        }

        if (myStream) {
            myStream.getTracks().forEach((track) => {
                track.stop();
            });
        }
        if (remoteStream) {
            remoteStream.getTracks().forEach((track) => {
                track.stop();
            });
        }

        SocketServices.emit("chat_list", { caseid: caseItem.CaseID, receiver_username: userType === "Client" ? `client_${caseItem.CaseID}` : `lawyer_${caseItem.CaseID}` })
    }, [myStream, remoteStream, caller, recieverId, caseItem.CaseID, callId, userType, notes, meeting, dispatch])

    const muteMyStream = useCallback(() => {
        if (mute) {
            SocketServices.emit('unmute', { username: caller, reciever_username: recieverId, caseid: caseItem.CaseID })
            setMute(false)
        } else {
            SocketServices.emit('mute', { username: caller, reciever_username: recieverId, caseid: caseItem.CaseID })
            setMute(true)
        }

    }, [caller, recieverId, caseItem.CaseID, mute])

    const handleMuteEvent = useCallback(() => {
        if (muted) {
            setMuted(false)
        } else {
            setMuted(true)
        }
    }, [muted])

    const handleCallId = useCallback(({ callID }: { callID: string }) => {
        setCallId(callID)
    }, [])


    const handleCallRejected = useCallback(async () => {
        setIncomingCall(false)
        setStartCall(false)
        setCallAccepted(false)
        setRemoteStream(null)
        setMute(false)
        setMuted(false)
        setNotes([])
        setShowNotes(false);
        setUnreadMessages(0)


        if (userType === "Lawyer" && notes.length > 0) {
            const orderedMessages = await createOrderedMessageString(notes)
            SocketServices.emit("send_message", {
                message: orderedMessages,
                receiver_username: `client_${caseItem.CaseID}`
            })
        }

        if (userType === "Lawyer" && Object.keys(meeting).length > 0) {
            dispatch(updateMeetingStatus(caseItem.CaseID,meeting.MeetingID))
        }

        if (myStream) {
            myStream.getTracks().forEach((track) => {
                track.stop();
            });
        }
        if (remoteStream) {
            remoteStream.getTracks().forEach((track) => {
                track.stop();
            });
        }
        SocketServices.emit("chat_list", { caseid: caseItem.CaseID, receiver_username: userType === "Client" ? `client_${caseItem.CaseID}` : `lawyer_${caseItem.CaseID}` })

    }, [myStream, remoteStream, userType, caseItem.CaseID, notes,dispatch,meeting])

    useEffect(() => {
        peer.addEventListener("track", async (ev: RTCTrackEvent) => {
            const remoteStream = ev.streams;
            setRemoteStream(remoteStream[0]);
        });
    }, [peer]);

    const handleToggleVideo = useCallback(() => {

        if (isRemoteVideoEnabled) {
            setIsRemoteVideoEnabled(false)
        } else {
            setIsRemoteVideoEnabled(true)
        }
    }, [isRemoteVideoEnabled])

    const handleMeetingNotes = useCallback(({ msg }: { msg: string }) => {
        setNotes(messages => [...messages, { id: (messages.length + 1), Message: msg, timestamp: new Date(), attachments: [], sender: "lawyer" }]);
        if (!showNotes) {
            setUnreadMessages(unreadMessages => unreadMessages + 1)
        }
    }, [showNotes])

    useEffect(() => {
        SocketServices.on("incoming_call", handleIncommingCall);
        SocketServices.on("call_accepted", handleCallAccepted);
        SocketServices.on("negotiation_needed", handleNegoNeedIncomming);
        SocketServices.on("negotiation_final", handleNegoNeedFinal);
        SocketServices.on("call_rejected", handleCallRejected)
        SocketServices.on('mute', handleMuteEvent)
        SocketServices.on('unmute', handleMuteEvent)
        SocketServices.on("recieve_callId", handleCallId)
        SocketServices.on("toggle-video", handleToggleVideo)
        SocketServices.on('recieve-meeting-notes', handleMeetingNotes)


        return () => {
            SocketServices.removeListener("incoming_call");
            SocketServices.removeListener("call_accepted");
            SocketServices.removeListener("negotiation_needed");
            SocketServices.removeListener("negotiation_final");
            SocketServices.removeListener("call_rejected")
            SocketServices.removeListener('mute')
            SocketServices.removeListener('unmute')
            SocketServices.removeListener("recieve_callId")
            SocketServices.removeListener("toggle-video")
            SocketServices.removeListener('recieve-meeting-notes')

        };
    }, [
        handleIncommingCall,
        handleCallAccepted,
        handleNegoNeedIncomming,
        handleNegoNeedFinal,
        handleCallRejected,
        handleMuteEvent,
        handleCallId,
        handleToggleVideo,
        handleMeetingNotes
    ]);

    const toggleVideo = useCallback(() => {
        if (myStream) {
            myStream.getVideoTracks().forEach(track => track.enabled = !isVideoEnabled);
            setIsVideoEnabled(!isVideoEnabled);
            SocketServices.emit("toggle-video", { username: caller, reciever_username: recieverId, caseid: caseItem.CaseID })
        }
    }, [myStream, isVideoEnabled, caller, recieverId, caseItem.CaseID])


    const handleSubmit = (e: FormEvent) => {
        e.preventDefault();
        if (!note) {
            return;
        }
        setNotes(messages => [...messages, { id: (messages.length + 1), Message: note, timestamp: new Date(), attachments: [], sender: "client" }]);
        SocketServices.emit("send-meeting-notes", { message: note, caseid: caseItem.CaseID, reciever_username: `client_${caseItem.CaseID}` })
        setNote('')
    }


    const Message: React.FC<MessageProps> = ({ msg }) => {
        const messageClass = msg.sender
            ? msg.sender
            : userType === 'Lawyer'
                ? msg.SenderID === `lawyer_${caseItem.CaseID}`
                    ? 'client'
                    : 'lawyer'
                : msg.SenderID === `lawyer_${caseItem.CaseID}`
                    ? 'lawyer'
                    : 'client';

        return (
            <>
                <div
                    key={msg.id}
                    className={`message__marketplace ${messageClass}`}
                >
                    <div className="meeting-message-content">
                        {msg.Message}

                    </div>
                </div>
            </>
        );
    };

    return (
        <div >
            {
                startCall && (
                    <Dialog open={startCall} onClose={handleRejectCall} className='calling-window'>
                        <div style={{ position: "relative" }}>


                            {remoteStream ? (
                                <div className="remote-video-screen" >
                                    {
                                        isRemoteVideoEnabled ?
                                            (<ReactPlayer
                                                playing
                                                height="100%"
                                                width="100%"
                                                muted={muted}
                                                url={remoteStream}
                                            />) : (
                                                <div className="market-video__calling">
                                                    <img className="market-video__calling--img" src={userInfo.PofilePicture ? userInfo.ProfilePicture : require("../../resources/dummy-profile-pic-male1.jpg")} alt="Profile" />
                                                </div>
                                            )
                                    }

                                </div >
                            ) :
                                <div className="market-video__calling">
                                    <img className="market-video__calling--img" src={userInfo.PofilePicture ? userInfo.ProfilePicture : require("../../resources/dummy-profile-pic-male1.jpg")} alt="Profile" />
                                </div>
                            }

                            {myStream && (
                                <div className="my-video-screen">
                                    {
                                        isVideoEnabled ?
                                            (<ReactPlayer
                                                playing
                                                muted
                                                height="100px"
                                                width="150px"
                                                url={myStream}
                                            />) : (
                                                <img style={{ width: '150px', height: "100px" }} src={userInfo.PofilePicture ? userInfo.ProfilePicture : require("../../resources/dummy-profile-pic-male1.jpg")} alt="Profile" />
                                            )
                                    }

                                </div>
                            )}

                            {
                                remoteStream ? (<>
                                    <div className='ongoing-video-call-btn-container'>
                                        <button onClick={muteMyStream} className={`mute-btn ${mute ? "muted" : ""} `}><i className="fa-solid fa-microphone-slash"></i></button>
                                        <button onClick={handleRejectCall} className='reject-call-btn'><i className="fa-solid fa-phone-slash"></i></button>
                                        <button onClick={toggleVideo} className={`video-btn ${isVideoEnabled ? "" : "video-off"}`}><i className={`fa-solid ${isVideoEnabled ? "fa-video" : "fa-video-slash"}`}></i></button>
                                        <button onClick={toggleShowNotes} className='notes-btn'><i className="fa-solid fa-notes-medical"></i></button>
                                    </div>
                                </>) : (<>
                                    <button onClick={handleRejectCall} className='dismiss-call-btn'><i className="fa-solid fa-phone-slash"></i></button>
                                </>)

                            }
                        </div>

                        {showNotes && (
                            <div className='notes-container'>
                                <h2>Meeting Notes</h2>

                                {
                                    <div className="meeting-container__chat" >
                                        <div className="meeting-message-list__container">
                                            {
                                                notes && notes.length > 0 && notes.map((note: ChatMessage, index) => {
                                                    return (
                                                        <Message key={note.id} msg={note} />
                                                    )
                                                })
                                            }
                                            <div style={{ marginTop: '0px' }} ref={messagesEndRef} />
                                        </div>
                                    </div>
                                }

                                <form onSubmit={handleSubmit} className='message-form__marketplace--row'>
                                    <input
                                        type="text"
                                        placeholder={"Type a message..."}
                                        value={note}
                                        onChange={handleNotesChange}
                                        className="message-input__marketplace"
                                    />
                                    <button type="submit" className="send-button__marketplace">
                                        <i className='fa-solid fa-arrow-up'></i>
                                    </button>
                                </form>
                            </div>
                        )}
                    </Dialog>
                )
            }

            {userType === 'Lawyer' && isMeetingTime && <Button variant="outlined" onClick={handleCallRequest}>Start Meeeting <i className="fa-solid fa-video" style={{ marginLeft: 5 }}></i></Button>}

            {
                incomingCall && (
                    <Dialog open={incomingCall} onClose={handleRejectCall} className='calling-window'>
                        {
                            callAccepted ? (
                                <div style={{ position: 'relative', display: 'flex', alignItems: 'center', flexDirection: "row" }}>
                                    <div style={{ position: "relative" }}>

                                        {remoteStream &&

                                            <div className="remote-video-screen" >
                                                {
                                                    isRemoteVideoEnabled ?
                                                        (<ReactPlayer
                                                            playing
                                                            height="100%"
                                                            width="100%"
                                                            muted={muted}
                                                            url={remoteStream}
                                                        />) : (<div className="market-video__calling">
                                                            <img className="market-video__calling--img" src={userInfo.PofilePicture ? userInfo.ProfilePicture : require("../../resources/dummy-profile-pic-male1.jpg")} alt="Profile" />
                                                        </div>)
                                                }

                                            </div >
                                        }
                                        {myStream && (
                                            <div className="my-video-screen">
                                                {
                                                    isVideoEnabled ?
                                                        (<ReactPlayer
                                                            playing
                                                            muted
                                                            height="100px"
                                                            width="150px"
                                                            url={myStream}
                                                        />) : (
                                                            <img style={{ width: '150px', height: "100px" }} src={userInfo.PofilePicture ? userInfo.ProfilePicture : require("../../resources/dummy-profile-pic-male1.jpg")} alt="Profile" />
                                                        )
                                                }

                                            </div>
                                        )}
                                        <div className='ongoing-video-call-btn-container'>
                                            <button onClick={muteMyStream} className={`mute-btn ${mute ? "muted" : ""} `}><i className="fa-solid fa-microphone-slash"></i></button>
                                            <button onClick={handleRejectCall} className='reject-call-btn'><i className="fa-solid fa-phone-slash"></i></button>
                                            <button onClick={toggleVideo} className={`video-btn ${isVideoEnabled ? "" : "video-off"}`}><i className={`fa-solid ${isVideoEnabled ? "fa-video" : "fa-video-slash"}`}></i></button>
                                            <div className="notification-container">
                                                {
                                                    !showNotes && unreadMessages > 0 && (
                                                        <div className="notification-bubble">
                                                            <span className="unread-msg-count">{unreadMessages}</span>
                                                        </div>
                                                    )
                                                }
                                                <button onClick={toggleShowNotes} className="notes-btn">
                                                    <i className="fa-solid fa-notes-medical"></i>
                                                    <div className="notification-tooltip">You have {unreadMessages} unread messages</div>
                                                </button>
                                            </div>
                                        </div>

                                    </div>



                                    {showNotes && (
                                        <div className='notes-container'>
                                            <h2>Meeting Notes</h2>
                                            {
                                                <div className="meeting-container__chat" >
                                                    <div className="meeting-message-list__container">
                                                        {
                                                            notes && notes.length > 0 && notes.map((note: ChatMessage, index) => {
                                                                return (
                                                                    <Message key={note.id} msg={note} />
                                                                )
                                                            })
                                                        }
                                                        <div style={{ marginTop: '0px' }} ref={messagesEndRef} />
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                    )}

                                </div>

                            ) : (
                                <>
                                    <div className="market-video__calling">
                                        <img className="market-video__calling--img" src={userInfo.PofilePicture ? userInfo.ProfilePicture : require("../../resources/dummy-profile-pic-male1.jpg")} alt="Profile" />
                                        <div className='incoming-video-call-btn-container'>
                                            <button onClick={() => { sendStreams(); setCallAccepted(true) }} className='accept-call-btn'><i className="fa-solid fa-phone"></i></button>
                                            <button onClick={handleRejectCall} className='reject-call-btn'><i className="fa-solid fa-phone-slash"></i></button>


                                        </div>
                                    </div>
                                </>
                            )
                        }



                    </Dialog>
                )
            }
        </div>
    );
};

export default VideoCallComponent;
