export interface SignUpState {
    firstName: string;
    lastName: string;
    email: string;
    phoneNumber: string;
    address: {
      streetAddress: string;
      country: string;
      zipCode: string;
      city: string;
      state: string;
    };
  }
  
  interface SignUpAction {
    type: string;
    payload: Partial<SignUpState>; 
  }
  
  const initialState: SignUpState = {
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    address: {
      streetAddress: "",
      country: "",
      zipCode: "",
      city: "",
      state: "",
    },
  };
  

  const signUp = (state: SignUpState = initialState, action: SignUpAction) => {
    switch (action.type) {
      case "SIGNUP":
        return {
          ...state,
          ...action.payload,
        };
        case 'STORE_USER_INFO':
          return {
            ...state,
            ...action.payload,
          };
  
      default:
        return state;
    }
  };
  

  export default signUp