import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState, useAppDispatch } from '../../store';
import { getUserID, getUserType } from '../../store/actions/ChatAction';
import { getCompanySettings, updateCompanySettings } from '../../store/actions/CompanyAction';
import './Settings.css';

interface FormData {
  UserID: string;
  UserType: string;
  CompanyLogo: File | null;
  CompanyName: string;
  PhoneNumber: string;
  OfficeAddress: string;
  Website: string;
  ContactEmail: string;
  Description: string;
}

const CompanySettings: React.FC = () => {
  const dispatch = useAppDispatch();
  const companySettings = useSelector((state: RootState) => state.company.settings);
  const [editing, setEditing] = useState(false);
  const [formData, setFormData] = useState<FormData>({
    UserID: getUserID(),
    UserType: getUserType(),
    CompanyLogo: null,
    CompanyName: '',
    PhoneNumber: '',
    OfficeAddress: '',
    Website: '',
    ContactEmail: '',
    Description: '',
  });
  const userId = getUserID();
  const userType = getUserType();

  useEffect(() => {
    dispatch(getCompanySettings(userId ,userType));
  }, [dispatch,userType]);

  useEffect(() => {
    if (companySettings) {
      setFormData({
        UserID: getUserID(),
        UserType: getUserType(),
        CompanyLogo: null,
        CompanyName: companySettings.CompanyName || '',
        PhoneNumber: companySettings.PhoneNumber || '',
        OfficeAddress: companySettings.OfficeAddress || '',
        Website: companySettings.Website || '',
        ContactEmail: companySettings.ContactEmail || '',
        Description: companySettings.Description || '',
      });
    }
  }, [companySettings]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setFormData(prevState => ({ ...prevState, [name]: value }));
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      setFormData(prevState => ({ ...prevState, CompanyLogo: e.target.files![0] }));
    }
  };

  const handleSubmit = async () => {
    const updateData = new FormData();
    updateData.append('UserID', formData.UserID);
    updateData.append('UserType', formData.UserType);
    updateData.append('CompanyName', formData.CompanyName);
    updateData.append('PhoneNumber', formData.PhoneNumber);
    updateData.append('OfficeAddress', formData.OfficeAddress);
    updateData.append('Website', formData.Website);
    updateData.append('ContactEmail', formData.ContactEmail);
    updateData.append('Description', formData.Description);
    if (formData.CompanyLogo) {
      updateData.append('CompanyLogo', formData.CompanyLogo);
    } else {
      updateData.append('CompanyLogo', ''); // Append empty string if no logo
    }

    await dispatch(updateCompanySettings(updateData));
    dispatch(getCompanySettings(userId ,userType));
    setEditing(false);
  };

  return (
    <div className="company-settings-container">
      <div className='company-settings-container__header'>
        <h2>Company Settings</h2>
        <div className="company-photo">
          <label htmlFor="company_photo" className={`photo-label ${editing ? '' : 'normal'}`}>
            {editing ? (
              <div className="photo-upload">
                {formData.CompanyLogo ? (
                  <img src={URL.createObjectURL(formData.CompanyLogo)} alt="Company Logo" className="photo-preview" />
                ) : (
                  <div className="photo-placeholder">
                    <p>Add Company Image</p>
                  </div>
                )}
                <input type="file" id="company_photo" name="company_photo" onChange={handleFileChange} />
              </div>
            ) : (
              <img
                src={companySettings?.Logo || 'https://via.placeholder.com/150'}
                alt="Company"
                className="photo-preview"
              />
            )}
          </label>
        </div>
      </div>
      <div className="company-settings-content">
        <div className='company-settings-container__header--row'>
          <p>{companySettings?.CompanyName}</p>
        </div>
        <div className="company-info">
          <div className='company-info--grid'>
            <div className="company-info-field">
              <label htmlFor="PhoneNumber">Phone Number</label>
              {editing ? (
                <input
                  type="text"
                  name="PhoneNumber"
                  value={formData.PhoneNumber}
                  onChange={handleInputChange}
                />
              ) : (
                <p>{companySettings?.PhoneNumber}</p>
              )}
            </div>
            <div className="company-info-field">
              <label htmlFor="ContactEmail">Contact Email</label>
              {editing ? (
                <input
                  type="text"
                  name="ContactEmail"
                  value={formData.ContactEmail}
                  onChange={handleInputChange}
                />
              ) : (
                <p>{companySettings?.ContactEmail}</p>
              )}
            </div>
            <div className="company-info-field">
              <label htmlFor="OfficeAddress">Office Address</label>
              {editing ? (
                <input
                  type="text"
                  name="OfficeAddress"
                  value={formData.OfficeAddress}
                  onChange={handleInputChange}
                />
              ) : (
                <p>{companySettings?.OfficeAddress}</p>
              )}
            </div>
            <div className="company-info-field">
              <label htmlFor="Website">Website</label>
              {editing ? (
                <input
                  type="text"
                  name="Website"
                  value={formData.Website}
                  onChange={handleInputChange}
                />
              ) : (
                <p>{companySettings?.Website}</p>
              )}
            </div>
          </div>
          <div className="company-info-field">
            <label htmlFor="Description">Description</label>
            {editing ? (
              <textarea
                name="Description"
                value={formData.Description}
                onChange={handleInputChange}
                style={{ height: '50px' }}
              />
            ) : (
              <p style={{ minHeight: '50px' }}>{companySettings?.Description}</p>
            )}
          </div>
          <div>
            {editing ? (
              <button onClick={handleSubmit}>Save</button>
            ) : (
              <button onClick={() => setEditing(true)}>Edit</button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CompanySettings;
