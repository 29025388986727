import React from 'react';
import './infoSectionTest.css';
import { Link } from "react-router-dom";
import GlobalDropdown from '../shared/GlobalDropdown';
import { isAnalysisPricing, isCasePricing, isContractPricing, isLawfirmPricing, isLicensePricing, isSessionPricing, isSubscriptionPricing, Pricing } from '../PlanCard/PricingPlans';
import paymentApis from '../../utils/paymentApis';
import { createCheckoutSession } from '../../store/actions/StripeActions';

interface InfoSectionProps {
    isOpen?: boolean,
    onClose: () => void,
    data: {
        plan: Pricing | null,
        interval: string,
        email: string | ""
    } | null,
}

const InfoSectionText: React.FC<InfoSectionProps> = ({ isOpen, onClose, data }) => {
    var displayPrice = ""
    var savingPrice = ""
    var currency = ""
    let reqAmount = '0.00'

    const renderListItems = (items: string) => {
        return items.split(", ").map((item, index) => (
            <li key={index}>
                {item.includes("Everything") ? (
                    <span className='li-item'><i className='fas fa-check-double'></i> {item}</span>
                ) : (
                    <span className='li-item'><i className='fas fa-check'></i> {item}</span>
                )}
            </li>
        ));
    };

    const calculateSavings = (plan: Pricing) => {
        if (data && data.plan) {
            let oldPrice = parseFloat(data?.interval === 'year' && data.plan.AnnualOld ? data.plan.AnnualOld : data.plan.OldPrice || "0");
            let currentPrice = parseFloat(isSubscriptionPricing(data.plan) && data?.interval === 'year' ? (data.plan.AnnualFee || "0") : (data.plan.Price || "0"));
            let savings = oldPrice - currentPrice;

            return `${data.plan.CurrencySymbol}${savings.toFixed(2)}`;
        } else {
            return ""
        }

    }
    if (data && data.plan) {
        if (isSubscriptionPricing(data.plan) || isLicensePricing(data.plan)) {
            currency = data.plan.Currency
            if (data.interval === 'year') {
                reqAmount = parseFloat(data.plan.AnnualFee).toFixed(2)
                displayPrice = `${data.plan.CurrencySymbol}${parseInt(data.plan.AnnualFee).toFixed(2)}/year`;
                savingPrice = calculateSavings(data.plan)

            } else {
                displayPrice = `${data.plan.CurrencySymbol}${parseInt(data.plan.MonthlyFee).toFixed(2)}/month`;
                reqAmount = parseFloat(data.plan.MonthlyFee).toFixed(2)

            }
        } else {
            currency = data.plan.Currency
            reqAmount = parseInt(data.plan.Price).toFixed(2)
            if (data.plan.isBulk) {
                displayPrice = `${data.plan.CurrencySymbol}${parseInt(data.plan.Price).toFixed(2)}`;
                savingPrice = calculateSavings(data.plan)
            } else {
                displayPrice = `${data.plan.CurrencySymbol}${parseInt(data.plan.Price).toFixed(2)}`

            }

        }
    }

    const handleSubmit = async (event: React.FormEvent, data: any, currency: string, reqAmount: string) => {
        event.preventDefault(); // Prevent the default form submission behavior
        const payload = {
            currency: currency,
            customer_email: (data && data.email) || "nitin@yopmail.com",
            planName: (data && `${data.plan?.PricingName} Plan`) || "",
            interval: (data && data.interval) || "",
            amount: reqAmount,
            mode: data?.plan?.Type === 'One-Time Purchase' ? "payment" : "subscription"
        };
        console.log(payload);
        const formData = new FormData()

        Object.entries(payload).forEach(([key, value]) => {
            if (value !== undefined && value !== null && value !== "") {
                if (typeof value === 'boolean') {
                    formData.append(key, value ? 'yes' : 'no');
                } else {
                    formData.append(key, value as string);
                }
            }
        });
        console.log("gere");
        const resp: any = await paymentApis({
            method: 'POST',
            url: "stripe/createCheckoutSession",
            headers: {
                'Content-Type': 'multipart/form-data',
            },
            data: formData
        })
        // Handle the response, for example, redirect to the Stripe checkout page
        if(resp && resp.status === 200) {
            let result = resp.data
            if (result.session_url) {
                window.location.href = result.session_url;
            } else {
                console.error("No URL returned from the server.");
            }
    
        }
       
    };

    return (
        <div>
            <div className={`center_panel open-panel`}>
                <button className="close-button" onClick={() => onClose()}><i className='fa-solid fa-xmark'></i></button>
                <div className='header-sec'>
                    <i className="fa-kit fa-logo  company-logo-pay-test" ></i>
                    <h1 style={{ color: "#fff !important" }}>PONS Payments </h1>
                </div>
                <hr />
                <div className='main-section-payment'>
                    <div className='user-info-sec'>
                        <p><i className="fa-solid fa-shield"></i>Where should we send your Invoice?</p>
                        <div className="email-section">
                            <div className='email-sec'>
                                <i className='fa-solid fa-envelope'></i>
                                <input
                                    placeholder='Email'
                                    value={(data && data.email) || "nitin@yopmail.com"}
                                />
                            </div>
                            <button className='change-email-btn'>Change Email</button>
                        </div>
                        <div className='details-sec'>
                            <div className='feature-container'>
                                {data && data.plan ?
                                    (isLawfirmPricing(data.plan)) ? (
                                        <>
                                            <p className="price">{data.plan.PricingName}</p>
                                            <p className='pricing-services__header'>Features</p>
                                            <ul>{renderListItems(data.plan.FeatureList)}</ul>
                                        </>
                                    ) : (
                                        <>
                                            <ul>
                                                {isLicensePricing(data.plan) && <>
                                                    <li className='pricing-services__header'>Services</li>
                                                    {renderListItems(data.plan.IncludedServices)}
                                                    <li className='pricing-services__header'>Features</li>
                                                    {renderListItems(data.plan.AdditionalFeatures)}
                                                </>}
                                            </ul>
                                        </>
                                    ) :
                                    (<p>Loading......</p>)

                                }
                            </div>
                            <div className='plan-btn'>
                                <button className='change-email-btn' onClick={onClose}>Change Plan</button>
                            </div>


                        </div>
                        <form onSubmit={(event) => handleSubmit(event, data, currency, reqAmount)} style={{ width: '100%' }}>
                            {/* <input type="hidden" name="currency" value={currency} />
                            <input type="hidden" name="customer_email" value={(data && data.email) || "nitin@yopmail.com"} />
                            <input type="hidden" name="planName" value={(data && `${data.plan?.PricingName} Plan`) || ""} />
                            <input type="hidden" name="interval" value={(data && data.interval) || ""} />
                            <input type="hidden" name="amount" value={reqAmount} />
                            <input type="hidden" name="mode" value={data?.plan?.Type === 'One-Time Purchase' ? "payment" : "subscription"} /> */}
                            <button type='submit' className='subscribe-btn'>{data?.plan?.Type === 'One-Time Purchase' ? "Buy now" : "Subscribe"}</button>

                        </form>


                        <span className="term-condition-detail">You are purchasing a Standard Licence. By submitting your payment, you agree to the Pons <Link className='link' to="#">
                            Terms & Conditions
                        </Link>
                            {"\u00A0"}and{"\u00A0"}
                            <Link className='link' to="#">
                                Privacy & Policy.
                            </Link>
                        </span>
                    </div>
                    <div className='payment-detail-sec'>
                        <p>Order Summary</p>
                        <div className='package-details'>
                            <span className='plan-name'>
                                {data?.plan?.PricingName}
                                {" "}
                                {data && data.plan && isSubscriptionPricing(data.plan) && <>
                                    {data.plan.FreeTrialDays && `+ ${data.plan.FreeTrialDays} days free trail`}
                                    {data.plan.FreeInqueries && `+ ${data.plan.FreeInqueries} free legal inqueries`}
                                </>}
                                {data && data.plan && isAnalysisPricing(data.plan) && `Max Words ${data.plan.MaxWord}`}
                                {data && data.plan && isSessionPricing(data.plan) && `${data.plan.Inqueries} Inqueries `}
                                {data && data.plan && isCasePricing(data.plan) && `${data.plan.Cases} cases`}
                                {data && data.plan && (isContractPricing(data.plan) && data.plan.Contracts?.length > 0) && `${data.plan.Contracts} Contracts`}
                            </span>
                            <span className='plan-amount'>{`${displayPrice}`}</span>
                        </div>
                        <div className='overall-details'>

                            <div className='package-detail'>
                                <span className='total'>Currency</span>
                                <GlobalDropdown
                                    options={[{ id: "USD", label: "USD" }, { id: "Nok", label: "NOK" }]}
                                    onSelect={() => console.log("swlected")}
                                    selectedLabel={currency}
                                    placeholder="NOK"
                                    style={{ width: '80px', fontSize: "12px", background: "none" }}
                                />
                            </div>
                            <div className='package-detail'>
                                <span className='total'>Total</span>
                                <span className='plan-amount total'>{`${displayPrice.split('/')[0]}`}</span>
                            </div>

                            <hr></hr>
                            {
                                savingPrice && (
                                    <>
                                        <p>{`You save ${savingPrice} every year`}</p>
                                        <hr></hr>
                                    </>
                                )
                            }
                        </div>
                        <img src={"https://jkleinfurniture.com/wp-content/uploads/2020/07/secure-payments-1.png"} alt="stripe"></img>
                    </div>
                </div>
            </div>
        </div>

    );
};

export default InfoSectionText;
