import React, { useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { TextField } from '@mui/material';
import { ReactComponent as Logo } from "../../resources/logo.svg";
import './ForgotPasswordLink.css';

export const ForgotPasswordLink = () => {
  // Formik setup

  const [showPassword, setShowPassword] = useState(false);
  
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  
  const formik = useFormik({
    initialValues: {
      newPassword: '',
      confirmPassword: '',
    },
    validationSchema: Yup.object({
      newPassword: Yup.string()
        .max(255)
        .min(
          8,
          "Password must contain 8 or more characters with at least one of each: uppercase, lowercase, number and special"
        )
        .matches(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
          "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
        )
        .required('New password is required'),
      confirmPassword: Yup.string()
        .oneOf([Yup.ref('newPassword'), undefined], 'Passwords must match')
        .required('Confirm password is required'),
    }),
    onSubmit: (values) => {
      // Here, you'll call the API to actually change the password
    },
  });

  return (
    <div className="forgot-password-container">
      <Logo className="company-logo-reset" />
      <h1 className='forgot-password__header'>Reset your password</h1>
      <form onSubmit={formik.handleSubmit}>
        <div className='forgot-password__textfields'>
        <div>
        <div className='row'>
          <TextField 
            name="newPassword"
            type={showPassword ? "text" : "password"}
            placeholder="New Password"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.newPassword}
            required
            variant='standard'
          />
          <i className={`bi ${showPassword ? "bi-eye-slash" : "bi-eye"}`} onClick={togglePasswordVisibility}></i>
          </div>
          {formik.touched.newPassword && formik.errors.newPassword ? (
            <div>{formik.errors.newPassword}</div>
          ) : null}
        </div>
        <div>
            <div className='row'>
          <TextField 
            name="confirmPassword"
            type={showPassword ? "text" : "password"}
            placeholder="Confirm New Password"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.confirmPassword}
            required
            variant='standard'
            />
          <i className={`bi ${showPassword ? "bi-eye-slash" : "bi-eye"}`} onClick={togglePasswordVisibility}></i>
          </div>
          {formik.touched.confirmPassword && formik.errors.confirmPassword ? (
            <div>{formik.errors.confirmPassword}</div>
          ) : null}
        </div>

        <button className='confirm-forgot-password__btn' type="submit">Confirm</button>
        </div>
      </form>
    </div>
  );
};
