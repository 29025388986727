import React, { useState } from 'react';
import './ChatBot.css';
import MessageList, { Message } from './MessageList';
import LCMPost from './lcm/LCMPost';
import { useTranslation } from 'react-i18next';
import ContractGenerator, { ContractType } from './Contract Generator/ContractGenerator';
import { ServiceAPIResponse } from '../../store/actions/ChatAction';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import { ReactComponent as CaseAnalysisIcon } from '../../resources/PricingIcons/caseanalysisicon.svg'
import CompanyAgent from './CompanyAgent/CompanyAgent';

interface ChatBotProps {
  isDocumentVisible: boolean;
  isFolderStructureVisible: boolean;
  messages: Message[];
  onConfirm: (confirmation: string) => void;
  isLoading: boolean;
  setMessages: React.Dispatch<React.SetStateAction<Message[]>>;
  selectedModel: string;
  setChatName: React.Dispatch<React.SetStateAction<string>>;
  setDocument1: React.Dispatch<React.SetStateAction<File | null>>;
  setDocument2: React.Dispatch<React.SetStateAction<File | null>>;
  Document1?: File | null;
  Document2?: File | null;
  loaderText: string;
  speakText: (textToSpeak: string) => void;
  latestResponse: ServiceAPIResponse | null;
  hasSentFirstMessage: boolean;
  initiateContractGeneration: (contract: ContractType) => void;
}

const ChatBot: React.FC<ChatBotProps> = ({
  isDocumentVisible,
  isFolderStructureVisible,
  messages,
  onConfirm,
  setMessages,
  selectedModel,
  Document1,
  Document2,
  setDocument1,
  setDocument2,
  isLoading,
  loaderText,
  speakText,
  latestResponse,
  initiateContractGeneration,
  hasSentFirstMessage,
}) => {
  const [firstJurisdiction, setFirstJurisdiction] = useState('');
  const [secondJurisdiction, setSecondJurisdiction] = useState('');
  const [isDragOver1, setIsDragOver1] = useState(false);
  const [isDragOver2, setIsDragOver2] = useState(false);
  const isSidebarOpen = useSelector((state: RootState) => state.sidebar.isOpen)
  const { t } = useTranslation();
  const jurisdictions = ['Denmark', 'Norway', 'Sweden', 'United Kingdom', 'United States', 'Finland'];

  const handleFileUpload = (file: File, documentNumber: number) => {
    if (documentNumber === 1) {
      setDocument1(file);
    } else if (documentNumber === 2) {
      setDocument2(file);
    }
  };

  const handleFileInputClick = (documentNumber: number) => {
    const fileInput = document.querySelector(`#file-input-${documentNumber}`) as HTMLInputElement;
    fileInput?.click();
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>, documentNumber: number) => {
    const file = event.target.files && event.target.files[0];
    if (file) {
      handleFileUpload(file, documentNumber);
    }
  };

  const handleDragOver = (e: React.DragEvent<HTMLDivElement>, documentNumber: number) => {
    e.preventDefault();
    if (documentNumber === 1) {
      setIsDragOver1(true);
    } else {
      setIsDragOver2(true);
    }
  };

  const handleDragLeave = (documentNumber: number) => {
    if (documentNumber === 1) {
      setIsDragOver1(false);
    } else {
      setIsDragOver2(false);
    }
  };

  const handleDrop = (e: React.DragEvent<HTMLDivElement>, documentNumber: number) => {
    e.preventDefault();
    e.stopPropagation();
    const file = e.dataTransfer.files[0];

    if (file) {
      handleFileUpload(file, documentNumber);
    } else {
      try {
        const fileData = e.dataTransfer.getData('application/json');
        if (fileData) {
          const data = JSON.parse(fileData);
          if (data.type === 'file' && data.documentURL && data.title) {
            const documentURL = Array.isArray(data.documentURL) ? data.documentURL[0] : data.documentURL;
            const fileName = data.title;
            const newFile = new File([documentURL], fileName); 

            handleFileUpload(newFile, documentNumber);
          }
        }
      } catch (error) {
        console.error('Error parsing dropped file data:', error);
      }
    }

    handleDragLeave(documentNumber);
  };

  const handleRemoveFile = (documentNumber: number) => {
    if (documentNumber === 1) {
      setDocument1(null);
    } else if (documentNumber === 2) {
      setDocument2(null);
    }
  };

  const renderDocumentBox = (documentNumber: number) => {
    const document = documentNumber === 1 ? Document1 : Document2;
    const isDragOver = documentNumber === 1 ? isDragOver1 : isDragOver2;

    return (
      <div
        className={`document-box ${isDragOver ? 'drag-over' : ''}`}
        onDragOver={(e) => handleDragOver(e, documentNumber)}
        onDragLeave={() => handleDragLeave(documentNumber)}
        onDrop={(e) => handleDrop(e, documentNumber)}
      >
        {document ? (
          <div className="document-box__content">
            <p>{document.name}</p>
            <button onClick={() => handleRemoveFile(documentNumber)} className="remove-button">
              {t('Remove')}
            </button>
          </div>
        ) : (
          <div className="document-box__content" onClick={() => handleFileInputClick(documentNumber)}>
            <i className="fa fa-file-contract document-icon"></i>
            <p>{t('Upload Contract')}</p>
          </div>
        )}
        <input
          type="file"
          id={`file-input-${documentNumber}`}
          onChange={(e) => handleFileChange(e, documentNumber)}
          style={{ display: 'none' }}
        />
      </div>
    );
  };

  const renderJurisdictionSelector = (
    selectedJurisdiction: string,
    setSelectedJurisdiction: React.Dispatch<React.SetStateAction<string>>
  ) => (
    <div className="jurisdiction-selector">
      <div className="jurisdiction-select__container">
        <select
          value={selectedJurisdiction}
          id="jurisdiction-select"
          onChange={(e) => setSelectedJurisdiction(e.target.value)}
        >
          <option value="">{t('Select Jurisdiction')}</option>
          {jurisdictions.map((jurisdiction, index) => (
            <option key={index} value={jurisdiction}>
              {jurisdiction}
            </option>
          ))}
        </select>
      </div>
    </div>
  );

  const renderService = () => {
    if (hasSentFirstMessage) {
      return (
        <MessageList
          setMessages={setMessages}
          messages={messages}
          isFolderStructureVisible={isFolderStructureVisible}
          onConfirm={onConfirm}
          isDocumentVisible={isDocumentVisible}
          isLoading={isLoading}
          loaderText={loaderText}
          speakText={speakText}
          latestResponse={latestResponse}
        />
      );
    }

    switch (selectedModel) {
      case 'Legal Advisor':
        return (
          <div className="legal-advisor__container">
            <i className="fa-kit fa-logo"></i>
            <p className="legal-advisor__container--mainp">{t('Your Digital Legal Advisor')} </p>
            <p className="legal-advisor__container--secondp">{t('Empowering you to make informed decisions')}</p>
          </div>
        );
      case 'Case Analysis':
        return (
          <div className="legal-advisor__container">
            <CaseAnalysisIcon style={{ width: '100px', height: '100px', marginBottom: 20 }} />
            <p className="legal-advisor__container--mainp">{t('Analyse the case you are working on.')} </p>
            <p className="legal-advisor__container--secondp">{t('Gain insights from historical legal precedents to support your current cases.')} </p>
          </div>
        );
      case 'Legal Case Mapping':
        return <LCMPost />;
      case 'Cross-Jurisdictional Assistance':
        return (
          <div className="jurisdiction-selector">
            <label>{t('Select countries of jurisdiction to explore')}</label>
            <div className="jurisdiction-selector__container--dropdown">
              {renderJurisdictionSelector(firstJurisdiction, setFirstJurisdiction)}
              {renderJurisdictionSelector(secondJurisdiction, setSecondJurisdiction)}
            </div>
          </div>
        );
      case 'Contract Generation':
        return (
          <div className="legal-advisor__container">
            <ContractGenerator initiateContractGeneration={initiateContractGeneration} />
          </div>
        );
      case 'Contract Analysis':
        return (
          <div className="contract-reviewing-ui">
            <p className="contract-reviewing__description">{t('Upload one contract for analysis or two contracts for comparison')}</p>
            <div className="document-box__container">
              {renderDocumentBox(1)}
              {renderDocumentBox(2)}
            </div>
          </div>
        );
        case 'Company Agent':
          return (
            <CompanyAgent />
          );
        default:
        return null;
    }
  };

  return (
    <div
      className={`chatbot-container ${
        (isDocumentVisible && !isFolderStructureVisible && selectedModel === 'Legal Advisor') ||
        (isDocumentVisible && !isFolderStructureVisible && selectedModel === 'Case Analysis') ||
        (isDocumentVisible && !isFolderStructureVisible && selectedModel === 'Contract Analysis')
          ? ''
          : 'shrink'
      } ${
        (selectedModel === 'Legal Advisor' && isFolderStructureVisible) ||
        (selectedModel === 'Case Analysis' && isFolderStructureVisible) ||
        (selectedModel === 'Contract Analysis' && isFolderStructureVisible)
          ? ''
          : 'expanded'
      }  ${!isFolderStructureVisible && isDocumentVisible ? 'expand-doc' : ''}
      ${!isFolderStructureVisible && isSidebarOpen ? 'expand-half' : ''}
      ${!isFolderStructureVisible && !isSidebarOpen ? 'expand-full' : ''}
      `}
    >
      {renderService()}
    </div>
  );
};

export default ChatBot;
