import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { RootState } from '../../store';
import apis from '../../utils/apis';


export const FETCH_COMPANY_SETTINGS_REQUEST = 'FETCH_COMPANY_SETTINGS_REQUEST';
export const FETCH_COMPANY_SETTINGS_SUCCESS = 'FETCH_COMPANY_SETTINGS_SUCCESS';
export const FETCH_COMPANY_SETTINGS_FAILURE = 'FETCH_COMPANY_SETTINGS_FAILURE';

export const UPDATE_COMPANY_SETTINGS_REQUEST = 'UPDATE_COMPANY_SETTINGS_REQUEST';
export const UPDATE_COMPANY_SETTINGS_SUCCESS = 'UPDATE_COMPANY_SETTINGS_SUCCESS';
export const UPDATE_COMPANY_SETTINGS_FAILURE = 'UPDATE_COMPANY_SETTINGS_FAILURE';


export const fetchCompanySettingsRequest = (): AnyAction => ({
  type: FETCH_COMPANY_SETTINGS_REQUEST,
});

export const fetchCompanySettingsSuccess = (settings: any): AnyAction => ({
  type: FETCH_COMPANY_SETTINGS_SUCCESS,
  payload: settings,
});

export const fetchCompanySettingsFailure = (error: string): AnyAction => ({
  type: FETCH_COMPANY_SETTINGS_FAILURE,
  payload: error,
});

export const updateCompanySettingsRequest = (): AnyAction => ({
  type: UPDATE_COMPANY_SETTINGS_REQUEST,
});

export const updateCompanySettingsSuccess = (settings: any): AnyAction => ({
  type: UPDATE_COMPANY_SETTINGS_SUCCESS,
  payload: settings,
});

export const updateCompanySettingsFailure = (error: string): AnyAction => ({
  type: UPDATE_COMPANY_SETTINGS_FAILURE,
  payload: error,
});


export const getCompanySettings = (userID: string, userType: string) => {
  return async (dispatch: ThunkDispatch<RootState, undefined, AnyAction>) => {
    dispatch(fetchCompanySettingsRequest());
    try {
      const response = await apis({
        method: 'POST',
        url: 'settings/getCompanySettings',
        data: { UserID: userID, UserType: userType },
      });
      const settings = await response.data;
      dispatch(fetchCompanySettingsSuccess(settings));
    } catch (error: any) {
      dispatch(fetchCompanySettingsFailure(error.toString()));
    }
  };
};

export const updateCompanySettings = (companySettings: FormData) => {
    return async (dispatch: ThunkDispatch<RootState, undefined, AnyAction>) => {
      dispatch(updateCompanySettingsRequest());
      try {
      const response = await apis({
        method: 'POST',
        url: 'settings/updateCompanySettings', 
        data: companySettings,
        headers: { 'Content-Type': 'multipart/form-data' },
      });

      const settings = await response.data;
      dispatch(updateCompanySettingsSuccess(settings));
    } catch (error: any) {
      dispatch(updateCompanySettingsFailure(error.toString()));
    }
  };
};
