import React, { useEffect, useState } from 'react'
import { Bar } from 'react-chartjs-2';

interface BarChartProps {
    chartData: any
    chartName: string
    windowWidth: number;
}

const BarChart: React.FC<BarChartProps> = ({ chartData, chartName, windowWidth }) => {

    
    const modifiedChartData = {
        ...chartData,
        datasets: chartData.datasets.map((dataset: any) => ({
            ...dataset,
            borderRadius: 5,
            borderWidth: 0,
            backgroundColor: '#64d2ff'
        })),
    };

    return (
        <div className="" style={{ width: '100%', height: '100%' }} key={windowWidth}>
            <Bar
                data={modifiedChartData}
                options={{
                    responsive: true,
                    maintainAspectRatio: false,
                    scales: {
                        x: {
                            grid: {
                                display: true,
                                drawOnChartArea: true,
                                color: '#222'
                            },
                            ticks: {
                                // Set color to white and make it bold
                                color: '#FFF',
                                font: {
                                    weight: 'bold',
                                },
                            },
                        },
                        y: {
                            grid: {
                                display: true,
                                drawOnChartArea: true,
                                color: '#222'
                            },
                            ticks: {
                                // Set color to white and make it bold
                                color: '#FFF',
                                font: {
                                    weight: 'bold',
                                },
                            },
                        },
                    },
                    plugins: {
                        title: {
                            display: true,
                            text: chartName,
                        },
                        legend: {
                            display: false,
                            position: 'bottom',
                            align: 'center',
                        },
                        tooltip: {
                            enabled: true,
                        },
                    },
                    animation: {
                        duration: 500,
                    },
                    layout: {
                        padding: 20,
                    },
                }}
            />
        </div>
    );
};

export default BarChart
