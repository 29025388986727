import { useCallback, useEffect, useRef, useState } from "react";
import { FileProps } from "../../pages/DocumentsPage";
import './Editor.css'
import { getUserID, getUserType } from "../../store/actions/ChatAction";
import ReactQuill, { Quill } from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { editDocument, fetchDocumentContent, deleteDocument, resetSelectedDocument, fetchDocumentVersion, anonymizeDocument, translateDocument } from '../../store/actions/DocumentsAction';
import { RootState, useAppDispatch } from "../../store";
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import dayjs, { Dayjs } from 'dayjs';
import { showModal, hideModal } from "../../store/actions/modalActions";
import { useTranslation } from "react-i18next";
import React from "react";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { useSelector } from "react-redux";

dayjs.extend(customParseFormat);

const Size = Quill.import('attributors/style/size');
Size.whitelist = ['10px', '12px', '14px', '16px', '18px', '20px', '24px', '30px'];
Quill.register(Size, true);

const Font = Quill.import('formats/font');
Font.whitelist = ['arial',
    'times-new-roman',
    'sans-serif',
    'comic-sans',
    'courier-new',
    'georgia',
    'helvetica',
    'lucida',
    'cursive',
    'monospace',
    'serif',
    'tahoma',
    'verdana',
    'impact',
    'palatino',
    'trebuchet-ms',
    'garamond',
    'brush-script-mt',
    'copperplate',
    'marker-felt',];
Quill.register(Font, true);


interface EditorProps {
    file: FileProps;
    onClose: () => void;
    setEditorOpen: (value: boolean) => void
    onFileDeleted: () => void
}

interface HeaderStructure {
    title: string;
    subheadings?: string[];
}

export const ToggleSwitchTheme: React.FC<{ isOn: boolean; onToggle: () => void }> = ({ isOn, onToggle }) => {
    const { t } = useTranslation()
    return (
        <div className="toggle-switch__theme">
            <button className={`button left ${isOn ? '' : 'active'}`} onClick={onToggle}>{t('Light')}</button>
            <button className={`button right ${isOn ? 'active' : ''}`} onClick={onToggle}>{t('Dark')}</button>
            <div className={`slider-theme ${isOn ? 'right' : ''}`}></div>
        </div>
    );
};

interface DocumentViewerProps {
    documentUrl: any;
}


const DocumentViewer: React.FC<DocumentViewerProps> = ({ documentUrl }) => {
    if (!documentUrl) return <div>No document to display</div>;

    const viewerUrl = `https://docs.google.com/viewer?url=${encodeURIComponent(documentUrl)}&embedded=true`;

    return (
        <iframe
            src={viewerUrl}
            onError={() => alert('Failed to load the document')}
            style={{ width: '100%', height: '100vh' }}
            frameBorder="0"
            title="test"
        ></iframe>
    );
};




const Editor: React.FC<EditorProps> = ({ file, onClose, setEditorOpen, onFileDeleted }) => {
    const [isDropdownAssigneeOpen, setIsDropdownAssigneeOpen] = useState(false);
    const [isDropdownItemOpen, setIsDropdownItemOpen] = useState(false);
    // const [documentUrls, setDocumentUrls] = useState<IDocument[]>([]);
    const [isDarkTheme, setIsDarkTheme] = useState(false);
    const [selectedDate, setSelectedDate] = React.useState<Dayjs | null>(dayjs());
    const [isOutlineOpen, setIsOutlineOpen] = useState(true);
    const [showAssigneeDropdown, setShowAssigneeDropdown] = useState(false);
    const [assignees, setAssignees] = useState(['user1@example.com', 'user2@example.com']);
    const [showShareDropdown, setShowShareDropdown] = useState(false);
    const [shareExamples, setShareExamples] = useState(['user1@example.com', 'user2@example.com']);
    const [showVersionControlDropdown, setShowVersionControlDropdown] = useState(false);
    const isSidebarOpen = useSelector((state: RootState) => state.sidebar.isOpen)
    const [newFile, setNewFile] = useState<File | null>(null)
    const fileInputRef = useRef<HTMLInputElement>(null);
    const [fileVerions, setFileVersions] = useState([])
    const [content, setContent] = useState('');
    const [isModified, setIsModified] = useState(false);
    const [comfortView, setComfortView] = useState(false);
    const dispatch = useAppDispatch()
    const userType = getUserType()
    const [outline, setOutline] = useState<HeaderStructure[]>([]);

    const handleButtonClick = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };

    const [versionExamples, setVersionsExamples] = useState([
        {
            date: '05.03.2024 15:35:14',
            initials: 'DM'
        },
        {
            date: '05.03.2024 13:25:13',
            initials: 'SM'
        },
        {
            date: '04.03.2024 17:12:46',
            initials: 'DM'
        }

    ])

    const [emailInput, setEmailInput] = useState('');
    const [isDeadlineOpen, setIsDeadlineOpen] = useState(false);
    const { t } = useTranslation()

    const handleClose = () => {
        dispatch(resetSelectedDocument());
        onClose();
    };


    const handleFetchVersions = useCallback(async () => {
        const resp: any = await dispatch(fetchDocumentVersion(file.FileID, userType))
        if (resp) {
            console.log(resp);

        }
    }, [file.FileID, dispatch, userType])

    useEffect(() => {
        handleFetchVersions()
        if (file && file.FileUrl) {
            const urls = typeof file.FileUrl === 'string' ? [file.FileUrl] : file.FileUrl;
            const docs = urls.map(url => ({ uri: url }));
            // setDocumentUrls(docs);
        }
    }, [file, handleFetchVersions]);
    // const fontFamilyArr = ["Roboto Condensed", "Times New Roman", "Calibri", "Calibri Light", "Sans-Serif"];
    // let fonts = Quill.import("attributors/style/font");
    // fonts.whitelist = fontFamilyArr;
    // Quill.register(fonts, true);



    const handleDeleteClick = () => {
        dispatch(showModal({
            type: 'confirmation',
            message: t('Are you sure you want to delete this document?'),
            onConfirm: handleConfirmDelete,
            onCancel: handleCancel,
            showModal: true,
        }));
    };

    const handleConfirmDelete = () => {
        if (!file) {
            console.error("No file selected");
            return;
        }
        const userId = getUserID();

        dispatch(deleteDocument(file.FileID, userId, userType, () => {
            setEditorOpen(false)
            onFileDeleted();
        }));

        dispatch(hideModal());
    };

    const handleCancel = () => {
        dispatch(hideModal());
        setEditorOpen(true)
    };


    const addAssignee = () => {
        if (emailInput && !assignees.includes(emailInput)) {
            setAssignees([...assignees, emailInput]);
            setEmailInput('');
        }
    };
    const removeAssignee = (email: string) => {
        setAssignees(assignees.filter(assignee => assignee !== email));
    };
    const addAccess = () => {
        if (emailInput && !shareExamples.includes(emailInput)) {
            setShareExamples([...shareExamples, emailInput]);
            setEmailInput('');
        }
    };
    const removeAccess = (email: string) => {
        setShareExamples(shareExamples.filter(access => access !== email));
    };

    const toggleAssigneeDropdown = () => {
        setShowAssigneeDropdown(!showAssigneeDropdown);
    };

    const toggleShareDropdown = () => {
        setShowShareDropdown(!showShareDropdown)
    }

    const toggleDeadline = () => {
        setIsDeadlineOpen(!isDeadlineOpen)
    }

    const toggleVersionControl = () => {
        setShowVersionControlDropdown(!showVersionControlDropdown)
    }

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        event.preventDefault();
        event.stopPropagation();
        if (event.currentTarget.files && event.currentTarget.files[0]) {
            setNewFile(event.currentTarget.files[0])
        }
    };

    useEffect(() => {

        updateOutline(content);
    }, [content]);

    const toggleOutline = () => {
        setIsOutlineOpen(!isOutlineOpen);
    };


    const updateOutline = (editorContent: string) => {
        const headers = extractHeaders(editorContent);
        setOutline(headers);
    };

    const extractHeaders = (content: string): HeaderStructure[] => {
        const parser = new DOMParser();
        const doc = parser.parseFromString(content, 'text/html');
        const elements = doc.querySelectorAll('h1, h2, h3, h4, h5, h6, span.subheader');
        const headerStructure: HeaderStructure[] = [];

        let currentHeader: HeaderStructure | null = null;

        Array.from(elements).forEach(element => {
            const isSpanSubheader = element.tagName === 'SPAN' && element.classList.contains('subheader');
            const level = isSpanSubheader ? 2 : parseInt(element.tagName.substring(1));

            if (level === 1) {

                if (currentHeader) {
                    headerStructure.push(currentHeader);
                }
                currentHeader = { title: element.textContent || '', subheadings: [] };
            } else if (level === 2) {

                if (currentHeader) {
                    if (!currentHeader.subheadings) {
                        currentHeader.subheadings = [];
                    }
                    currentHeader.subheadings.push(element.textContent || '');
                }
            }

        });

        if (currentHeader) {
            headerStructure.push(currentHeader);
        }

        return headerStructure;
    };


    useEffect(() => {
        if (file && file.FileType === "Template") {
            const urls = Array.isArray(file.FileUrl) ? file.FileUrl : [file.FileUrl];
            // setDocumentUrls(urls.map(url => ({ uri: url })));
        } else if (file && file.FileType !== "Template") {
            DocumentContent();
        }
    }, [file]);


    const toggleComfortView = () => {
        setComfortView(!comfortView);
        if (!comfortView) {
            openFullscreen(document.documentElement);
        } else {
            closeFullscreen();
        }
    };


    const toggleTheme = () => {
        setIsDarkTheme(!isDarkTheme);
    };


    const scrollToHeader = (headerText: string) => {
        const editor = document.querySelector('.ql-editor');
        if (editor) {
            const headers = editor.querySelectorAll('h1, h2, h3, h4, h5, h6');
            for (let header of headers) {
                if (header.textContent === headerText) {
                    header.scrollIntoView({ behavior: 'smooth', block: 'start' });
                    break;
                }
            }
        }
    };



    function openFullscreen(elem: HTMLElement) {
        if ("requestFullscreen" in elem) {
            elem.requestFullscreen();
        } else if ("mozRequestFullScreen" in elem) {
            (elem as any).mozRequestFullScreen();
        } else if ("webkitRequestFullscreen" in elem) {
            (elem as any).webkitRequestFullscreen();
        } else if ("msRequestFullscreen" in elem) {
            (elem as any).msRequestFullscreen();
        }
    }

    function closeFullscreen() {
        if ("exitFullscreen" in document) {
            document.exitFullscreen();
        } else if ("mozCancelFullScreen" in document) {
            (document as any).mozCancelFullScreen();
        } else if ("webkitExitFullscreen" in document) {
            (document as any).webkitExitFullscreen();
        } else if ("msExitFullscreen" in document) {
            (document as any).msExitFullscreen();
        }
    }
    const DocumentContent = async () => {
        const userId = getUserID();
        try {
            let resp: any = await dispatch(fetchDocumentContent(userId, file.FileID, userType))
            if (resp) {
                setContent(resp.content);
                const base64Content = resp.content;
                const blob = convertBase64ToBlob(base64Content);
                const url = URL.createObjectURL(blob);
                // setDocumentUrls([{ uri: url }]);
            }
        } catch (error) {
            console.error('Error fetching document content:', error);
        }
    };

    const convertBase64ToBlob = (base64: string) => {
        const byteCharacters = atob(base64);
        const byteArrays = [];
        for (let offset = 0; offset < byteCharacters.length; offset += 512) {
            const slice = byteCharacters.slice(offset, offset + 512);
            const byteNumbers = new Array(slice.length);
            for (let i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }
            const byteArray = new Uint8Array(byteNumbers);
            byteArrays.push(byteArray);
        }
        return new Blob(byteArrays, { type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' });
    };

    const handleSave = async () => {
        const userId = getUserID();
        if (newFile) {
            dispatch(showModal({
                type: "confirm-upload",
                message: 'Upload File, Choose what this file will do!',
                onConfirm: (selectedOption?: string | File) => {
                    console.log(selectedOption, "jhjkhkjh");

                },
                showModal: true,
            }))
        } else {
            dispatch(editDocument(userId, file.FileID, file.FileName, content, userType))
        }
    };

    const handleContent = (text: string) => {
        setIsModified(true)
        setContent(text)
    }

    <div id="toolbar">
        <span className="ql-formats">
            <select className="ql-font">
                <option value="arial">{t('Arial')}</option>
                <option value="comic-sans">{t('Comic Sans')}</option>
                <option value="courier-new">{t('Courier New')}</option>
                <option value="georgia">{t('Georgia')}</option>
                <option value="helvetica">{t('Helvetica')}</option>
                <option value="lucida">{t('Lucida')}</option>
                <option value="cursive">{t('Cursive')}</option>



            </select>
            <select className="ql-size">
                <option value="10px">10{t('px')}</option>
                <option value="12px">12{t('px')}</option>
                <option value="14px">14{t('px')}</option>
                <option value="16px">16{t('px')}</option>
                <option value="18px">18{t('px')}</option>
                <option value="20px">20{t('px')}</option>
                <option value="24px">24{t('px')}</option>
                <option value="30px">30{t('px')}</option>
            </select>

        </span>
    </div>

    const darkTheme = createTheme({
        palette: {
            mode: 'dark',
        },
    });

    const handleTranslateDocument = async () => {
        if (!file || !file.FileUrl) {
          console.error("No file or file URL available for translation");
          return;
        }
        
        const desiredLanguage = prompt("Enter the desired language for translation:");
        if (desiredLanguage) {
          const fileUrls = Array.isArray(file.FileUrl) ? file.FileUrl : [file.FileUrl];
          const fileUrl = fileUrls.find((url: string) => url.endsWith('.pdf') || url.endsWith('.docx') || url.endsWith('.rtf'));
      
          if (!fileUrl) {
            console.error("No valid file URL available for translation");
            return;
          }
      
          const translated = await dispatch(translateDocument(fileUrl, desiredLanguage));
          console.log(translated);
        }
      };
      
    const handleAnonymizeDocument = async () => {
        if (!file.FileUrl) {
            console.error("No file URL available for anonymization");
            return;
        }
    
        const fileUrls = Array.isArray(file.FileUrl) ? file.FileUrl : [file.FileUrl];
        const pdfUrl = fileUrls.find((url: string) => url.endsWith('.pdf'));
        const rtfUrl = fileUrls.find((url: string) => url.endsWith('.rtf'));
        const docxUrl = fileUrls.find((url: string) => url.endsWith('.docx'));
    
        if (!pdfUrl) {
            const response = await dispatch(anonymizeDocument(docxUrl));
            console.error("No PDF file URL available for anonymization");
            return;
        }
        if (!pdfUrl && !docxUrl) {
            const response = await dispatch(anonymizeDocument(rtfUrl));
            console.error("No PDF or DOCX file URL available for anonymization");
            return;
        }
        
        const response = await dispatch(anonymizeDocument(pdfUrl));
        console.log(response);
    };
    
    return (
        <>
            <div className={`editor-overlay`}>
                <div className={`editor ${!isSidebarOpen ? 'expanded' : ''}`}>
                    {file.FileType ? (
                        <div className={`editor-sidebar__wrapper ${isOutlineOpen ? 'open' : ''}`}>

                            {!isOutlineOpen && (
                                <i onClick={toggleOutline} className={`fa-regular fa-list-radio`}></i>

                            )}

                            <div className={`editor-sidebar ${isOutlineOpen ? 'open' : ''}`}>
                                <div className="editor-sidebar-content">
                                <div className="editor-sidebar__header">
                                    <h1>{t('Outline')}</h1>
                                    {/* <div className="editor-sidebar__spacer"></div> */}
                                    <i onClick={toggleOutline} className="fa-regular fa-chevron-left"></i>
                                </div>
                                    {outline.map((header, index) => (
                                        <div key={index}>
                                            <div className="outline-item" >
                                                <h2 onClick={() => scrollToHeader(header.title)}>
                                                    {header.title}
                                                </h2>
                                                {header.subheadings && header.subheadings.map((subheading, subIndex) => (
                                                    <div key={subIndex} className="outline-subitem" onClick={() => scrollToHeader(subheading)}>
                                                        <li>
                                                            {subheading}
                                                        </li>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>

                        </div>
                    ) : (
                        <div title="Close Reader" onClick={handleClose} className="reader-lcm__close">
                            <i className="fas fa-xmark"></i>
                        </div>
                    )}



                    <div className={`editor-content `}>
                        {file.FileType === "Template" ? (
                            <DocumentViewer documentUrl={file.FileUrl} />
                        ) : (
                            <>
                                <div id="toolbar">
                                    <span className="ql-formats">
                                        <select className="ql-font">
                                            <option value="arial">{t('Arial')}</option>
                                            <option value="times-new-roman">{t('Times New Roman')}</option>
                                            <option value="comic-sans">{t('Comic Sans')}</option>
                                            <option value="courier-new">{t('Courier New')}</option>
                                            <option value="georgia">{t('Georgia')}</option>
                                            <option value="helvetica">{t('Helvetica')}</option>
                                            <option selected value="lucida">{t('Lucida')}</option>
                                            <option value="cursive">{t('Cursive')}</option>

                                            <option value="grey-qo">{t('Grey Qo')}</option>
                                        </select>
                                        <select className="ql-size">
                                            <option value="10px">10{t('px')}</option>
                                            <option value="12px">12{t('px')}</option>
                                            <option value="14px">14{t('px')}</option>
                                            <option value="16px">16{t('px')}</option>
                                            <option value="18px">18{t('px')}</option>
                                            <option value="20px">20{t('px')}</option>
                                            <option value="24px">24{t('px')}</option>
                                            <option value="30px">30{t('px')}</option>
                                        </select>
                                        <select className="ql-header" defaultValue="3">
                                            <option value="1">{t('Heading')}</option>
                                            <option value="2">{t('Subheading')}</option>
                                            <option value="3">{t('Normal')}</option>
                                        </select>
                                    </span>
                                    <span className="ql-formats">
                                        <button className="ql-bold" />
                                        <button className="ql-italic" />
                                        <button className="ql-underline" />
                                        <button className="ql-strike" />
                                    </span>
                                    <span className="ql-formats">
                                        <button className="ql-list" value="ordered" />
                                        <button className="ql-list" value="bullet" />
                                        <button className="ql-indent" value="-1" />
                                        <button className="ql-indent" value="+1" />
                                    </span>
                                    <span className="ql-formats">
                                        <button className="ql-script" value="super" />
                                        <button className="ql-script" value="sub" />
                                        <button className="ql-blockquote" />
                                        <button className="ql-direction" />
                                    </span>
                                    <span className="ql-formats">
                                        <select className="ql-align" />
                                        <select className="ql-color" />
                                        <select className="ql-background" />
                                    </span>
                                    <span className="ql-formats">
                                        <button className="ql-link" />
                                        <button className="ql-image" />
                                        <button className="ql-video" />
                                    </span>
                                    <span className="ql-formats">
                                        <button className="ql-formula" />
                                        <button className="ql-code-block" />
                                        <button className="ql-clean" />
                                    </span>
                                    <span className="ql-formats">
                                        <button className="ql-undo">
                                            <i style={{ color: '#fff', fontSize: 16, marginTop: 2 }} className="fas fa-rotate-left"></i>
                                        </button>
                                        <button className="ql-redo">
                                            <i style={{ color: '#fff', fontSize: 16, marginTop: 2 }} className="fas fa-rotate-right"></i>
                                        </button>
                                    </span>
                                </div>

                                <div style={{ position: 'relative', height: '100%' }} className={comfortView ? 'editor-comfort-view' : ''}>
                                    <div className={`${comfortView ? 'editor-comfort-view-content' : ''} ${comfortView ? 'editor-fullscreen' : ''}`} style={{ position: 'relative', height: '100%' }}>

                                        <ReactQuill
                                            theme="snow"
                                            value={content}
                                            // readOnly={!isEditing} 
                                            onChange={handleContent}
                                            modules={{ toolbar: "#toolbar" }}
                                            className={`editor-textarea ${isDarkTheme ? 'dark' : 'light'} ${comfortView ? 'comfort' : ''}`}
                                        />
                                    </div>
                                    {comfortView && (
                                        <button className="exit-comfort-view-btn" onClick={toggleComfortView}><i className="fa-regular fa-chevron-left"></i></button>
                                    )}

                                </div>
                            </>
                        )}
                    </div>

                    <div className="editor-actions" style={{ display: file.FileType === "Template" ? 'none' : '' }}>

                        <div className="editor-actions__top--section">
                            <header className="editor-header">
                                <h2 className="editor-title">{file.FileName}</h2>
                            </header>
                            <button
                                onClick={handleSave}
                                className="action-btn save"
                                disabled={!isModified}
                            >
                                {t('Save')}
                            </button>


                            <button className="action-btn" onClick={toggleAssigneeDropdown}>
                                <span>{t('Add Assignees')}</span>
                                <i className="fa-solid fa-file-signature"></i>
                            </button>

                            {showAssigneeDropdown && (
                                <div className="assignee-dropdown">
                                    <div className="input-group">
                                        <input
                                            type="text"
                                            className="search-assignee"
                                            placeholder="Assignee E-Mail..."
                                            value={emailInput}
                                            onChange={e => setEmailInput(e.target.value)}
                                        />
                                        <button className="add-assignee-btn" onClick={addAssignee}><i className="fa-solid fa-plus"></i></button>
                                    </div>
                                    <ul className="assignee-list">
                                        {assignees.map((assignee, index) => (
                                            <li key={index} className="assignee-item">
                                                <p title={assignee}>{assignee}</p>
                                                <button className="remove-assignee-btn" onClick={() => removeAssignee(assignee)}><i className="fa-solid fa-xmark"></i></button>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            )}


                            <button className="action-btn" onClick={toggleShareDropdown}>
                                <span> {t('Share Access')}</span>
                                <i className="fa-regular fa-share-nodes"></i>
                            </button>

                            {showShareDropdown && (
                                <div className="assignee-dropdown">
                                    <div className="input-group">
                                        <input
                                            type="text"
                                            className="search-assignee"
                                            placeholder="Enter E-Mail..."
                                            value={emailInput}
                                            onChange={e => setEmailInput(e.target.value)}
                                        />
                                        <button className="add-assignee-btn" onClick={addAccess}><i className="fa-solid fa-plus"></i></button>
                                    </div>
                                    <ul className="assignee-list">
                                        {shareExamples.map((example, index) => (
                                            <li key={index} className="assignee-item">
                                                <p title={example}>{example}</p>
                                                <button className="remove-assignee-btn" onClick={() => removeAccess(example)}><i className="fa-solid fa-xmark"></i></button>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            )}
                            <button className="action-btn" onClick={toggleVersionControl}>
                                <span>
                                    {t('Version Control')}
                                </span>
                                <i className="fa-regular fa-code-compare"></i>
                            </button>

                            {showVersionControlDropdown && (
                                <div className="assignee-dropdown">

                                    <ul className="assignee-list">
                                        {fileVerions && fileVerions.length > 0 ?  (fileVerions.map((version: any, index) => (
                                            <li key={index} className="version-item">
                                                <span className="version-item__date">{version.date}</span>

                                                <span className="version-item__initials">{version.initials}</span>


                                            </li>
                                        ))): (
                                            <p>{t("No verions available")}</p>
                                        )
                                    
                                    }
                                    </ul>
                                </div>
                            )}


                            <button className="action-btn" onClick={handleButtonClick}>
                                <input id="fileUploadInput" ref={fileInputRef} type="file" onChange={handleFileChange} style={{ display: 'none' }} />
                                {newFile ? (
                                    <div className="uploaded-new-file--docs">
                                        <span>{newFile.name}</span>
                                        <button type="button" onClick={() => setNewFile(null)}>
                                            <i className="fas fa-times"></i>
                                        </button>
                                    </div>
                                ) : (
                                    <>
                                        <span>{t('Upload Document')}</span>
                                        <i className="fa-solid fa-cloud-arrow-up"></i>
                                    </>
                                )}

                            </button>

                            {userType !== "Client" && (
                                <button className="action-btn">
                                    <span>{t('Translate')}</span>
                                    <i className="fa-solid fa-globe"></i>

                                </button>
                            )}

                            <button className="action-btn deadline" onClick={toggleDeadline}>
                                <span>{t('Set Deadline')}</span>
                                <i className="fa-regular fa-calendar-check"></i>
                            </button>

                            {isDeadlineOpen && (
                                <div className='date-picker-container'>
                                    <ThemeProvider theme={darkTheme}>
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <DatePicker
                                                value={selectedDate}
                                                onChange={(newValue) => {
                                                    setSelectedDate(newValue);
                                                }}

                                            />
                                        </LocalizationProvider>
                                    </ThemeProvider>
                                </div>
                            )}

                            <button onClick={toggleComfortView} className="action-btn">

                                {comfortView ? 'Exit Reading Mode' : <><span>{t('Reading Mode')}</span> <i className="fa-regular fa-presentation-screen"></i></>}
                            </button>
                            <button className="action-btn" onClick={handleTranslateDocument}>
                    <span>{t('Translate')}</span>
                    <i className="fa-solid fa-globe"></i>
                </button>

                <button className="action-btn" onClick={handleAnonymizeDocument}>
                    <span>{t('Anonymize')}</span>
                    <i className="fa-solid fa-user-secret"></i>
                </button>
                            <button className="action-btn action-btn__sign ">
                                <span>{t('Sign Document')}</span>
                                <i className="fa-kit fa-sign-document"></i>
                            </button>

                            {/* <div className="document-tag__container">
                                <div className="document-tag__row">
                                    <p>{t('Tags')}</p>
                                    <button className="add-tag__btn">{t('Add New')}</button>
                                </div>

                                <div className="document-tag__display">
                                    <span>12.03.2024</span>
                                    <span>david@pons.io</span>
                                    <span>sebastian@pons.io</span>
                                    <span>upload.pdf</span>


                                </div>
                            </div> */}
                        </div>

                        <div className="editor-actions__bottom--section">
                            <div className="theme-toggle">
                                <ToggleSwitchTheme isOn={isDarkTheme} onToggle={toggleTheme} />
                            </div>
                            <div className="editor-actions-bottom">
                                <button className="action-btn delete" onClick={handleDeleteClick}>
                                    <span>{t('Delete')}</span>
                                    <i className="fa-regular fa-trash-can"></i>
                                </button>
                                <button className="action-btn">
                                    <span>{t('Download')}</span>
                                    <i className="fa-solid fa-arrow-down-to-line"></i>
                                </button>
                                <button onClick={handleClose} className="action-btn">
                                    <span>{t('Close')}</span>
                                    <i className="fa-regular fa-xmark-circle"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};


export default Editor;