import Settings from "../components/Settings/Settings";

function SettingsPage() {
    return (
        <div style={{ height: 'calc(100vh - 100px)', overflowY: 'auto', scrollbarColor: '#64d2ff #111', scrollbarWidth: 'thin' }}>
            <Settings />
        </div>
    )
}

export default SettingsPage;