import { useEffect, useState } from 'react'
import PieChart from '../Charts/PieChart';
import Chart from "chart.js/auto";
import { CategoryScale, Legend, LineElement, LinearScale, PointElement, Title, Tooltip } from 'chart.js';
import './Charts.css'
import BarChart from '../Charts/BarChart';
import LineChart from '../Charts/LineChart';
import { getUserType } from '../../store/actions/ChatAction';
import HeatmapChart from '../Charts/HeatmapChart';
import RadarMapChart from '../Charts/RadarMapChart';
import GaugeChart from '../Charts/GaugeChart';

Chart.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

function Charts() {

    const initialLabels = ["Legal Case Mapping", "Legal Advisor", "Contract Generator", "Contract Analysis",];
    const [timePeriod, setTimePeriod] = useState('week');
    const [Labels, setLables] = useState<string[] | number[]>([])
    const userType = getUserType();
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
      const handleResize = () => {
        setWindowWidth(window.innerWidth); 
      };
  
      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
    }, []);
    
    

    function generateHeatmapColors(baseColors: string[], steps: number) {
        const colorArray = [];
        const gradientSteps = steps - 1;

        for (let i = 0; i < baseColors.length - 1; i++) {
            const startColor = baseColors[i];
            const endColor = baseColors[i + 1];

            for (let j = 0; j < gradientSteps; j++) {
                const ratio = j / gradientSteps;
                const newColor = interpolateColor(startColor, endColor, ratio);
                colorArray.push(newColor);
            }
        }

        colorArray.push(baseColors[baseColors.length - 1]);

        return colorArray;
    }

    function interpolateColor(color1: string, color2: string, ratio: number) {
        const hex = (color: string) => parseInt(color.slice(1), 16);
        const r = Math.ceil(((hex(color2) >> 16) - (hex(color1) >> 16)) * ratio + (hex(color1) >> 16));
        const g = Math.ceil((((hex(color2) >> 8) & 0xff) - ((hex(color1) >> 8) & 0xff)) * ratio + ((hex(color1) >> 8) & 0xff));
        const b = Math.ceil(((hex(color2) & 0xff) - (hex(color1) & 0xff)) * ratio + (hex(color1) & 0xff));
        return `#${((r << 16) | (g << 8) | b).toString(16).padStart(6, '0')}`;
    }

    const baseColors = ['#0000FF', '#00FF00', '#FFFF00', '#FF0000'];
    const colorArray = generateHeatmapColors(baseColors, 20);

    function generateData(count: number, obj: { min: number, max: number }) {
        const data = Array.from({ length: count }, () => Math.floor(Math.random() * (obj.max - obj.min + 1) + obj.min));
        return data;
    }

    const radarOptions = {
        series: [{
            name: 'Series 1',
            data: Array.from({ length: 6 }, () => Math.floor(Math.random() * 10)),
        }],
        options: {
            fill: {
                color: "#64d2ff",
                opacity: 0.9,
                type: 'solid',
            },
            chart: {
                type: 'radar',
                toolbar: {
                    show: false
                },
            },
            title: {
                text: 'Service utilization rate',
                align: 'center',
                margin: 40,
                offsetX: 0,
                offsetY: 0,
                floating: true,
                style: {
                    fontSize: '20px',
                    fontWeight: 'bold',
                    fontFamily: undefined,
                    color: '#FFFFFF'
                },

            },
            xaxis: {
                categories: ['January', 'February', 'March', 'April', 'May', 'June'],
                labels: {
                    style: {
                        colors: "#FFFFFF",
                        fontSize: '12px',
                        fontFamily: '"Poppins", sans-serif',
                        fontWeight: 400,
                        cssClass: 'apexcharts-xaxis-label',
                    }
                }
            },
            yaxis: {
                show: false
            }
        },
    }
    const chartOptions = {
        series: [{
            name: 'Department1',
            data: generateData(18, {
                min: 0,
                max: 90
            })
        },
        {
            name: 'Department2',
            data: generateData(18, {
                min: 0,
                max: 90
            })
        },
        {
            name: 'Department3',
            data: generateData(18, {
                min: 0,
                max: 90
            })
        },
        {
            name: 'Department4',
            data: generateData(18, {
                min: 0,
                max: 90
            })
        },
        {
            name: 'Department5',
            data: generateData(18, {
                min: 0,
                max: 90
            })
        },
        {
            name: 'Department6',
            data: generateData(18, {
                min: 0,
                max: 90
            })
        },
        {
            name: 'Department7',
            data: generateData(18, {
                min: 0,
                max: 90
            })
        },
        {
            name: 'Department8',
            data: generateData(18, {
                min: 0,
                max: 90
            })
        },
        {
            name: 'Department9',
            data: generateData(18, {
                min: 0,
                max: 90
            })
        }
        ],
        options: {
            chart: {
                // height: 350,
                type: 'heatmap',
                toolbar: {
                    show: true
                },
            },
            dataLabels: {
                enabled: false
            },
            colors: colorArray,
            xaxis: {
                tickPlacement: 'between',
                labels: {
                    show: true,
                    rotate: -45,
                    rotateAlways: false,
                    hideOverlappingLabels: true,
                    showDuplicates: false,
                    trim: false,
                    minHeight: undefined,
                    maxHeight: 120,
                    style: {
                        colors: "#FFFFFF",
                        fontSize: '12px',
                        fontFamily: '"Poppins", sans-serif',
                        fontWeight: 400,
                        cssClass: 'apexcharts-xaxis-label',
                    },
                    offsetX: 0,
                    offsetY: 0,
                    format: undefined,
                    formatter: undefined,
                    datetimeUTC: true,
                    datetimeFormatter: {
                        year: 'yyyy',
                        month: "MMM 'yy",
                        day: 'dd MMM',
                        hour: 'HH:mm',
                    },
                },
            },

            yaxis: {
                tickPlacement: 'between',
                labels: {
                    show: true,
                    rotate: 0,
                    rotateAlways: false,
                    hideOverlappingLabels: true,
                    showDuplicates: false,
                    trim: false,
                    minHeight: undefined,
                    maxHeight: 120,
                    style: {
                        colors: "#FFFFFF",
                        fontSize: '10px',
                        fontFamily: '"Poppins", sans-serif',
                        fontWeight: 400,
                        cssClass: 'apexcharts-xaxis-label',
                    },
                    offsetX: 0,
                    offsetY: 0,
                    format: undefined,
                    formatter: undefined,
                    datetimeUTC: true,
                    datetimeFormatter: {
                        year: 'yyyy',
                        month: "MMM 'yy",
                        day: 'dd MMM',
                        hour: 'HH:mm',
                    },
                },
            },
            title: {
                text: 'Employee legal activity',
                align: 'center',
                margin: 10,
                offsetX: 0,
                offsetY: 0,
                floating: false,
                style: {
                    fontSize: '20px',
                    fontWeight: 'bold',
                    fontFamily: undefined,
                    color: '#FFFFFF'
                },
            },
            theme: {
                mode: 'light',
            },
            grid: {
                padding: {
                    right: 20
                }
            }
        },


    };

    const [lineData, setLineData] = useState({
        labels: Labels,
        datasets: [
            {
                label: `Data for ${timePeriod}`,
                data: Labels.map((item => Math.random() * 100)),
                fill: false,
                backgroundColor: '#64d2ff',
                borderColor: '#64d2ff',
            },
        ],
    });

    const [lineData2, setLineData2] = useState({
        labels: Labels,
        datasets: [
            {
                label: `Data for ${timePeriod}`,
                data: Labels.map((item => Math.random() * 100)),
                fill: false,
                backgroundColor: '#64d2ff',
                borderColor: '#64d2ff',
            },
        ],
    });

    const [chartData, setChartData] = useState({
        labels: initialLabels,
        datasets: [
            {
                label: "Usage",
                data: [100, 45, 78, 90, 30, 22, 54],
                backgroundColor: [
                    "#64d2ff"
                ],
                borderColor: "white",
                borderWidth: 2
            }
        ]
    });


    const [chartData2, setChartData2] = useState({
        labels: initialLabels,
        datasets: [
            {
                label: "Usage",
                data: [100, 45, 78, 90, 30, 22, 54],
                backgroundColor: [
                    "#64d2ff"
                ],
                borderColor: "white",
                borderWidth: 2
            }
        ]
    });
    useEffect(() => {
        if (userType === "BusinessAdmin") {
            setChartData({
                labels: initialLabels,
                datasets: [{
                    label: 'Lawyer Hires',
                    data: [100, 45, 78, 90, 30, 22, 54],
                    backgroundColor: ["#64d2ff"],
                    borderColor: 'rgba(255, 99, 132, 1)',
                    borderWidth: 1
                }, {
                    label: 'Document services',
                    data: [50, 60, 70, 80, 20, 13, 43],
                    backgroundColor: ['rgba(54, 162, 235, 0.5)'],
                    borderColor: 'rgba(54, 162, 235, 1)',
                    borderWidth: 1
                }]
            })
        }

        if (userType === "Lawyer") {
            setChartData({
                labels: initialLabels,
                datasets: [{
                    label: 'Consultations',
                    data: [100, 45, 78, 90, 30, 22, 54],
                    backgroundColor: ["#6fd2ff"],
                    borderColor: 'rgba(255, 99, 132, 1)',
                    borderWidth: 1
                }, {
                    label: 'Case wins',
                    data: [50, 60, 70, 80, 20, 13, 43],
                    backgroundColor: ['rgba(54, 162, 235, 0.5)'],
                    borderColor: 'rgba(54, 162, 235, 1)',
                    borderWidth: 1
                },
                {
                    label: 'Document preparation',
                    data: [32, 8, 30, 150, 20, 13, 43],
                    backgroundColor: ['rgba(64, 32, 85, 0.5)'],
                    borderColor: 'rgba(64, 32, 85, 1)',
                    borderWidth: 1
                },
                ]
            })

            setChartData2({
                labels: initialLabels,
                datasets: [{
                    label: 'Contract Analysis',
                    data: [100, 45, 78, 90, 30, 22, 54],
                    backgroundColor: ["#6fd2ff"],
                    borderColor: 'rgba(255, 99, 132, 1)',
                    borderWidth: 1
                }, {
                    label: 'Contract Generation',
                    data: [50, 60, 70, 80, 20, 13, 43],
                    backgroundColor: ['rgba(54, 162, 235, 0.5)'],
                    borderColor: 'rgba(54, 162, 235, 1)',
                    borderWidth: 1
                },
                {
                    label: 'Legal Advisor',
                    data: [32, 8, 30, 15, 20, 13, 43],
                    backgroundColor: ['rgba(64, 32, 85, 0.5)'],
                    borderColor: 'rgba(64, 32, 85, 1)',
                    borderWidth: 1
                },
                {
                    label: 'LCM',
                    data: [3, 8, 0, 10, 20, 13, 27],
                    backgroundColor: ['rgba(4, 89, 125, 0.5)'],
                    borderColor: 'rgba(4, 89, 125, 1)',
                    borderWidth: 1
                },
                ]
            })
        }
    }, [userType])

    const pieLables = userType === "Lawyer" ? ["Civil", "Criminal", "Family Law"] : ["Published", "Off-Marked", "Closed", "In Progress"]
    const [pieData, setPieData] = useState({
        labels: pieLables,
        datasets: [
            {
                label: "Status",
                data: pieLables.map(item => Math.random() * 100),
                backgroundColor: [
                    "green",
                    "#888",
                    "#000",
                    "#64d2ff",
                    "#000"
                ],
                borderWidth: 1
            }
        ]
    })

    const pieLables2 = ["Rating", "Case Type"]
    const [pieData2, setPieData2] = useState({
        labels: pieLables2,
        datasets: [
            {
                label: "Status",
                data: pieLables2.map(item => Math.random() * 100),
                backgroundColor: [
                    "rgba(75,192,192,1)",
                    "#e2f0f1",
                    "#50AF95",
                    "#f3ba2f",
                    "#2a71d0"
                ],
                borderWidth: 1
            }
        ]
    })

    const generateRandomData = () => {
        const newData = initialLabels.map(() => Math.floor(Math.random() * 100));
        const newData2 = initialLabels.map(() => Math.floor(Math.random() * 100));
        const newData3 = initialLabels.map(() => Math.floor(Math.random() * 100));
        const newData4 = initialLabels.map(() => Math.floor(Math.random() * 100));



        const newPieData = pieLables.map(() => Math.floor(Math.random() * 100));
        const newPieData2 = pieLables2.map(() => Math.floor(Math.random() * 100));


        if (userType === "BusinessAdmin") {
            setChartData({
                ...chartData,
                datasets: [
                    {
                        ...chartData.datasets[0],
                        data: newData
                    },
                    {
                        ...chartData.datasets[1],
                        data: newData2
                    }
                ]
            });
        } else if (userType === "Lawyer") {
            setChartData({
                ...chartData,
                datasets: [
                    {
                        ...chartData.datasets[0],
                        data: newData
                    },
                    {
                        ...chartData.datasets[1],
                        data: newData2
                    },
                    {
                        ...chartData.datasets[2],
                        data: newData3
                    }
                ]
            });

            setChartData2({
                ...chartData2,
                datasets: [
                    {
                        ...chartData2.datasets[0],
                        data: newData
                    },
                    {
                        ...chartData2.datasets[1],
                        data: newData2
                    },
                    {
                        ...chartData2.datasets[2],
                        data: newData3
                    },
                    {
                        ...chartData2.datasets[3],
                        data: newData4
                    }
                ]
            });
        }
        else {
            setChartData({
                ...chartData,
                datasets: [
                    {
                        ...chartData.datasets[0],
                        data: newData
                    }
                ]
            });

        }


        setPieData({
            ...pieData,
            datasets: [{
                ...pieData.datasets[0],
                data: newPieData
            }]
        });

        setPieData2({
            ...pieData2,
            datasets: [{
                ...pieData.datasets[0],
                data: newPieData2
            }]
        });
    };

    const onTimePeriodChange = (value: string) => {
        setTimePeriod(value)
    }

    function generateLabelsForTimePeriod(period: string) {
        const now = new Date();
        switch (period) {
            case 'day':
                let days = Array.from({ length: 24 }, (_, i) => `${i}:00`);
                return days
            case 'week':
                const daysOfWeek = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
                const startOfWeek = now.getDate() - now.getDay();
                const labels = daysOfWeek.map((_, i) => {
                    let day = new Date(now.setDate(startOfWeek + i));
                    return daysOfWeek[day.getDay()];
                });
                return labels;
            case 'month':
                const monthsOfYear = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
                return monthsOfYear

            case 'year':

                const currentYear = new Date().getFullYear();
                const lastTenYear = [];

                for (let i = 0; i < 10; i++) {
                    lastTenYear.push(currentYear - i);
                }
                return lastTenYear;
            default:
                return [];
        }
    }


    const fetchDataForTimePeriod = (period: string) => {
        let resp = generateLabelsForTimePeriod(timePeriod)
        return {
            labels: resp,
            datasets: [
                {
                    label: `Data for ${period}`,
                    data: resp.map((item => Math.random() * 100)),
                    fill: false,
                    backgroundColor: 'rgb(100, 210, 255)',
                    borderColor: 'rgba(100, 210, 255, 0.2)',
                },
            ],
        };
    };

    useEffect(() => {
        const data = fetchDataForTimePeriod(timePeriod);
        const data2 = fetchDataForTimePeriod(timePeriod)
        setLineData(data);
        setLineData2(data2)
    }, [timePeriod]);

    var options = {
        series: [(Math.random() * 100).toFixed(0)],
        chart: {
            height: 380,
            type: 'radialBar',
            offsetY: -15
        },
        plotOptions: {
            radialBar: {
                startAngle: -125,
                endAngle: 125,
                hollow: {
                    margin: 0,
                    size: '70%',
                    background: 'none',
                    image: undefined,
                    imageOffsetX: 0,
                    imageOffsetY: 0,
                    position: 'front',
                    dropShadow: {
                        enabled: true,
                        top: 3,
                        left: 0,
                        blur: 4,
                        opacity: 0.24
                    }
                },
                track: {
                    background: '#fff',
                    strokeWidth: '67%',
                    margin: 0, 
                    dropShadow: {
                        enabled: true,
                        top: -3,
                        left: 0,
                        blur: 4,
                        opacity: 0.35
                    }
                },
                dataLabels: {
                    name: {
                        fontSize: '16px',
                        color: undefined,
                        offsetY: -10,
                    },
                    value: {
                        fontSize: '22px',
                        color: "#FFFFFF",
                        offsetY: 0,
                        formatter: function (val: number) {
                            return val + "%";
                        }
                    }
                }
            }
        },
        fill: {
            type: 'gradient',
            gradient: {
                shade: 'dark',
                shadeIntensity: 0.15,
                inverseColors: false,
                opacityFrom: 1,
                opacityTo: 1,
                stops: [0, 50, 65, 91]
            },
        },
        stroke: {
            dashArray: 9,
            lineCap: 'round'
        },
        labels: [],
        yaxis: {
            labels: {
                style: {
                    colors: "#FFFFFF",
                    fontSize: '10px',
                    fontFamily: '"Poppins", sans-serif',
                    fontWeight: 400,
                    cssClass: 'apexcharts-xaxis-label',
                }
            }
        }
    };

    return (
        <>

            {
                userType === "LawfirmAdmin" ? (<>
                    <div className='grid-container'>
                        <div className='chart-container'>
                            <HeatmapChart chartOptions={chartOptions.options} chartSeries={chartOptions.series} />
                        </div>
                    </div>
                </>) : (<>
                    <div className='grid-container'>
                        <div className='chart-container'>
                            <h2 style={{ color: "#fff", fontSize: 20, textAlign: 'center' }}>{userType === "Client" ? "Case status overview" : userType === "BusinessEmployee" ? "Employee case status overview" : userType === "BusinessAdmin" ? "Case status overview" : userType === "Lawyer" ? "Case type distribution" : ""}</h2>
                            <PieChart chartData={pieData} chartName={""} windowWidth={windowWidth} />
                        </div>
                        <div className='chart-container'>
                            <h2 style={{ color: "#fff", fontSize: 20, textAlign: 'center' }}>{userType === "Client" ? "Legal service usage" : userType === "BusinessEmployee" ? "service request frequency" : userType === "BusinessAdmin" ? "Market engagement" : userType === "Lawyer" ? "Income overview" : ""}</h2>
                            <BarChart windowWidth={windowWidth} chartData={chartData} chartName={""} />
                        </div>
                        <div className='chart-container'>
                            {
                                userType === "BusinessAdmin" ? (<>
                                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", width: "100%" }}>
                                        <span></span>

                                        <h2 style={{ color: "#fff", fontSize: 20 }}>{userType === "Client" ? "Signature usage" : userType === "BusinessAdmin" ? "Document signned" : userType === "BusinessEmployee" ? "Document signned" : ""}</h2>
                                        <div>
                                            <select onChange={(e) => onTimePeriodChange(e.target.value)} style={{ padding: '0px', borderRadius: '5px', cursor: 'pointer', background: '#222', color: '#fff' }}>
                                                <option value="week">Week</option>
                                                <option value="month">Month</option>
                                                <option value="year">Year</option>
                                            </select>
                                        </div>

                                    </div>
                                    <LineChart windowWidth={windowWidth} chartData={lineData2} chartName={""} />
                                </>) : userType === "Lawyer" ? (<>
                                    <div className='chart-container'>
                                        <h2 style={{ color: "#fff", fontSize: 20 }}>Client Satisfaction OverView</h2>
                                        <PieChart windowWidth={windowWidth} chartData={pieData2} chartName={""} />
                                    </div>
                                </>) : (<>
                                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", width: "100%", paddingRight: 20, paddingLeft: 20 }}>
                                        <h2 style={{ color: "#fff", fontSize: 20 }}>Referred friends</h2>
                                        <button className='refer-friend__btn'>
                                        <span>Refer a friend</span>
                                        </button>

                                    </div>
                                    <BarChart windowWidth={windowWidth} chartData={chartData} chartName={""} />
                                </>)
                            }

                        </div>
                        <div className='chart-container'>
                            <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", width: "100%", paddingLeft: 20 }}>

                                <h2 style={{ color: "#fff", fontSize: 20 }}>{userType === "Client" ? "Signature usage" : userType === "BusinessAdmin" ? "Overall legal spend" : userType === "BusinessEmployee" ? "Employee Signature usage" : userType === "Lawyer" ? "Client acquisition trends" : ""}</h2>
                                <div>
                                    <select onChange={(e) => onTimePeriodChange(e.target.value)} style={{ padding: '0px', borderRadius: '5px', cursor: 'pointer', background: '#222', color: '#fff' }}>
                                        <option value="week">Week</option>
                                        <option value="day">Day</option>
                                        <option value="month">Month</option>
                                        <option value="year">Year</option>
                                    </select>
                                </div>

                            </div>
                            <LineChart windowWidth={windowWidth} chartData={lineData} chartName={""} />
                        </div>

                        {
                            userType === "BusinessAdmin" && (
                                <>
                                    <div className='chart-container'>
                                        <HeatmapChart chartOptions={chartOptions.options} chartSeries={chartOptions.series} />
                                    </div>

                                    <div className='chart-container'>
                                        <RadarMapChart chartOptions={radarOptions.options} chartSeries={radarOptions.series} />
                                    </div>
                                </>
                            )

                        }

                        {
                            userType === "Lawyer" && (
                                <>

                                    <div className='chart-container'>
                                        <h2 style={{ color: "#fff", fontSize: 20 }}>Legal Service Usage</h2>
                                        <BarChart windowWidth={windowWidth} chartData={chartData2} chartName={""} />

                                    </div>
                                    <div className='chart-container'>
                                        <h2 style={{ color: "#fff", fontSize: 20 }}>Task Completion Rate</h2>
                                        <GaugeChart chartOptions={options} chartSeries={options.series} />
                                    </div>
                                </>
                            )
                        }

                    </div>
                </>)
            }

        </>

    )
}

export default Charts
