import React from 'react'
import ApexCharts from 'react-apexcharts';

interface RadarMapChartProps {
    chartOptions: any;
    chartSeries: any;
}

const RadarMapChart: React.FC<RadarMapChartProps> = ({ chartOptions, chartSeries }) => {
    return (
        <div>
            <ApexCharts
                options={chartOptions}
                series={chartSeries}
                type="radar"
                width={600}
                height={320}
            />
        </div>
    )
}

export default RadarMapChart
