import { FETCH_PRICING_SUCCESS, TOGGLE_PRICING_MODE, TOGGLE_SUBSCRIPTION_PERIOD } from "../actions/SignUpAction";



const initialState = {
  pricingPlans: [],
  isBulk: false,
  isAnnual: false
};

const pricingReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case FETCH_PRICING_SUCCESS:
      return {
        ...state,
        pricingPlans: action.payload
      };
    case TOGGLE_PRICING_MODE:
      return {
        ...state,
        isBulk: !state.isBulk
      };
    case TOGGLE_SUBSCRIPTION_PERIOD:
      return {
        ...state,
        isAnnual: !state.isAnnual
      };
    default:
      return state;
  }
};

export default pricingReducer;
