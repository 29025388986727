import React, { useState } from 'react';
import './OtpInput.css';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';

interface OtpInputProps {
  onSubmit: (data: string | File) => void;
  handleClose: () => void;
}

const OtpInput: React.FC<OtpInputProps> = ({ onSubmit, handleClose }) => {
  const [otp, setOtp] = useState<string[]>(Array(6).fill(''));
  const errorMessage = useSelector((state: RootState) => state.otp.otpErrorMessage);


  const handleChange = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
    const value = e.target.value;
    if (/^[0-9]$/.test(value) || value === '') {
      const newOtp = [...otp];
      newOtp[index] = value;
      setOtp(newOtp);

      // Move focus to the next input
      if (value !== '' && index < 6 - 1) {
        const nextSibling = document.getElementById(`otp-${index + 1}`);
        if (nextSibling) {
          (nextSibling as HTMLInputElement).focus();
        }
      }
    }
  };

  const handleSubmit = () => {
    const otpValue = otp.join('');
    if (onSubmit) {
      onSubmit(otpValue);
    }
  };

  const handleClear = () => {
    setOtp(Array(6).fill(''));
  };


  return (
    <div className="otp-container">
      <div className="otp--wrapper">
        <h1>Verify your Account</h1>
        <div className="info-text">Please enter the 6-digit OTP from your authenticator app.</div>
        <div className="otp-input">
          {otp.map((digit, index) => (
            <input
              key={index}
              id={`otp-${index}`}
              type="text"
              value={digit}
              onChange={(e) => handleChange(e, index)}
              maxLength={1}
            />
          ))}
        </div>
        {errorMessage && <div className="error-message">{errorMessage}</div>}
      </div>
      <div className="btn-cnt">
        <button className="submit-btn" onClick={handleSubmit}>
          Verify
        </button>
        <button className="submit-btn clear" onClick={handleClear}>
          Clear
        </button>
      </div>
    </div>
  );
};

export default OtpInput;
