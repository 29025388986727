import React, { useState, useRef, useEffect } from 'react';
import { useSelector } from 'react-redux';
import './ChatInterface.css';
import { RootState, useAppDispatch } from '../../store';
import { sendMessage } from '../../store/actions/HelpAction';
import { useTranslation } from 'react-i18next';

const ChatInterface: React.FC = () => {
  const [userInput, setUserInput] = useState('');
  const [chatStarted, setChatStarted] = useState(false);
  const [displayedTexts, setDisplayedTexts] = useState<(string | JSX.Element)[]>([]);
  const dispatch = useAppDispatch();
  const messages = useSelector((state: RootState) => state.help.messages);
  const loading = useSelector((state: RootState) => state.help.loadingChat);
  const messageEndRef = useRef<HTMLDivElement | null>(null);
  const [width, setWidth] = useState(400); 
  const [height, setHeight] = useState(400); 
  const minWidth = 350;
  const minHeight = 300;
  const maxWidth = 1000;
  const maxHeight = 650;
  const resizeRef = useRef<HTMLDivElement | null>(null);
  const { t } = useTranslation();

  const formatMessageText = (text: string) => {
    const paragraphs = text.split('\n\n').map((paragraph, paragraphIndex) => {
      const lines = paragraph.split('\n').map((line, lineIndex) => {
        let jsxElements: (JSX.Element | string)[] = [];
  
        if (line.startsWith('### ')) {
          jsxElements.push(<h3 key={`h3-${paragraphIndex}-${lineIndex}`}><strong>{line.substring(4)}</strong></h3>);
        } else if (line.startsWith('#### ')) {
          jsxElements.push(<h4 key={`h4-${paragraphIndex}-${lineIndex}`}><strong>{line.substring(5)}</strong></h4>);
        } else if (line.startsWith('##### ')) {
          jsxElements.push(<h5 key={`h5-${paragraphIndex}-${lineIndex}`}><strong>{line.substring(6)}</strong></h5>);
        } else if (line.startsWith('###### ')) {
          jsxElements.push(<h6 key={`h6-${paragraphIndex}-${lineIndex}`}><strong>{line.substring(7)}</strong></h6>);
        } else if (line.match(/^\d+\.\s/)) {
          const tokens = line.split(/(\*\*.*?\*\*|\*.*?\*|\[doc\d+\])/g);
          tokens.forEach((token, i) => {
            if (token.startsWith('**')) {
              jsxElements.push(<strong key={`strong-${paragraphIndex}-${lineIndex}-${i}`}>{token.slice(2, -2)}</strong>);
            } else if (token.startsWith('*')) {
              jsxElements.push(<em key={`em-${paragraphIndex}-${lineIndex}-${i}`}>{token.slice(1, -1)}</em>);
            } else if (token.match(/\[doc\d+\]/)) {
              jsxElements.push(<span key={`span-${paragraphIndex}-${lineIndex}-${i}`}></span>);
            } else {
              jsxElements.push(token);
            }
          });
          jsxElements = [<li key={`li-${paragraphIndex}-${lineIndex}`}>{jsxElements}</li>];
        } else if (line.startsWith('- ')) {
          const tokens = line.substring(2).split(/(\*\*.*?\*\*|\*.*?\*|\[doc\d+\])/g);
          tokens.forEach((token, i) => {
            if (token.startsWith('**')) {
              jsxElements.push(<strong key={`strong-${paragraphIndex}-${lineIndex}-${i}`}>{token.slice(2, -2)}</strong>);
            } else if (token.startsWith('*')) {
              jsxElements.push(<em key={`em-${paragraphIndex}-${lineIndex}-${i}`}>{token.slice(1, -1)}</em>);
            } else if (token.match(/\[doc\d+\]/)) {
              jsxElements.push(<span key={`span-${paragraphIndex}-${lineIndex}-${i}`}></span>);
            } else {
              jsxElements.push(token);
            }
          });
          jsxElements = [<li key={`li-${paragraphIndex}-${lineIndex}`}>{jsxElements}</li>];
        } else {
          const tokens = line.split(/(\*\*.*?\*\*|\*.*?\*|\[doc\d+\])/g);
          tokens.forEach((token, i) => {
            if (token.startsWith('**')) {
              jsxElements.push(<strong key={`strong-${paragraphIndex}-${lineIndex}-${i}`}>{token.slice(2, -2)}</strong>);
            } else if (token.startsWith('*')) {
              jsxElements.push(<em key={`em-${paragraphIndex}-${lineIndex}-${i}`}>{token.slice(1, -1)}</em>);
            } else if (token.match(/\[doc\d+\]/)) {
              jsxElements.push(<span key={`span-${paragraphIndex}-${lineIndex}-${i}`}></span>);
            } else {
              jsxElements.push(token);
            }
          });
        }
  
        return <div key={`${paragraphIndex}-${lineIndex}`}>{jsxElements}</div>;
      });
  
      return (
        <div key={paragraphIndex}>
          {lines}
        </div>
      );
    });
  
    return <div>{paragraphs}</div>;
  };  
  

  const scrollToBottomImmediate = () => {
    if (messageEndRef.current) {
      messageEndRef.current.scrollIntoView({ behavior: 'auto' });
    }
  };

  useEffect(() => {
    const lastAssistantMessageIndex = messages.map((m, index) => ({ ...m, index }))
      .reverse()
      .find(m => m.sender === 'bot')?.index;

    const updatedMessages = [...messages];

    messages.forEach((message, index) => {
      if (typeof message.text === 'string' && message.sender === 'bot' && index === lastAssistantMessageIndex && !message.animationComplete) {
        let currentLength = 0;
        let fullText = message.text;

        const timer = setInterval(() => {
          currentLength++;
          if (currentLength <= fullText.length) {
            const textSegment = fullText.substring(0, currentLength);
            setDisplayedTexts(prev => {
              const updatedTexts = [...prev];
              updatedTexts[index] = formatMessageText(textSegment);
              return updatedTexts;
            });
            scrollToBottomImmediate();
          } else {
            setDisplayedTexts(prev => {
              const updatedTexts = [...prev];
              updatedTexts[index] = formatMessageText(fullText);
              return updatedTexts;
            });
            clearInterval(timer);
            updatedMessages[index].animationComplete = true;
            setDisplayedTexts(updatedMessages.map((msg) => formatMessageText(msg.text)));
            scrollToBottomImmediate();
          }
        }, 10);
      } else if (message.sender === 'bot' && !message.animationComplete) {
        setDisplayedTexts(prev => {
          const updatedTexts = [...prev];
          updatedTexts[index] = formatMessageText(message.text);
          return updatedTexts;
        });
      } else if (message.sender !== 'bot') {
        setDisplayedTexts(prev => {
          const updatedTexts = [...prev];
          updatedTexts[index] = message.text;
          return updatedTexts;
        });
      }
    });
  }, [messages]);

  useEffect(() => {
    messageEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [messages, loading]);

  const handleSendMessage = () => {
    if (userInput.trim() === '') return;
    setChatStarted(true);
    dispatch(sendMessage(userInput));
    setDisplayedTexts(prev => [...prev, userInput]);
    setUserInput('');
    scrollToBottomImmediate();
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUserInput(event.target.value);
  };

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      handleSendMessage();
    }
  };

  const handleMouseDown = (e: React.MouseEvent<HTMLDivElement>) => {
    e.preventDefault();

    const startX = e.clientX;
    const startY = e.clientY;
    const startWidth = resizeRef.current?.offsetWidth || width;
    const startHeight = resizeRef.current?.offsetHeight || height;

    const doDrag = (dragEvent: MouseEvent) => {
      const newWidth = Math.min(
        Math.max(startWidth - (dragEvent.clientX - startX), minWidth),
        maxWidth
      );
      const newHeight = Math.min(
        Math.max(startHeight + dragEvent.clientY - startY, minHeight),
        maxHeight
      );
      setWidth(newWidth);
      setHeight(newHeight);
    };

    const stopDrag = () => {
      window.removeEventListener('mousemove', doDrag);
      window.removeEventListener('mouseup', stopDrag);
    };

    window.addEventListener('mousemove', doDrag);
    window.addEventListener('mouseup', stopDrag);
  };

  return (
    <div className='chatbot-interface--wrapper'>
    <div
      className="chat-interface"
      style={{ width: `${width}px`, height: `${height}px` }} 
      ref={resizeRef}
    >
      {chatStarted ? (
        <>
          <div className="message-list--support">
            {messages.map((message, index) => (
              <div key={index} className={`message--supportbot ${message.sender}`}>
                {message.sender === 'bot' && (
                  <i className='fas fa-message-bot message-bot-icon'></i>
                )}
                <span>{displayedTexts[index]}</span>
              </div>
            ))}
            {loading && (

              <div className="message--supportbot bot" style={{ backgroundColor: loading && '#171717' }}>
                <i className={`fas fa-message-bot message-bot-icon ${loading ? 'typing-indicator' : ''}`}></i>
                {/* <span className="typing-indicator">{t('...')}</span> */}
              </div>
            )}
            <div ref={messageEndRef}></div>
          </div>
        </>
      ) : (
        <div className="front-screen">
          <i className='fas fa-message-bot'></i>
          <p>{t('PONS Support Bot')}</p>
          <span>{t('Here to help you navigate the platform')}</span>
          <span>{t('Search for or ask me anything!')}</span>
        </div>
      )}
      <div className="prompt-bar">
        <input
          type="text"
          value={userInput}
          onChange={handleInputChange}
          onKeyPress={handleKeyPress}
          placeholder="Type a message..."
          disabled={loading}
        />
        <button onClick={handleSendMessage} disabled={loading}><i className='fas fa-send'></i></button>
      </div>
    </div>
    <div
        className="chat-resize-handle"
        onMouseDown={handleMouseDown}
      >
        <i className="fa-regular fa-arrow-up-right-and-arrow-down-left-from-center"></i>
      </div>
    </div>
  );
};

export default ChatInterface;
