import { AnyAction } from "redux";

const initialState = {
    otpErrorMessage: '',
  };
  
  const otpReducer = (state = initialState, action: AnyAction) => {
    switch (action.type) {
      case 'SET_OTP_ERROR_MESSAGE':
        return {
          ...state,
          otpErrorMessage: action.payload,
        };
      default:
        return state;
    }
  };  
  
  export default otpReducer;
  