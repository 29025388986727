import React, { useEffect, useState } from 'react';
import { Redirect, Route, RouteProps } from 'react-router-dom';
import { useAppDispatch } from './store';
import { getPreferences, userInformation } from './store/actions/DataAction';
import { getUserID, getUserType } from './store/actions/ChatAction';
import { RootState } from './store';
import { useSelector } from 'react-redux';
import { changeLanguage } from './i18n/i18n';
import { hideModal, showModal } from './store/actions/modalActions';
import SocketServices from './utils/SocketServices';


interface PrivateRouteProps extends RouteProps {
  children?: React.ReactNode;
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({ children, ...rest }) => {
  let userCred = localStorage.getItem('token') || sessionStorage.getItem('token');
  let accessToken = ""
  const userId = getUserID();
  const userType = getUserType();
  const preferences = useSelector((state: RootState) => state.lists.preferences);
  const dispatch = useAppDispatch()
  const userInfo = useSelector((state: RootState) => state.user.userInfo)
  const [show, setShow] = useState(false)

  useEffect(() => {
    if (userId !== null) {
      const fetchData = async () => {
        dispatch(userInformation(userType))
        dispatch(getPreferences(userType));
      }
      fetchData()
    }
  }, [userId, userType, dispatch])


  if (userCred) {
    let data = JSON.parse(userCred);
    accessToken = data.token
  }

  useEffect(() => {
    if (preferences && preferences.Language) {
      changeLanguage(preferences.Language);
    }
  }, [preferences]);



  // useEffect(() => {
  //   if (!userInfo.subscription) {
  //     dispatch(showModal({
  //       type: 'subscription-modal',
  //       message: '',
  //       onConfirm: () => { },
  //       onCancel: () => hideModal(),
  //       showModal: true,
  //     }))
  //   }

  // }, [userInfo,dispatch])

  useEffect(() => {
    if (userInfo && userInfo.FirstName) {
    SocketServices.initializeSocket()
    // SocketServices.emit("add user", { fullName: user.FirstName + " " + user.LastName, userId: userId, userType: userType })
    }

  }, [userInfo])

  return (
    <div style={{ background: '#111', overflow: 'hidden' }}>
      <Route
        {...rest}
        render={(props) => {
          return accessToken ? (
            React.cloneElement(children as React.ReactElement, props)
          ) : (
            <Redirect to="/" />
          );
        }}
      />
    </div>
  );
}

export default PrivateRoute;
