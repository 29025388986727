import React, { useState } from 'react';
import './ClientPortal.css';
import SidePanel from './SidePanel';
import AddClientForm from './AddClientForm';

const regularClients = [
  {
    id: 'client1',
    name: 'John Doe',
    cases: 3,
    status: 'Active',
    contact: 'john.doe@example.com',
    phone: '+123456789',
    recentActivity: 'Reviewed Contract for Case #001',
    caseTypes: ['Contract Law', 'Real Estate'],
  },
  {
    id: 'client2',
    name: 'Jane Smith',
    cases: 5,
    status: 'Inactive',
    contact: 'jane.smith@example.com',
    phone: '+987654321',
    recentActivity: 'Consulted on Intellectual Property Rights',
    caseTypes: ['IP Law', 'Corporate Law'],
  },
  {
    id: 'client3',
    name: 'Michael Johnson',
    cases: 2,
    status: 'Active',
    contact: 'michael.j@example.com',
    phone: '+1122334455',
    recentActivity: 'Prepared Documents for Case #010',
    caseTypes: ['Family Law'],
  },
];

const marketplaceClients = [
  {
    id: 'mclient1',
    name: 'Emily Doe',
    rating: 4.5,
    casesCompleted: 8,
    recentActivity: 'Completed Contract Review for Case #008',
  },
  {
    id: 'mclient2',
    name: 'Chris Johnson',
    rating: 4.7,
    casesCompleted: 5,
    recentActivity: 'Provided Corporate Law Consultation for Case #005',
  },
  {
    id: 'mclient3',
    name: 'John Smith',
    rating: 4.9,
    casesCompleted: 10,
    recentActivity: 'Won Litigation for Case #012',
  },
];

const ClientPortal: React.FC = () => {
  const [selectedClient, setSelectedClient] = useState<any | null>(null);
  const [showSidePanel, setShowSidePanel] = useState(false);
  const [isAddingClient, setIsAddingClient] = useState(false); 

  const handleClientClick = (client: any) => {
    setSelectedClient(client);
    setShowSidePanel(true);
    setIsAddingClient(false); 
  };

  const handleAddClientClick = () => {
    setSelectedClient(null); 
    setShowSidePanel(true);
    setIsAddingClient(true); 
  };

  const closeSidePanel = () => {
    setShowSidePanel(false);
    setSelectedClient(null);
    setIsAddingClient(false);
  };

  return (
    <div className="client-portal">
      {/* Client Container */}
      <div className={`backdrop ${showSidePanel ? 'show' : ''}`} onClick={() => setShowSidePanel(false)}></div>
      <div className="client-container">
        <div className="client-header">
          <h2>Clients</h2>
          <button className="add-client-btn" onClick={handleAddClientClick}>
            Add Client
          </button>
        </div>
        <div className="client-list">
          {regularClients.map((client) => (
            <div
              key={client.id}
              className="client-item"
              onClick={() => handleClientClick(client)}
            >
              <h3>{client.name}</h3>
              <p>Cases: {client.cases}</p>
              <p>Status: {client.status}</p>
              <p>Recent Activity: {client.recentActivity}</p>
            </div>
          ))}
        </div>
      </div>

      {/* Right Side Column */}
      <div className="right-column">
        {/* Stats Row */}
        <div className="stats-row">
          <div className="stat-card">
            <h3>Active Clients</h3>
            <p>{regularClients.filter((client) => client.status === 'Active').length}</p>
          </div>
          <div className="stat-card">
            <h3>Completed Cases</h3>
            <p>{marketplaceClients.reduce((acc, client) => acc + client.casesCompleted, 0)}</p>
          </div>
          <div className="stat-card">
            <h3>Rating</h3>
            <p>
              {(
                marketplaceClients.reduce((acc, client) => acc + client.rating, 0) /
                marketplaceClients.length
              ).toFixed(1)}
            </p>
          </div>
        </div>

        {/* Marketplace Clients Container */}
        <div className="marketplace-clients-container">
          <h2>Marketplace Clients</h2>
          <div className="marketplace-client-list">
            {marketplaceClients.map((client) => (
              <div
                key={client.id}
                className="marketplace-client-item"
                onClick={() => handleClientClick(client)}
              >
                <h3>{client.name}</h3>
                <p>Rating: {client.rating}</p>
                <p>Cases Completed: {client.casesCompleted}</p>
                <p>Recent Activity: {client.recentActivity}</p>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* Side Panel for Client Details or Add Client */}
      
        <div className={`side-panel ${showSidePanel ? 'open_panel' : ''}`}>
          <button className="close-btn--sidepanel" onClick={closeSidePanel}>
            <i className="fas fa-chevron-right"></i>
          </button>
          <div style={{ minHeight: 300, overflowY: "auto", padding: 10, scrollbarWidth: 'thin', scrollbarColor: '#64d2ff transparent' }}>
            {isAddingClient ? (
              <AddClientForm onClose={closeSidePanel} />
            ) : (
              <SidePanel client={selectedClient} onClose={closeSidePanel} />
            )}
          </div>
        </div>
    </div>
  );
};

export default ClientPortal;
