import { useCallback, useEffect, useState } from "react";
import Files, { FolderProps } from "../components/Documents/Files";
import SuggestedFilesContainer from "../components/Documents/SuggestedFilesContainer";
import Document from "../components/Documents/Document";
import Editor from "../components/Documents/Editor";
import { useSelector } from 'react-redux';
import { RootState } from '../store/index';
import { useAppDispatch } from '../store/index';
import ApiLoader from "../components/ApiLoader";
import { getUserID, getUserType } from "../store/actions/ChatAction";
import { createFolder, createSubFolder, deleteFolder, fetchStructure } from "../store/actions/FolderAction";
import { useParams } from "react-router-dom";
import { fetchCategories, fetchDocuments } from "../store/actions/DocumentsAction";

export interface ICategory {
    CategoryID: string;
    CategoryName: string;
}
export interface StructureProps extends FolderProps {
    SubFolders: StructureProps[];
    Documents: FileProps[];
  }
  export interface FileProps {
    FileID: string;
    FileName: string;
    FileType: 'Contract' | 'Legal Document' | 'General' | 'Template' | 'Legal_Document';
    FileUrl: string;
    FileWebUrl: string;
    FolderID: string;
    Creation: string;
    Modified: string;
    Status: string;
    UserID: string;
    Expiry?: string;
    UserType: string;
    CaseID?: string; // For Legal Documents
    Assignees?: string[]; // For Contracts
    StartDate?: string; // For Contracts
    SharedWith?: string[];
    EndDate?: string; // For Contracts
    Approvers?: string[]; // For Contracts
    PartiesInvolved?: string[]; // For Contracts
    LegalType?: string; // For Legal Documents
  }
  

function DocumentsPage() {
    const { documentId } = useParams<{ documentId: string }>();
    const [selectedFile, setSelectedFile] = useState<FileProps | null>(null);
    const [editorOpen, setEditorOpen] = useState(false);
    const folders = useSelector((state: RootState) => state.folders.folders);
    const [isLoading, setIsLoading] = useState(false)
    const dispatch = useAppDispatch();
    const userId = getUserID();
    const userType = getUserType()
    const selectedDocument = useSelector((state: RootState) => state.documents.selectedDocument);

    const apiCall = useCallback(async () => {
        if (userId) {
            setIsLoading(true)
            let resp: any = dispatch(fetchStructure());
            if (documentId) {
                const documentDetails = await dispatch(fetchDocuments(userId, documentId, userType));
                setSelectedFile(documentDetails);

            }
            if (resp && resp.length > 0) {
                setIsLoading(false)
            } else {
                setIsLoading(false)
            }
        }
    }, [dispatch, documentId, userId, userType])

    useEffect(() => {
        apiCall();
        dispatch(fetchCategories());
        if (selectedDocument) {
            setSelectedFile(selectedDocument);
            setEditorOpen(true);
        }

    }, [dispatch, apiCall, selectedDocument]);

    const resetSelectedFile = () => {
        setSelectedFile(null);
        apiCall()
    };


    const handleSelectFile = ((file: FileProps) => {
        setSelectedFile(file);
    });

    const handleCreateFolder = async (folderName: string) => {
        console.log("hererre");
        
        if (folderName.trim()) {
            let resp: any = dispatch(createFolder(folderName, userType));
            if (resp) {
                apiCall()
            }
        }
        resetSelectedFile();
    };

    const handleDeleteFolder = async (folderId: string) => {
        const userId = getUserID();
        let resp: any = dispatch(deleteFolder(folderId, userId, userType, () => { }))
        if (resp) {
            apiCall()
        }

    };

    const handleCreateSubFolder = async (folderName: string, folderID: string) => {
        console.log(folderID, folderName);
        if (folderName.trim()) {
            let resp: any = dispatch(createSubFolder(folderName, folderID));
            if (resp) {
                apiCall()
            }
        }
        resetSelectedFile();
    };

 


    if (isLoading) {
        return <div style={{ width: '100%', display: 'flex', justifyContent: 'center', height: '100vh' }}> <ApiLoader /></div>
    }


    return (
        <>

            {editorOpen ? (
                <Editor file={selectedFile!} onClose={() => setEditorOpen(false)} setEditorOpen={setEditorOpen} onFileDeleted={resetSelectedFile} />
            ) : (


                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', gap: 10, height: 'calc(100vh - 120px)', overflow: 'hidden', paddingLeft: 20, paddingRight: 20, paddingBottom: 10, position: 'relative' }}>
                    <div style={{ display: 'flex', flexDirection: 'column', width: '50%', gap: 10, position: 'relative', height: 'calc(100vh - 115px)' }}>

                        <div style={{ height: '100%' }}>
                            <Files onSelectFile={handleSelectFile} initialFolders={folders} handleCreateFolder={handleCreateFolder} handleDeleteFolder={handleDeleteFolder} handleCreateSubFolder={handleCreateSubFolder} />
                        </div>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', width: '50%', gap: 10, position: 'relative', height: 'calc(100vh - 150px)' }}>
                        <div style={{  height: '45%', minHeight: '45%', maxHeight: '45%' }}>
                        <SuggestedFilesContainer
                            setSelectedFile={setSelectedFile}
                            style={{ height: '95%', alignSelf: 'center' }} 
                        />
                        </div>
                        <div style={{ height: '55%',  minHeight: '55%', maxHeight: '55%' }}>
                        <Document file={selectedFile} onOpenDocument={() => setEditorOpen(true)} onFileDeleted={resetSelectedFile} />
                        </div>
                    </div>
                </div>
            )}
        </>
    )
}

export default DocumentsPage;