import  { FC, useEffect, useRef } from 'react'
import './PaymentModal.css'
import InfoSectionText from '../Payment/InforSectionTest'
import { Pricing } from '../PlanCard/PricingPlans'

type PaymentModalProps = {
    isOpen: boolean,
    onClose: () => void,
    data?: {
        plan: Pricing | null,
        interval: string,
        email: string
    }
}

const PaymentModal: FC<PaymentModalProps> = ({ isOpen, onClose, data }) => {
    const topElement = useRef<HTMLDivElement>(null);

    useEffect(() => {
        topElement.current?.scrollIntoView();
    }, []);

    return (
        <div className={`payment-modal ${isOpen ? 'show' : ''}`}>

            <InfoSectionText onClose={onClose} data={data ? data : null} />
        </div>
    )
}

export default PaymentModal
