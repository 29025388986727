import React, { useEffect, useRef, useState } from 'react';
import './CustomDropdownLCM.css';
import { useTranslation } from 'react-i18next';

export interface Option {
  value: string;
  label: string;
}

interface CustomDropdownLCMProps {
  options: Option[] | string[];
  value: string | string[];
  onChange: (value: string | string[]) => void;
  label: string;
  multiSelect?: boolean;
  className?: string;
  error?: string | string[];
  placeholder?: string;
}

export const CustomDropdownLCM: React.FC<CustomDropdownLCMProps> = ({
  options,
  value,
  onChange,
  label,
  className,
  error,
  multiSelect,
  placeholder = 'Select Option'
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const { t } = useTranslation();

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleSelect = (option: Option | string) => {
    const selectedValue = typeof option === 'string' ? option : option.value;
    if (multiSelect) {
      if (Array.isArray(value)) {
        if (value.includes(selectedValue)) {
          onChange(value.filter((val) => val !== selectedValue));
        } else {
          onChange([...value, selectedValue]);
        }
      } else {
        onChange([selectedValue]);
      }
    } else {
      onChange(selectedValue);
      setIsOpen(false);
    }
  };

  const displayValue = Array.isArray(value) && value.length === 0
    ? placeholder
    : !Array.isArray(value) && value === ''
      ? placeholder
      : Array.isArray(value)
        ? value.join(', ')
        : typeof options[0] === 'string'
          ? value
          : (options as Option[]).find((option: Option) => option.value === value)?.label || value;

  return (
    <div className={`custom-dropdown-lcm ${className || ''}`} ref={dropdownRef}>
      <label>{t(label)}</label>
      <div className="dropdown-header-lcm" onClick={() => setIsOpen(!isOpen)}>
        {t(displayValue)}
        <i className={`fa-regular ${isOpen ? 'fa-chevron-up' : 'fa-chevron-down'}`}></i>
      </div>
      {error && (
        <span className='error-message'>{t(error)}</span>
      )}
      {isOpen && (
        <div className="dropdown-list-lcm">
          {Array.isArray(options) && options.map((option, index) => {
            const optionValue = typeof option === 'string' ? option : option.value;
            const optionLabel = typeof option === 'string' ? option : option.label;
            return (
              <div
                className={`dropdown-item-lcm ${Array.isArray(value) && value.includes(optionValue) ? 'selected' : ''}`}
                key={index}
                onClick={() => handleSelect(option)}
              >
                {t(optionLabel)}
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default CustomDropdownLCM;
