import React, { useState } from 'react';
import './FullscreenImageModal.css';

interface FullscreenImageModalProps {
    isOpen: boolean;
    onRequestClose: any;
    images: any;
}

const FullscreenImageModal: React.FC<FullscreenImageModalProps> = ({ isOpen, onRequestClose, images }) => {
    const [currentIndex, setCurrentIndex] = useState(0);

    const goToNext = () => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    };

    const goToPrevious = () => {
        setCurrentIndex((prevIndex) => (prevIndex - 1 + images.length) % images.length);
    };

    if (!isOpen) {
        return null;
    }

    const selectImage = (index: number) => {
        setCurrentIndex(index);
    };

    return (
        <div className={`overlay ${isOpen ? 'show' : ''}`} >
            <div className='images-con'>
                <div className="modal" onClick={(e) => e.stopPropagation()}>
                    <button className="arrow left-arrow" onClick={goToPrevious}>
                        <i className="fa-solid fa-chevron-left"></i>

                    </button>
                    <img
                        src={URL.createObjectURL(images[currentIndex])}
                        alt={images[currentIndex].name}
                        className="modal-image"
                    />
                    <button className="arrow right-arrow" onClick={goToNext}>
                        <i className="fa-solid fa-chevron-right"></i>
                    </button>
                </div>

                <div className="mini-gallery">
                    {images.map((image: any, index: number) => (
                        <img
                            key={index}
                            src={URL.createObjectURL(image)}
                            alt={image.name}
                            className={`mini-image ${index === currentIndex ? 'selected' : ''}`}
                            onClick={() => selectImage(index)}
                        />
                    ))}
                </div>
            </div>

            <button className='close-btn' onClick={onRequestClose}>
                <i className="fa-solid fa-xmark" ></i>
            </button>


        </div>
    );
};

export default FullscreenImageModal;
