import { useEffect, useState } from 'react'
import './PerformanceSection.css'
import RevenueDetailsTable from './RevenueDetailsTable';
import GlobalDropdown from '../../shared/GlobalDropdown';
import { useTranslation } from 'react-i18next';

function PerformanceSection() {
    const [lastFourYears, setLastFourYears] = useState<string[]>([]);
    const [selectedYear, setSelectedYear] = useState(lastFourYears[0]);
    const { t } = useTranslation()

    useEffect(() => {
        let now = new Date();
        let lastFourYears = [];

        for (let i = 0; i < 3; i++) {
            const year = new Date(now.getFullYear() - i, now.getMonth()).toISOString().slice(0, 4);
            lastFourYears.push(year);
        }
        setLastFourYears(lastFourYears);
    }, [])

    const handleYearChange = (yearId: string) => {
        setSelectedYear(yearId);
    };


    return (
        <div className="performance-section" >
            <GlobalDropdown
                options={lastFourYears.map(year => ({ id: year, label: year }))}
                onSelect={handleYearChange}
                selectedLabel={selectedYear}
                placeholder="2024"
                style={{ width: 200 }}
                reverseHeader={false}
            />
           <div className='performance-section__wrapper'>
            <div className='tag-container'>
                <div className='chart-Container-third'>
                    <div className='chart-header' style={{ backgroundColor: '#333', width: '100%', textAlign: 'center', marginTop: 10 }}>
                        <span >{t('Revenue Details')}</span>
                    </div>
                    <div className='chart-body'>
                        <RevenueDetailsTable />
                    </div>
                </div>


            </div>
            </div>

        </div>
    )
}

export default PerformanceSection
