import React, { useCallback, useEffect, useMemo, useState } from 'react';
import ChatList from './ChatList';
import ChatWindow from './ChatWindow';
import ToggleButton from './ToggleButton';
import { ClientCase } from '../data/dummyData';
import './MessageCenter.css';
import { ClipLoader } from 'react-spinners';
import { useSelector } from 'react-redux';
import { RootState, useAppDispatch } from '../../../../store';
import { Lawyer } from '../interface/IFirmLawyer';
import { getAllLawyers } from '../../../../store/actions/LawfirmActions';
import { getUserID } from '../../../../store/actions/ChatAction';
import SocketServices from '../../../../utils/SocketServices';
import { fetchCHatList, fetchMessageList } from '../../../../store/actions/DataAction';

const MessageCenter: React.FC = () => {
  const [selectedChatId, setSelectedChatId] = useState<number | null |string>(null);
  const [isEmployeeView, setIsEmployeeView] = useState(true);
  const [openListView, setOpenListView] = useState(false)
  const [selectedEmployee, setSelectedEmployee] = useState<Lawyer | null>(null)
  const [newChat, setNewChat] = useState(false)
  const { loading, lawyers } = useSelector((state: RootState) => state.lawfirm);
  const dispatch = useAppDispatch();
  const userID = getUserID();
  const date: Date = useMemo(() => new Date(), []);
  const [unreadMessages, setUnreadMessages] = useState(0);
  const [isLoading, setIsLoading] = useState(true)
  const chatList = useSelector((state: RootState) => state.lists.chatList)
  const MessageList = useSelector((state: RootState) => state.lists.MessageList)
  const [messages, setMessages] = useState<any[]>(MessageList)
  const selectedChat: any = chatList.find((chat: any) => chat.ChatID === selectedChatId);

  useEffect(() => {
    SocketServices.initializeSocket()
  }, [])


  useEffect(() => {
    dispatch(getAllLawyers(userID));
    dispatch(fetchCHatList())
  }, [dispatch, userID]);

  const recievePersonalMessage = useCallback((data: any) => {
    const {
      message,
    } = data;

    setMessages(messages => [...messages, { id: (messages.length + 1), content: message, timestamp: date, sender: "" }]);
  }, [date])

  const emitUserJoin = useCallback(() => {
    SocketServices.emit("user_join", { username: userID, caseid: "" });
  }, [userID]);

  const newChatStarted = useCallback((data: any) => {
    const { chat_id } = data
    if (chat_id) {
      dispatch(fetchCHatList())
      setSelectedChatId(chat_id)
    }
  }, [dispatch])

  useEffect(() => {
    emitUserJoin()
    SocketServices.on('recieve_personal_message', recievePersonalMessage)
    SocketServices.on("new_chat_started", newChatStarted)
    return () => {
      SocketServices.removeListener('recieve_personal_message')
      SocketServices.removeListener("new_chat_started")
    };
  }, [userID, date, recievePersonalMessage, emitUserJoin, newChatStarted])

  const handleChatSelect = async (id: number) => {
    setSelectedChatId(id);
    setMessages([])
    const resp: any = await dispatch(fetchMessageList(id))
      if(resp){
        setMessages(resp)
      }
  };


  const handleSendMessage = (message: string) => {
    setMessages(messages => [...messages, { id: (messages.length + 1), content: message, timestamp: date, sender: userID }]);
    SocketServices.emit('send_personal_message', { "ChatID": selectedChatId, SenderID: userID, RecieverID: selectedEmployee?.LawfirmLawyerID ? selectedEmployee?.LawfirmLawyerID : Object.keys(selectedChat).length > 0 ? selectedChat.senderId === userID ? selectedChat.recieverId : selectedChat.senderId : selectedChat.recieverId === userID ? selectedChat.senderId : selectedChat.recieverId, Message: message, GroupID: "" })
    // dispatch(fetchCHatList())
  };



  const handleEmplyeeList = () => {
    setOpenListView(true)
  }

  const handleSelectEmployee = (employee: Lawyer) => {
    setSelectedChatId(null)
    setSelectedEmployee(employee)
    setOpenListView(false)
    setNewChat(true)
    setMessages([])
  }

  const renderEmployee = (employees: Lawyer[]) => (
    <ul className="emp-list">
      {employees.map((employee: any) => (
        <li
          key={employee.id}
          className={`emp-list-item ${selectedChat === employee.LawfirmLawyerID ? 'selected' : ''}`}
          onClick={() => handleSelectEmployee(employee)}
        >
          <div className="emp-item-header">
            <div>
              {employee.ProfilePicture === "" ? (
                <img src={employee.ProfilePicture!} alt="Profile" className="emp-profile-image" />
              ) : (
                <img src={`${employee.ProfilePicture ? employee.ProfilePicture : require("../../../../resources/dummy-profile-pic-male1.jpg")}  `} alt="Profile" className="emp-profile-image" />
              )}

            </div>
            <div className="chat-item-title">
              {employee.FirstName + " " + employee.LastName}
              <div className="chat-item-role">{employee.Email}</div>

            </div>
          </div>
        </li>
      ))}
    </ul>
  );

  return (
    <div className="message-center">
      <div className="message-center--sidebar">
        <ToggleButton isEmployeeView={isEmployeeView} setIsEmployeeView={setIsEmployeeView} />
        <ChatList chatList={chatList} selectedChat={selectedChatId} onChatSelect={handleChatSelect} unreadMessages={unreadMessages} isLoading={isLoading} setIsLoading={setIsLoading} />
        <button className='newChat-btn' onClick={handleEmplyeeList}>New Chat</button>
      </div>
      {openListView && (
        <div className="emp-center--sidebar">
          <div className='emp-center--sidebar-top'>
            <i onClick={() => setOpenListView(false)} className='fas fa-chevron-left icon-hover-effect-message-center'></i>
            <div style={{ width: '90%' }}>
              <div>Create New Group</div>

            </div>
          </div>
          <hr style={{ width: '100%' }}></hr>
          {loading ? (
            <ClipLoader size={30} color='#64d2ff' />
          ) : lawyers.length > 0 ? (
            renderEmployee(lawyers)
          ) : (
            <tr>
              <td colSpan={4} className="no-teams--placeholder">{'No employees'}</td>
            </tr>
          )}
        </div>
      )

      }
      <div className="message-center--main">
        {selectedChat && selectedChatId ? (
          <ChatWindow
            chatId={selectedChatId}
            messages={messages}
            chatName={isEmployeeView ? selectedChat && selectedChat.userData.FullName ? selectedChat.userData.FullName : selectedChat.userData.FirstName + " " + selectedChat.userData.LastName : (selectedChat as ClientCase).caseType}
            onSendMessage={handleSendMessage}
            // MessageList={MessageList}
            // setMessages={setMessages}
          />
        ) : newChat ? (
          <ChatWindow
            chatId={""}
            messages={messages}
            chatName={selectedEmployee ? selectedEmployee.FirstName + " " + selectedEmployee.LastName : ""}
            onSendMessage={handleSendMessage}
            // MessageList={[]}
            // setMessages={setMessages}
          />
        ) : (
          <div className="empty-chat-window">
            <p>Select a chat to start messaging</p>
          </div>
        )
        }
      </div >
    </div >
  );
};

export default MessageCenter;
