import { Configuration } from '@azure/msal-browser';

export const msalConfig: Configuration = {
    auth: {
        clientId: '0bd26934-d21d-4902-af1e-75a5a3513e6e',
        authority: 'https://login.microsoftonline.com/35100afd-c1b4-4a57-86f0-22db19cc815b',
        redirectUri: "http://localhost:3000",
    },
    cache: {
        cacheLocation: 'localStorage',
        storeAuthStateInCookie: false,
    }
};

export const loginRequest = {
    scopes: ['openid', 'profile']
};
