import React from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

// Replace with your actual publishable key
const stripePromise = loadStripe("pk_test_51NUAIpCKZInHFLiaoqKLdIpmsng4qX2W0ylQG7C7SvB6jAmLmUw3brzQUbMdBjHWpPTGyGJFfmbEYIYsZjGvARMu0000tqTUgw");

const StripeContext = ({ children }) => {
    return <Elements stripe={stripePromise}>{children}</Elements>;
};

export default StripeContext;