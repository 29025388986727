import React, { useEffect } from 'react';
import { Employee, ClientCase } from '../data/dummyData';
import './MessageCenter.css';
import { ClipLoader } from 'react-spinners';

interface ChatListProps {
  chatList: (Employee | ClientCase)[];
  selectedChat: number | null | string;
  onChatSelect: (id: number) => void;
  unreadMessages: number | 0;
  isLoading: boolean;
  setIsLoading: (isLoading: boolean) => void
}

const ChatList: React.FC<ChatListProps> = ({ chatList, selectedChat, onChatSelect, unreadMessages,isLoading,setIsLoading}) => {
  useEffect(() => {
    if (chatList.length > 0) {
      setIsLoading(false)
    }
  }, [chatList,setIsLoading])

  return (
    <ul className="chat-list">
      {
        isLoading ? (
          <li
            key={1}
            className={`chat-list-item }`}
          >
            <ClipLoader size={20} color='#fff' />
          </li>
        ) : (
          chatList.map((chat: any) => (
            <li
              key={chat.id}
              className={`chat-list-item ${selectedChat === chat.ChatID ? 'selected' : ''}`}
              onClick={() => onChatSelect(chat.ChatID)}
            >
              <div className="chat-item-header">
                <div className="chat-item-title">
                  {/* {isEmployee(chat) ? chat.name : chat.caseType} */}
                  {
                    chat.userData.FullName ? chat.userData.FullName : chat.userData.FirstName + " " + chat.userData.LastName
                  }
                </div>
                <div className="chat-item-role">{chat.userData.Email}</div>
                <div className="chat-item-last-message">{'lastMessage'}</div>
              </div>
              {
                !selectedChat && unreadMessages > 0 && (
                  <div className="msg-bubble">
                    <span className="unread-msg-count">{unreadMessages}</span>
                  </div>
                )
              }
            </li>
          ))
        )
      }
    </ul>
  );
};

export default ChatList;
