import React from 'react';
import { Team, Member } from './ManageTeam';
import { useTranslation } from 'react-i18next';
import './TeamView.css';

interface TeamViewProps {
  team: Team | null;
}

const TeamView: React.FC<TeamViewProps> = ({ team }) => {
  const { t } = useTranslation();

  if (!team) {
    return <div>{t('No team selected')}</div>;
  }

  return (
    <div className="team-view-container">
      <div>
        <p><strong>{t('Team Lead')}</strong></p>
        <p className='team-view__teamlead'>{team.TeamLeadFirstName} {team.TeamLeadLastName}</p>
      </div>

      <div>
        <p><strong>{t('Legal Subjects')}</strong></p>
        <ul className='team-member__practice-areas'>
          {team.LegalSubjects && team.LegalSubjects.map((subject, index) => (
            <li key={index}>{subject.LegalSubjectName}</li>
          ))}
        </ul>
      </div>

      <h3>{t('Team Members')}</h3>
      <div className="team-members">
        {team.Members && team.Members.map((member: Member) => (
          <div key={member.MemberID} className="team-member">
            <div className='team-member__header'>
              <div>
                <p>{member.MemberFirstName} {member.MemberLastName}</p>
                <p className='team-member__role' style={(member.MemberFirstName === team.TeamLeadFirstName && member.MemberLastName === team.TeamLeadLastName) ? { color: 'rgb(66, 212, 66)' } : {}}>
                  {(member.MemberFirstName === team.TeamLeadFirstName && member.MemberLastName === team.TeamLeadLastName) ? t('Team Lead') : member.MemberRole}
                </p>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default TeamView;
