import React, { useState, useRef, useEffect  } from 'react';
import './PromptBar.css';
import { RootState } from '../../store';
import useMicrophone from './MicrophoneServiceTest';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

interface PromptBarProps {
  onSend: (input: string, file?: File) => void;
  isDocumentVisible: boolean;
  isFolderStructureVisible?: boolean;
  uploadedFile: File | null;
  handleFileDrop: (file: File) => void;
  selectedService: string;
  isLoading?: boolean;
}

interface ISpeechRecognition extends EventTarget {
  continuous: boolean;
  interimResults: boolean;
  onresult: (event: SpeechRecognitionEvent) => void;
  onend: () => void;
  start: () => void;
  stop: () => void;
}

interface SpeechRecognitionEvent {
  results: SpeechRecognitionResultList;
}
interface ISpeechGrammarList {
}


interface SpeechRecognitionResult {
  isFinal: boolean;
  [index: number]: SpeechRecognitionAlternative;
}

interface SpeechRecognitionAlternative {
  transcript: string;
}

interface SpeechRecognitionResultList {
  length: number;
  item: (index: number) => SpeechRecognitionResult;
  [index: number]: SpeechRecognitionResult;
}


declare global {
  interface Window {
    SpeechRecognition: new () => ISpeechRecognition;
    webkitSpeechRecognition: new () => ISpeechRecognition;
    SpeechGrammarList: new () => ISpeechGrammarList;
  }
}


const PromptBar: React.FC<PromptBarProps> = ({ onSend, isLoading }) => {
  const [input, setInput] = useState('');
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const [uploadedFile, setUploadedFile] = useState<File | null>(null);
  const selectedModel = useSelector((state: RootState) => state.model.selectedModel);
  const indexExists = useSelector((state: RootState) => state.companyAgent.indexExists)

  const { text, isListening, startListening, stopListening } = useMicrophone();
  const { t } = useTranslation();
  const wrapperRef = useRef<HTMLDivElement>(null); 

  useEffect(() => {
    if (text) {
      setInput(text);
    }
  }, [text]);

  useEffect(() => {
    adjustTextareaHeight();
  }, [input, text]);


  const handleSend = () => {
    if (input.trim()) {
      let fileToSend;

      try {
        if (uploadedFile) {
          fileToSend = uploadedFile ? uploadedFile : undefined;
        }
        onSend(input, fileToSend);
      } catch (error) {
        console.error('Error:', error);
      }

      setInput('');
      setUploadedFile(null);
    }
  };

  const handleKeyPress = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      handleSend();
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setInput(e.target.value);
  };

  const handleMicrophoneToggle = () => {
    if (isListening) {
      stopListening();
    } else {
      startListening();
    }
  };

  const adjustTextareaHeight = () => {
    if (textareaRef.current && wrapperRef.current) { 
      textareaRef.current.style.height = '5px';
      const maxHeight = 24 * 8; 
      if (textareaRef.current.scrollHeight > maxHeight) {
        textareaRef.current.style.height = `${maxHeight}px`;
        textareaRef.current.style.overflowY = 'scroll';
        wrapperRef.current.classList.add('textarea-max-height'); 
        textareaRef.current.classList.add('textarea-max-height--area');
      } else {
        textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
        textareaRef.current.style.overflowY = 'hidden';
        wrapperRef.current.classList.remove('textarea-max-height'); 
        textareaRef.current.classList.remove('textarea-max-height--area'); 
      }
    }
  };

  const removeUploadedFile = () => {
    setUploadedFile(null);
  };

  return (
    <>
      {uploadedFile && (
        <div className="file-preview__prompt">
          <span>{uploadedFile.name}</span>
          <span onClick={removeUploadedFile}><i className='bi bi-x-square'></i></span>
        </div>
      )}
        <div className={`prompt-bar-container ${isLoading ? 'disabled' : ''}`}>
        <button className="microphone-button" onClick={handleMicrophoneToggle} disabled={isLoading || (!indexExists && selectedModel === "Company Agent")}>
          <i style={{ color: ((isLoading || !indexExists) && selectedModel === "Company Agent") ? '#aaa' : '#64d2ff', cursor: isLoading || (!indexExists && selectedModel === "Company Agent") ? 'not-allowed' : 'pointer' }} className={isListening ? 'bi bi-stop-circle' : 'bi bi-mic-fill'}></i>
        </button>
        <div ref={wrapperRef} className={`textarea-wrapper ${(!indexExists && selectedModel === "Company Agent") ? 'disabled' : ''} ${isLoading ? 'disabled' : ''}`}>
          <textarea
            ref={textareaRef}
            style={{ overflow: 'hidden' }}
            placeholder={`${t('Message PONS')}`}
            value={input}
            onKeyPress={handleKeyPress}
            onChange={handleInputChange}
            onInput={adjustTextareaHeight}
            disabled={isLoading || (!indexExists && selectedModel === "Company Agent")}
          />
          <button className={`send-button embedded`} onClick={handleSend} disabled={isLoading || (!indexExists && selectedModel === "Company Agent") || input.trim().length === 0}>
            <i className={`fa-solid fa-paper-plane-top ${isLoading ? 'disabled' : ''} ${(!indexExists && selectedModel === "Company Agent") ? 'disabled' : ''}`} ></i>
          </button>
        </div>
      </div>
    </>
  );
};

export default PromptBar;
