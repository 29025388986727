import { useEffect, useState, useRef } from "react";

interface ISpeechRecognition extends EventTarget {
    grammars?: SpeechGrammarList;
    lang: string;
    continuous: boolean;
    interimResults: boolean;
    maxAlternatives?: number;
    onaudioend?: ((this: SpeechRecognition, ev: Event) => any) | null;
    onaudiostart?: ((this: SpeechRecognition, ev: Event) => any) | null;
    onend?: ((this: SpeechRecognition, ev: Event) => any) | null;
    onerror?: ((this: SpeechRecognition, ev: SpeechRecognitionErrorEvent) => any) | null;
    onnomatch?: ((this: SpeechRecognition, ev: SpeechRecognitionEvent) => any) | null;
    onresult: ((this: SpeechRecognition, ev: SpeechRecognitionEvent) => any) | null;
    onsoundend?: ((this: SpeechRecognition, ev: Event) => any) | null;
    onsoundstart?: ((this: SpeechRecognition, ev: Event) => any) | null;
    onspeechend?: ((this: SpeechRecognition, ev: Event) => any) | null;
    onspeechstart?: ((this: SpeechRecognition, ev: Event) => any) | null;
    onstart?: ((this: SpeechRecognition, ev: Event) => any) | null;
    start: () => void;
    stop: () => void;
}

const useMicrophone = () => {
    const [text, setText] = useState("");
    const [isListening, setIsListening] = useState(false);
    const microphoneRef = useRef<ISpeechRecognition | null>(null);

    useEffect(() => {
        if ("webkitSpeechRecognition" in window) {
            const SpeechRecognition = window.webkitSpeechRecognition as unknown as { new(): ISpeechRecognition };
            microphoneRef.current = new SpeechRecognition();
            microphoneRef.current.continuous = true;
            microphoneRef.current.interimResults = true;
            microphoneRef.current.lang = 'en-US';

            microphoneRef.current.onresult = (event: any) => {
                for (let i = event.resultIndex; i < event.results.length; ++i) {
                    if (event.results[i].isFinal) {
                        setText(event.results[i][0].transcript);
                    }
                }
            };

            microphoneRef.current.onend = () => {
                setIsListening(false);
            };

            microphoneRef.current.onerror = (event: any) => {
                console.error("Speech recognition error", event);
                setIsListening(false);
            };
        }

        return () => {
            microphoneRef.current?.stop();
        };
    }, []);

    const startListening = () => {
        setText('');
        setIsListening(true);
        microphoneRef.current?.start();
    };

    const stopListening = () => {
        setIsListening(false);
        microphoneRef.current?.stop();
    };

    return {
        text,
        isListening,
        startListening,
        stopListening,
        hasRecognitionSupport: !!microphoneRef.current
    };
};

export default useMicrophone;
