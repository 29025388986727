import React from 'react';
import './MiniMyDocument.css';
import ReactQuill from 'react-quill';

interface MiniMyDocumentProps {
  isFolderStructureVisible: boolean;
  fileContent: string;
  setFileContent: React.Dispatch<React.SetStateAction<string>>; 
  isEditing: boolean;
  toggleDocumentExpansion: () => void;
  isDocumentVisible: boolean;
  toggleFolderStructure: () => void;
  closeFileAndViewFolders: () => void;
  toggleViewMode: () => void;
  isDarkTheme: boolean;
  onEdit: () => void;
}

const MiniMyDocument: React.FC<MiniMyDocumentProps> = ({ fileContent, setFileContent, isEditing, isFolderStructureVisible, isDocumentVisible, toggleViewMode, isDarkTheme, onEdit }) => {

  const handleContentChange = (content: string) => {
    setFileContent(content);
    onEdit();
  };


  return (
    <div className={`mini-my-document-container ${isDocumentVisible ? 'visible' : 'hidden'} ${isFolderStructureVisible ? 'visible' : 'expand'} `}>
      <i className="fas fa-chevron-right exit-button" onClick={toggleViewMode}></i>
        <ReactQuill 
          value={fileContent}
          onChange={handleContentChange}
          readOnly={!isEditing} 
          theme="snow"
          className={`mini-my-document-content ${isDarkTheme ? 'dark' : 'light'}`}

        />
    </div>
);

};

export default MiniMyDocument;
