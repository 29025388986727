import { AnyAction, Dispatch } from "redux";
import apis from "../../utils/apis";
import axios from "axios";
import { getUserID, getUserType } from "./ChatAction";
import { showFeedbackModal } from "./UserFeedbackActions";
import { ThunkDispatch } from "redux-thunk";
import { RootState } from "..";

export const SEND_MESSAGE_REQUEST = 'SEND_MESSAGE_REQUEST';
export const SEND_MESSAGE_SUCCESS = 'SEND_MESSAGE_SUCCESS';
export const SEND_MESSAGE_FAILURE = 'SEND_MESSAGE_FAILURE';
export const RECEIVE_MESSAGE = 'RECEIVE_MESSAGE';

export const FETCH_HELP_REQUEST = 'FETCH_HELP_REQUEST';
export const FETCH_HELP_SUCCESS = 'FETCH_HELP_SUCCESS';
export const FETCH_HELP_FAILURE = 'FETCH_HELP_FAILURE';

export const FETCH_USER_HELP_REQUEST = 'FETCH_USER_HELP_REQUEST';
export const FETCH_USER_HELP_SUCCESS = 'FETCH_USER_HELP_SUCCESS';
export const FETCH_USER_HELP_FAILURE = 'FETCH_USER_HELP_FAILURE';

export const FETCH_CATEGORIES_REQUEST = 'FETCH_CATEGORIES_REQUEST';
export const FETCH_CATEGORIES_SUCCESS = 'FETCH_CATEGORIES_SUCCESS';
export const FETCH_CATEGORIES_FAILURE = 'FETCH_CATEGORIES_FAILURE';

export const CREATE_TICKET_REQUEST = 'CREATE_TICKET_REQUEST';
export const CREATE_TICKET_SUCCESS = 'CREATE_TICKET_SUCCESS';
export const CREATE_TICKET_FAILURE = 'CREATE_TICKET_FAILURE';

export const FETCH_TICKET_REQUEST = 'FETCH_TICKET_REQUEST';
export const FETCH_TICKET_SUCCESS = 'FETCH_TICKET_SUCCESS';
export const FETCH_TICKET_FAILURE = 'FETCH_TICKET_FAILURE';

export const FETCH_ALL_TICKETS_REQUEST = 'FETCH_ALL_TICKETS_REQUEST';
export const FETCH_ALL_TICKETS_SUCCESS = 'FETCH_ALL_TICKETS_SUCCESS';
export const FETCH_ALL_TICKETS_FAILURE = 'FETCH_ALL_TICKETS_FAILURE';

export const FETCH_ALL_USER_TICKETS_REQUEST = 'FETCH_ALL_USER_TICKETS_REQUEST';
export const FETCH_ALL_USER_TICKETS_SUCCESS = 'FETCH_ALL_USER_TICKETS_SUCCESS';
export const FETCH_ALL_USER_TICKETS_FAILURE = 'FETCH_ALL_USER_TICKETS_FAILURE';

export const FETCH_RESOLVED_TICKETS_REQUEST = 'FETCH_RESOLVED_TICKETS_REQUEST';
export const FETCH_RESOLVED_TICKETS_SUCCESS = 'FETCH_RESOLVED_TICKETS_SUCCESS';
export const FETCH_RESOLVED_TICKETS_FAILURE = 'FETCH_RESOLVED_TICKETS_FAILURE';

export const FETCH_UNRESOLVED_TICKETS_REQUEST = 'FETCH_UNRESOLVED_TICKETS_REQUEST';
export const FETCH_UNRESOLVED_TICKETS_SUCCESS = 'FETCH_UNRESOLVED_TICKETS_SUCCESS';
export const FETCH_UNRESOLVED_TICKETS_FAILURE = 'FETCH_UNRESOLVED_TICKETS_FAILURE';

export const RESOLVE_TICKET_REQUEST = 'RESOLVE_TICKET_REQUEST';
export const RESOLVE_TICKET_SUCCESS = 'RESOLVE_TICKET_SUCCESS';
export const RESOLVE_TICKET_FAILURE = 'RESOLVE_TICKET_FAILURE';

export const sendMessage = (userInput: string) => async (dispatch: ThunkDispatch<RootState, undefined, AnyAction>) => {
  dispatch({ type: SEND_MESSAGE_REQUEST, payload: userInput });

  const formData = new FormData();
  formData.append('UserInput', userInput);
  formData.append('UserID', getUserID());
  formData.append('UserType', getUserType());

  try {
    const response = await apis({
      method: 'POST',
      url: 'support/chat',
      data: formData,
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });

    dispatch({ type: SEND_MESSAGE_SUCCESS });
    dispatch({ type: RECEIVE_MESSAGE, payload: response.data.ChatResponse });
  } catch (error: any) {
    dispatch({ type: SEND_MESSAGE_FAILURE, payload: error.message });
  }
};


export const fetchHelp = () => async (dispatch: Dispatch) => {
  dispatch({ type: FETCH_HELP_REQUEST });
  try {
    const response = await apis({
      method: "GET",
      url: "help/getAll"
    });
    dispatch({
      type: FETCH_HELP_SUCCESS,
      payload: response.data
    });
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      dispatch({
        type: FETCH_HELP_FAILURE,
        payload: error.response.data
      });
    } else {
      dispatch({
        type: FETCH_HELP_FAILURE,
        payload: "An unknown error occurred"
      });
    }
  }
};

export const fetchUserHelp = () => async (dispatch: Dispatch) => {
  dispatch({ type: FETCH_USER_HELP_REQUEST });
  const userType = getUserType();
  try {
    const response = await apis({
      method: "POST",
      url: "help/usertype/getList",
      data: { UserType: userType }
    });
    dispatch({
      type: FETCH_USER_HELP_SUCCESS,
      payload: response.data
    });
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      dispatch({
        type: FETCH_USER_HELP_FAILURE,
        payload: error.response.data
      });
    } else {
      dispatch({
        type: FETCH_USER_HELP_FAILURE,
        payload: "An unknown error occurred"
      });
    }
  }
};

export const fetchCategories = () => async (dispatch: Dispatch) => {
  dispatch({ type: FETCH_CATEGORIES_REQUEST });
  try {
    const response = await apis({
      method: "GET",
      url: "support/categories"
    });
    dispatch({
      type: FETCH_CATEGORIES_SUCCESS,
      payload: response.data
    });
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      dispatch({
        type: FETCH_CATEGORIES_FAILURE,
        payload: error.response.data
      });
    } else {
      dispatch({
        type: FETCH_CATEGORIES_FAILURE,
        payload: "An unknown error occurred"
      });
    }
  }
};

export const createTicket = (ticketData: any) => async (dispatch: Dispatch<any>) => {
  dispatch({ type: CREATE_TICKET_REQUEST });
  try {
    const response = await apis({
      method: 'POST',
      url: 'create-ticket',
      data: ticketData,
    });

    dispatch({
      type: CREATE_TICKET_SUCCESS,
      payload: response.data,
    });

    dispatch(showFeedbackModal({
      showModal: true,
      message: 'Ticket successfully created!',
      modalType: 'success',
      duration: 3000,
    }));
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      dispatch({
        type: CREATE_TICKET_FAILURE,
        payload: error.response.data,
      });
      dispatch(showFeedbackModal({
        showModal: true,
        message: error.response.data.message || 'Failed to create ticket. Please try again later.',
        modalType: 'error',
        duration: 5000,
      }));
    } else {
      dispatch({
        type: CREATE_TICKET_FAILURE,
        payload: 'An unknown error occurred',
      });
      dispatch(showFeedbackModal({
        showModal: true,
        message: 'An unknown error occurred. Please try again later.',
        modalType: 'error',
        duration: 5000,
      }));
    }
  }
};

export const fetchTicket = (ticketId: string) => async (dispatch: Dispatch) => {
  dispatch({ type: FETCH_TICKET_REQUEST });
  try {
    const response = await apis({
      method: "GET",
      url: `get-ticket/${ticketId}`
    });
    dispatch({
      type: FETCH_TICKET_SUCCESS,
      payload: response.data
    });
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      dispatch({
        type: FETCH_TICKET_FAILURE,
        payload: error.response.data
      });
    } else {
      dispatch({
        type: FETCH_TICKET_FAILURE,
        payload: "An unknown error occurred"
      });
    }
  }
};

export const fetchAllUserTickets = () => async (dispatch: Dispatch) => {
  dispatch({ type: FETCH_ALL_USER_TICKETS_REQUEST });
  try {
    const response = await apis({
      method: "POST",
      url: "get-ticket/user",
      data: { UserID: getUserID(), UserType: getUserType() }
    });
    dispatch({
      type: FETCH_ALL_USER_TICKETS_SUCCESS,
      payload: response.data
    });
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      dispatch({
        type: FETCH_ALL_USER_TICKETS_FAILURE,
        payload: error.response.data
      });
    } else {
      dispatch({
        type: FETCH_ALL_TICKETS_FAILURE,
        payload: "An unknown error occurred"
      });
    }
  }
}

export const fetchAllTickets = () => async (dispatch: Dispatch) => {
  dispatch({ type: FETCH_ALL_TICKETS_REQUEST });
  try {
    const response = await apis({
      method: "GET",
      url: "admin/get-all-tickets"
    });
    dispatch({
      type: FETCH_ALL_TICKETS_SUCCESS,
      payload: response.data
    });
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      dispatch({
        type: FETCH_ALL_TICKETS_FAILURE,
        payload: error.response.data
      });
    } else {
      dispatch({
        type: FETCH_ALL_TICKETS_FAILURE,
        payload: "An unknown error occurred"
      });
    }
  }
};

export const fetchResolvedTickets = () => async (dispatch: Dispatch) => {
  dispatch({ type: FETCH_RESOLVED_TICKETS_REQUEST });
  try {
    const response = await apis({
      method: "GET",
      url: "admin/get-resolved-tickets"
    });
    dispatch({
      type: FETCH_RESOLVED_TICKETS_SUCCESS,
      payload: response.data
    });
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      dispatch({
        type: FETCH_RESOLVED_TICKETS_FAILURE,
        payload: error.response.data
      });
    } else {
      dispatch({
        type: FETCH_RESOLVED_TICKETS_FAILURE,
        payload: "An unknown error occurred"
      });
    }
  }
};

export const fetchUnresolvedTickets = () => async (dispatch: Dispatch) => {
  dispatch({ type: FETCH_UNRESOLVED_TICKETS_REQUEST });
  try {
    const response = await apis({
      method: "GET",
      url: "admin/get-unresolved-tickets"
    });
    dispatch({
      type: FETCH_UNRESOLVED_TICKETS_SUCCESS,
      payload: response.data
    });
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      dispatch({
        type: FETCH_UNRESOLVED_TICKETS_FAILURE,
        payload: error.response.data
      });
    } else {
      dispatch({
        type: FETCH_UNRESOLVED_TICKETS_FAILURE,
        payload: "An unknown error occurred"
      });
    }
  }
};

export const resolveTicket = (ticketId: string) => async (dispatch: Dispatch) => {
  dispatch({ type: RESOLVE_TICKET_REQUEST });
  try {
    const response = await apis({
      method: "POST",
      url: "admin/resolve-ticket",
      data: { ticketId }
    });
    dispatch({
      type: RESOLVE_TICKET_SUCCESS,
      payload: response.data
    });
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      dispatch({
        type: RESOLVE_TICKET_FAILURE,
        payload: error.response.data
      });
    } else {
      dispatch({
        type: RESOLVE_TICKET_FAILURE,
        payload: "An unknown error occurred"
      });
    }
  }
};
