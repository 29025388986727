
import { Action } from 'redux';
import { ThunkAction } from 'redux-thunk';
// import RootState  from './index';
import {  FolderProps } from '../components/Documents/Files';
import { FileProps } from '../pages/DocumentsPage';


export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;


  
  export interface RootState {
    documents: {
      loading: boolean;
      documents: FileProps[]; 
      error: string;
    };
    folders: {
      loading: boolean;
      folders: FolderProps[];
      error: string;
    };  
    
  }  
  
  // Chat folder struct
  export const SET_FOLDER_VISIBILITY = "SET_FOLDER_VISIBILITY";
  export const SET_DOCUMENT_VISIBILITY = "SET_DOCUMENT_VISIBILITY";
  export const FETCH_CONTRACTS_SUCCESS = 'FETCH_CONTRACTS_SUCCESS';
  export const FETCH_CHATGROUPS_SUCCESS = 'FETCH_CHATGROUPS_SUCCESS';

  
  // Documents
  export const FETCH_DOCUMENTS_REQUEST = 'FETCH_DOCUMENTS_REQUEST';
  export const FETCH_DOCUMENTS_SUCCESS = 'FETCH_DOCUMENTS_SUCCESS';
  export const FETCH_DOCUMENTS_FAILURE = 'FETCH_DOCUMENTS_FAILURE';

  export const FETCH_FILE_TYPES_SUCCESS = 'FETCH_FILE_TYPES_SUCCESS';
  export const FETCH_FILE_TYPES_FAILURE = 'FETCH_FILE_TYPES_FAILURE';
  
  export const FETCH_SUBFOLDERS_SUCCESS = 'FETCH_SUBFOLDERS_SUCCESS';
  export const FETCH_SUBFOLDERS_FAILURE = 'FETCH_SUBFOLDERS_FAILURE';
  
  export const FETCH_STRUCTURE_SUCCESS = 'FETCH_STRUCTURE_SUCCESS';
  export const FETCH_STRUCTURE_FAILURE = 'FETCH_STRUCTURE_FAILURE';
  
  export const CREATE_SUBFOLDER_SUCCESS = 'CREATE_SUBFOLDER_SUCCESS';
  export const CREATE_SUBFOLDER_FAILURE = 'CREATE_SUBFOLDER_FAILURE';
  

  export const FETCH_FOLDERS_REQUEST = 'FETCH_FOLDERS_REQUEST';
  export const FETCH_FOLDERS_SUCCESS = 'FETCH_FOLDERS_SUCCESS';
  export const FETCH_FOLDERS_FAILURE = 'FETCH_FOLDERS_FAILURE';
  export const CREATE_FOLDER_SUCCESS = 'CREATE_FOLDER_SUCCESS';
  export const SET_CURRENT_FOLDER = 'SET_CURRENT_FOLDER';
  export const CLEAR_CURRENT_FOLDER = 'CLEAR_CURRENT_FOLDER';
  export const DELETE_FOLDER_REQUEST = 'DELETE_FOLDER_REQUEST';
  export const DELETE_FOLDER_SUCCESS = 'DELETE_FOLDER_SUCCESS';
  export const DELETE_FOLDER_FAILURE = 'DELETE_FOLDER_FAILURE';
  export const RENAME_FOLDER = 'RENAME_FOLDER';



  export const SEND_FEEDBACK_REQUEST = 'SEND_FEEDBACK_REQUEST';
  export const SEND_FEEDBACK_SUCCESS = 'SEND_FEEDBACK_SUCCESS';
  export const SEND_FEEDBACK_FAILURE = 'SEND_FEEDBACK_FAILURE';
  export const FETCH_FEEDBACK_TYPES_REQUEST = 'FETCH_FEEDBACK_TYPES_REQUEST';
  export const FETCH_FEEDBACK_TYPES_SUCCESS = 'FETCH_FEEDBACK_TYPES_SUCCESS';
  export const FETCH_FEEDBACK_TYPES_FAILURE = 'FETCH_FEEDBACK_TYPES_FAILURE';



// Marketplace  


export const SUBMIT_CASE_SUCCESS = 'SUBMIT_CASE_SUCCESS';
export const SUBMIT_CASE_FAILURE = 'SUBMIT_CASE_FAILURE';

export const FETCH_CASE_LIST_SUCCESS = 'FETCH_CASE_LIST_SUCCESS';
export const FETCH_CASE_LIST_FAILURE = 'FETCH_CASE_LIST_FAILURE';
export const FETCH_CASE_LIST_LAWYER_SUCCESS = 'FETCH_CASE_LIST_SUCCESS';
export const FETCH_CASE_LIST_LAWYER_FAILURE = 'FETCH_CASE_LIST_FAILURE';
export const FETCH_LCM_LIST_SUCCESS = 'FETCH_LCM_LIST_SUCCESS';
export const UPDATE_CASE_STATUS_SUCCESS = 'UPDATE_CASE_STATUS_SUCCESS';
export const UPDATE_CASE_STATUS_FAILURE = 'UPDATE_CASE_STATUS_FAILURE';

export const FETCH_LAWYERS_SUCCESS = 'FETCH_LAWYERS_SUCCESS';
export const FETCH_LAWYERS_FAILURE = 'FETCH_LAWYERS_FAILURE';


export const GET_RATINGS_REQUEST = 'GET_RATINGS_REQUEST';
export const GET_RATINGS_SUCCESS = 'GET_RATINGS_SUCCESS';
export const GET_RATINGS_FAILURE = 'GET_RATINGS_FAILURE';

export const GET_PUBLISHED_CASES_REQUEST = 'GET_PUBLISHED_CASES_REQUEST';
export const GET_PUBLISHED_CASES_SUCCESS = 'GET_PUBLISHED_CASES_SUCCESS';
export const GET_PUBLISHED_CASES_FAILURE = 'GET_PUBLISHED_CASES_FAILURE';

export const FETCH_BIDS_SUCCESS = 'FETCH_BIDS_SUCCESS';
export const FETCH_BIDS_FAILURE = 'FETCH_BIDS_FAILURE';

export const FETCH_BIDDING_SUCCESS = 'FETCH_BIDDING_SUCCESS';
export const FETCH_BIDDING_FAILURE = 'FETCH_BIDDING_FAILURE';
export const FETCH_FULL_CASE_SUCCESS = 'FETCH_FULL_CASE_SUCCESS';
export const FETCH_FULL_CASE_FAILURE = 'FETCH_FULL_CASE_FAILURE';
export const PROPOSE_CASE_SUCCESS = 'PROPOSE_CASE_SUCCESS';
export const PROPOSE_CASE_FAILURE = 'PROPOSE_CASE_FAILURE';
export const FETCH_PROPOSAL_SUCCESS = 'FETCH_PROPOSAL_SUCCESS';
export const FETCH_PROPOSAL_FAILURE = 'FETCH_PROPOSAL_FAILURE';


export const SUBMIT_BID_REQUEST = 'SUBMIT_BID_REQUEST';
export const SUBMIT_BID_SUCCESS = 'SUBMIT_BID_SUCCESS';
export const SUBMIT_BID_FAILURE = 'SUBMIT_BID_FAILURE';


export const UPDATE_BIDDING_STATUS_REQUEST = 'UPDATE_BIDDING_STATUS_REQUEST';
export const UPDATE_BIDDING_STATUS_SUCCESS = 'UPDATE_BIDDING_STATUS_SUCCESS';
export const UPDATE_BIDDING_STATUS_FAILURE = 'UPDATE_BIDDING_STATUS_FAILURE';


export const SET_EXTERNAL_ACCOUNTS = "SET_EXTERNAL_ACCOUNTS"



// LAW FIRM

export const FETCH_TASK_SUCCESS = 'FETCH_TASK_SUCCESS'; 

// TIME KEEPING
export const FETCH_TIME_ENTRIES = "FETCH_TIME_ENTRIES";
export const SET_TIME_ENTRIES = "SET_TIME_ENTRIES";
export const START_TIMER = "START_TIMER";
export const STOP_TIMER = "STOP_TIMER";
export const PAUSE_TIMER = "PAUSE_TIMER";
export const RESUME_TIMER = "RESUME_TIMER";
export const LOG_TIME_ENTRY = "LOG_TIME_ENTRY";
export const EDIT_TIME_ENTRY = "EDIT_TIME_ENTRY";
export const DELETE_TIME_ENTRY = "DELETE_TIME_ENTRY";
export const SET_TIMER_RUNNING_STATE = "SET_TIMER_RUNNING_STATE"; 
export const SET_TIMER = "SET_TIMER"; 
export const SET_PAUSED_STATE = "SET_PAUSED_STATE"; 