import './SuccessPage.css';

const SuccessPage = () => {
    return (
        <div className="success-container">
            <div className="success-tick">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
                    <circle className="success-tick__circle" cx="26" cy="26" r="25" fill="none" />
                    <path className="success-tick__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
                </svg>
            </div>
            <h1>Payment Successful!</h1>
            <p>Thank you for your payment. Your transaction has been completed successfully.</p>
            <p>You will be redirected to the services shortly. Please wait...</p>
            <div className="payment-details">
                <h2>Payment Details:</h2>
                <p><strong>Transaction ID:</strong> 1234567890</p>
                <p><strong>Amount:</strong> $100.00</p>
                <p><strong>Date:</strong> 20th May 2024</p>
            </div>
        </div>
    );
};

export default SuccessPage;
