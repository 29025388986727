import React from 'react';
import './TimeTracker.css';

interface Task {
  name: string;
  hours: number;
  case: string;
}

interface Case {
  name: string;
  hours: number;
}

interface DayDetails {
  tasks: Task[];
  cases: Case[];
}

interface SidePanelProps {
  day: string | null;
  onClose: () => void;
}

const SidePanel: React.FC<SidePanelProps> = ({ day, onClose }) => {
  // Detailed data for the side panel based on the selected day
  const detailedData: Record<string, DayDetails> = {
    Monday: {
      tasks: [
        { name: 'Client Meeting', hours: 2, case: 'Smith vs. Johnson' },
        { name: 'Draft Contract', hours: 3, case: 'Acme Corp Partnership' },
        { name: 'Research on Case Law', hours: 2, case: 'Doe vs. State' }
      ],
      cases: [
        { name: 'Smith vs. Johnson', hours: 2 },
        { name: 'Acme Corp Partnership', hours: 3 },
        { name: 'Doe vs. State', hours: 2 }
      ]
    },
    Tuesday: {
      tasks: [
        { name: 'Court Appearance', hours: 4, case: 'State vs. Parker' },
        { name: 'Prepare Evidence', hours: 2, case: 'State vs. Parker' },
        { name: 'Write Legal Opinion', hours: 2, case: 'Tech Innovators Ltd.' }
      ],
      cases: [
        { name: 'State vs. Parker', hours: 6 },
        { name: 'Tech Innovators Ltd.', hours: 2 }
      ]
    },
    Wednesday: {
        tasks: [
          { name: 'Deposition Prep', hours: 3, case: 'Johnson vs. Williams' },
          { name: 'Case Review', hours: 2, case: 'Global Tech Inc.' }
        ],
        cases: [
          { name: 'Johnson vs. Williams', hours: 3 },
          { name: 'Global Tech Inc.', hours: 2 }
        ]
      },
      Thursday: {
        tasks: [
          { name: 'Client Consultation', hours: 4, case: 'Peterson vs. Insurance Co.' },
          { name: 'Draft Motion', hours: 2, case: 'Peterson vs. Insurance Co.' }
        ],
        cases: [
          { name: 'Peterson vs. Insurance Co.', hours: 6 }
        ]
      },
      Friday: {
        tasks: [
          { name: 'Case Settlement Discussion', hours: 5, case: 'Enterprise vs. Jones' },
          { name: 'Review Evidence', hours: 2, case: 'Enterprise vs. Jones' }
        ],
        cases: [
          { name: 'Enterprise vs. Jones', hours: 7 }
        ]
      },
    // Additional days data...
  };

  // Fetch the correct data for the selected day
  const dayDetails: DayDetails | undefined = day ? detailedData[day] : undefined;

  // If no data is found for the selected day, show a message
  if (!dayDetails) {
    return (
      <div className="employee-day--details">
        {/* <button className="close-btn" onClick={onClose}>Close</button> */}
        <div className='non-access'>No data available for {day}</div>
      </div>
    );
  }

  return (
    <div className="employee-day--details">
      {/* <button className="close-btn" onClick={onClose}>Close</button> */}
      <h3>Details for {day}</h3>
      <div className="employee-day--tasks-details">
        <h4>Tasks Completed</h4>
        <ul>
          {dayDetails.tasks.map((task: Task, index: number) => (
            <li key={index}>{task.name} - {task.hours} hours (Case: {task.case})</li>
          ))}
        </ul>
      </div>
      <div className="employee-day--cases-details">
        <h4>Cases Worked On</h4>
        <ul>
          {dayDetails.cases.map((caseInfo: Case, index: number) => (
            <li key={index}>{caseInfo.name} - {caseInfo.hours} hours</li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default SidePanel;
