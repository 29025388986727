import { FETCH_ACTIVE_TODO_LIST, FETCH_TASK_DETAILS, FETCH_TODO_LIST } from "../../actions/marketplace/TasksActions"


const initialState = {
    ToDoList: [],
    activeTasks: [],
    TaskDetail: {}
}

export const TaskReducer = (state = initialState, action: any) => {

    switch (action.type) {
        case FETCH_ACTIVE_TODO_LIST:
            return {
                ...state,
                activeTasks: action.payload
            }
        case FETCH_TODO_LIST:
            return {
                ...state,
                ToDoList: action.payload
            }
        case FETCH_TASK_DETAILS:
            return {
                ...state,
                TaskDetail: action.payload
            }
        default: return state
    }
}