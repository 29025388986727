import { AnyAction } from "redux";
import {
    POST_COMPANY_FILES_SUCCESS,
    POST_COMPANY_FILES_FAILURE,
    POST_ADDITIONAL_COMPANY_FILES_SUCCESS,
    POST_ADDITIONAL_COMPANY_FILES_FAILURE,
    FETCH_INDEX_INFO_SUCCESS,
    FETCH_INDEX_INFO_FAILURE,
    FETCH_BLOB_DOCUMENTS_SUCCESS,
    FETCH_BLOB_DOCUMENTS_FAILURE,
    DELETE_BLOB_DOCUMENT_SUCCESS,
    DELETE_BLOB_DOCUMENT_FAILURE,
    RERUN_INDEX_SUCCESS,
    RERUN_INDEX_FAILURE,
    FETCH_INDEX_INFO_REQUEST,
    FETCH_BLOB_DOCUMENTS_REQUEST,
    POST_ADDITIONAL_COMPANY_FILES_REQUEST,
    POST_COMPANY_FILES_REQUEST,
    RERUN_INDEX_REQUEST,
    POLL_INDEX_STATUS_START,
    POLL_INDEX_STATUS_STOP,
    UPDATE_INDEX_STATUS_MESSAGE
} from "../actions/CompanyAgentAction";

interface CompanyAgentState {
    loading: boolean;
    companyFilesData: any | null;
    additionalCompanyFilesData: any | null;
    indexInfo: any | undefined;
    indexExists: boolean;
    blobDocuments: string[] | null;
    deleteBlobDocumentResult: any | null;
    rerunIndexResult: any | null;
    error: string | null;
    polling: boolean;
    statusMessage: string;
}

const initialState: CompanyAgentState = {
    loading: false,
    companyFilesData: null,
    additionalCompanyFilesData: null,
    indexInfo: undefined,
    indexExists: false,
    blobDocuments: null,
    deleteBlobDocumentResult: null,
    rerunIndexResult: null,
    error: null,
    polling: false,
    statusMessage: "Loading..." 
};

const companyAgentReducer = (state = initialState, action: AnyAction): CompanyAgentState => {
    switch (action.type) {
        case FETCH_INDEX_INFO_REQUEST:
        case POST_ADDITIONAL_COMPANY_FILES_REQUEST:
        case POST_COMPANY_FILES_REQUEST:
        case RERUN_INDEX_REQUEST:
        case FETCH_BLOB_DOCUMENTS_REQUEST:
                return {
                    ...state,
                    loading: true,
                };
        case POST_COMPANY_FILES_SUCCESS:
            return {
                ...state,
                companyFilesData: action.payload,
                loading: false,
                error: null
            };
        case POST_COMPANY_FILES_FAILURE:
            return {
                ...state,
                companyFilesData: null,
                loading: false,
                error: action.payload
            };
        case POST_ADDITIONAL_COMPANY_FILES_SUCCESS:
            return {
                ...state,
                additionalCompanyFilesData: action.payload,
                loading: false,
                error: null
            };
        case POST_ADDITIONAL_COMPANY_FILES_FAILURE:
            return {
                ...state,
                additionalCompanyFilesData: null,
                loading: false,
                error: action.payload
            };
        case FETCH_INDEX_INFO_SUCCESS:
            return {
                ...state,
                indexInfo: action.payload,
                indexExists: true,
                loading: action.payload.index_status.lastResult.status !== "success",
                error: null
            };
        case FETCH_INDEX_INFO_FAILURE:
            return {
                ...state,
                indexInfo: undefined,
                indexExists: action.payload.indexExists,
                loading: false,
                error: action.payload.error
            };
        case FETCH_BLOB_DOCUMENTS_SUCCESS:
            return {
                ...state,
                blobDocuments: action.payload,
                loading: false,
                error: null
            };
        case FETCH_BLOB_DOCUMENTS_FAILURE:
            return {
                ...state,
                loading: false,
                blobDocuments: [],
                error: action.payload
            };
        case DELETE_BLOB_DOCUMENT_SUCCESS:
            return {
                ...state,
                deleteBlobDocumentResult: action.payload,
                error: null
            };
        case DELETE_BLOB_DOCUMENT_FAILURE:
            return {
                ...state,
                deleteBlobDocumentResult: null,
                error: action.payload
            };
        case RERUN_INDEX_SUCCESS:
            return {
                ...state,
                rerunIndexResult: action.payload,
                error: null
            };
        case RERUN_INDEX_FAILURE:
            return {
                ...state,
                rerunIndexResult: null,
                error: action.payload
            };
        case POLL_INDEX_STATUS_START:
            return {
                ...state,
                polling: true,
                statusMessage: "Building Agent..."
            };
        case POLL_INDEX_STATUS_STOP:
            return {
                ...state,
                polling: false,
            };
            case UPDATE_INDEX_STATUS_MESSAGE:
                return {
                    ...state,
                    statusMessage: action.payload,
                };
        default:
            return state;
    }
};

export default companyAgentReducer;
