import * as React from 'react';

declare global {
    namespace JSX {
        interface IntrinsicElements {
            'stripe-pricing-table': React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement>;
        }
    }
}

interface PricingPageProps {
    userType: string
}

const PricingPage: React.FC<PricingPageProps> = ({ userType }) => {
    React.useEffect(() => {
        const script = document.createElement('script');
        script.src = 'https://js.stripe.com/v3/pricing-table.js';
        script.async = true;
        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        };
    }, []);

    const getStripePricingTable = () => {
        switch (userType) {
            case 'client':
                return React.createElement('stripe-pricing-table', {
                    'pricing-table-id': 'prctbl_1Pqd20CKZInHFLia2UFemMAj',
                    'publishable-key': 'pk_live_51NUAIpCKZInHFLiaMGTspIzhw1mnR0josNTWmiNx8i5FB6h4Hb9t1mi3defTQBXbuA5Ec9OxsdDjnXOopaHnK0Hn00sFvOvFQu'
                });
            case 'business':
                return React.createElement('stripe-pricing-table', {
                    'pricing-table-id': 'prctbl_1PqdOFCKZInHFLiaDmdZsepV',
                    'publishable-key': 'pk_live_51NUAIpCKZInHFLiaMGTspIzhw1mnR0josNTWmiNx8i5FB6h4Hb9t1mi3defTQBXbuA5Ec9OxsdDjnXOopaHnK0Hn00sFvOvFQu'
                });
            case 'lawyer':
                return React.createElement('stripe-pricing-table', {
                    'pricing-table-id': 'prctbl_1PqcctCKZInHFLianvQmZTuQ',
                    'publishable-key': 'pk_live_51NUAIpCKZInHFLiaMGTspIzhw1mnR0josNTWmiNx8i5FB6h4Hb9t1mi3defTQBXbuA5Ec9OxsdDjnXOopaHnK0Hn00sFvOvFQu'
                });
            case 'lawFirm':
                return React.createElement('stripe-pricing-table', {
                    'pricing-table-id': 'your_pricing_table_id_for_lawFirm',
                    'publishable-key': 'your_publishable_key_for_lawFirm'
                });
            default:
                return null;
        }
    };
    return (
        // <div>
        //     {
        //         getStripePricingTable()
        //     }
        // </div>
        <div>

            {
                userType === "client" ? (
                    <stripe-pricing-table pricing-table-0id="prctbl_1Pqd71CKZInHFLiaa9nH9i9X"
                        publishable-key="pk_live_51NUAIpCKZInHFLiaMGTspIzhw1mnR0josNTWmiNx8i5FB6h4Hb9t1mi3defTQBXbuA5Ec9OxsdDjnXOopaHnK0Hn00sFvOvFQu" style={{display: "flex", flexDirection: "row"}}>
                    </stripe-pricing-table>
                ) : userType === "lawyer" ? (
                    <stripe-pricing-table pricing-table-id="prctbl_1PqcctCKZInHFLianvQmZTuQ"
                        publishable-key="pk_live_51NUAIpCKZInHFLiaMGTspIzhw1mnR0josNTWmiNx8i5FB6h4Hb9t1mi3defTQBXbuA5Ec9OxsdDjnXOopaHnK0Hn00sFvOvFQu">
                    </stripe-pricing-table>
                ) : userType === "business" ? (
                    <stripe-pricing-table pricing-table-id="prctbl_1PqdOFCKZInHFLiaDmdZsepV"
                        publishable-key="pk_live_51NUAIpCKZInHFLiaMGTspIzhw1mnR0josNTWmiNx8i5FB6h4Hb9t1mi3defTQBXbuA5Ec9OxsdDjnXOopaHnK0Hn00sFvOvFQu">
                    </stripe-pricing-table>
                ) : (
                    <stripe-pricing-table pricing-table-id="prctbl_1Pqd71CKZInHFLiaa9nH9i9X"
                        publishable-key="pk_live_51NUAIpCKZInHFLiaMGTspIzhw1mnR0josNTWmiNx8i5FB6h4Hb9t1mi3defTQBXbuA5Ec9OxsdDjnXOopaHnK0Hn00sFvOvFQu">
                    </stripe-pricing-table>
                )
            }

        </div>


    );
}

export default PricingPage;