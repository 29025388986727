import React, { useState } from 'react';
import './Projects.css';
import { useTranslation } from 'react-i18next';

interface Employee {
    id: number;
    name: string;
}

interface Project {
    id: number;
    name: string;
    employees: Employee[];
    projectLead?: Employee;
    deadline: string;
}

const dummyProjects = {
    active: [
        { id: 1, name: 'Project A', employees: [{ id: 1, name: 'Alice Johnson' }, { id: 2, name: 'Bob Smith' }], projectLead: { id: 1, name: 'Alice Johnson' }, deadline: '2024-12-01' },
        { id: 2, name: 'Project B', employees: [{ id: 3, name: 'Carol White' }, { id: 4, name: 'David Brown' }], deadline: '2024-11-15' }
    ],
    awaitingApproval: [
        { id: 3, name: 'Project C', employees: [{ id: 5, name: 'Eva Green' }, { id: 6, name: 'Frank Wilson' }], deadline: '2024-12-30' }
    ],
    completed: [
        { id: 4, name: 'Project D', employees: [{ id: 7, name: 'Grace Lee' }, { id: 8, name: 'Hank Turner' }], projectLead: { id: 7, name: 'Grace Lee' }, deadline: '2024-10-01' }
    ]
};

const Projects = () => {
    const { t } = useTranslation();
    const [isPanelOpen, setIsPanelOpen] = useState<boolean>(false);

    const togglePanel = () => {
        setIsPanelOpen(!isPanelOpen);
    };

    const renderProject = (project: Project) => (
        <tr key={project.id} className="project-item">
            <td>{project.name}</td>
            <td>{project.id}</td>
            <td>{project.employees.map(e => e.name).join(', ')}</td>
            <td>{project.projectLead ? project.projectLead.name : 'N/A'}</td>
            <td>{project.deadline}</td>
        </tr>
    );

    return (
        <div className="projects-main__container">
            <div className="projects-container--section-column">
                <div className="projects-container top">
                    <div className='projects-header-container'>
                        <span>{t('Active Projects')}</span>
                        <button onClick={togglePanel}>{t('Create New Project')} +</button>
                    </div>
                    <table className="projects-table">
                        <thead>
                            <tr>
                                <th>{t('Name')}</th>
                                <th>{t('ID')}</th>
                                <th>{t('Employees')}</th>
                                <th>{t('Lead')}</th>
                                <th>{t('Deadline')}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {dummyProjects.active.map(renderProject)}
                        </tbody>
                    </table>
                </div>
                <div className="projects-container bottom">
                    <div className="projects-container left">
                        <div className='projects-header-container'>
                            <span>{t('Projects Awaiting Approval')}</span>
                        </div>
                        <table className="projects-table">
                            <thead>
                                <tr>
                                    <th>{t('Name')}</th>
                                    <th>{t('ID')}</th>
                                    <th>{t('Employees')}</th>
                                    <th>{t('Lead')}</th>
                                    <th>{t('Deadline')}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {dummyProjects.awaitingApproval.map(renderProject)}
                            </tbody>
                        </table>
                    </div>
                    <div className="projects-container right">
                        <div className='projects-header-container'>
                            <span>{t('Completed Projects')}</span>
                        </div>
                        <table className="projects-table">
                            <thead>
                                <tr>
                                    <th>{t('Name')}</th>
                                    <th>{t('ID')}</th>
                                    <th>{t('Employees')}</th>
                                    <th>{t('Lead')}</th>
                                    <th>{t('Deadline')}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {dummyProjects.completed.map(renderProject)}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>


            <div className={`side-panel ${isPanelOpen ? 'open_panel' : ''}`}>
                <button className="close-btn" onClick={togglePanel}><i className='fas fa-chevron-right'></i></button>
                <h1 className="panel-title">{t('Create New Project')}</h1>
                <div className="panel-content">
                    <div className="form-group--project">
                        <label>{t('Project Name')}</label>
                        <input type="text" placeholder={t('Enter project name')} />
                    </div>
                    <div className="form-group--project">
                        <label>{t('Deadline')}</label>
                        <input type="date" />
                    </div>
                    <div className="form-group--project">
                        <label>{t('Employees')}</label>
                        <input type="text" placeholder={t('Enter employee names')} />
                    </div>
                    <div className="form-group--project">
                        <label>{t('Project Lead')}</label>
                        <input type="text" placeholder={t('Enter project lead name (optional)')} />
                    </div>
                    <button className="create-button">{t('Create')}</button>
                </div>
            </div>
        </div>
    );
};

export default Projects;
