
import React, { useEffect, useState } from 'react';
import './ChatBot.css';
import { useSelector } from 'react-redux';
import { AIModel, getAiModels, getUserType, setSelectedModel } from '../../store/actions/ChatAction';
import { RootState, useAppDispatch } from '../../store';
import { useTranslation } from 'react-i18next';

const DropdownMenu = () => {
  const [menuOptions, setMenuOptions] = useState<string[]>([]);
  const dispatch = useAppDispatch()
  const selectedModel = useSelector((state: RootState) => state.model.selectedModel);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [selectedService, setSelectedService] = useState(selectedModel);
  const { t } = useTranslation()
  const userType = getUserType();




  const toggleDropdown = () => setIsDropdownOpen(!isDropdownOpen);

  const handleMenuClick = (service: string) => {
    dispatch(setSelectedModel(service));
    localStorage.setItem('selectedModel', service);
    localStorage.setItem('chatGroupID', '');
    setIsDropdownOpen(false);
  };


  useEffect(() => {
    const fetchServices = async () => {
      try {
        const response = await dispatch(getAiModels())
        if (response) {
          let services = response.map((model: AIModel) => model.model_display_name);

          if (['Lawyer', 'Lawfirmlawyer', 'LawfirmAdmin'].includes(userType)) {
            services = services.filter((service: any) => !service.includes('Legal Case Mapping'));
          }

          if (['Client', 'Employee', 'BusinessAdmin'].includes(userType)) {
            services = services.filter((service: any) => !service.includes('Case Analysis'));
          }

          if (['Client', 'Lawyer'].includes(userType)) {
            services = services.filter((service: any) => !service.includes('Company Agent'));
          }

          setMenuOptions(services);
        }


      } catch (error) {
        console.error('Error fetching services:', error);
      }
    };

    fetchServices();
  }, [userType, dispatch]);



  useEffect(() => {
    setSelectedService(selectedModel);
  }, [selectedModel]);

  useEffect(() => {
    const storedModel = localStorage.getItem('selectedModel');
    if (storedModel) {
      dispatch(setSelectedModel(storedModel));
    }
  }, [dispatch]);


  const getTooltipText = (option: string) => {
    switch (option) {
      case 'Legal Advisor':
        return t('Provides legal advising services on various matters.');
      case 'Legal CaseMapper':
        return t('Helps in mapping out legal cases for better understanding.');
      case 'Cross-Jurisdictional Assistance':
        return t('Assists with legal issues across different jurisdictions.');
      case 'Contract Generator':
        return t('Automates the process of contract generation.');
      case 'Contract Advisor':
        return t('Offers services for thorough contract reviews.');
      case 'Case Analysis':
        return t('Offers case analysis.');
      case 'Company Agent':
        return t('Search through uploaded documents.');
      default:
        return '';
    }
  };




  return (
    <div className="dropdown-container__chat">
      <button onClick={toggleDropdown} className={`dropdown-btn__chat ${isDropdownOpen ? 'selected' : ''}`}>
        <h2>{t(`${selectedModel}`)}</h2> <i style={{ fontSize: 20 }} className={`fa ${isDropdownOpen ? 'fa-chevron-up' : 'fa-chevron-down'}`} ></i>
      </button>
      {isDropdownOpen && (

        <div className="dropdown-menu__chat">
          {menuOptions.map((option, index) => {
            const tooltipText = getTooltipText(option);
            return (
              <div
                key={index}
                className={`dropdown-item__chat ${selectedService === option ? 'selected' : ''}`}
                onClick={() => handleMenuClick(option)}
              >
                <p title={t(`${tooltipText}`)}>{t(option)}</p>
                {/* <span className="dropdown-tooltip__chat">{tooltipText}</span> */}
              </div>
            );
          })}
        </div>


      )}
    </div>
  );
};

export default DropdownMenu;
