import React from 'react';

export interface UserContextProps {
    firstName: string;
    lastName: string;
    email: string;
    phoneNumber?: string;
    address?: {
      streetAddress: string;
      country: string;
      zipCode: string;
      city: string;
      state: string;
    };
    setUserData: (data: Partial<UserContextProps>) => void;
}

export const UserContext = React.createContext<UserContextProps>({
  firstName: '',
  lastName: '',
  email: '',
  phoneNumber: '',
  address: {
    streetAddress: '',
    country: '',
    zipCode: '',
    city: '',
    state: '',
  },
  setUserData: () => {},
});
