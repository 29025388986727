import Help from "../components/Help/Help";
function HelpPage() {

    return (
        <div style={{ paddingLeft: 20, overflowY: 'auto', height: 'calc(100vh - 100px)', scrollbarWidth: 'thin', scrollbarColor: '#64d2ff #111' }}>
            <Help />
        </div>
    )
}

export default HelpPage;