import { useState, ChangeEvent } from 'react';
import '../Settings.css';
import { useTranslation } from 'react-i18next';

const Reminder = () => {
  type ReminderData = {
    taskReminder: boolean;
    signatureReminder: boolean;
    meetingReminder: boolean;
    newsletter: boolean;
    generalReminder: boolean;
    mobilePush: boolean;
    emailNotification: boolean;
    desktopNotification: boolean;
  };
  const { t } = useTranslation()


  const [reminderData, setReminderData] = useState<ReminderData>({
    taskReminder: false,
    signatureReminder: false,
    meetingReminder: false,
    newsletter: false,
    generalReminder: false,
    mobilePush: false,
    emailNotification: false,
    desktopNotification: false,
  });

  const handleCheckboxChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = e.target;
    setReminderData((prevData) => ({
      ...prevData,
      [name as keyof ReminderData]: checked,
    }));
  };



  return (
      <div className={`reminder-container`}>
        <div className={`checkbox-options`}>
          <h2>{t('Reminders')}</h2>
          <div className='reminder-options'>
            <label>
              <input
                type="checkbox"
                name="taskReminder"
                checked={reminderData.taskReminder}
                onChange={handleCheckboxChange}
              />
              <span></span>
              <p>
                {t('Task reminder')}
              </p>
            </label>
            <label>

              <input
                type="checkbox"
                name="signatureReminder"
                checked={reminderData.signatureReminder}
                onChange={handleCheckboxChange}
              />
              <span></span>
              <p>
                {t('Signature reminder')}
              </p>
            </label>

            <label>
              <input
                type="checkbox"
                name="generalReminder"
                checked={reminderData.generalReminder}
                onChange={handleCheckboxChange}
              />
              <span></span>
              <p>
                {t('General reminder')}
              </p>
            </label>
            <label>
              <input
                type="checkbox"
                name="meetingReminder"
                checked={reminderData.meetingReminder}
                onChange={handleCheckboxChange}
              />
              <span></span>
              <p>
                {t('Meeting reminder')}
              </p>
            </label>
          </div>
        </div>

      </div>
  );

};

export default Reminder;
