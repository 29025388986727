import React, { useEffect, useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import './Sidebar.css';
import { signOut } from "../../store/actions/SignInAction";
import { RootState, useAppDispatch } from "../../store";
import { getUserType } from '../../store/actions/ChatAction';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { closeSidebar, toggleSidebar } from '../../store/actions/SidebarActions';

const Sidebar: React.FC = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const { t } = useTranslation();
  const userType = getUserType();
  const isOpen = useSelector((state: RootState) => state.sidebar.isOpen);
  const [isMarketplaceOpen, setIsMarketplaceOpen] = useState(false);
  const [isSettingsOpen, setIsSettingsOpen] = useState(false);
  const [isDashboardOpen, setIsDashboardOpen] = useState(false);

  useEffect(() => {
    window.addEventListener('keydown', handleKeyPress);

    return () => {
      window.removeEventListener('keydown', handleKeyPress);
    };
  }, []);

  const determineActiveIndex = (): number => {
    if (location.pathname.startsWith("/dashboard/marketplace-page")) {
      return 2;
    }
    switch (location.pathname) {
      case "/dashboard": return 0;
      case "/ai-assistant": return 1;
      case "/documents": return 3;
      case "/analytics": return 4;
      case "/settings": return 5;
      case "/help": return 6;
      case "/feedback": return 7;
      default: return 0;
    }
  };

  const [index, setIndex] = useState(determineActiveIndex());

  const handleLogout = async () => {
    await dispatch(signOut());
  };

  useEffect(() => {
    const newIndex = determineActiveIndex();
    if (index !== newIndex) {
      setIndex(newIndex);
    }
  }, [location, index]);

  const handleToggleSidebar = () => {
    dispatch(toggleSidebar());
  };

  const handleKeyPress = (event: KeyboardEvent) => {
    if ((event.ctrlKey || event.metaKey) && event.key === 'b') {
      event.preventDefault();
      handleToggleSidebar();
    }
  };

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 768) {
        dispatch(closeSidebar());
      }
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [dispatch]);

  const [isTextVisible, setIsTextVisible] = useState(true);

  useEffect(() => {
    if (!isOpen) {
      setIsTextVisible(false);
    } else {
      const timer = setTimeout(() => {
        setIsTextVisible(true);
      }, 50);
      return () => clearTimeout(timer);
    }
  }, [isOpen]);

  const handleMarketplaceToggle = () => {
    setIsMarketplaceOpen(!isMarketplaceOpen);
    setIsDashboardOpen(false)
    setIsSettingsOpen(false)
  };

  const handleSettingsToggle = () => {
    setIsSettingsOpen(!isSettingsOpen);
    setIsMarketplaceOpen(false)
    setIsDashboardOpen(false)
  };

  const handleDashboardToggle = () => {
    setIsDashboardOpen(!isDashboardOpen);
    setIsMarketplaceOpen(false)
    setIsSettingsOpen(false)
  };

  const resetToggles = () => {
    setIsDashboardOpen(false)
    setIsMarketplaceOpen(false)
    setIsSettingsOpen(false)
  }

  return (
    <>
      <div className={`sidebar ${isOpen ? 'open' : 'closed'}`}>
        <div className="logo-section">
          <NavLink onClick={resetToggles} style={{ display: 'flex', alignItems: 'center', textDecoration: 'none', paddingLeft: 0 }} exact to="/dashboard">
            <i style={{ fontSize: '3rem', color: '#64d2ff' }} className="fa-kit fa-logo company-logo-sidebar-header"></i>
            {isOpen && <h1 className={`sidebar-title ${!isTextVisible ? 'fade-out' : ''}`}>PONS</h1>}
          </NavLink>
        </div>

        <div className='sidebar-wrapper'>
          <div className="nav-section">
            <ul className="sidebar-list">
            {(userType === "LawfirmAdmin" || userType === "Lawfirmlawyer") ? (
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <li>
                    <a onClick={handleDashboardToggle} className={`${isDashboardOpen && 'active'}`}>
                      <i className="bi bi-grid-fill"></i>
                      {isOpen && <span className={`${!isTextVisible ? 'fade-out' : ''}`}>{t('Dashboard')}</span>}
                      {isOpen && (
                        <i className={`fa-solid fa-chevron-${isDashboardOpen ? 'up' : 'down'}`} style={{ marginLeft: 'auto' }}></i>
                      )}
                    </a>
                  </li>

                  {isDashboardOpen && (
                    <ul className="sidebar-submenu">

                      {userType === "LawfirmAdmin" ? (
                      <>
                      <li>
                        <NavLink to="/dashboard/admin" activeClassName="active">
                          <i className="fa-solid fa-user-shield"></i>
                          <span className={`${!isTextVisible ? 'fade-out' : ''}`}>{t('Admin')}</span>
                        </NavLink>
                      </li>
                      <li>
                        <NavLink to="/dashboard/finance" activeClassName="active">
                          <i className="fa-solid fa-wallet"></i>
                          <span className={`${!isTextVisible ? 'fade-out' : ''}`}>{t('Finance')}</span>
                        </NavLink>
                      </li>
                      <li>
                        <NavLink to="/dashboard/message-center" activeClassName="active">
                          <i className="fa-solid fa-comments"></i>
                          <span className={`${!isTextVisible ? 'fade-out' : ''}`}>{t('Message Center')}</span>
                        </NavLink>
                      </li>
                      </>
                      ) : userType === "Lawfirmlawyer" && (
                        <>
                                              <li>
                        <NavLink to="/dashboard/panel" activeClassName="active">
                          <i className="fa-solid fa-square-dashed"></i>
                          <span className={`${!isTextVisible ? 'fade-out' : ''}`}>{t('Panel')}</span>
                        </NavLink>
                      </li>
                      <li>
                        <NavLink to="/dashboard/time-keeping" activeClassName="active">
                          <i className="fas fa-clock"></i>
                          <span className={`${!isTextVisible ? 'fade-out' : ''}`}>{t('Time Keeping')}</span>
                        </NavLink>
                      </li>
                      <li>
                        <NavLink to="/dashboard/message-center" activeClassName="active">
                          <i className="fa-solid fa-comments"></i>
                          <span className={`${!isTextVisible ? 'fade-out' : ''}`}>{t('Message Center')}</span>
                        </NavLink>
                      </li>
                        </>
                      )}
                    </ul>
                  )}
                </div>
              ) : (
                <li>
                  <NavLink onClick={resetToggles} title={`${!isOpen ? t('Dashboard') : ''}`} exact to="/dashboard" activeClassName={index === 0 ? "active" : ""}>
                    <i className="bi bi-grid-fill"></i> {isOpen && <span className={`${!isTextVisible ? 'fade-out' : ''}`}>{t('Dashboard')}</span>}
                  </NavLink>
                </li>
              )}
              {(userType === "Lawfirmlawyer" || userType === "LawfirmAdmin" || userType === "Lawyer") && (
                <li>
                  <NavLink onClick={resetToggles} title={`${!isOpen ? t('Client Portal') : ''}`} exact to="/client-portal" activeClassName={index === 0 ? "active" : ""}>
                    <i className="fa-duotone fa-solid fa-chart-network"></i> {isOpen && <span className={`${!isTextVisible ? 'fade-out' : ''}`}>{t('Client Portal')}</span>}
                  </NavLink>
                </li>
              )}

              {(userType === "BusinessAdmin" || userType === "Employee") && (
                <li>
                  <NavLink onClick={resetToggles} title={`${!isOpen ? t('Projects') : ''}`} exact to="/projects" activeClassName={index === 0 ? "active" : ""}>
                    <i className="fa-duotone fa-solid fa-diagram-project"></i> {isOpen && <span className={`${!isTextVisible ? 'fade-out' : ''}`}>{t('Projects')}</span>}
                  </NavLink>
                </li>
              )}

              <li>
                <NavLink onClick={resetToggles} title={`${!isOpen ? t('AI Assistant') : ''}`} to="/ai-assistant" activeClassName="active">
                  <i className="bi bi-stars"></i>
                  {isOpen && <span style={{ whiteSpace: 'nowrap' }} className={`${!isTextVisible ? 'fade-out' : ''}`}>{t('AI Assistant')}</span>}
                </NavLink>
              </li>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <li>
                  <a onClick={handleMarketplaceToggle} className={`${isMarketplaceOpen && 'active'}`}>
                    <i style={{ marginRight: '1rem' }} className="fa-regular fa-scale-balanced"></i>
                    {isOpen && <span className={`${!isTextVisible ? 'fade-out' : ''}`}>{t('Marketplace')}</span>}
                    {isOpen && (
                      <i className={`fa-solid fa-chevron-${isMarketplaceOpen ? 'up' : 'down'}`} style={{ marginLeft: 'auto' }}></i>
                    )}
                  </a>
                </li>

                {isMarketplaceOpen && (
                  <ul className="sidebar-submenu">
                    {userType === 'Client' && (
                      <>
                        <li>
                          <NavLink to="/marketplace/client/cases" activeClassName="active">
                            <i className="fa-solid fa-briefcase"></i>
                            <span className={`${!isTextVisible ? 'fade-out' : ''}`}>{t('Cases')}</span>
                          </NavLink>
                        </li>
                        <li>
                          <NavLink to="/marketplace/client/lawyers" activeClassName="active">
                            <i className="fa-solid fa-gavel"></i>
                            <span className={`${!isTextVisible ? 'fade-out' : ''}`}>{t('Lawyers')}</span>
                          </NavLink>
                        </li>
                      </>
                    )}
                    {(userType === 'Lawyer' || userType === 'LawfirmAdmin' || userType === 'Lawfirmlawyer') && (
                      <>
                        <li>
                          <NavLink to="/marketplace/lawyer/market" activeClassName="active">
                            <i className="fa-solid fa-globe"></i>
                            <span className={`${!isTextVisible ? 'fade-out' : ''}`}>{t('Market')}</span>
                          </NavLink>
                        </li>
                        <li>
                          <NavLink to="/marketplace/lawyer/cases" activeClassName="active">
                            <i className="fa-solid fa-briefcase"></i>
                            <span className={`${!isTextVisible ? 'fade-out' : ''}`}>{t('Cases')}</span>
                          </NavLink>
                        </li>
                        <li>
                          <NavLink to="/marketplace/lawyer/profile" activeClassName="active">
                            <i className="fa-solid fa-square-user"></i>
                            <span className={`${!isTextVisible ? 'fade-out' : ''}`}>{t('Profile')}</span>
                          </NavLink>
                        </li>
                      </>
                    )}
                  </ul>
                )}
              </div>

              <li>
                <NavLink onClick={resetToggles} title={`${!isOpen ? t('Documents') : ''}`} to="/documents" activeClassName="active">
                  <i className="fas fa-folders"></i>
                  {isOpen && <span className={`${!isTextVisible ? 'fade-out' : ''}`}>{t('Documents')}</span>}
                </NavLink>
              </li>
              <li>
                <NavLink onClick={resetToggles} title={`${!isOpen ? t('Analysis') : ''}`} to="/analytics" activeClassName="active">
                  <i className="bi bi-bar-chart-fill"></i>
                  {isOpen && <span className={`${!isTextVisible ? 'fade-out' : ''}`}>{t('Analytics')}</span>}
                </NavLink>
              </li>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <li>
                  <a onClick={handleSettingsToggle} className={`${isSettingsOpen && 'active'}`}>
                    <i className="bi bi-gear-fill"></i>
                    {isOpen && <span className={`${!isTextVisible ? 'fade-out' : ''}`}>{t('Settings')}</span>}
                    {isOpen && (
                      <i className={`fa-solid fa-chevron-${isSettingsOpen ? 'up' : 'down'}`} style={{ marginLeft: 'auto' }}></i>
                    )}
                  </a>
                </li>

                {isSettingsOpen && (
                  <ul className="sidebar-submenu">
                    <li>
                      <NavLink to="/settings/account" activeClassName="active">
                        <i className="fa-solid fa-user-cog"></i>
                        <span className={`${!isTextVisible ? 'fade-out' : ''}`}>{t('Account')}</span>
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to="/settings/security" activeClassName="active">
                        <i className="fa-solid fa-shield-alt"></i>
                        <span className={`${!isTextVisible ? 'fade-out' : ''}`}>{t('Security')}</span>
                      </NavLink>
                    </li>
                    {(userType === "LawfirmAdmin" || userType === "BusinessAdmin") ? (
                      <li>
                        <NavLink to="/settings/company" activeClassName="active">
                          <i className="fa-solid fa-building"></i>
                          <span className={`${!isTextVisible ? 'fade-out' : ''}`}>{t('Company')}</span>
                        </NavLink>
                      </li>
                    ) : (
                      <li>
                        <NavLink to="/settings/finance" activeClassName="active">
                          <i className="fa-solid fa-wallet"></i>
                          <span className={`${!isTextVisible ? 'fade-out' : ''}`}>{t('Finance')}</span>
                        </NavLink>
                      </li>
                    )}
                  </ul>
                )}
              </div>
              <li>
                <NavLink onClick={resetToggles} title={`${!isOpen ? t('Feedback') : ''}`} to="/feedback" activeClassName="active">
                  <i style={{ fontSize: '0.9rem' }} className="fa-solid fa-comment-dots"></i>
                  {isOpen && <span className={`${!isTextVisible ? 'fade-out' : ''}`}>{t('Feedback')}</span>}
                </NavLink>
              </li>
            </ul>
          </div>
        </div>

        <li className='sidebar-help--section'>
          <div style={{ borderTop: '1px solid #64d2ff', marginRight: 5, paddingTop: 10 }}>
            <NavLink onClick={resetToggles} title={`${!isOpen ? t('Help') : ''}`} to="/help" activeClassName="active">
              <i className="bi bi-question-circle"></i>
              {isOpen && <span style={{ paddingRight: 5 }} className={`${!isTextVisible ? 'fade-out' : ''}`}>{t('Help & get started')}</span>}
            </NavLink>
          </div>
        </li>
        <div title={isOpen ? 'Collapse sidebar' : 'Expand sidebar'} className={`sidebar-clickable-area ${isOpen ? '' : 'closed'}`} onClick={handleToggleSidebar}>
        </div>
      </div>
    </>
  );
};

export default Sidebar;
