import { useHistory, useParams } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import "./ResetPassword.css"
import { useDispatch } from "react-redux";
import { ThunkDispatch } from 'redux-thunk';
import { RootState } from '../../store';
import { AnyAction } from 'redux';
import { resetPassword } from '../../store/actions/SignInAction';


function ResetPassword() {

  let { token }: { token: string } = useParams()
  const dispatch = useDispatch<ThunkDispatch<RootState, undefined, AnyAction>>();
  const history = useHistory()

  const validationSchema = Yup.object().shape({
    newPassword: Yup.string()
      .min(8, 'Password must be at least 8 characters')
      .matches(
        /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*?&#])[A-Za-z\d@$!%*?&#]+$/,
        'Password must contain at least one letter, one number, and one special character'
      )
      .required('New password is required'),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref('newPassword'), ""], 'Passwords must match')
      .required('Confirm password is required'),
  });

  const formik = useFormik({
    initialValues: {
      newPassword: '',
      confirmPassword: '',
    },
    validationSchema,
    onSubmit: async (values) => {
      let sendValues = {
        UID: token,
        Password: values.newPassword
      }

      let resp = await dispatch(resetPassword(sendValues))

      if (resp && resp.status === 200) {
        history.push("/")
      }

    },
  });

  return (
    <div className="reset-password-container">
      <h2>Reset Password</h2>
      <form onSubmit={formik.handleSubmit}>
        <div className="form-group-reset">
          <label htmlFor="newPassword">New Password</label>
          <input
            type="password"
            id="newPassword"
            name="newPassword"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.newPassword}
            className={
              formik.touched.newPassword && formik.errors.newPassword
                ? 'error'
                : ''
            }
          />
          {formik.touched.newPassword && formik.errors.newPassword ? (
            <div className="error-message">{formik.errors.newPassword}</div>
          ) : null}
        </div>

        <div className="form-group-reset">
          <label htmlFor="confirmPassword">Confirm Password</label>
          <input
            type="password"
            id="confirmPassword"
            name="confirmPassword"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.confirmPassword}
            className={
              formik.touched.confirmPassword && formik.errors.confirmPassword
                ? 'error'
                : ''
            }
          />
          {formik.touched.confirmPassword && formik.errors.confirmPassword ? (
            <div className="error-message">{formik.errors.confirmPassword}</div>
          ) : null}
        </div>

        <button type="submit" className="reset-password-button">
          Reset Password
        </button>
      </form>

    </div>
  );
}

export default ResetPassword;
