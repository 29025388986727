import { AnyAction } from "redux";

export const TOGGLE_SIDEBAR = 'TOGGLE_SIDEBAR';
export const OPEN_SIDEBAR = 'OPEN_SIDEBAR';
export const CLOSE_SIDEBAR = 'CLOSE_SIDEBAR';

export const toggleSidebar = (): AnyAction => ({
  type: TOGGLE_SIDEBAR,
});

export const openSidebar = (): AnyAction => ({
  type: OPEN_SIDEBAR,
});

export const closeSidebar = (): AnyAction => ({
  type: CLOSE_SIDEBAR,
});
