import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store';
import { ApiResponse, callLCMAPI, getUserID, getUserType } from '../../../store/actions/ChatAction';
import './lcmStyles.css';
import PromptBar from '../PromptBar';
import CustomMessageList from './CustomMessageList';
import { ReactComponent as LCMIcon } from '../../../resources/PricingIcons/LCMIcon.svg'
import { useTranslation } from 'react-i18next';

interface Message {
  text: string;
  sender: 'user' | 'assistant';
  action?: string | null;
  confirmationMessage?: string | null;
  customClass?: string;
}

const LCMPost: React.FC = () => {
  const [currentSection, setCurrentSection] = useState<string | null>('InitiateLCM');
  const [lcmData, setLcmData] = useState<any | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [userInput, setUserInput] = useState('');
  const [messages, setMessages] = useState<Message[]>([]);
  const [isAwaitingConfirmation, setIsAwaitingConfirmation] = useState(false);
  const [isDocumentVisible, setIsDocumentVisible] = useState(false);
  const [uploadedFile, setUploadedFile] = useState<File | null>(null);
  const [confirmMessage, setConfirmMessage] = useState('');
  const [lcmFlowStarted, setLcmFlowStarted] = useState(false);
  const selectedModel = useSelector((state: RootState) => state.model.selectedModel);
  const { t } = useTranslation();
  const [loaderText, setLoaderText] = useState(t('Please wait...'));

  useEffect(() => {
    let timer: ReturnType<typeof setTimeout>;

    switch (currentSection) {
      case "Issue":
        setLoaderText("Analyzing the issue...");
        break;
      case "Witnesses":
        setLoaderText("Summarizing your case...");
        break;
      case "SummaryConfirm":
        setLoaderText("Searching through relevant legislations...");
        break;
      case "RelevantLegislationConfirm":
        setLoaderText("Analyzing relevance to your case...");
        break;
      case "InterpretationConfirm":
        setLoaderText("Crafting your strategy...");
        break;
      default:
        setLoaderText("Please wait...");
    }

    if (isLoading) {
      timer = setTimeout(() => {
        setLoaderText("Almost complete...");
      }, 30000);
    }

    return () => {
      clearTimeout(timer);
      if (!isLoading) {
        setLoaderText("Please wait...");
      }
    };
  }, [currentSection, isLoading]);

  const onSend = async (input: string) => {
    const newUserMessage: Message = {
      text: input,
      sender: 'user',
      action: null
    };
    setMessages(prevMessages => [...prevMessages, newUserMessage]);
    legalCaseMappingFunction(input);
  };

  const initiateLCM = async () => {
    setIsLoading(true);
    setMessages(prevMessages => [
      ...prevMessages,
      { text: 'Starting Legal Case Mapping...', sender: 'user', action: null },
      { text: 'Loading...', sender: 'assistant', action: null }
    ]);

    const initialPayload = {
      LCMID: '',
      UserID: getUserID() || '',
      Section: 'InitiateLCM',
      SectionData: '',
      UserType: getUserType() || ''
    };

    try {
      const initialData = await callLCMAPI(initialPayload);
      updateLCMState(initialData);
      setLcmFlowStarted(true);
    } catch (error) {
      console.error('Error starting LCM flow:', error);
    }
    setIsLoading(false);
  };

  const handleConfirmationClick = async (confirmationResponse: string) => {
    setMessages(prevMessages => [
      ...prevMessages,
      { text: 'Loading...', sender: 'assistant' }
    ]);
    await handleSectionSubmit(confirmationResponse);
  };

  const handleSectionSubmit = async (sectionData: string) => {
    const payload = {
      LCMID: lcmData?.LCMID || "",
      UserID: getUserID() || "",
      Section: currentSection || "Issue",
      SectionData: sectionData,
      UserType: getUserType() || "",
    };

    try {
      const updatedData = await callLCMAPI(payload);
      updateLCMState(updatedData);
    } catch (error) {
      console.error('Error updating LCM section:', error);
    }
  };

  const handleFileDrop = (file: File) => {
    setUploadedFile(file);
  };

  const updateLCMState = (data: ApiResponse, prompt?: string) => {
    if (data) {
      setLcmData(data);
      setCurrentSection(data.NextSection);
      setMessages(prevMessages => {
        const newMessages = prevMessages.slice(0, -1);
        if (data.ConfirmMessage) {
          setIsAwaitingConfirmation(true);
          newMessages.push({ text: data.Output, sender: 'assistant', action: null, confirmationMessage: data.ConfirmMessage });
        } else {
          setIsAwaitingConfirmation(false);
          newMessages.push({ text: data.Output, sender: 'assistant', action: null });
        }
        return newMessages;
      });
    } else {
      console.error('LCM data not found in response:', data);
    }
  };

  const legalCaseMappingFunction = async (prompt: string, file?: File) => {
    const loadingMessageId = Date.now();
    setMessages(prevMessages => [...prevMessages, { id: loadingMessageId, text: 'Loading...', sender: 'assistant', action: null, loading: true }]);

    await new Promise(resolve => setTimeout(resolve, 2000)); // Add 2 seconds delay

    const initialPayload = { LCMID: lcmData?.LCMID || "", UserID: getUserID() || "", Section: currentSection || "Issue", SectionData: prompt, UserType: getUserType() || "" };

    try {
      const initialData = await callLCMAPI(initialPayload);
      updateLCMState(initialData, prompt);
    } catch (error) {
      setMessages(prevMessages => [...prevMessages.slice(0, -1), { text: 'An error occurred while processing your request.', sender: 'assistant', action: null, ques: prompt, loading: false }]);
    }
  }

  const renderCurrentSection = () => {
    if (!lcmFlowStarted) {
      return (
        <div className='legal-case-mapping__container'>
          <div className="legalView">
            <div className='legalView--top'>
              <h2 className="LCMHeading">
                <LCMIcon style={{ width: '80px', height: '80px' }} />
              </h2>
              <p style={{ color: '#64d2ff', fontSize: '1rem', fontWeight: 400 }}>
                {t('Navigate the complexities of legal proceedings with ease.')}
              </p>
              <div>
                <p style={{ fontWeight: 400, color: '#ccc', width: '80%', margin: '0px auto' }}>
                  {t('Begin by detailing your legal issue, PONS will guide you through a structured process, identifying relevant laws and advising on case strengths and weaknesses.')}
                </p>
              </div>
            </div>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              <p style={{ fontSize: '1.2rem' }}>
                {t('Ready to start?')}
              </p>
              <p style={{ fontWeight: 400, color: '#ccc' }}>
                {t('Let PONS AI help you map out your legal strategy and connect with professionals.')}
              </p>
              <button className="buyNowButton" onClick={initiateLCM}>
                {t('Start Legal Case Mapping')}
              </button>
            </div>
          </div>
        </div>
      );
    }

    return (
      <>
        <CustomMessageList messages={messages} handleConfirmationClick={handleConfirmationClick} loaderText={loaderText} setMessages={setMessages} currentSection={currentSection} />
        <PromptBar 
          onSend={onSend} 
          isDocumentVisible={isDocumentVisible} 
          uploadedFile={uploadedFile} 
          handleFileDrop={handleFileDrop} 
          selectedService={selectedModel} 
          isLoading={isLoading || isAwaitingConfirmation} 
        />
      </>
    );
  };

  return <div className="lcm-container">{renderCurrentSection()}</div>;
};

export default LCMPost;
