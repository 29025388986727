import React, { useState, useEffect } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import "./LoginSection.css";
import { useHistory, Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { RootState } from "../../store";
import { SignIn, check2fa, forgotPassword, loginGoogle, loginMsal, setOtpErrorMessage, verify2faQr } from "../../store/actions/SignInAction";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import { GoogleLogin, GoogleLoginResponse, GoogleLoginResponseOffline } from 'react-google-login';
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../../msalConfig";
import { hideModal, showModal } from "../../store/actions/modalActions";
import { getUserType } from "../../store/actions/ChatAction";



const CLIENT_ID = "508561668626-j8f9taa9hm2d2pnlbibadb4t4bk2pmd3.apps.googleusercontent.com"
interface MyComponentProps {
  isLoading: boolean;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  setIsLoginExpanded: React.Dispatch<React.SetStateAction<boolean>>;
}

interface LoginProps {
  email: string;
  password: string;
  RememberMe: number;
}
const LoginSection: React.FC<MyComponentProps> = ({ isLoading, setIsLoading, setIsLoginExpanded }) => {
  const history = useHistory();
  const dispatch = useDispatch<ThunkDispatch<RootState, undefined, AnyAction>>();
  const [isModalVisible, setModalVisible] = useState(false);
  const { instance } = useMsal();
  const userCred = localStorage.getItem('token') || sessionStorage.getItem('token');
  let accessToken = "";
  const [showForm, setShowForm] = useState(false);
  const userType = getUserType();
  const isDemoEnvironment = window.location.hostname.includes('demo.pons.io') 
  || window.location.hostname.includes('beta.pons.io');


  useEffect(() => {
    if (isDemoEnvironment) {
    setShowForm(true)
    }
  }, [showForm])

  useEffect(() => {
    if (userCred) {
      let data = JSON.parse(userCred);
      accessToken = data?.token;
    }
    if (accessToken) {
      userType === 'LawfirmAdmin' ? (
        history.push("/dashboard/admin")
      ) : (
        history.push("/dashboard")
      )
    }
  }, [userCred, history]);


  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email("Please enter a valid email ID")
      .required("Email is required"),
    password: Yup.string()
      .min(
        8,
        "8+ characters, uppercase, lowercase, number & special (!?&..)"
      )
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#/$%/^&/*])(?=.{8,})/,
        "8+ characters, uppercase, lowercase, number & special (!?&..)"
      )
      .required("Password is required"),
  });

  const formik = useFormik({
    initialValues: { email: "", password: "", RememberMe: 0 },
    validationSchema,
    onSubmit: async (values: LoginProps) => {
      try {
        setIsLoading(true)
        const md5password = values.password;

        let sendValue = {
          Email: values.email,
          Password: md5password,
          RememberMe: 0
        };

        if (values.RememberMe) {
          sendValue.RememberMe = 1
        }

        let resp: any = await dispatch(check2fa(values.email, values.password))
        if (resp.Enable2FA === "Yes") {
          dispatch(showModal({
            type: 'otp-verification',
            message: "",
            onConfirm: async (otp: File | string) => {
              const response: any = await  dispatch(verify2faQr(otp,"","",values.email,values.password,values.RememberMe))
              if (response.OTPVerified === 'NO') {
                dispatch(setOtpErrorMessage(response.message));
              } else {
                dispatch(setOtpErrorMessage(''));
                dispatch(hideModal())
              }

              setIsLoading(false)


            },
            onCancel: () => { dispatch(hideModal()); setIsLoading(false) },
            showModal: true
          }))
        }else{
          await dispatch(SignIn(sendValue));
          setIsLoading(false)

        }
      } catch (error) {
        setIsLoading(false)
        console.error("Error during login:", error);
      }
    },
  });

  const toggleModal = () => {
    setModalVisible(!isModalVisible);
  };

  const confirmEmailAndSendLink = async (email: string | File) => {
    let data = await dispatch(forgotPassword(email))

    if (data) (
      history.push(`/reset-password/{1}`)
    )
    toggleModal();
  };

  const handleForgotPasswordClick = () => {
    dispatch(showModal({
      type: 'resetPassword',
      message: 'Please enter your email address to receive a reset link.',
      onConfirm: (email) => confirmEmailAndSendLink(email),
      showModal: false
    }));
  };

  const responseGoogle = async (response: GoogleLoginResponse | GoogleLoginResponseOffline) => {
    if ('tokenId' in response) {
      const tokenBlob = new Blob([JSON.stringify({ token: response.tokenId }, null, 2)], { type: 'application/json' });
      let resp = await dispatch(loginGoogle(tokenBlob))
      if (resp) {
        userType === 'LawfirmAdmin' ? (
          history.push("/dashboard/admin")
        ) : (
          history.push("/dashboard")
        )
      }
    } else {
      return
    }

  }

  const handleLoginMSAL = () => {
    instance.loginPopup(loginRequest)
      .then(response => {
        let token = response.idToken;
        dispatch(loginMsal(token))
      })
      .catch(error => {
        console.error("Login error:", error);
      });
  };

  const handleExpandLogin = () => {
    setIsLoginExpanded(true);
    setTimeout(() => setShowForm(true), 350); 
  };

  const handleCollapseLogin = () => {
    setShowForm(false);
    setTimeout(() => setIsLoginExpanded(false), 300); 
  };

  return (
    <>
    {(showForm && !isDemoEnvironment) && (
        <i onClick={handleCollapseLogin} className='fas fa-chevron-left icon-hover-effect'></i>
    )}
      <div className="login-section">
        {!showForm && (
          <div className="login-section--intro">
            <i className="fa-kit fa-logo"></i>
          <button
            onClick={handleExpandLogin}
            style={{ opacity: isLoading ? 0 : 1, transition: 'opacity 0.3s ease, border 0.3s ease' }}
          >
            Log In
          </button>
          <div className="login--first__termspriv">
                <p><a href="https://law.pons.io/legal/terms-of-service/" target="_blank" rel="noopener noreferrer">Terms of service</a> | <a href="https://law.pons.io/legal/privacy-policy/" target="_blank" rel="noopener noreferrer">Privacy Policy</a></p>
              </div>
          </div>
        )}
        {showForm && (
          <>
          <div className='login__header'>
              <i className="fa-kit fa-logo company-logo"></i>
            </div>
            <div className="login-section--form-container">
              <div className="login-form--wrapper">
              <h2>Welcome back!</h2>
            <form onSubmit={formik.handleSubmit} noValidate>
              <div className="form-group--login" style={{ gap: 5 }}>
                <label htmlFor="email" style={{ color: '#fff', fontWeight: '500' }} className="form-label">Email Address</label>
                <input
                  autoComplete="off"
                  type="email"
                  name="email"
                  maxLength={70}
                  tabIndex={1}
                  value={formik.values?.email}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  className={`form-control-data--login ${formik.errors?.email && formik.touched.email ? 'select-box-error' : ''}`}
                  id="email"
                />
                {formik.errors?.email && formik.touched.email && <div className="error-message-display">{formik.errors?.email}</div>}
              </div>

              <div className="form-group--login" style={{ width: '70% !important', gap: 5 }}>
                <label htmlFor="password" style={{ color: '#fff', fontWeight: '500' }} className="form-label">Password</label>
                <input
                  autoComplete="off"
                  type="password"
                  name="password"
                  maxLength={70}
                  tabIndex={1}
                  value={formik.values?.password}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  className={`form-control-data--login ${formik.errors?.password && formik.touched.password ? 'select-box-error' : ''}`}
                  id="password"
                />
                {formik.errors?.password && formik.touched.password && <div className="error-message-display">{formik.errors?.password}</div>}
              </div>

              <label className="check-keep-me-signed-in">
                <input className="checkbox-keep-me-signed-in" type="checkbox" name="RememberMe" onChange={formik.handleChange} />
                Keep me signed in on this device.
              </label>
              <button
                type="submit" 
                className="login-btn"
                disabled={isLoading}
              >
                Log In
              </button>
            <Link className="forgot-password" onClick={handleForgotPasswordClick} to={""}>
              Forgot password?
            </Link>
            </form>
            {!isDemoEnvironment && (
            <>
            <div className="divider-container__login">
              <div className="divider-line__login"></div>
              <div className="divider-text__login">or continue with</div>
              <div className="divider-line__login"></div>
            </div>
            <div className='social-buttons__container'>
              <GoogleLogin
                className='social-login-btn Google'
                clientId={CLIENT_ID}
                buttonText="Google"
                onSuccess={responseGoogle}
                onFailure={responseGoogle}
                cookiePolicy="single_host_origin"
                scope="profile email"
                render={renderProps => (
                  <button className='social-login-btn Google' onClick={renderProps.onClick}>Google <i className="bi bi-google"></i></button>
                )}
              />
              <button className='social-login-btn Windows' onClick={handleLoginMSAL}>Windows <i className="fab fa-windows"></i></button>
              <button className='social-login-btn Apple'>Apple <i className="fab fa-apple"></i></button>
            </div>
            </>
            )}
            </div>
            <div className="login--first__termspriv" style={{ display: 'flex', justifyContent: 'center', height: ''  }}>
                <p><a href="https://law.pons.io/legal/terms-of-service/" target="_blank" rel="noopener noreferrer">Terms of service</a> | <a href="https://law.pons.io/legal/privacy-policy/" target="_blank" rel="noopener noreferrer">Privacy Policy</a></p>
              </div>
            </div>
            <i style={{ fontSize: 650, position: "absolute", bottom: '-240px', left: '-320px', color: '#333', opacity: '1', transform: 'rotate(-15deg)' }} className="fa-kit fa-logo"></i>
            </>
        )}
        
      </div>
    </>
  );
}

export default LoginSection;