import { createTheme } from '@mui/material/styles';

const theme = createTheme({
    components: {
      MuiTextField: {
        styleOverrides: {
          root: {
            width: '100%',
            marginBottom: '20px',
            '& .MuiInputBase-input': {
              fontSize: '1.5rem',
              paddingBottom: '0px',  
              lineHeight: '1.2', 
              gap: '1px'
            },
            '& .MuiInputLabel-root': {
              fontSize: '1.5rem',
            },
            '& .MuiInput-underline:before': { 
              lineHeight: '1.2',
            },
            '& .MuiInput-underline:after': { 
              lineHeight: '1.2',
            },
          },
        },
      },
    },
  });
  

export default theme;
