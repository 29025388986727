import React, { useState } from 'react';
import './../Settings.css'; 
import { useTranslation } from 'react-i18next';

const NotificationSettingsComponent: React.FC = () => {
  const [mobilePush, setMobilePush] = useState(false);
  const [emailNotifications, setEmailNotifications] = useState(false)
  const {t} = useTranslation()

  const handleToggleMobilePush = () => {
    setMobilePush(!mobilePush);
  };

  const handleToggleEmailNotifications = () => {
    setEmailNotifications(!emailNotifications);
  };

  return (
    <div className="notification-settings-container">
      <h1 className="notification-settings-title">{t('Notification Settings')}</h1>
      <div className="notification-settings-form">
        <div className="notification-settings-field">
          <label className="notification-settings-label">{t('Mobile Push Notifications')}</label>
          <div className="notification-settings-toggle">
            <input
              type="checkbox"
              checked={mobilePush}
              onChange={handleToggleMobilePush}
              className="notification-settings-checkbox"
            />
            <span className="notification-settings-slider"></span>
          </div>
        </div>
        <div className="notification-settings-field">
          <label className="notification-settings-label">{t('Email Notifications')}</label>
          <div className="notification-settings-toggle">
            <input
              type="checkbox"
              checked={emailNotifications}
              onChange={handleToggleEmailNotifications}
              className="notification-settings-checkbox"
            />
            <span className="notification-settings-slider"></span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NotificationSettingsComponent;
