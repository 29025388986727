import React, { useEffect, useState } from 'react';
import '../Marketplace.css';
import ChatInterface from './ChatInterface';
import { CaseData } from '../Interfaces/ICaseData';
import InvoicePaymentScreen from './InvoicePayments';
import CaseDocumentation from './CaseDocumentation';
import { ICaseItem } from '../Interfaces/ICaseItem';
import { useDispatch, useSelector } from 'react-redux';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { RootState, useAppDispatch } from '../../../store';
import { BiddingStatusUpdate, fetchCaseList, updateBiddingStatus, updateCaseStatus, fetchBids, fetchCaseBidding } from '../../../store/actions/marketplace/caseActions';
import { useTranslation } from 'react-i18next';
import { getUserID, getUserType } from '../../../store/actions/ChatAction';
import { RatingsData, fetchRatings } from '../../../store/actions/marketplace/lawyerAction';
import { Stars } from './LawyerProfileModal';
import { BiddingDataProps, BiddingState } from '../../../store/reducer/marketplace/bidReducer';
import { IFullCaseData, LawyerDetails } from '../Interfaces/IMarketCase';
import { IAdminCaseItem } from '../Interfaces/IAdminCaseItem';
import { LawyerData } from './ClientSection';

interface LCMCaseDetailsModalProps {

  lcmCase: IFullCaseData | null;
  onUpdate?: (updatedLCMCase: CaseData) => void;
  formatNumber: (value: string) => String;
  CaseItem: ICaseItem | IAdminCaseItem;
  fullCase: IFullCaseData;
}

interface ToggleSwitchProps {
  status: 'Published' | 'Off Market';
  handleToggle: () => void;
}

const ToggleSwitch: React.FC<ToggleSwitchProps> = ({ status, handleToggle }) => {
  const { t } = useTranslation()
  return (
    <div className="toggle-switch__bid-type">
      <button
        className={`button left ${status === 'Published' ? 'active' : ''}`}
        onClick={handleToggle}
      >
        {t('Published')}
      </button>
      <button
        className={`button right ${status === 'Off Market' ? 'active' : ''}`}
        onClick={handleToggle}
      >
        {t('Off Market')}
      </button>
      <div className={`slider-otp ${status === 'Off Market' ? 'right' : ''}`}></div>
    </div>
  );
};



const BidItem: React.FC<{    
  bid: BiddingDataProps; formatNumber: (value: string) => String; setSelectedBid: (bid: BiddingDataProps | null) => void; selectedBid: string | null;  caseItem: any; fullCase: IFullCaseData; }> = ({ bid, formatNumber, setSelectedBid, caseItem, selectedBid, fullCase }) => {
  const { t } = useTranslation()

  const userRatings = useSelector((state: RootState) => state.lawyers.ratingsData[bid.UserID] as RatingsData);
  const [showDetails, setShowDetails] = useState(false);
  const dispatch = useAppDispatch();

  const getBidClass = (status: string) => {
    switch (status) {
      case 'Approved':
        return 'bid-approved';
      case 'Declined':
        return 'bid-declined';
      default:
        return 'bid-pending';
    }
  };

  // const handleBidClick = () => {
  //   if (bid.Status !== 'Declined') {
  //     setShowDetails(!showDetails);
  //     setSelectedBid(bid.BiddingID);
  //   }
  // };

  const handleAccept = () => {
    const biddingDetails: BiddingStatusUpdate = {
      UserID: getUserID(),
      UserType: getUserType() as 'Client' | 'Employee',
      BiddingID: bid.BiddingID,
      Status: "Approved"
    };
    dispatch(updateBiddingStatus(biddingDetails));
    dispatch(fetchBids(caseItem.CaseID))
  };

  const handleDecline = () => {
    const biddingDetails: BiddingStatusUpdate = {
      UserID: getUserID(),
      UserType: getUserType() as 'Client' | 'Employee',
      BiddingID: bid.BiddingID,
      Status: "Declined"
    };
    dispatch(updateBiddingStatus(biddingDetails));
  };


  useEffect(() => {
    if (showDetails && bid.UserID && bid.UserType === 'Lawyer') {
      
      if (!userRatings) {
        dispatch(fetchRatings(bid.UserID, bid.UserType));
      }
    }
  }, [showDetails, bid.UserID, bid.UserType, dispatch,  userRatings]);


  useEffect(() => {
    return () => {
      setShowDetails(false); // Reset on component unmount
    };
  }, []);

  const activeLawyer = fullCase?.ActiveLawyerDetails[0]; 

  // const handleViewProfile = () => {
  //   if (activeLawyer) {
  //     handleLawyerClick(activeLawyer); // Pass the activeLawyer directly
  //   }
  // }

  return (
    <>
      <div className={`bid-item__container ${getBidClass(bid.Status)} ${selectedBid === bid.BiddingID ? 'selected' : ''}`} onClick={() => setShowDetails(!showDetails)}>
        <div className='bid-item__header'>
          {/* <i className='fas fa-ticket'></i> */}
          <div>
        <p className='bid-item__username'>{bid.LawyerName}</p>
        <p style={{ color: '#ccc', fontStyle: 'italic', margin: 0 }}>{activeLawyer.City}, {activeLawyer.Country}</p>
        </div>
        </div>
        {/* <div className='bid-item__div'>
        </div>
        <div className='bid-item__div'>
        </div> */}
        <div className='bid-price-tag'>
        <p>80 {t('Cases')}</p>
        <Stars rating={4.5} />
          {/* <p>{formatNumber(bid.LawyerMaxBudget)} {bid.LawyerBudgetCurrency}</p> */}
        </div>
      </div>
      {showDetails && (
        <div className='bid-item__details'>
          <div className='bit-item__wrapper'>
            {activeLawyer && (
              <>
              <div className='bid-item__profile--header--wrapper'>
              <div className='bid-item__profile--header'>
                <img src={`${activeLawyer.ProfilePicture ? activeLawyer.ProfilePicture : require("../../../resources/dummy-profile-pic-male1.jpg") }  `} alt="Profile"  />
                <div>
                <p>{activeLawyer.FirstName} {activeLawyer.LastName}</p>
                <p style={{ color: '#ccc', fontStyle: 'italic' }}>{activeLawyer.City}, {activeLawyer.Country}</p>
                </div>
            </div>
            <div className='bid-item__top'>
              <button>{t('View profile')}</button>
              <p> <span>{t('Agreed price')}</span> <span className='bid-item__pricing'>{formatNumber(bid.LawyerMaxBudget)} {formatNumber(bid.LawyerBudgetCurrency)}</span></p>
              <p><span>{t('per hour')}</span> <span className='bid-item__pricing'>{activeLawyer.HourlyRate} {formatNumber(bid.LawyerBudgetCurrency)}</span></p>
            </div>
                </div>
                <div className='bid-item__bottom'>
                <p>{t('Expertise')}</p>
                <p className='bid-item__expertise'>{activeLawyer.PracticeArea}</p>
                {/* <p>{t('Biography')}</p>
                <p className='bid-item__lawyerbio'>{activeLawyer.Biography}</p> */}
                {userRatings && (
                  <p>Rating: {userRatings.AverageRating} from {userRatings.TotalCases} cases</p>
                )}
                </div>
              </>
            )}

            <div className='bid-item__message--container'>
              <p style={{ margin: '5px 0px' }}>{t('Message')}</p>
              <p className='bid-item__message'>{bid.Message}</p>
            </div>
                  <div className='bid-item__actions'>
                    <button disabled={(bid.Status === "Approved" || bid.Status === "Declined" ) ? true : false} className={`accept  ${(bid.Status === "Approved" || bid.Status === "Declined" ) ? 'disable' : ""} `} onClick={handleAccept}>{t('Accept')}</button>
                    <button disabled={(bid.Status === "Approved" || bid.Status === "Declined" ) ? true : false} className={`decline  ${(bid.Status === "Approved" || bid.Status === "Declined" ) ? 'disable' : ""} `} onClick={handleDecline}>{t('Decline')}</button>
                  </div>
          </div>
        </div>
      )}
    </>
  );
};

const LCMCaseDetailsContent: React.FC<LCMCaseDetailsModalProps> = ({ lcmCase,formatNumber, CaseItem, fullCase }) => {
  const [selectedBid, setSelectedBid] = useState<BiddingDataProps | null>(null);
  const dispatch = useDispatch<ThunkDispatch<RootState, undefined, AnyAction>>();
  const [activeContent, setActiveContent] = useState('CASE_DETAILS');
  const [isMarketToggle, setIsMarketToggle] = useState(lcmCase?.Status === 'Published');
  const caseId = lcmCase?.CaseID;
  const { t } = useTranslation();
  const bid = useSelector((state: RootState) => state.bid as BiddingState);
  const bids = bid.biddingData


  useEffect(() => {
    setIsMarketToggle(lcmCase?.Status === 'Published');
  }, [lcmCase?.Status]);

  useEffect(() => {
    setActiveContent('CASE_DETAILS')
    dispatch(fetchCaseBidding(CaseItem!.CaseID, "Approved"))
  }, [CaseItem,dispatch])

  const isValidStatus = (status: string): status is "Published" | "Off Market" => {
    return status === "Published" || status === "Off Market";
  };

  const handleMarketToggle = async () => {
    if (caseId && isValidStatus(lcmCase.Status)) {
      const newStatus = isMarketToggle ? 'Off Market' : 'Published';
      setIsMarketToggle(!isMarketToggle);

      try {
        await dispatch(updateCaseStatus(caseId, newStatus));
        dispatch(fetchCaseList());
      } catch (error) {
        console.error("Failed to update case status", error);
      }
    }
  };


  const handleSetActiveContent = (content: string) => {
    setActiveContent(content);
  };

  const handleExitFullView = () => {
    setSelectedBid(null);
  };


  if (!lcmCase) return null;

  const handleSendMessage = (message: string) => {

  };

  const renderActiveContent = () => {
    switch (activeContent) {
      case 'BIDS':
        return (
          <>
            {bids && bids.length === 0 ? (
              <div className="no-bids-message">
                {t('No bids have been received for this case')}
              </div>
            ) : (
              <>
                {!selectedBid ? (
                  <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
                    {bids.map((bid: BiddingDataProps) => (
                      <BidItem fullCase={fullCase} key={bid.BiddingID} bid={bid} setSelectedBid={setSelectedBid} selectedBid={selectedBid} formatNumber={formatNumber}  caseItem={CaseItem}/>
                    ))}
                  </div>
                ) : (
                  <div className="bid-full-view">
                    <div>
                      <p>{selectedBid.LawyerBudgetCurrency} {formatNumber(selectedBid.LawyerMinBudget)} - {formatNumber(selectedBid.LawyerMaxBudget)}</p>
                      <p>{selectedBid.LawyerName}</p>
                    </div>
                    <button onClick={handleExitFullView}>{t('Exit')}</button>
                  </div>
                )}
              </>
            )}
          </>
        );
      case 'COMMUNICATION':
        return (
          <>
            
            <ChatInterface onSendMessage={handleSendMessage} CaseItem={CaseItem} />


          </>

        );
      case 'PAYMENT':
        return (
          <>
            <InvoicePaymentScreen />
          </>
        );
      case 'DOCUMENTATION':
        return (
          <>
            <CaseDocumentation />
          </>
        );
      case 'CASE_DETAILS':
      default:
        return (
          <>
            <div className="lcm-case-details">
              <div className='lcm-case-details__client--header'>
              <h2>{lcmCase.CaseType}</h2>
                {lcmCase.Status === "Published" || lcmCase.Status === "Off Market" ? (
                  <ToggleSwitch
                    status={isMarketToggle ? t('Published') : t('Off Market')}
                    handleToggle={handleMarketToggle}
                  />
                ) : (
                  <span className='lcm-case-details__client--status' style={lcmCase.Status === "Ongoing" ? { backgroundColor: 'green', color: '#fff' } : {}}>{t(`${lcmCase.Status}`)}</span>
                )}


              </div>

                <div className='lcm-case-details--info-wrapper'>
              <div className='lcm-case-details__row'>
                <p>{t('Urgency')}</p><p className='lcm-case-details__urgency'>{lcmCase.Urgency}</p>
              </div>

              <div className='lcm-case-details__row'>
                <div className='lcm-case-details__row' style={{ marginRight: 10 }}>
                  <p>{t('Jurisdiction')}</p>
                  <p className='lcm-case-details__div--default'>{lcmCase.CountryOfJurisdiction}</p>
                </div>

                <div className='lcm-case-details__row'>
                  <p>{t('Preferred Language')}</p>
                  <p className='lcm-case-details__div--default'>{lcmCase.PreferredLanguage}</p>
                </div>
              </div>



              <div className='lcm-case-details__row'>
                <p>{t('Budget Range')}</p> <p
                className='lcm-case-details__div--default price'>{formatNumber(lcmCase.MinBudget)} - {formatNumber(lcmCase.MaxBudget)} {lcmCase.Currency}</p>
              </div>
              <div className='lcm-case-details__row'>
                <p>{t('Desired Outcome')}</p>
                <p className='lcm-case-details__div--default'>{t(`${lcmCase.DesiredOutcome}`)}</p>
              </div>

              <div className='lcm-case-details__row'>
                <p>{t('Expected Outcome')}</p>
                <p className='lcm-case-details__div--default'>{t(`${lcmCase.ExpectedOutcome}`)}</p>
              </div>
                <div>
              <p>{t('Attachments')}</p>

              <div className='lcm-case-details__row--documents'>
                {lcmCase.AttachmentsLink && lcmCase.AttachmentsLink.length > 0 ? (
                  lcmCase.AttachmentsLink.map((file, index) => (
                    <p key={index} className='lcm-case-detailes__attachments'>
                      {file}
                    </p>
                  ))
                ) : (
                  <p className='lcm-case-detailes__attachments'>{t('No attachments')}</p>
                )}
              </div>
              </div>
              </div>

                <p>{t('Description')}</p>
              <div className='lcm-case-details__description'>
                <p className='lcm-case-details__description--p'>{lcmCase.Description}</p>
              </div>
            </div>
              <div className='lcm-case-details__header'>
                <button>{t('View Legal Case Mapping')}</button>
              </div>
            <div>
            </div>
          </>
        );
    }
  };


  return (
    <div className='lcm-modal-content__mycase'>
      <div className='lcm-modal-details__top'>
        <div onClick={() => handleSetActiveContent('BIDS')}>
          <i className='fa-solid fa-money-check-dollar' style={{ opacity: activeContent === 'BIDS' ? 1 : 0.2 }}></i>
          <h2>{t('Bids')}</h2>
        </div>

        <div onClick={() => handleSetActiveContent('COMMUNICATION')}>
          <i className='fa-solid fa-comments' style={{ opacity: activeContent === 'COMMUNICATION' ? 1 : 0.2 }}></i>
          <h2>{t('Inbox & Meetings')}</h2>
        </div>

        <div onClick={() => handleSetActiveContent('PAYMENT')}>
          <i className='fa-solid fa-hand-holding-circle-dollar' style={{ opacity: activeContent === 'PAYMENT' ? 1 : 0.2 }}></i>
          <h2>{t('Finance & Invoice')}</h2>
        </div>

        <div onClick={() => handleSetActiveContent('DOCUMENTATION')}>
          <i className='fa-solid fa-gavel' style={{ opacity: activeContent === 'DOCUMENTATION' ? 1 : 0.2 }}></i>
          <h2 >{t('Case Documentation')}</h2>
        </div>
      </div>

      <div className='lcm-modal-details__bottom'>
        {activeContent !== 'CASE_DETAILS' && (
          <div className='back-to-case-details' onClick={() => handleSetActiveContent('CASE_DETAILS')}>
            <i className='fa-regular fa-chevron-left' ></i>
          </div>
        )}
        {renderActiveContent()}
      </div>
    </div>
  );

};

export default LCMCaseDetailsContent;
