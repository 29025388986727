import AiAssistant from "../components/AIAssistant/AiAssistant";

function AIAssistantPage() {

    return (
        <>
            < AiAssistant />
        </>
    )
}

export default AIAssistantPage;