import React, { useEffect, useState } from 'react';
import './FolderStructure.css';
import { FolderProps } from '../Documents/Files';
import { useSelector } from 'react-redux';
import { RootState, useAppDispatch } from '../../store';
import { ChatGroupData, chatGroupApi, deleteChatGroup, getUserID, getUserType, setDocumentVisibility, setFolderVisibility, deleteChatHistory, chatHistoryApi } from '../../store/actions/ChatAction';
import ApiLoader from '../ApiLoader';
import { createNewDocument } from '../../store/actions/DocumentsAction';
import NewFileModal, { NewFileData } from '../Documents/NewFileModal';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { ToggleSwitchTheme } from '../Documents/Editor';
import { hideModal, showAddFileModal, showModal } from '../../store/actions/modalActions';
import { FileProps, StructureProps } from '../../pages/DocumentsPage';
import { useTranslation } from 'react-i18next';
import dayjs, { Dayjs } from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { ClipLoader } from 'react-spinners';
import { Tooltip } from '@mui/material';
import AISettingsComponent from '../Settings/AccountSettings/AISettings';

dayjs.extend(customParseFormat);
interface FolderStructureProps {
  openFile: () => void;
  folderState: string;
  isFolderStructureVisible: boolean;
  onFileClick: (file: FileProps) => void;
  selectedFile: FileProps | null;
  isEditing: boolean;
  onEditSaveClick: () => void;
  toggleFolderStructure: (folderType: string) => void;
  isDocumentVisible: boolean;
  fileContent: string;
  setFileContent: React.Dispatch<React.SetStateAction<string>>;
  onRevertClick: () => void;
  folders: StructureProps[];
  chatName: string;
  chatGroups: ChatGroupData[] | null
  handleChatHistory: (id: string) => void
  setStartNewChat: (value: boolean) => void
  userId: string
  setIsDarkTheme: () => void;
  handleSave: () => void;
  handleSelectAIVoice: (value: string) => void;
  handleSelectAILanguage: (value: string) => void;
  handleSelectFontSize: (value: string) => void;
  toggleTheme: () => void;
  isDarkTheme: boolean;
  selectedAILanguage: string;
  selectedVoice: string;
  selectedFontSize: string;
  handleSaveChanges: () => void
  setDocumentVisible: () => void;
  selectedModel: string
}


const FolderStructure: React.FC<FolderStructureProps> = ({ onFileClick, selectedFile, isEditing, openFile, setStartNewChat, isFolderStructureVisible, folders, chatName, chatGroups, handleChatHistory, userId, isDocumentVisible, setIsDarkTheme, handleSave, handleSelectAIVoice, handleSelectAILanguage, handleSelectFontSize, isDarkTheme, selectedVoice, selectedFontSize, handleSaveChanges, setDocumentVisible, selectedModel }) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const isLoading = useSelector((state: RootState) => state.folders.loading);
  const foldersDropdown = useSelector((state: RootState) => state.folders.folders);
  const [currentView, setCurrentView] = useState<'FOLDERS' | 'CHAT_HISTORY' | 'FILE' | 'AI_SETTINGS'>('FOLDERS');
  const [searchQuery, setSearchQuery] = useState('');
  const [searchFolderQuery, setSearchFolderQuery] = useState('');
  const dispatch = useAppDispatch()
  const [selectedFolderId, setSelectedFolderId] = useState<string[]>([]);
  const [selectedChatGroupId, setSelectedChatGroupId] = useState<string>("");
  const isNewFileModalOpen = false;
  const [emailInput, setEmailInput] = useState('');
  const [selectedDate, setSelectedDate] = React.useState<Dayjs | null>(dayjs());
  const [showAssigneeDropdown, setShowAssigneeDropdown] = useState(false);
  const [assignees, setAssignees] = useState(['user1@example.com', 'user2@example.com']);
  const [showShareDropdown, setShowShareDropdown] = useState(false);
  const [shareExamples, setShareExamples] = useState(['user1@example.com', 'user2@example.com']);
  const [isDeadlineOpen, setIsDeadlineOpen] = useState(false);
  const { t } = useTranslation();
  const selectedAILanguage = 'Norwegian';
  const [filteredChatGroups, setFilteredChatGroups] = useState<ChatGroupData[] | undefined>([]);
  const [isChatLoading, setIsChatLoading] = useState(false);
  const [activeFolders, setActiveFolders] = useState<StructureProps[]>(folders);
  const userType = getUserType()


  const voices = [
    { label: 'Norwegian Male', id: 'nb-NO-FinnNeural' },
    { label: 'Norwegian Female', id: 'nb-NO-PernilleNeural' },
    { label: 'Spanish Male', id: 'es-ES-DarioNeural' },
    { label: 'Spanish Female', id: 'es-ES-ElviraNeural' },
    { label: 'English Male', id: 'en-US-AndrewMultilingualNeural' },
    { label: 'English Female', id: 'en-US-AvaMultilingualNeural' },
    { label: 'Danish Male', id: 'da-DK-JeppeNeural' },
    { label: 'Danish Female', id: 'da-DK-ChristelNeural' },
    { label: 'Swedish Male', id: 'sv-SE-MattiasNeural' },
    { label: 'Swedish Female', id: 'sv-SE-HilleviNeural' },
    { label: 'Finland Male', id: 'fi-FI-HarriNeural' },
    { label: 'Finland Female', id: 'fi-FI-NooraNeural' }
  ];

  useEffect(() => {
    const storedChatGroupID = localStorage.getItem('chatGroupID');
    if (storedChatGroupID) {
      setSelectedChatGroupId(storedChatGroupID)
    }
    const handleResize = () => {
      if (window.innerWidth < 768) {
        dispatch(setFolderVisibility(false));
      }
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [dispatch]);

  const handleFileDragStart = (event: React.DragEvent<HTMLDivElement>, file: FileProps) => {
    event.stopPropagation();
    const fileData = {
      type: 'file',
      documentURL: file.FileUrl,
      title: file.FileName
    };
    event.dataTransfer.setData("application/json", JSON.stringify(fileData));
  };

  const handleFolderDragStart = (event: React.DragEvent<HTMLDivElement>, folder: FolderProps) => {
    event.stopPropagation();

    const folderData = {
      type: 'folder',
      folderId: folder.FolderID
    };

    event.dataTransfer.setData("application/json", JSON.stringify(folderData));
  };

  useEffect(() => {
    setActiveFolders(folders)
    const filtered = chatGroups?.filter(group =>
      group.ChatTitle && group.ChatTitle.replace(/"/g, "").toLowerCase().includes(searchQuery.toLowerCase())
    );
    setFilteredChatGroups(filtered);
  }, [searchQuery, chatGroups, folders]);



  const handleDeleteClick = (chatGroupId: string) => {
    dispatch(showModal({
      type: 'confirmation',
      message: 'Are you sure you want to delete this chat group?',
      onConfirm: () => handleConfirmDelete(chatGroupId),
      onCancel: handleCancel,
      showModal: true,
    }));
  };

  const handleConfirmDelete = async (chatGroupId: string) => {
    try {
      dispatch(hideModal());
      setIsChatLoading(true);
      await dispatch(deleteChatGroup(chatGroupId));

      dispatch(chatGroupApi(userId, 'LegalAdvisor', getUserType()) as any);

      if (chatGroups && chatGroups.length > 0) {
        setFilteredChatGroups(chatGroups);
      } else {
        setFilteredChatGroups([]);
      }
    } catch (error) {
      console.error('Failed to delete chat group or fetch groups:', error);
    } finally {
      setIsChatLoading(false);
    }
  };




  const handleCancel = () => {
    dispatch(hideModal());
  };


  const handleNewChat = () => {
    setStartNewChat(true);
    localStorage.removeItem('chatGroupID');
  };


  const addAssignee = () => {
    if (emailInput && !assignees.includes(emailInput)) {
      setAssignees([...assignees, emailInput]);
      setEmailInput('');
    }
  };
  const removeAssignee = (email: string) => {
    setAssignees(assignees.filter(assignee => assignee !== email));
  };
  const addAccess = () => {
    if (emailInput && !shareExamples.includes(emailInput)) {
      setShareExamples([...shareExamples, emailInput]);
      setEmailInput('');
    }
  };
  const removeAccess = (email: string) => {
    setShareExamples(shareExamples.filter(access => access !== email));
  };

  const toggleAssigneeDropdown = () => {
    setShowAssigneeDropdown(!showAssigneeDropdown);
  };

  const toggleShareDropdown = () => {
    setShowShareDropdown(!showShareDropdown)
  }

  const toggleDeadline = () => {
    setIsDeadlineOpen(!isDeadlineOpen)
  }

  const toggleTheme = () => {
    setIsDarkTheme();
  };

  const toggleView = (view: 'FOLDERS' | 'CHAT_HISTORY' | 'FILE' | 'AI_SETTINGS') => {
    if (view === 'FOLDERS' || view === 'CHAT_HISTORY' || view === 'AI_SETTINGS') {
      dispatch(setDocumentVisibility(!isDocumentVisible))
    } else if (currentView === 'FILE') {
      openFile();
      dispatch(setDocumentVisibility(isDocumentVisible))
    }

    setCurrentView(view);
    setIsDropdownOpen(false);
  };

  function addSpacesBeforeCaps(str: string): string {
    return str.replace(/([A-Z])/g, ' $1').trim();
  }

  const renderChatHistory = () => {
    if (isChatLoading) {
      return <ClipLoader color="#fff" size={80} />;
    }


    return (
      <div className="chat-history__section">
        <input
          type="text"
          className="chat-history__search-bar"
          placeholder={t('Search Chat History')}
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
        {
          filteredChatGroups && filteredChatGroups.length > 0 ? (
            <>
              <ul>
                {filteredChatGroups.map((group, index) => (
                  <li
                    key={index}
                    className={selectedChatGroupId === group.ChatGroupID ? "selected" : ""}
                    title={group.ChatTitle}
                  >
                    <div onClick={() => {
                      handleChatHistory(group.ChatGroupID);
                      setSelectedChatGroupId(group.ChatGroupID);

                    }}>
                      <span>{group.ChatTitle.replace(/"/g, "")}</span>
                      <span className='chat-groups--aitype'>{t(addSpacesBeforeCaps(group.AIType))}</span>
                    </div>
                    <i onClick={() => handleDeleteClick(group.ChatGroupID)} className='fas fa-xmark'></i>
                  </li>
                ))}
              </ul>
            </>
          ) : (
            <div className='no-chat-history'>No history</div>
          )
        }
      </div>
    );
  };


  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleFileClick = (file: FileProps) => {
    if (file.Status === "Active") {
      onFileClick(file);
      openFile();
      dispatch(setDocumentVisibility(isDocumentVisible))
      setCurrentView('FILE');
      // setRecentFile(file);
      if (selectedFile) {
        dispatch(setDocumentVisibility(isDocumentVisible))
      }
      // DocumentContent(file); 
    }
    else {
    }
  };

  const handleFolderToggle = (event: React.MouseEvent<HTMLDivElement>) => {
    dispatch(setFolderVisibility(!isFolderStructureVisible))

  };

  const folderStructureClass = isFolderStructureVisible ? 'folder-structure-visible' : 'folder-structure-hidden';

  const handleFolderClick = (folderId: string) => {
    setSelectedFolderId((prevOpenFolderIds) =>
      prevOpenFolderIds.includes(folderId)
        ? prevOpenFolderIds.filter((id) => id !== folderId)
        : [...prevOpenFolderIds, folderId]
    );
  };

  const openNewFileModal = () => {
    if (folders && folders.length > 0) {
      dispatch(showAddFileModal(foldersDropdown.map((folder: FolderProps) => ({
        id: folder.FolderID,
        label: folder.FolderName
      }))));
    } else {
      // Handle the case where folders data isn't ready yet
      console.error("Folders data is unavailable.");
    }
  };

  const handleNewFileSave = (fileData: NewFileData) => {
    const { folderId, fileName, fileType, file, additionalFields } = fileData;

    if (!userId) {
      console.error('User ID is null. Cannot create document.');
      return;
    }

    // Build additionalFields based on fileType
    let additionalFieldsData: { [key: string]: string | string[] } = {};
    switch (fileType) {
      case 'Contract':
        additionalFieldsData = {
          FileName: fileName,
          PartiesInvolved: additionalFields.PartiesInvolved || [],
          ContractStart: additionalFields.StartDate || '',
          Approvers: additionalFields.Approvers || [],
          Assignees: additionalFields.Assignees || [],
          Expiry: additionalFields.Expiry || '',
        };
        break;
      case 'Legal Document':
        additionalFieldsData = {
          FileName: fileName,
          LegalType: additionalFields.LegalType || '',
          CaseID: additionalFields.CaseID || '',
          Expiry: additionalFields.Expiry || '',
        };
        break;
      case 'General':
      case 'Template':
        additionalFieldsData = {
          FileName: fileName,
          Expiry: additionalFields.Expiry || '',
        };
        break;
      default:
        console.error('Unknown file type:', fileType);
        return;
    }

    dispatch(
      createNewDocument(
        fileType,
        folderId.toString(),
        file,
        additionalFieldsData
      )
    );
  };
  const darkTheme = createTheme({
    palette: {
      mode: 'dark',
    },
  });

  const renderFolders = (folders: StructureProps[], level = 0) => {
    return folders.map((folder) => (
      <div key={folder.FolderID} className={`folder-level-${level}`}>
        <div
          className="folder-item"
          onClick={() => handleFolderClick(folder.FolderID)}
          style={{ paddingLeft: `${level * 15}px` }}
        >
          <div className="folder-item--inner">
            {level === 0 ? (
              <>
                <i className="fa-solid fa-bars"></i>
              </>
            ) : (
              <i className="fa-solid fa-level-up subfolder-marker"></i>
            )}
            <p>{folder.FolderName}</p>
            <span>{folder.Documents?.length || 0}</span>
          </div>
        </div>
        {selectedFolderId.includes(folder.FolderID) && (
          <div className="folder-content">
            <ul className="file-list-open">
              {(folder.Documents || []).map((file) => (
                <div
                  className="file-item"
                  key={file.FileID}
                  onClick={() => handleFileClick(file)}
                  draggable
                  onDragStart={(e) => handleFileDragStart(e, file)}
                  style={{ paddingLeft: `${(level + 1) * 10}px`, marginLeft: level > 0 ? '0px' : '0px' }}
                >
                  {file.FileName}
                </div>
              ))}
              {renderFolders(folder.SubFolders || [], level + 1)}
            </ul>
          </div>
        )}
      </div>
    ));
  };

  const handleClearChat = () => {
    dispatch(deleteChatHistory(selectedChatGroupId, userId, userType))
    handleChatHistory(selectedChatGroupId)
  }

  const renderEmailCircles = (emails: string[] | string | undefined) => {
    if (!emails || emails.length === 0) return <span>{t('None')}</span>;
    const emailArray = typeof emails === 'string' ? emails.split(',').map(email => email.trim()) : emails;
    return emailArray.map((email, index) => (
      <Tooltip title={email} key={index} content={email}>
        <div className="email-circle">
          {email[0]?.toUpperCase() || "?"}
        </div>
      </Tooltip>
    ));
  };

  const filterFolders = (folders: StructureProps[], query: string): StructureProps[] => {
    return folders
      .map((folder) => {
        const filteredSubFolders = filterFolders(folder.SubFolders || [], query);
        const filteredDocuments = folder.Documents.filter((doc) =>
          doc.FileName.toLowerCase().includes(query.toLowerCase())
        );

        if (
          folder.FolderName.toLowerCase().includes(query.toLowerCase()) ||
          filteredDocuments.length > 0 ||
          filteredSubFolders.length > 0
        ) {
          // Automatically expand folders with matches
          if (!selectedFolderId.includes(folder.FolderID)) {
            setSelectedFolderId((prev) => [...prev, folder.FolderID]);
          }
          return {
            ...folder,
            Documents: filteredDocuments,
            SubFolders: filteredSubFolders,
          };
        }
        return null;
      })
      .filter(Boolean) as StructureProps[];
  };

  useEffect(() => {
    if (searchFolderQuery) {
      setActiveFolders(filterFolders(folders, searchFolderQuery));
    } else {
      setActiveFolders(folders);
      setSelectedFolderId([]); 
    }
  }, [searchFolderQuery, folders]);

  return (

    <div className={`folder-structure ${folderStructureClass}`}>
      <div className="folder-structure-container">

        {currentView === 'FILE' ? (
          <>
            {/* Document Information Section */}
            <div className='folder-structure__header--wrapper'>
              <i onClick={handleFolderToggle} className="fas fa-chevron-right icon"></i>  {/* Folder Arrow Right */}
              <h3>
              {t("File")}
              </h3>
              <i className='bi bi-three-dots' onClick={toggleDropdown}></i>
              {isDropdownOpen && (
                <>
                  <div className="dropdown-menu__folders">
                    <div className="exit-icon-wrapper">
                      <p>{chatName}</p>
                      <i onClick={toggleDropdown} className="bi bi-x-circle-fill"></i>
                    </div>
                    <div onClick={handleNewChat} className="dropdown-item__folders"><p>{t('New Chat')}</p><i className="fa-solid fa-message-plus"></i></div>

                    <div onClick={() => toggleView('FILE')} className={`dropdown-item__folders ${currentView === 'FILE' ? "selectedview" : ""}`}>
                      <p>{t('Active File')}</p>
                      <i className="fa-solid fa-folder"></i>
                    </div>

                    <div onClick={() => toggleView('FOLDERS')} className="dropdown-item__folders">
                      <p>{t('Folders')}</p>
                      <i className="fa-solid fa-folder"></i>
                    </div>

                    <div onClick={() => toggleView('CHAT_HISTORY')} className="dropdown-item__folders">
                      <p>{t('Chat History')}</p>
                      <i className="fa-solid fa-rectangle-vertical-history"></i>
                    </div>

                    <div onClick={openNewFileModal} className="dropdown-item__folders"><p>{t('New File')}</p><i className="fa-solid fa-file-circle-plus"></i></div>
                    {isNewFileModalOpen && (
                      <NewFileModal
                        folders={folders}
                        onSave={handleNewFileSave}
                        currentUserId={getUserID()}
                      />
                    )}
                    {
                      selectedChatGroupId && (
                        <div onClick={() => handleClearChat()} className="dropdown-item__folders"><p>{t('Clear Chat')}</p><i className="bi bi-eraser-fill"></i></div>
                      )
                    }
                    <div onClick={() => toggleView('AI_SETTINGS')} className="dropdown-item__folders">
                      <p>{t('AI Settings')}</p>
                      <i className="fa-solid fa-gear"></i>
                    </div>
                  </div>


                </>
              )}
            </div>

            <div className='folder-section__document--wrapper'>

              <div className="folder-section--document">
                <>
                  <div className="document-card">
                    <div className='document-card__information'>

                      <h2>{selectedFile?.FileName}</h2>
                      <button
                        onClick={handleSave}
                        className="action-btn save"
                        disabled={!isEditing}
                      >
                        {t('Save')}
                      </button>


                      <button className="action-btn" onClick={toggleAssigneeDropdown}>
                        <span>{t("Add Assignees")}</span>
                        <i className="fa-solid fa-file-signature"></i>
                      </button>

                      {showAssigneeDropdown && (
                        <div className="assignee-dropdown">
                          <div className="input-group">
                            <input
                              type="text"
                              className="search-assignee"
                              placeholder={t("Assignee E-Mail...")}
                              value={emailInput}
                              onChange={e => setEmailInput(e.target.value)}
                            />
                            <button className="add-assignee-btn" onClick={addAssignee}><i className="fa-solid fa-plus"></i></button>
                          </div>
                          <ul className="assignee-list">
                            {assignees.map((assignee, index) => (
                              <li key={index} className="assignee-item">
                                <p title={assignee}>{assignee}</p>
                                <button className="remove-assignee-btn" onClick={() => removeAssignee(assignee)}><i className="fa-solid fa-xmark"></i></button>
                              </li>
                            ))}
                          </ul>
                        </div>
                      )}


                      <button className="action-btn" onClick={toggleShareDropdown}>
                        <span>{t("Share Access")}</span>
                        <i className="fa-regular fa-share-nodes"></i>
                      </button>

                      {showShareDropdown && (
                        <div className="assignee-dropdown">
                          <div className="input-group">
                            <input
                              type="text"
                              className="search-assignee"
                              placeholder={t("Enter E-Mail...")}
                              value={emailInput}
                              onChange={e => setEmailInput(e.target.value)}
                            />
                            <button className="add-assignee-btn" onClick={addAccess}><i className="fa-solid fa-plus"></i></button>
                          </div>
                          <ul className="assignee-list">
                            {shareExamples.map((example, index) => (
                              <li key={index} className="assignee-item">
                                <p title={example}>{example}</p>
                                <button className="remove-assignee-btn" onClick={() => removeAccess(example)}><i className="fa-solid fa-xmark"></i></button>
                              </li>
                            ))}
                          </ul>
                        </div>
                      )}


                      <button className="action-btn deadline" onClick={toggleDeadline}>
                        <span>{t("Set Deadline")}</span>
                        <i className="fa-regular fa-calendar-check"></i>
                      </button>

                      {isDeadlineOpen && (
                        <div className='date-picker-container'>
                          <ThemeProvider theme={darkTheme}>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <DatePicker
                                value={selectedDate}
                                onChange={(newValue) => {
                                  setSelectedDate(newValue);
                                }}

                              />
                            </LocalizationProvider>
                          </ThemeProvider>
                        </div>
                      )}

                      <button className="action-btn action-btn__sign ">
                        <span>{t("Sign Document")}</span>
                        <i className="fa-kit fa-sign-document"></i>
                      </button>

                    <div className='info-sections'>
                  {selectedFile?.SharedWith && (
                    <div className='info-section--documentai'>
                      <h3>{t("Shared With")}</h3>
                      <div className='info-section__content'>
                        {renderEmailCircles(selectedFile.SharedWith)}
                      </div>
                    </div>
                  )}

                  {selectedFile?.Assignees && (
                    <div className='info-section--documentai'>
                      <h3>{t("Assignees")}</h3>
                      <div className='info-section__content'>
                        {renderEmailCircles(selectedFile.Assignees)}
                      </div>
                    </div>
                  )}

                  {selectedFile?.Approvers && (
                    <div className='info-section--documentai'>
                      <h3>{t("Approvers")}</h3>
                      <div className='info-section__content'>
                        {renderEmailCircles(selectedFile.Approvers)}
                      </div>
                    </div>
                  )}

                  {selectedFile?.PartiesInvolved && (
                    <div className='info-section--documentai'>
                      <h3>{t("Parties Involved")}</h3>
                      <div className='info-section__content'>
                        {renderEmailCircles(selectedFile.PartiesInvolved)}
                      </div>
                    </div>
                  )}
                </div>

                  </div>
                    <div className="theme-toggle">
                      <ToggleSwitchTheme isOn={isDarkTheme} onToggle={toggleTheme} />
                    </div>

                    <button className='folder-structure__showhidedoc' onClick={() => setDocumentVisible()}>{isDocumentVisible ? t('Hide Document') : t('Show Document')}</button>
                  </div>
                </>
              </div>
            </div>
          </>
        ) : (
          <>
              <div className='folder-structure__header--wrapper'>
                <h3>
              <i onClick={handleFolderToggle} className="fas fa-chevron-right icon"></i>
              {(currentView === 'FOLDERS' && t('Folders')) || (currentView === 'CHAT_HISTORY' && t('Chat History')) || (currentView === 'AI_SETTINGS' && t('AI Settings'))}
              </h3>
              <div className="dropdown-container__folders">
                <i onClick={toggleDropdown} className="bi bi-three-dots"></i>
                {isDropdownOpen && (
                  <>
                    <div className="dropdown-menu__folders">
                      <div className="exit-icon-wrapper">
                        <p>{chatName}</p>
                        <i onClick={toggleDropdown} className="bi bi-x-circle-fill"></i>
                      </div>
                      <div onClick={handleNewChat} className="dropdown-item__folders"><p>{t('New Chat')}</p><i className="fa-solid fa-message-plus"></i></div>

                      {isDocumentVisible && (
                        <div onClick={() => toggleView('FILE')} className={`dropdown-item__folders`}>
                          <p>{t('Active File')}</p>
                          <i className="fa-solid fa-folder"></i>
                        </div>
                      )}

                      <div onClick={() => toggleView('FOLDERS')} className={`dropdown-item__folders ${currentView === "FOLDERS" ? "selectedview" : ""}`}>
                        <p>{t('Folders')}</p>
                        <i className="fa-solid fa-folder"></i>
                      </div>

                      <div onClick={() => toggleView('CHAT_HISTORY')} className={`dropdown-item__folders ${currentView === "CHAT_HISTORY" ? "selectedview" : ""}`}>
                        <p>{t('Chat History')}</p>
                        <i className="fa-solid fa-rectangle-vertical-history"></i>
                      </div>

                      <div onClick={openNewFileModal} className="dropdown-item__folders"><p>{t('New File')}</p><i className="fa-solid fa-file-circle-plus"></i></div>
                      {isNewFileModalOpen && (
                        <NewFileModal
                          folders={folders}
                          onSave={handleNewFileSave}

                          currentUserId={getUserID()}
                        />
                      )}
                      {
                        selectedChatGroupId && (
                          <div onClick={() => handleClearChat()} className="dropdown-item__folders"><p>{t('Clear Chat')}</p><i className="bi bi-eraser-fill"></i></div>
                        )
                      }
                      <div onClick={() => toggleView('AI_SETTINGS')} className={`dropdown-item__folders ${currentView === "AI_SETTINGS" ? "selectedview" : ""}`}>
                        <p>{t('AI Settings')}</p>
                        <i className="fa-solid fa-gear"></i>
                      </div>
                    </div>

                  </>
                )}
              </div>
            </div>
            {currentView === 'CHAT_HISTORY' ? (
              <div className="folder-section">
                {renderChatHistory()}
              </div>
            ) : currentView === 'AI_SETTINGS' ? (
              <div className='folder-structure__ai-settings'>
                <AISettingsComponent style={{ flexDirection: 'column', alignItems: 'flex-start', gap: 10 }} />
              </div>
            ) : (
              <>
                <div className="folder-section">
                  <input
                    type="text"
                    className="ai-folder__search-bar"
                    placeholder={t('Search for any document')}
                    value={searchFolderQuery}
                    onChange={(e) => setSearchFolderQuery(e.target.value)}
                  />
                  <div className="folder-section__items">
                    {isLoading ? (
                      <div className="api-loader-container"><ApiLoader /></div>
                    ) : (
                      activeFolders.length > 0 ? (
                        activeFolders.map((folder) => (
                          <div
                            key={folder.FolderID}
                            className="folder-item"
                            draggable
                            onDragStart={(e) => handleFolderDragStart(e, folder)}
                          >
                            <div className="folder-item--inner" onClick={() => handleFolderClick(folder.FolderID)}>
                              <p>{folder.FolderName}</p>
                              <span>{folder.Documents?.length || 0}</span>
                            </div>
                            {selectedFolderId.includes(folder.FolderID) && (
                              <ul className="file-list-open">
                                {folder.Documents?.map((file) => (
                                  <div
                                    className="file-item"
                                    key={file.FileID}
                                    onClick={() => handleFileClick(file)}
                                    draggable 
                                    onDragStart={(e) => handleFileDragStart(e, file)}
                                    style={{ paddingLeft: '15px' }}
                                  >
                                    {file.FileName}
                                  </div>
                                ))}
                                {folder.SubFolders && renderFolders(folder.SubFolders, 1)}
                              </ul>
                            )}
                          </div>
                        ))
                      ) : (
                        <div style={{ textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', height: '80vh' }}>
                          <p style={{ color: '#fff', fontSize: '1rem' }}>{t('No Folders')}</p>
                        </div>
                      )
                    )}
                  </div>

                </div>
                {/* <div className='folder-structure__bottom-section'>

                  <div className='folder-structure__bottom-section--column'>
                    <p>{t('AI model')}
                    </p>
                    <span className='folder-structure__bottom-section--value'>
                      {selectedModel}
                    </span>
                  </div>

                  <div className='folder-structure__bottom-section--column'>
                    <p>{t('Remaining inquiries')}</p>
                    <span className='folder-structure__bottom-section--value'>
                      5
                    </span>
                  </div>

                  <button className='folder-structure__bottom-section--button'>
                    <span>{t('Buy more')}</span>
                  </button>

                </div> */}
              </>
            )}
          </>
        )}
      </div>
    </div>
  );
}

export default FolderStructure;