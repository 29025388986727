import React, { useState, useEffect } from 'react';
import "./Preferences.css"
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RootState, useAppDispatch } from '../../../store';
import { currencyList, getPreferences, updatePreferences } from '../../../store/actions/DataAction';
import { getUserID, getUserType } from '../../../store/actions/ChatAction';
import { changeLanguage } from '../../../i18n/i18n';
import AISettingsComponent from './AISettings';

const languages = [
  { code: 'en', name: 'English' },
  { code: 'es', name: 'Spanish' },
  { code: 'nor', name: "Norwegian" }
];

const PreferencesSection: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [isCurrencyOpen, setIsCurrencyOpen] = useState(false);
  const [isLanguageOpen, setIsLanguageOpen] = useState(false);
  const currencies = useSelector((state: RootState) => state.lists.currencies);
  const preferences = useSelector((state: RootState) => state.lists.preferences);
  const [selectedLanguage, setSelectedLanguage] = useState(languages[0]);
  const [selectedCurrency, setSelectedCurrency] = useState<{ code: string; name: string }>({ code: '', name: '' });
  const [communicationPreference, setCommunicationPreference] = useState('SMS');
  const userId = getUserID();
  const userType = getUserType();

  useEffect(() => {
    dispatch(getPreferences(userType));
    dispatch(currencyList());
  }, [dispatch, userId, userType]);

  useEffect(() => {
    if (preferences) {
      const language = languages.find(lang => lang.code === preferences.Language) || languages[0];
      setSelectedLanguage(language);
      setSelectedCurrency({ code: preferences.Currency, name: preferences.CurrencyName });
      setCommunicationPreference(preferences.Communication);
      changeLanguage(preferences.Language);
    }
  }, [preferences]);

  const handleLanguageChange = (code: string, name: string) => {
    setSelectedLanguage({ code, name });
    changeLanguage(code);
    updatePreferencesOnServer({ Language: code });
    setIsLanguageOpen(false)
  };

  const handleCurrencyChange = (code: string, name: string) => {
    setSelectedCurrency({ code, name });
    updatePreferencesOnServer({ Currency: code, CurrencyName: name });
    setIsCurrencyOpen(false)
  };

  const updatePreferencesOnServer = (updatedFields: Partial<typeof preferences>) => {
    const updatedPreferences = {
      UserID: userId,
      UserType: userType,
      Communication: updatedFields.Communication || communicationPreference,
      Language: updatedFields.Language || selectedLanguage.code,
      Currency: updatedFields.Currency || selectedCurrency.code,
      CurrencyName: updatedFields.CurrencyName || selectedCurrency.name,
      ThemeColor: 'dark',
    };

    dispatch(updatePreferences(updatedPreferences));
  };

  return (
    <div className="preferences-container">
      <>
        <div className="preferences-container__toggles">
          <h2>{t('Platform Preferences')}</h2>
          <div className='preferences-options'>
            <div className="theme-preference">
              <div className="preference-text">
                <p>{t('Language')}</p>
                <span className="preference-description">{t('Choose your preferred language.')}</span>
              </div>
              <div>
                <div className="global-dropdown">
                  <div className={`global-dropdown-header language`} onClick={() => setIsLanguageOpen(!isLanguageOpen)}>
                    <span style={{ fontSize: "0.9rem" }}>{selectedLanguage.name}</span>
                    <i className={`fas fa-chevron-${isLanguageOpen ? 'up' : 'down'}`} style={{ fontSize: "18px" }}></i>
                  </div>
                  {isLanguageOpen && (
                    <div className="global-dropdown-list">
                      {languages.map((option) => (
                        <div key={option.code} style={{ fontSize: '0.8rem' }} className="global-dropdown-item" onClick={() => handleLanguageChange(option.code, option.name)}>
                          {option.name}
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div className="theme-preference">
              <div className="preference-text">
                <p>{t('Currency')}</p>
                <span className="preference-description">{t('Choose your preferred currency.')}</span>
              </div>
              <div>
                <div className="global-dropdown currency">
                  <div className={`global-dropdown-header language`} onClick={() => setIsCurrencyOpen(!isCurrencyOpen)}>
                    <span style={{ fontSize: "0.9rem" }}>{selectedCurrency.name || t('Select currency')}</span>
                    <i className={`fas fa-chevron-${isCurrencyOpen ? 'up' : 'down'}`} style={{ fontSize: "18px" }}></i>
                  </div>
                  {isCurrencyOpen && (
                    <div className="global-dropdown-list">
                      {currencies?.map((option: { Currency: string; Name: string }) => (
                        <div key={option.Currency} className="global-dropdown-item" style={{ fontSize: '0.8rem' }} onClick={() => handleCurrencyChange(option.Currency, option.Name)}>
                          {option.Name}
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              </div>
            </div>

          </div>
          {/* <AISettingsComponent /> */}
        </div>


      </>


    </div>
  );
};

export default PreferencesSection;
