import React, { useEffect } from "react";

const StripePricingTable: React.FC<{ userType: string }> = ({ userType }) => {
    useEffect(() => {
      const loadTypeformScript = () => {
        const script = document.createElement('script');
        script.src = '//embed.typeform.com/next/embed.js';
        script.async = true;
        document.body.appendChild(script);
  
        return () => {
          document.body.removeChild(script);
        };
      };
  
      if (userType === 'lawFirm') {
        loadTypeformScript();
      }
    }, [userType]); 
  
    switch (userType) {
      case 'client':
        return React.createElement('stripe-pricing-table', {
          'pricing-table-id': 'prctbl_1Pqd20CKZInHFLia2UFemMAj',
          'publishable-key': 'pk_live_51NUAIpCKZInHFLiaMGTspIzhw1mnR0josNTWmiNx8i5FB6h4Hb9t1mi3defTQBXbuA5Ec9OxsdDjnXOopaHnK0Hn00sFvOvFQu'
        });
      case 'business':
        return React.createElement('stripe-pricing-table', {
          'pricing-table-id': 'prctbl_1PqdOFCKZInHFLiaDmdZsepV',
          'publishable-key': 'pk_live_51NUAIpCKZInHFLiaMGTspIzhw1mnR0josNTWmiNx8i5FB6h4Hb9t1mi3defTQBXbuA5Ec9OxsdDjnXOopaHnK0Hn00sFvOvFQu'
        });
      case 'lawyer':
        return React.createElement('stripe-pricing-table', {
          'pricing-table-id': 'prctbl_1PqcctCKZInHFLianvQmZTuQ',
          'publishable-key': 'pk_live_51NUAIpCKZInHFLiaMGTspIzhw1mnR0josNTWmiNx8i5FB6h4Hb9t1mi3defTQBXbuA5Ec9OxsdDjnXOopaHnK0Hn00sFvOvFQu'
        });
      case 'lawFirm':
        return <>
        <div style={{ display: 'flex', justifyContent: 'center' }} data-tf-live="01J6CQPV771JFWFHD8S802C74M"></div>;
        </>
        // React.createElement('stripe-pricing-table', {
        //   'pricing-table-id': 'your_pricing_table_id_for_lawFirm',
        //   'publishable-key': 'your_publishable_key_for_lawFirm'
        // });
      default:
        return null;
    }
  };

  export default StripePricingTable;