import React, { useEffect, useRef } from 'react';
import './SignUpRequirements.css';
// import { ReactComponent as Logo } from '../../resources/logo.svg';
import Divider from '@mui/material/Divider';
import termsConditions from '../../resources/TermsConditions.json';

interface Props {
  changeSection: (section: string) => void;
}

interface Section {
  title: string;
  content?: string;
  services?: Array<{ name: string; description: string; }>;
  requirements?: string[];
  subsections?: { [key: string]: Subsection };
  items?: string[];
}

interface Subsection {
  title: string;
  content: string;
}

interface TermsConditionsJSON {
  [key: string]: Section;
}

const TermsConditions: React.FC<Props> = ({ changeSection }) => {
  const topElement = useRef<HTMLDivElement>(null);

  useEffect(() => {
    topElement.current?.scrollIntoView();
  }, []);

  const renderContent = (section: Section) => {
    return (
      <>
        <h2>{section.title}</h2>
        {section.content && <p>{section.content}</p>}
        {section.services && section.services.map((service, index) => (
          <p key={index}><strong>{service.name}:</strong> {service.description}</p>
        ))}
        {section.requirements && section.requirements.map((req, index) => (
          <p key={index}>{req}</p>
        ))}
        {section.subsections && Object.values(section.subsections).map((subsec, index) => (
          <div key={index}>
            <h3>{subsec.title}</h3>
            <p>{subsec.content}</p>
          </div>
        ))}
        {section.items && section.items.map((item, index) => (
          <p key={index}>{item}</p>
        ))}
      </>
    );
  };

  return (
    <div ref={topElement} className='requirements-section'>
      <div className='requirements-container'>
        <div className='requirements__company-stamp'>
          {/* <Logo className="company-logo-requirements" /> */}
          <h1 className='pons-text'>PONS LABS</h1>
        </div>
        <h1 className='requirements-header'>Terms & Conditions</h1>
        <div className='info-placement'>
          {Object.values(termsConditions as unknown as TermsConditionsJSON).map((section, index) => (
            <div key={index}>
              {renderContent(section)}
            </div>
          ))}
        </div>
        <Divider variant="middle" style={{borderColor: '#fff'}} />
        <div className='requirements-btns'>
          <button onClick={() => changeSection("signup")} className='back-to-sign-up__btn'>I Understand</button>
        </div>
      </div>
    </div>
  );
};

export default TermsConditions;
