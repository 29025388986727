import { Dispatch } from "react";
import apis from "../../../utils/apis";
import { GET_RATINGS_REQUEST, GET_RATINGS_SUCCESS, GET_RATINGS_FAILURE, SUBMIT_BID_FAILURE, SUBMIT_BID_REQUEST, SUBMIT_BID_SUCCESS, FETCH_PROPOSAL_SUCCESS, FETCH_PROPOSAL_FAILURE } from "../../types";
import axios, { AxiosError } from "axios";
import { ErrorResponse } from "../SignInAction";
import { showFeedbackModal } from "../UserFeedbackActions";
import { Action, AnyAction } from "redux";
import { RootState } from "../..";
import { ThunkDispatch } from "redux-thunk";


export interface RatingsData {
  AllReviews: {
    CaseID: string;
    Rating: string;
    Review: string;
  }[];
  AverageRating: number;
  TotalCases: number;
}

export interface BidDetails {
  CaseID: string;
  UserID: string;
  UserType: 'Lawyer' | 'LawfirmLawyer';
  Message: string;
  LawyerMinBudget: string;
  LawyerMaxBudget: string;
  LawyerBudgetCurrency: 'USD' | 'NOK' | 'EUR';
}

export interface ApiError {
  message: string;
  code: number;
}

type RatingAction = {
  type: string;
  payload?: RatingsData | ApiError;
};


const getRatingsRequest = () => ({
  type: GET_RATINGS_REQUEST,
});

const getRatingsSuccess = (data: RatingsData) => ({
  type: GET_RATINGS_SUCCESS,
  payload: data,
});

const getRatingsFailure = (error: ApiError) => ({
  type: GET_RATINGS_FAILURE,
  payload: error,
});

export const submitBidRequest = () => ({
  type: SUBMIT_BID_REQUEST,
});

export const submitBidSuccess = (data: BidDetails) => ({
  type: SUBMIT_BID_SUCCESS,
  payload: data,
});

export const submitBidFailure = (error: ApiError) => ({
  type: SUBMIT_BID_FAILURE,
  payload: error,
})


export const fetchRatings = (userId: string, userType: string) => async (dispatch: Dispatch<RatingAction>) => {
  dispatch(getRatingsRequest());
  try {


    const response = await apis({
      method: 'POST',
      url: '/ratings/getRating',
      data: {
        UserID: userId,
        UserType: userType,
      }
    });
    dispatch(getRatingsSuccess(response.data));
  } catch (error: any) {
    dispatch(getRatingsFailure(error));
  }
};

export const fetchProposals = (userId: string, userType: string) => async (dispatch: ThunkDispatch<RootState, undefined, AnyAction>) => {
  try {
    const response = await apis({
      method: 'POST',
      url: 'market/getProposedCases',
      data: {
        UserID: userId,
        UserType: userType,
      }
    });
    dispatch({
      type: FETCH_PROPOSAL_SUCCESS,
      payload: response.data
    });
  } catch (error: any) {
    dispatch({
      type: FETCH_PROPOSAL_FAILURE,
      payload: error
    });
  }
};


export const submitBid = (bidDetails: BidDetails) => async (dispatch: ThunkDispatch<RootState, undefined, Action>) => {
  dispatch(submitBidRequest());
  try {

    const response = await apis({
      method: 'POST',
      url: 'market/case/acceptClientBidding',
      data: bidDetails
    });
    if (response && response.status === 200) {
      dispatch(submitBidSuccess(response.data));
      dispatch(showFeedbackModal({
        showModal: true,
        message: 'Bid successfully sent!',
        modalType: 'success',
        duration: 3000
      }));
    } else {
      if (axios.isAxiosError(response)) {
        const axiosError = response as AxiosError;
        if (axiosError.response && axiosError.response.data) {
          let erroMessage = axiosError.response.data as ErrorResponse
          dispatch(showFeedbackModal({
            showModal: true,
            message: (erroMessage.message || 'An unknown error occurred'),
            modalType: 'error',
            duration: 3000
          }));
        } else {
          dispatch(showFeedbackModal({
            showModal: true,
            message: 'Network error, please try again',
            modalType: 'error',
            duration: 3000
          }));
        }
      }
    }

  } catch (error: any) {
    dispatch(submitBidFailure(error));
    dispatch(showFeedbackModal({
      showModal: true,
      message: (error.message),
      modalType: 'error',
      duration: 3000
    }));
  }
};