import { AnyAction } from "redux";
import { SUBMIT_BID_REQUEST, SUBMIT_BID_SUCCESS, SUBMIT_BID_FAILURE, UPDATE_BIDDING_STATUS_FAILURE, UPDATE_BIDDING_STATUS_REQUEST, UPDATE_BIDDING_STATUS_SUCCESS, FETCH_BIDDING_SUCCESS } from "../../types";
import { BiddingStatusUpdate } from "../../actions/marketplace/caseActions";

export interface BiddingDataProps {
  BiddingID: string,
  CaseID: string,
  LawyerBudgetCurrency: string,
  LawyerEmail: string,
  LawyerMaxBudget: string,
  LawyerMinBudget: string,
  LawyerName: string,
  LawyerPhoneNumber: string,
  Message?: string,
  Status: string,
  UserID: string,
  UserType: string,
}

export interface BiddingState {
  bidSubmissionStatus:  BiddingStatusUpdate | null,
  biddingData: BiddingDataProps[]
}

const initialState: BiddingState = {
  bidSubmissionStatus: null,
  biddingData: []
};

export const bidReducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case FETCH_BIDDING_SUCCESS:
      return {
        ...state,
        biddingData: action.payload
      };
    case SUBMIT_BID_REQUEST:
      return {
        ...state,
        isLoading: true,
        bidSubmissionStatus: null
      };
    case SUBMIT_BID_SUCCESS:
      return {
        ...state,
        isLoading: false,
        bidSubmissionStatus: 'success'
      };
    case SUBMIT_BID_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
        bidSubmissionStatus: 'failure'
      };
    case UPDATE_BIDDING_STATUS_REQUEST:
      return {
        ...state,
        loading: true
      };
    case UPDATE_BIDDING_STATUS_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case UPDATE_BIDDING_STATUS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    
    default:
      return state;
  }
};
