import React, { useEffect, useState } from "react";
import './Help.css';
import Accordion from "../accordion/Accordion";
import { getUserType, getUserID } from "../../store/actions/ChatAction";
import { useTranslation } from "react-i18next";
import { RootState, useAppDispatch } from "../../store";
import { ShowHelp } from "../../store/actions/modalActions";
import { useSelector } from "react-redux";
import { createTicket, fetchAllUserTickets, fetchCategories, fetchHelp, fetchUserHelp } from "../../store/actions/HelpAction";
import { Formik } from "formik";
import * as Yup from "yup";
import GlobalDropdown, { DropdownOption } from "../shared/GlobalDropdown";
import { ClipLoader } from "react-spinners";

export interface TicketItem {
  category: string;
  client_email: string;
  created_at: string;
  description: string;
  resolved: boolean;
  status: string;
  subject: string;
  ticket_id: string;
  updated_at: string;
  user_id: string;
  user_type: string;
}

const formatHelpContent = (content: string): string => {
    content = content.replace(/\d+\.\s+/g, match => `<li>${match.slice(3)}</li>`);
    if (content.includes('<li>')) {
      content = `<ul>${content}</ul>`;
    }
    content = content.replace(/\n/g, '<br>');
    content = content.replace(/### (.*?)(<br>|$)/g, '<h3><strong>$1</strong></h3>');
    content = content.replace(/#### (.*?)(<br>|$)/g, '<h4><strong>$1</strong></h4>');
    content = content.replace(/##### (.*?)(<br>|$)/g, '<h5><strong>$1</strong></h5>');
    content = content.replace(/###### (.*?)(<br>|$)/g, '<h6><strong>$1</strong></h6>');
    content = content.replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>');
    content = content.replace(/\*(.*?)\*/g, '<em>$1</em>');
    return content;
};

const Help: React.FC = () => {
  const userType = getUserType();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const [categoryOptions, setCategoryOptions] = useState<DropdownOption[]>([]);
  const allHelp = useSelector((state: RootState) => state.help.allHelp);
  const categories = useSelector((state: RootState) => state.help.categories);
  const userHelp = useSelector((state: RootState) => state.help.userHelp);
  const userTickets = useSelector((state: RootState) => state.help.allUserTickets);
  const [isHelpLoading, setIsHelpLoading] = useState(false);
  const [isUserHelpLoading, setIsUserHelpLoading] = useState(false);
  const [isCategoriesLoading, setIsCategoriesLoading] = useState(false);
  const [isTicketsLoading, setIsTicketsLoading] = useState(false);

  useEffect(() => {
    setIsHelpLoading(true);
    dispatch(fetchHelp()).finally(() => setIsHelpLoading(false));

    setIsUserHelpLoading(true);
    dispatch(fetchUserHelp()).finally(() => setIsUserHelpLoading(false));

    setIsCategoriesLoading(true);
    dispatch(fetchCategories()).finally(() => setIsCategoriesLoading(false));

    setIsTicketsLoading(true);
    dispatch(fetchAllUserTickets()).finally(() => setIsTicketsLoading(false));
  }, [dispatch]);

  useEffect(() => {
    if (categories) {
      const options = categories.map((category: { id: string; name: string }) => ({
        id: category.id,
        label: category.name,
      }));
      setCategoryOptions(options);
    }
  }, [categories]);

  const faqs = userHelp ? userHelp.filter((helpItem: any) =>
    helpItem.helpType === "FAQ" && helpItem.usertype === userType
  ) : [];

  const midIndex = Math.ceil(faqs.length / 2);
  const firstHalf = faqs.slice(0, midIndex);
  const secondHalf = faqs.slice(midIndex);

  const handleClick = (header: string, content: string) => {
    dispatch(ShowHelp(header, formatHelpContent(content)));
  };

  const initialValues = {
    Subject: '',
    Description: '',
    Category: ''
  };

  const validationSchema = Yup.object({
    Subject: Yup.string().required(t('Subject is required')),
    Description: Yup.string().required(t('Description is required')),
    Category: Yup.string().required(t('Category is required')),
  });

  const onSubmit = async (values: any, { setSubmitting, resetForm }: { setSubmitting: (isSubmitting: boolean) => void, resetForm: () => void }) => {
    const userId = getUserID(); 
    const userType = getUserType();
    const ticketData = {
      UserID: userId,
      UserType: userType,
      ...values
    };
    await dispatch(createTicket(ticketData)).then(() => {
      resetForm(); 
    }).finally(() => setSubmitting(false));
    setIsTicketsLoading(true);
    dispatch(fetchAllUserTickets()).finally(() => setIsTicketsLoading(false));
  };

  const getStatusColor = (status: string) => {
    switch (status) {
      case "Open":
        return "rgb(66, 212, 66)"; 
      case "Resolved":
        return "#64d2ff"; 
      default:
        return "#ccc"; 
    }
  };

  return (
    <div className="help-container">
      <div className="help-top__section">
        <div className="help-intro__section" style={{ flex: 0.4 }}>
          <h2 className="help-heading">{t('What you will find here')}</h2>
          <div className="help-content">
            <p>{t("Welcome to the PONS Help Center!")}</p>
            <p>{t("Here, you'll find resources to guide you through using our platform effectively.")}</p>
            <ul>
              <li><span style={{ color: '#64d2ff' }}>{t('General Help')}</span> {t("Quick start and basic operations.")}</li>
              <li><span style={{ color: '#64d2ff' }}>{('Tips')}</span> {t("Strategies for effective platform use.")}</li>
              <li><span style={{ color: '#64d2ff' }}>{t('Visual Guidance')}</span> {t("Videos on platform usage.")}</li>
              <li><span style={{ color: '#64d2ff' }}>{t('FAQs')}</span> {t("Answers to common questions.")}</li>
              <li><span style={{ color: '#64d2ff' }}>{t('Data Policy')}</span> {t("Info on data handling and user rights.")}</li>
              <li><span style={{ color: '#64d2ff' }}>{t('Updates')}</span> {t("Latest on version control and updates.")}</li>
              <li><span style={{ color: '#64d2ff' }}>{t('Support')}</span> {t("Send us a support ticket if you need help.")}</li>
            </ul>
          </div>
        </div>
        <div className="help-intro__section" style={{ flex: 0.7 }}>
          <h2 className="help-heading">{t('General Help')}</h2>
          <div className={`${!isHelpLoading && 'help-tips__container--wrapper'}`}>
            {isHelpLoading ? (
              <div className="loader-container">
                <ClipLoader size={35} color={"#64d2ff"} />
              </div>
            ) : (
              userHelp && userHelp.length > 0 && (
                userHelp.filter((helpItem: any) => helpItem.helpType === "General Help").map((info: any) => (
                  <div key={info.helpID} className="help-tips__container" onClick={() => handleClick(info.container, info.content)}>
                    {t(info.container)}
                  </div>
                ))
              )
            )}
          </div>
          <h2 className="help-heading">{t('Tips for Effective Platform Use')}</h2>
          <div className={`${!isHelpLoading && 'help-tips__container--wrapper'}`}>
            {isUserHelpLoading ? (
              <div className="loader-container">
                <ClipLoader size={35} color={"#64d2ff"} />
              </div>
            ) : (
              userHelp && userHelp.length > 0 && (
                userHelp.filter((helpItem: any) => helpItem.helpType === "Tips").map((info: any) => (
                  <div key={info.helpID} className="help-tips__container" onClick={() => handleClick(info.container, info.content)}>
                    {t(info.container)}
                  </div>
                ))
              )
            )}
          </div>
        </div>
      </div>
      <div style={{ marginTop: 10, backgroundColor: '#1e1f21', borderRadius: 10, paddingTop: 10, marginRight: 20, paddingBottom: 15 }}>
        <h2 className="help-subheading">{t('Frequently Asked Questions')}</h2>
        <div className="faq-container">
          {isUserHelpLoading ? (
            <div className="loader-container">
              <ClipLoader size={35} color={"#64d2ff"} />
            </div>
          ) : (
            faqs.length > 0 ? (
              <>
                <div className="faq-column">
                  {firstHalf.map((info: any) => (
                    <Accordion key={info.helpID} title={t(info.question)} content={t(info.answer)} />
                  ))}
                </div>
                <div className="faq-column">
                  {secondHalf.map((info: any) => (
                    <Accordion key={info.helpID} title={t(info.question)} content={t(info.answer)} />
                  ))}
                </div>
              </>
            ) : (
              <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }} className="non-access">{t('No FAQs available')}</div>
            )
          )}
        </div>
      </div>
      <div className="help-ps__container">
        {isCategoriesLoading ? (
          <div className="loader-container">
            <ClipLoader size={35} color={"#64d2ff"} />
          </div>
        ) : (
          allHelp && allHelp.length > 0 && (
            allHelp.filter((helpItem: any) => helpItem.helpType === "DataInfo").slice(0, 3).map((info: any) => (
              <div key={info.helpID} onClick={() => handleClick(info.container, info.content)} className="help-ps__item version">
                <p>{t(info.container)}</p>
                <span title={t('Current Version')} className="current-version-tag">{info.version}</span>
              </div>
            ))
          )
        )}
      </div>
      <h2 className="subheader-support">{t('Support')}</h2>
      <div className="help-support--row">
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {({ isSubmitting, handleSubmit, handleChange, handleBlur, values, setFieldValue, errors, touched }) => (
            <form onSubmit={handleSubmit} className="support-ticket-form">
              <div className="support-ticket-form--row">
                <div className="form-group--support">
                  <label htmlFor="Subject">{t('Subject')}</label>
                  <input
                    type="text"
                    id="Subject"
                    name="Subject"
                    value={values.Subject}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className={errors.Subject && touched.Subject ? 'input-error' : ''}
                  />
                  {errors.Subject && touched.Subject && <div className="error-message">{errors.Subject}</div>}
                </div>
                <div className="form-group--support">
                  <label htmlFor="Category">{t('Category')}</label>
                  <GlobalDropdown
                    options={categoryOptions}
                    onSelect={(id) => setFieldValue('Category', id)}
                    selectedLabel={values.Category ? categoryOptions.find(option => option.id === values.Category)?.label || '' : ''}
                    style={{ width: '100%' }}
                  />
                  {errors.Category && touched.Category && <div className="error-message">{errors.Category}</div>}
                </div>
              </div>
              <div className="form-group--support">
                <label htmlFor="Description">{t('Description')}</label>
                <textarea
                  id="Description"
                  name="Description"
                  value={values.Description}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className={errors.Description && touched.Description ? 'input-error' : ''}
                />
                {errors.Description && touched.Description && <div className="error-message">{errors.Description}</div>}
              </div>
              <button type="submit" disabled={isSubmitting}>
                {isSubmitting ? t('Submitting...') : t('Submit Ticket')}
              </button>
            </form>
          )}
        </Formik>
        <div className="ticket-list-container">
          <h3>{t('Your Tickets')}</h3>
          <ul className="ticket-list">
            {isTicketsLoading ? (
              <div className="loader-container">
                <ClipLoader size={35} color={"#64d2ff"} />
              </div>
            ) : (
              userTickets && userTickets.length > 0 ? (
                userTickets.map((ticket: TicketItem) => (
                  <li key={ticket.ticket_id} className="ticket-item">
                    <div className="ticket-subject">{t(ticket.subject)}</div>
                    <div className="ticket-status" style={{ color: getStatusColor(ticket.status) }}>
                      {t(ticket.status)}
                    </div>
                  </li>
                ))
              ) : (
                <div style={{ display: 'flex', justifyContent: 'center', color: '#64d2ff', alignItems: 'center', height: '100%' }}>
                  {t('No tickets')}
                </div>
              )
            )}
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Help;
