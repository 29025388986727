import { AnyAction } from 'redux';
import { FETCH_DOCUMENTS_REQUEST, FETCH_DOCUMENTS_SUCCESS, FETCH_DOCUMENTS_FAILURE, CREATE_DOCUMENT_REQUEST, CREATE_DOCUMENT_SUCCESS, CREATE_DOCUMENT_FAILURE, DELETE_DOCUMENT_REQUEST, DELETE_DOCUMENT_SUCCESS, DELETE_DOCUMENT_FAILURE, RESET_SELECTED_DOCUMENT, SELECT_DOCUMENT_FOR_EDITING, ADD_SIGNATURE_FAILURE, ADD_SIGNATURE_REQUEST, ADD_SIGNATURE_SUCCESS, FETCH_RECOMMENDED_DOCUMENTS_REQUEST, FETCH_RECOMMENDED_DOCUMENTS_FAILURE, FETCH_RECOMMENDED_DOCUMENTS_SUCCESS } from '../actions/DocumentsAction';
import { FileProps } from '../../pages/DocumentsPage';

interface DocumentState {
    loading: boolean;
    documents: FileProps[];
    recommendedDocuments: FileProps[];
    error: string;
    selectedDocument: FileProps | null;
    signature: string | null;
}


const initialState: DocumentState = {
    loading: true,
    documents: [],
    recommendedDocuments: [],
    error: "",
    selectedDocument: null,
    signature: null,
};

const documentReducer = (state = initialState, action: AnyAction) => {
    switch (action.type) {
        case FETCH_DOCUMENTS_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case FETCH_DOCUMENTS_SUCCESS:
            return {
                ...state,
                loading: false,
                documents: action.payload,
                error: ''
            };
        case FETCH_DOCUMENTS_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload
            };
        case FETCH_RECOMMENDED_DOCUMENTS_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case FETCH_RECOMMENDED_DOCUMENTS_SUCCESS:
            return {
                ...state,
                loading: false,
                recommendedDocuments: action.payload,
                error: ''
            };
        case FETCH_RECOMMENDED_DOCUMENTS_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload
            };
        case CREATE_DOCUMENT_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case CREATE_DOCUMENT_SUCCESS:
            return {
                ...state,
                loading: false,
                documents: [...state.documents, action.payload],
                error: ''
            };
        case CREATE_DOCUMENT_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload
            };
        case DELETE_DOCUMENT_REQUEST:
            return { ...state, loading: true };
        case DELETE_DOCUMENT_SUCCESS:
            return {
                ...state,
                loading: false,
                documents: state.documents.filter(doc => doc.FileID !== action.payload),
                error: ''
            };
        case DELETE_DOCUMENT_FAILURE:
            return { ...state, loading: false, error: action.payload };
        case SELECT_DOCUMENT_FOR_EDITING:
            return {
                ...state,
                selectedDocument: action.payload,
            };

        case RESET_SELECTED_DOCUMENT:
            return {
                ...state,
                selectedDocument: null,
            };
        case ADD_SIGNATURE_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case ADD_SIGNATURE_SUCCESS:
            return {
                ...state,
                loading: false,
                signature: action.payload,
                error: '',
            };
        case ADD_SIGNATURE_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };

        default:
            return state;
    }
};

export default documentReducer;
