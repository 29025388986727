import React, { MutableRefObject, useEffect, useState } from 'react';
import './PricingPlans.css';
import { Link } from "react-router-dom";
import { RootState, useAppDispatch } from '../../store';
import { useSelector } from 'react-redux';
import { ReactComponent as LAIcon } from '../../resources/PricingIcons/LegalAdvisoryIcon.svg'
import { ReactComponent as LCMIcon } from '../../resources/PricingIcons/LCMIcon.svg'
import { ReactComponent as CAIcon } from '../../resources/PricingIcons/AnalysisIcon.svg'
import { ReactComponent as CGIcon } from '../../resources/PricingIcons/CGIcon.svg'
import { fetchPricing, togglePricingMode, toggleSubscriptionPeriod } from '../../store/actions/SignUpAction';

type BasePricing = {
    DisplayName: string;
    Currency: string;
    CurrencySymbol: string;
    Description: string;
    Price: string;
    OldPrice?: string;
    AnnualOld?: string;
    AnnualFee?: string;
    MontlyFee?: string;
    PricingID: string;
    PricingName: string;
    Type: string;
    UserType: string;
    _attachments: string;
    _etag: string;
    _rid: string;
    _self: string;
    _ts: number;
    id: string;
    isBulk: string;
}

type SessionPricing = BasePricing & {
    SessionDuration: string;
    Inqueries: string;
}

type CasePricing = BasePricing & {
    Cases: string;
}

type AnalysisPricing = BasePricing & {
    MaxWord: string;
    MinWords: string;
    Analysis: string;
}

type ContractPricing = BasePricing & {
    MaxWord: string;
    MinWords: string;
    Contracts: string;
}

export type ServiceUsage = {
    ContractAnalysis?: number;
    ContractGeneration?: number;
    DocumentSharing?: number;
    LCM?: number;
    LegalAdvisor?: number;
    Signatures?: number;
    Storage?: number;
};

type SubscriptionPricing = BasePricing & {
    MonthlyFee: string;
    AnnualFee: string;
    IncludedServices: string;
    AdditionalFeatures: string;
    FeatureList: string;
    Included: ServiceUsage;
    Used: ServiceUsage;
    FreeTrialDays?: string;
    FreeInqueries?: string;
};

type LicensePricing = BasePricing & {
    AnnualFee: string;
    MonthlyFee: string;
    IncludedServices: string;
    AdditionalFeatures: string;
    FreeTrialDays?: string;
    FreeTrial?: string;
}

type LawfirmPricing = BasePricing & {
    CustomPricing: string;
    FeatureList: string;
    AdditionalFeatures: string;
}

export type Pricing = SessionPricing | CasePricing | AnalysisPricing | ContractPricing | SubscriptionPricing | LicensePricing | LawfirmPricing;

interface PricingPlansProps {
    userType: string;
    setSelectedPlan: (value: string) => void;
    selectedPlan: string | undefined;
}

export type UserType = 'client' | 'business' | 'lawyer' | 'lawFirm';

const userTypeMessages: Record<UserType, { title: string; message: string }> = {
    client: {
        title: "Available Plans & Products",
        message: "Explore a range of tailored plans designed to meet your individual legal needs.<br />From one-time legal services to comprehensive packages, find the perfect solution to navigate legal challenges effectively."
    },
    business: {
        title: "Available Plans",
        message: "Unlock the full potential of your business with our specialized plans.<br />Whether you're a startup or an established enterprise, our plans provide the legal tools you need to secure your operations and scale seamlessly."
    },
    lawyer: {
        title: "Available Licenses",
        message: "Enhance your legal practice with our flexible licensing options.<br />Tailored for independent lawyers, these licenses offer access to powerful AI tools, marketplace visibility, and resource management features.<br />Streamline your workflow, expand your service offerings, and build your client base with the right license."
    },
    lawFirm: {
        title: "Custom Solution",
        message: "Create a bespoke solution that aligns perfectly with your law firm’s unique requirements.<br />Our custom solutions are designed to integrate seamlessly into your firm's operations, providing scalable options from advanced legal tools to comprehensive document management and tailored analytics.<br /><br />Connect with us to craft your tailored package."
    }
};

// Define type guards
export const isSessionPricing = (plan: Pricing): plan is SessionPricing => 'SessionDuration' in plan;
export const isCasePricing = (plan: Pricing): plan is CasePricing => 'Cases' in plan;
export const isAnalysisPricing = (plan: Pricing): plan is AnalysisPricing => 'Analysis' in plan;
export const isContractPricing = (plan: Pricing): plan is ContractPricing => 'MaxWord' in plan && 'MinWords' in plan;
export const isSubscriptionPricing = (plan: Pricing): plan is SubscriptionPricing => 'FreeInqueries' in plan || 'AnnualFee' in plan;
export const isLicensePricing = (plan: Pricing): plan is LicensePricing => 'FreeTrial' in plan || 'AnnualFee' in plan;
export const isLawfirmPricing = (plan: Pricing): plan is LawfirmPricing => plan.UserType.toLowerCase() === 'lawfirm';


const PricingPlans: React.FC<{   endRef?: HTMLDivElement | null | MutableRefObject<HTMLDivElement | null>  , userType: UserType, setSelectedPlan: Function, selectedPlan: string, setPlanDetails: (plan: Pricing) => void, setInterval: (interval: boolean) => void,   scrollToBottom?: () => void;
}> = ({ userType, setSelectedPlan, selectedPlan, setPlanDetails, setInterval, endRef }) => {
    const dispatch = useAppDispatch();
    const { pricingPlans } = useSelector((state: RootState) => state.pricing);
    const [isBulk, setIsBulk] = useState(false);
    const [isAnnual, setIsAnnual] = useState(false);
    const [showSubscription, setShowSubscription] = useState(true);

    useEffect(() => {
        if (pricingPlans.length === 0) {
            dispatch(fetchPricing());
        }
        setShowSubscription(true);
    }, [dispatch, pricingPlans.length, userType]);


    const toggleBulkMode = () => {
        setIsBulk(!isBulk);
    };

    const togglePeriod = () => {
        setIsAnnual(!isAnnual);
        setInterval(!isAnnual)
    };

    const renderListItems = (items: string) => {
        return items.split(", ").map((item, index) => (
            <li key={index}>
                {item.includes("Everything") ? (
                    <span><i className='fas fa-check-double'></i> {item}</span>
                ) : (
                    <span><i className='fas fa-check'></i> {item}</span>
                )}
            </li>
        ));
    };

    const getPricingIcon = (pricingName: string) => {
        switch (pricingName) {
            case "Legal Advisory Session":
                return <LAIcon />;
            case "Legal Case Mapping":
                return <LCMIcon />;
            case "Contract Analysis":
                return <CAIcon />;
            case "Contract Generation":
                return <CGIcon />;
            default:
                return <i className="fas fa-question-circle"></i>;
        }
    };



    const renderPricingCard = (plan: Pricing, index: number) => {
        let displayPrice = `${plan.CurrencySymbol}${plan.Price}`;
        let frequency = '';

        const formatNumber = (value: string) => {
            return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
        }

        function calculateSavings(plan: Pricing) {
            let oldPrice = parseFloat(isAnnual && plan.AnnualOld ? plan.AnnualOld : plan.OldPrice || "0");
            let currentPrice = parseFloat(isSubscriptionPricing(plan) && isAnnual ? (plan.AnnualFee || "0") : (plan.Price || "0"));
            let savings = oldPrice - currentPrice;

            // Format the savings as a currency.
            return `${savings.toFixed(2)}${plan.CurrencySymbol}`;
        }


        if (isSubscriptionPricing(plan) || isLicensePricing(plan)) {
            if (isAnnual) {
                displayPrice = `${plan.CurrencySymbol}${plan.AnnualFee}`;
                frequency = '/yearly';
            } else {
                displayPrice = `${plan.CurrencySymbol}${plan.MonthlyFee}`;
                frequency = '/monthly';
            }
        }

        return (
            <div key={index} className={`pricing-card ${selectedPlan === plan.PricingID ? 'selected' : ''} ${userType.toLocaleLowerCase() === "lawfirm" && "wide"}`} onClick={() => { setSelectedPlan(plan.PricingID); setPlanDetails(plan) }}>
                <div className='pricing-top__row'>

                        <div className='pricing-price-wrapper'>
                            <h3>{displayPrice} <span className="frequency" style={{ color: '#bbb' }}>
                                {frequency}
                            </span></h3>
                            {(plan.OldPrice || (isAnnual && plan.AnnualOld)) && (
                                <span className="pricing-savings">Save {calculateSavings(plan)}!</span>
                            )}
                        </div>

                </div>
                {(isLawfirmPricing(plan)) ? (
                    <>
                        <p className="price">{plan.PricingName}</p>
                        <p className='pricing-services__header'>Features</p>
                        <ul>{renderListItems(plan.FeatureList)}</ul>
                    </>
                    ) : (
                    <>
                        <div className='pricing-title__container'>
                            <p className="price">{plan.PricingName}</p>

                        </div>
                        <ul>
                            <div className='pricing-row'>
                                {!showSubscription && (
                                    <div className="pricing-icon">{getPricingIcon(plan.PricingName)}</div>
                                )}
                                {isSessionPricing(plan) && <>
                                    <div className='pricing-one-time__row'>
                                        <li>Inqueries</li>
                                        <li className='pricing-cases__title'><strong>{plan.Inqueries}</strong></li>
                                    </div>
                                </>}
                                {isCasePricing(plan) && (
                                    <div className='pricing-one-time__row'>
                                        <li className='pricing-cases__title'>Cases</li>
                                        <li className='pricing-cases__title'><strong>{plan.Cases}</strong></li>
                                    </div>
                                )}
                                {(isContractPricing(plan) && plan.Contracts?.length > 0) && <>
                                    <div className='pricing-one-time__row'>
                                        <li className='pricing-cases__title'>Contracts</li>
                                        <li className='pricing-cases__title'><strong>{plan.Contracts}</strong></li>
                                    </div>
                                </>}
                                {isAnalysisPricing(plan) && <>
                                    <div className='pricing-one-time__row'>
                                        <li>Max Words</li>
                                        <li className='pricing-cases__title'><strong>{plan.MaxWord}</strong></li>

                                    </div>
                                </>}
                            </div>
                            {plan.Description && (
                                <p className='pricing-plan-description'>{plan.Description}</p>
                            )}
                            {isSubscriptionPricing(plan) && <>
                                {plan.FreeTrialDays && <li className='pricing-free-trail'>{plan.FreeTrialDays} days free trail</li>}
                                {plan.FreeInqueries && <li className='pricing-free-trail'>{plan.FreeInqueries} free legal inqueries</li>}
                            </>}
                            {isLicensePricing(plan) && <>
                                <li className='pricing-services__header'>Services</li>
                                {renderListItems(plan.IncludedServices)}
                                <li className='pricing-services__header'>Features</li>
                                {renderListItems(plan.AdditionalFeatures)}
                            </>}
                        </ul>
                    </>
                )}
            </div>
        );
    };
    const handlePlanTypeToggle = () => setShowSubscription(!showSubscription);

    const filteredPlans = pricingPlans.filter((plan: Pricing) => {

        if (!plan || !plan.UserType) return false;

        if (plan.UserType.toLowerCase() === 'lawfirm' && userType.toLowerCase() === 'lawfirm') {
            return true;
        }

        if (plan.UserType.toLowerCase() !== userType.toLowerCase()) {
            return false;
        }

        if (showSubscription) {
            if (plan.Type === "Subscription") {
                return isAnnual ? 'AnnualFee' in plan : 'MonthlyFee' in plan;
            } else if (plan.Type === "License") {
                return true;
            }
        } else {
            if (plan.Type === "One-Time Purchase") {
                return (plan.isBulk === "yes") === isBulk;
            } else if (plan.Type === "License") {
                return true;
            }
        }

        return false;
    });





    return (
        <div>
            <div className='pricing-header' style={{ justifyContent: 'center', display: 'flex', flexDirection: 'column', alignItems: 'center', width: '85%', margin: '30px auto 0px auto ', gap: 20 }} >
                <h2 className="user-type-heading">{userTypeMessages[userType].title}</h2>
                <span className='user-type-message' dangerouslySetInnerHTML={{ __html: userTypeMessages[userType].message }}></span>
                {userType === "client" && (
                    <div className="toggle-switch__pricing">
                        <button className={`button left ${showSubscription ? 'active' : ''}`} onClick={handlePlanTypeToggle}>
                            Subscriptions
                        </button>
                        <button className={`button right ${!showSubscription ? 'active' : ''}`} onClick={handlePlanTypeToggle}>
                            One-Time Purchases
                        </button>
                        <div className={`slider-otp-pricing ${!showSubscription ? 'right' : ''}`}></div>
                    </div>
                )}
                <div
                    className='pricing-toggle__container'
                    style={userType.toLowerCase() === "client" ? { justifyContent: 'center', display: 'flex' } : { justifyContent: 'center', display: 'flex' }}
                >
                    {(showSubscription && userType.toLowerCase() !== "lawfirm") && (
                        <div className="toggle-switch__pricing month-year">
                            <button className={`button left ${!isAnnual ? 'active' : ''}`} onClick={togglePeriod}>Monthly</button>
                            <button className={`button right ${isAnnual ? 'active' : ''}`} onClick={togglePeriod}>Yearly <span className='pricing-sale-tag'>20%</span></button>
                            <div className={`slider-otp-pricing ${!isAnnual ? '' : 'right'}`}></div>
                        </div>
                    )}
                    {!showSubscription && userType === "client" && (
                        <div className="toggle-switch__pricing month-year">
                            <button className={`button left ${!isBulk ? 'active' : ''}`} onClick={toggleBulkMode}>Single</button>
                            <button className={`button right ${isBulk ? 'active' : ''}`} onClick={toggleBulkMode}>Bulk <span className='pricing-sale-tag'>20%</span></button>
                            <div className={`slider-otp-pricing ${isBulk ? 'right' : ''}`}></div>
                        </div>
                    )}
                </div>
            </div>

            <div className="pricing-container">
                {filteredPlans.length > 0 ? filteredPlans.map(renderPricingCard) : <p>No pricing plans available for this category.</p>}
            </div>
            {/* <div ref={endRef as MutableRefObject<HTMLDivElement | null>}></div> */}
        </div>
    );
};


export default PricingPlans;
