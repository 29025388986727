import { SET_TOKEN, CLEAR_TOKEN, SET_QR_CODE } from '../actions/SignInAction';
import { SET_EXTERNAL_ACCOUNTS } from '../types';

interface StripeStates {
    external_accounts: any[]
}

const initialState: StripeStates = {
    external_accounts: []
};


const stripeReducer = (state = initialState, action: { type: string; external_accounts: any[] }) => {
    switch (action.type) {
        case SET_EXTERNAL_ACCOUNTS:
            return {
                ...state,
                external_accounts: action.external_accounts
            }
        default:
            return state;
    }
};

export default stripeReducer;