import React, { useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    ChartData,
} from 'chart.js';
import './EarningTracker.css';
import './CreditTracker.css';
import GlobalDropdown from '../../shared/GlobalDropdown';
import { useTranslation } from 'react-i18next';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

interface EarningTrackerProps {
    totalCredits: number;
    initialUsedCredits?: number;
    onBuyMoreCredits?: () => void;
}

const years = ['2024', '2023', '2022'];

const EarningTracker: React.FC<EarningTrackerProps> = ({ totalCredits, initialUsedCredits = 0, onBuyMoreCredits }) => {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [selectedYear, setSelectedYear] = useState(years[0]);
    const [selectedMonth, setSelectedMonth] = useState<string | null>(null);
    const [selectedWeek, setSelectedWeek] = useState<string | null>(null);
    const [timeFrame, setTimeFrame] = useState<'year' | 'month' | 'week' | 'day'>('year');
    const { t } = useTranslation();

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const [data, setData] = useState<ChartData<'bar'>>({
        labels: [t('January'), t('February'), t('March'), t('April'), t('May'), t('June'), t('July'), t('August'), t('September'), t('October'), t('November'), t('December')],
        datasets: [
            {
                label: 'Monthly Earnings',
                data: Array.from({ length: 12 }, () => Math.floor(Math.random() * 1000 + 100)),
                backgroundColor: 'rgba(100, 210, 255, 1)',
                borderColor: 'rgba(100, 210, 255, 1)',
                borderWidth: 2,
                borderRadius: 4,
            },
        ],
    });

    const handleYearChange = (yearId: string) => {
        setSelectedYear(yearId);
        setSelectedMonth(null);
        setSelectedWeek(null);
        setTimeFrame('year');
        // Fetch the data for the selected year
        setData({
            labels: [t('January'), t('February'), t('March'), t('April'), t('May'), t('June'), t('July'), t('August'), t('September'), t('October'), t('November'), t('December')],
            datasets: [
                {
                    label: 'Monthly Earnings',
                    data: Array.from({ length: 12 }, () => Math.floor(Math.random() * 1000 + 100)),
                    backgroundColor: 'rgba(100, 210, 255, 1)',
                    borderColor: 'rgba(100, 210, 255, 1)',
                    borderWidth: 2,
                    borderRadius: 4,
                },
            ],
        });
    };

    const handleMonthClick = (month: string) => {
        setSelectedMonth(month);
        setSelectedWeek(null);
        setTimeFrame('month');
        const weeks = ['Week 1', 'Week 2', 'Week 3', 'Week 4', 'Week 5'];
        const weeklyData = weeks.map(() => Math.floor(Math.random() * 1000 + 100));

        setData({
            labels: weeks,
            datasets: [{
                label: `Earnings for ${month}, ${selectedYear}`,
                data: weeklyData,
                backgroundColor: 'rgba(100, 210, 255, 1)',
                borderColor: 'rgba(100, 210, 255, 1)',
                borderWidth: 2,
                borderRadius: 4,
            }]
        });
    };

    const handleWeekClick = (week: string) => {
        setSelectedWeek(week);
        setTimeFrame('week');
        const days = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
        const dailyData = days.map(() => Math.floor(Math.random() * 100 + 10));

        setData({
            labels: days,
            datasets: [{
                label: `Earnings for ${week}, ${selectedMonth} ${selectedYear}`,
                data: dailyData,
                backgroundColor: 'rgba(100, 210, 255, 1)',
                borderColor: 'rgba(100, 210, 255, 1)',
                borderWidth: 2,
                borderRadius: 4,
            }]
        });
    };

    const handleBackClick = () => {
        if (timeFrame === 'day') {
            setTimeFrame('week');
            setSelectedWeek(null);
            const weeks = ['Week 1', 'Week 2', 'Week 3', 'Week 4', 'Week 5'];
            const weeklyData = weeks.map(() => Math.floor(Math.random() * 1000 + 100));

            setData({
                labels: weeks,
                datasets: [{
                    label: `Earnings for ${selectedMonth}, ${selectedYear}`,
                    data: weeklyData,
                    backgroundColor: 'rgba(100, 210, 255, 1)',
                    borderColor: 'rgba(100, 210, 255, 1)',
                    borderWidth: 2,
                    borderRadius: 4,
                }]
            });
        } else if (timeFrame === 'week') {
            setTimeFrame('month');
            setSelectedMonth(null);
            setSelectedWeek(null);
            handleYearChange(selectedYear);
        } else if (timeFrame === 'month') {
            setTimeFrame('year');
            setSelectedMonth(null);
            setSelectedWeek(null);
            setData({
                labels: [t('January'), t('February'), t('March'), t('April'), t('May'), t('June'), t('July'), t('August'), t('September'), t('October'), t('November'), t('December')],
                datasets: [
                    {
                        label: 'Monthly Earnings',
                        data: Array.from({ length: 12 }, () => Math.floor(Math.random() * 1000 + 100)),
                        backgroundColor: 'rgba(100, 210, 255, 1)',
                        borderColor: 'rgba(100, 210, 255, 1)',
                        borderWidth: 2,
                        borderRadius: 4,
                    },
                ],
            });
        }
    };

    const options = {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
            y: {
                beginAtZero: true,
                grid: {
                    color: '#222'
                },
                ticks: {
                    color: '#fff'
                }
            },
            x: {
                grid: {
                    color: '#222'
                },
                ticks: {
                    color: '#fff',
                    display: false
                }
            },
        },
        plugins: {
            legend: {
                display: false
            },
            tooltip: {
                callbacks: {
                    label: function (context: any) {
                        return `${context.label}: $${context.parsed.y.toLocaleString()}`;
                    }
                }
            }
        }
    };

    const generateTags = () => {
        return data.labels?.map((label, index) => (
            <div key={label as string}
                onClick={() => {
                    if (timeFrame === 'year') {
                        handleMonthClick(label as string);
                    } else if (timeFrame === 'month') {
                        handleWeekClick(label as string);
                    }
                }}
                className={`earning-tracker__tag ${selectedMonth === label ? 'selected-month' : ''}`}
                style={{ cursor: 'pointer', color: selectedMonth === label ? '#4CAF50' : '#FFF' }}>
                <strong style={{ color: '#fff' }}>{label as string}</strong><p>${data.datasets[0].data[index]}</p>
            </div>
        ));
    };

    return (
        <div className="earning-tracker__container">
            <div className='earning-tracker__header'>
                <GlobalDropdown
                    options={years.map(year => ({ id: year, label: year }))}
                    onSelect={handleYearChange}
                    selectedLabel={selectedYear}
                    placeholder={t("Select Year")}
                    style={{ width: 85 }}
                    reverseHeader={true}
                />
                <h3>{t('Total Earnings')} {selectedYear}</h3>
            </div>
            <button className="back-button">
            {timeFrame !== 'year' && (
                <i onClick={handleBackClick} className='fas fa-chevron-right'></i>
            )}
            </button>
            <div style={{ display: 'flex', gap: '10px', position: 'relative', width: '100%', height: '100%' }}>
                <div className="earning-tracker__chart" key={windowWidth}>
                    <Bar data={data} options={options} />
                </div>
                <div className="token-tracker__header">
                    <div className='earning-counter__wrapper'>
                        {generateTags()}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default EarningTracker;
