import {  ChartEvent, LegendItem } from 'chart.js';
import React from "react";
import { Doughnut } from "react-chartjs-2";

interface PieChartProps {
    chartData: any,
    chartName: string
    windowWidth: number
}

const PieChart: React.FC<PieChartProps> = ({ chartData, chartName, windowWidth }) => {

    const legendClickHandler = function (
        this: any,
        event: ChartEvent,
        legendItem: LegendItem,
    ): void {
        const index: any = legendItem.index;
        const chart = this.chart;
        const meta = chart.getDatasetMeta(0);

        const item: any = meta.data[index];
        item.hidden = !item.hidden;

        chart.update();
    };
    return (
        <div className="" style={{ width: '100%', height: '100%' }} key={windowWidth}>
            <Doughnut
                data={chartData}
                options={{
                    responsive: true,
                    maintainAspectRatio: false,
                    plugins: {
                        title: {
                            display: true,
                            text: chartName
                        },
                        legend: {
                            display: true,
                            position: 'right',
                            align: 'center',
                            reverse: false,
                            labels: {
                                color: 'rgb(255, 255, 255)',
                                boxWidth: 10,
                                boxHeight: 10,
                                padding: 20,
                                borderRadius: 15,
                                font: {
                                    size: 15,
                                    style: 'italic',
                                    family: 'Poppins',
                                    weight: 'bold',
                                },
                                usePointStyle: true,
                                pointStyle: 'circle',
                                textAlign: 'left',
                            },
                            title: {
                                display: true,
                                text: '',
                                color: '#fff',
                                font: {
                                    size: 15,
                                    family: 'Poppins',
                                    weight: 'bold',
                                },
                            },
                            onClick: legendClickHandler,

                        },

                    }
                }}
            />
        </div>
    );
}
export default PieChart;