import React, { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import SuccessPage from './SuccessPage';
import CancelPage from './CancelPage';
import FailedPage from './FailedPage';

const PaymentResults = () => {
    const location = useLocation();
    const History = useHistory();
    const query = new URLSearchParams(location.search);

    const success = query.get('success');
    const canceled = query.get('canceled');
    const Failed = query.get('failed');


    useEffect(() => {
        const timer = setTimeout(() => {
            History.push('/marketPlace/'); 
        }, 20000); 

        return () => clearTimeout(timer); 
    }, [History]);

    if (success) {
        return <SuccessPage />;
    }

    if (canceled) {
        return <CancelPage />;
    }
    if (Failed) {
        return <FailedPage />
    }

    return <h1>Unknown state</h1>;
};

export default PaymentResults;
