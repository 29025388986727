import ClientPortal from "../components/ClientPortal/ClientPortal";

const ClientPortalPage = () => {
    return (
      <div style={{ position: 'relative', height: 'calc(100vh - 115px)' }}>
        <ClientPortal />
      </div>
    );
  };
  
  export default ClientPortalPage;
  