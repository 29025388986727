
import { AnyAction, Dispatch } from 'redux';
import axios, { AxiosError } from 'axios';
import { SEND_FEEDBACK_REQUEST, SEND_FEEDBACK_SUCCESS, SEND_FEEDBACK_FAILURE, FETCH_FEEDBACK_TYPES_REQUEST, FETCH_FEEDBACK_TYPES_FAILURE, FETCH_FEEDBACK_TYPES_SUCCESS, RootState } from '../types';
import { showModal, hideModal } from './modalActions';
import apis from '../../utils/apis';
import { showFeedbackModal } from './UserFeedbackActions';
import { ThunkDispatch } from 'redux-thunk';

interface SendFeedbackParams {
  FeedbackContent: string;
  UserID: string;
  UserType: string;
  FeedbackTypeID: number;
}



export const fetchFeedbackTypes = () => async (dispatch: Dispatch) => {
  dispatch({ type: FETCH_FEEDBACK_TYPES_REQUEST });
  try {
    const response = await apis({
      method: "GET",
      url: "user/getFeedbackTypes"
    });
    dispatch({
      type: FETCH_FEEDBACK_TYPES_SUCCESS,
      payload: response.data
    });
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      dispatch({
        type: FETCH_FEEDBACK_TYPES_FAILURE,
        payload: error.response.data
      });
    } else {
      dispatch({
        type: FETCH_FEEDBACK_TYPES_FAILURE,
        payload: "An unknown error occurred"
      });
    }
  }
};

export const sendFeedback = ({ FeedbackContent, UserID, UserType, FeedbackTypeID }: SendFeedbackParams) =>
  async (dispatch: ThunkDispatch<RootState, undefined, AnyAction>) => {
    dispatch({ type: SEND_FEEDBACK_REQUEST });
    const dataToSend = { FeedbackContent, UserID, UserType, FeedbackTypeID };

    try {
      const response = await apis({
        method: 'POST',
        url: 'user/SendFeedback',
        data: dataToSend,
      });

      if (response.status === 200) {
        dispatch({ type: SEND_FEEDBACK_SUCCESS, payload: response.data });
        dispatch(showFeedbackModal({
          showModal: true,
          message: 'Feedback successfully sent!',
          modalType: 'success',
          duration: 3000
        }));

      } else {
        throw new Error('Feedback submission failed');
      }
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const axiosError = error as AxiosError;
        dispatch({
          type: SEND_FEEDBACK_FAILURE,
          payload: axiosError.response ? axiosError.response.data : "An unknown error occurred"
        });

        dispatch(showModal({
          showModal: true,
          type: 'error',
          message: 'Failed to send feedback. Please try again later.',
          duration: 3000
        }));

        setTimeout(() => {
          dispatch(hideModal());
        }, 3000);
      } else {
        dispatch({
          type: SEND_FEEDBACK_FAILURE,
          payload: "An unknown error occurred"
        });
      }
    }
  };


