import './AiAssistant.css';
import { useEffect, useRef, useState } from 'react';
import FolderStructure from './FolderStructure';
import ChatBot from './ChatBot';
import MiniMyDocument from './MiniMyDocument';
import { ServiceAPIResponse, callServiceAPI, chatGroupApi, chatHistoryApi, fetchContracts, getUserID, getUserType, setChatGroupIDStore, setFolderVisibility, setSelectedModel } from '../../store/actions/ChatAction';
import { RootState, useAppDispatch } from '../../store';
import { fetchStructure } from '../../store/actions/FolderAction';
import { useSelector } from 'react-redux';
import PromptBar from './PromptBar';
import { editDocument, fetchDocumentContent } from '../../store/actions/DocumentsAction';
import { useLocation } from 'react-router-dom';
import { Message } from './MessageList';
import { FileProps } from '../../pages/DocumentsPage';
import * as sdk from 'microsoft-cognitiveservices-speech-sdk';
import { setVoice } from '../../store/actions/SpeechAction';
import { setFontSize } from '../../store/actions/FontSizeAction';
import { useTranslation } from 'react-i18next';
import { getAISettings, updateAISettings } from '../../store/actions/DataAction';
import { ContractType } from './Contract Generator/ContractGenerator';
import { getBlobDocuments, getIndexInfo } from '../../store/actions/CompanyAgentAction';


type AiAssistantLocationState = {
  selectedModel?: string;
};


const AiAssistant = () => {
  const { t } = useTranslation()

  const isFolderStructureVisible = useSelector((state: RootState) => state.model.isFolderStructureVisible)
  const [isDocumentVisible, setDocumentVisible] = useState(false);
  const isSidebarOpen = useSelector((state: RootState) => state.sidebar.isOpen);
  const [folderState, setFolderState] = useState('FOLDERS');
  const [selectedFile, setSelectedFile] = useState<FileProps | null>(null);
  const [fileContent, setFileContent] = useState<string>('');
  const [isDocumentExpanded, setDocumentExpanded] = useState(false);
  const [contractTypeID, setContractTypeID] = useState<number | null>(null);
  const [messages, setMessages] = useState<Message[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [originalContent, setOriginalContent] = useState('');
  const dispatch = useAppDispatch();
  const userId = getUserID();
  const structure = useSelector((state: RootState) => state.folders.structure)
  const [chatName, setChatName] = useState('');
  const location = useLocation<AiAssistantLocationState>();
  const { selectedModel: selectedServiceFromDashboard } = location.state || {};
  const selectedModel = useSelector((state: RootState) => state.model.selectedModel);
  const [chatGroupID, setChatGroupID] = useState('');
  const [Document1, setDocument1] = useState<File | null>(null);
  const [Document2, setDocument2] = useState<File | null>(null);
  const [startNewChat, setStartNewChat] = useState(false)
  const userType = getUserType()
  const [isEditing, setIsEditing] = useState(false);
  const aiSettings = useSelector((state: RootState) => state.lists.aiSettings);
  const userInfo = useSelector((state: RootState) => state.user.userInfo)
  const [selectedAILanguage, setSelectedAILanguage] = useState('');
  const [selectedVoice, setSelectedVoiceState] = useState('');
  const [selectedFontSize, setSelectedFontSizeState] = useState('');
  const fontSize = useSelector((state: RootState) => state.fontSize.fontSize)
  const [isDarkTheme, setIsDarkTheme] = useState(false);
  const chatGroupss = useSelector((state: RootState) => state.model.chatGroups)
  const currentFetch = useRef<AbortController | null>(null);
  const [latestResponse, setLatestResponse] = useState<ServiceAPIResponse | null>(null); 
  const [loaderText, setLoaderText] = useState(t('Please wait...'));
  const { speechKey, serviceRegion, voice } = useSelector((state: RootState) => state.speechSettings);
  const hasSentFirstMessage = messages.length > 0;

  useEffect(() => {
    if (selectedModel) {
      localStorage.setItem('selectedModel', selectedModel);
    }
    if (chatGroupID) {
      localStorage.setItem('chatGroupID', chatGroupID);
    }
  }, [selectedModel, chatGroupID]);

  useEffect(() => {
    const storedModel = localStorage.getItem('selectedModel');
    const storedChatGroupID = localStorage.getItem('chatGroupID');
    
    if (location.pathname === '/ai-assistant') {
      if (storedModel) {
        dispatch(setSelectedModel(storedModel));
      } else if (selectedServiceFromDashboard) {
        dispatch(setSelectedModel(selectedServiceFromDashboard));
      }

      if (storedChatGroupID) {
        dispatch(setChatGroupIDStore(storedChatGroupID));
        handleChatHistory(storedChatGroupID); 
      }

      if (userId) {
        dispatch(chatGroupApi(userId, removeSpaces(storedModel || selectedModel), getUserType()));
      }
    }
  }, [dispatch, userId, selectedModel, selectedServiceFromDashboard, location.pathname]);
  

  useEffect(() => {
    dispatch(fetchContracts());
    dispatch(fetchStructure())
  }, [dispatch]);

  useEffect(() => {
    if (userType !== "Client" && userType !== "Lawyer") {
      dispatch(getIndexInfo());
      dispatch(getBlobDocuments());
    }
  }, [dispatch, userType]);
  

  useEffect(() => {
    dispatch(getAISettings(userType));
  }, [dispatch, userId, userType]);

  useEffect(() => {
    if (aiSettings) {
      setSelectedAILanguage(aiSettings.AILanguageResponse);
      setSelectedVoiceState(aiSettings.AIVoice);
      setSelectedFontSizeState(aiSettings.FontSize);
      setIsDarkTheme(aiSettings.ThemeColor === 'dark');
    }
  }, [aiSettings]);

  const handleSelectAIVoice = (value: string) => {
    setSelectedVoiceState(value);
    dispatch(setVoice(value));
  };

  const handleSelectAILanguage = (value: string) => {
    setSelectedAILanguage(value);
  };

  const handleSelectFontSize = (value: string) => {
    setSelectedFontSizeState(value);
    dispatch(setFontSize(value));
  };

  const handleThemeToggle = () => {
    setIsDarkTheme(!isDarkTheme);
  };

  const handleSaveChanges = () => {
    const updatedAISettings = {
      UserID: userId,
      UserType: userType,
      AIVoice: selectedVoice,
      AILanguageResponse: selectedAILanguage,
      FontSize: fontSize,
      ThemeColor: isDarkTheme ? 'dark' : 'light',
      CountryOfJurisdiction: aiSettings?.CountryOfJurisdiction || '',
      FontFamily: 'Poppins',
    };
    dispatch(updateAISettings(updatedAISettings));
  };

  const speakText = (textToSpeak: string): void => {
    const speechConfig = sdk.SpeechConfig.fromSubscription(speechKey, serviceRegion);
    speechConfig.speechSynthesisVoiceName = voice;
    const speechSynthesizer = new sdk.SpeechSynthesizer(speechConfig);

    speechSynthesizer.speakTextAsync(
      textToSpeak,
      (result: sdk.SpeechSynthesisResult) => {
        speechSynthesizer.close();
      },
      (error: any) => {
        console.error('Error synthesizing speech:', error);
        speechSynthesizer.close();
      }
    );
  };

  const handleEdit = () => {
    setIsEditing(true);
  };

  useEffect(() => {
    let timer: ReturnType<typeof setTimeout>;

    switch (selectedModel) {
      case "Contract Generation":
        setLoaderText("Generating Contract...");
        break;
      case "Legal Advisor":
        setLoaderText("Inspecting your concern...");
        break;
      case "Contract Analysis":
        setLoaderText("Analysing your contract...");
        break;
      case "Case Analysis":
        setLoaderText("Analysing your case...");
        break;
      default:
        setLoaderText("Please wait...");
    }

    if (isLoading) {
      timer = setTimeout(() => {
        setLoaderText("Almost complete...");
      }, 30000);
    }

    return () => {
      clearTimeout(timer);
      if (!isLoading) { 
        setLoaderText("Please wait...");
      }
    };

  }, [selectedModel, isLoading]);




  const DocumentContent = async (file: FileProps) => {
    const userId = getUserID();
    try {
      let resp: any = await dispatch(fetchDocumentContent(userId, file.FileID, userType));
      if (resp) {
        setFileContent(resp.content);
      }
    } catch (error) {
      console.error('Error fetching document content:', error);
    }
  };

  const handleSave = async () => {
    const userId = getUserID();
    if (selectedFile) {
      dispatch(editDocument(userId, selectedFile.FileID, selectedFile.FileName, fileContent, userType))
    }
  };

  useEffect(() => {
    const fun = () => {
      setMessages([])
      setChatGroupID('')
      if (startNewChat) {
        setStartNewChat(false)
      }
    }
    fun()
  }, [selectedModel, startNewChat])


  const toggleTheme = () => {
    setIsDarkTheme(!isDarkTheme);
  };

  useEffect(() => {
    if (selectedServiceFromDashboard) {
      setSelectedModel(selectedServiceFromDashboard);
    }

    // const fun = async () => {
    //   if (userId) {
    //     dispatch(chatGroupApi(userId, removeSpaces(selectedModel), getUserType()));
    //   }
    // };
    // fun();
  }, [dispatch, userId, chatGroupID, selectedModel, selectedServiceFromDashboard,userType]);



  const closeFileAndViewFolders = () => {
    setDocumentVisible(false);
    dispatch(setFolderVisibility(isFolderStructureVisible));
    setFolderState('FOLDERS');
  };

  function removeSpaces(str: string): string {
    return str.replace(/\s+/g, '');
  }
  

  const handleEditClick = () => {
    if (!isEditing) {
      setOriginalContent(fileContent);
    }
    setIsEditing(!isEditing);
  };

  const handleRevertClick = () => {
    setFileContent(originalContent);
    setIsEditing(false);
  };
  const toggleFolderStructure = () => {
    dispatch(setFolderVisibility(!isFolderStructureVisible));
  };

  const openFile = () => {
    setDocumentVisible(!isDocumentVisible);
    setFolderState('FILE');
    if(selectedFile) {
      setDocumentVisible(isDocumentVisible);
    }
  };


  const onFileClick = (file: FileProps) => {
    setSelectedFile(file);
    openFile();
    DocumentContent(file)
  };

  const toggleDocumentExpansion = () => {
    setDocumentExpanded(!isDocumentExpanded);
    dispatch(setFolderVisibility(!isFolderStructureVisible));
  };

  const onConfirm = (confirmation: string) => {
    
  };



  const toggleViewMode = () => {
    setDocumentVisible(prevVisible => !prevVisible);

  };

  const onSend = async (input: string) => {
    setLoaderText("Processing your request...");
    setIsLoading(true);
  
    setMessages(prevMessages => [
      ...prevMessages,
      { text: input, sender: 'user', action: null, file1: Document1, file2: Document2 },
      { text: 'Loading...', sender: 'assistant', action: null }
    ]);
  
    try {
      let response: ServiceAPIResponse | null = null;
      switch (selectedModel) {
        case 'Legal Advisor':
          response = await callServiceAPI('LegalAdvisor', { Command: input }, undefined, undefined, chatGroupID);
          if (response.ChatGroupID && !chatGroupID) {
            setChatGroupID(response.ChatGroupID);
            dispatch(setChatGroupIDStore(response.ChatGroupID));
          }
          break;
        case 'Case Analysis':
          response = await callServiceAPI('CaseAnalysis', { Command: input }, Document1 || undefined, undefined, chatGroupID);
          if (response.ChatGroupID && !chatGroupID) {
            setChatGroupID(response.ChatGroupID);
            dispatch(setChatGroupIDStore(response.ChatGroupID));
          }
          break;
        case 'Contract Analysis':
          response = await callServiceAPI('ContractAdvisor', { Command: input }, Document1, Document2, chatGroupID);
          if (response.ChatGroupID && !chatGroupID) {
            setChatGroupID(response.ChatGroupID);
            dispatch(setChatGroupIDStore(response.ChatGroupID));
          }
          break;
        case 'Contract Generation':
          response = await callServiceAPI('ContractGenerator', { ContractTypeID: contractTypeID, Command: input }, undefined, undefined, chatGroupID);
          if (response.ChatGroupID && !chatGroupID) {
            setChatGroupID(response.ChatGroupID);
            dispatch(setChatGroupIDStore(response.ChatGroupID));
          }
          break;
        case 'Company Agent':
          response = await callServiceAPI('CompanyAgent', { UserInput: input }, undefined, undefined, chatGroupID);
          if (response.ChatGroupID && !chatGroupID) {
            setChatGroupID(response.ChatGroupID);
            dispatch(setChatGroupIDStore(response.ChatGroupID));
          }
          break;
        default:
          throw new Error(`Service ${selectedModel} not recognized`);
      }
  
      const responseText = response?.LegalAdvisorResponse || response?.ContractAdvisorResponse || response?.ContractGenerationResponse || response?.CaseAnalysisResponse || response?.ChatResponse|| "No response";
      setMessages(prevMessages => {
        const newMessages = [...prevMessages];
        newMessages[newMessages.length - 1] = { text: responseText, sender: 'assistant', action: null };
        return newMessages;
      });
      setLatestResponse(response);
      setLoaderText("Please wait...");
      setIsLoading(false);
    } catch (err) {
      setLoaderText("Please wait...");
      setIsLoading(false);
    }
  };
  
  
    const initiateContractGeneration = async (contract: ContractType) => {
      try {
        setIsLoading(true);
        setContractTypeID(contract.ContractTypeID); 
        setMessages(prevMessages => [
          ...prevMessages,
          { text: `Initiating ${contract.ContractType}...`, sender: 'user', action: null },
          { text: 'Loading...', sender: 'assistant', action: null }
        ]);
  
        const response = await callServiceAPI('ContractGenerator', { ContractTypeID: contract.ContractTypeID, Command: '', GroupID: '' }, undefined, undefined, chatGroupID);
  
        if (response.ChatGroupID && !chatGroupID) {
          setChatGroupID(response.ChatGroupID);
        }
  
        const responseText = response?.ContractGenerationResponse || "No response";
  
        setMessages(prevMessages => {
          const newMessages = [...prevMessages];
          newMessages[newMessages.length - 1] = { text: responseText, sender: 'assistant', action: null };
          return newMessages;
        });
  
        setIsLoading(false);
      } catch (err) {
        setIsLoading(false);
      }
    };
  


  const uploadedFile = null
  const handleFileDrop = () => { }

  const handleChatHistory = async (chatId: string) => {
    setMessages([]);
    setChatGroupID(chatId);
    dispatch(setChatGroupIDStore(chatId)); 
    setMessages(prevMessages => [...prevMessages, { text: 'Loading...', sender: 'assistant', action: null }]);
  
    const abortController = new AbortController();
    const signal = abortController.signal;
  
    if (currentFetch.current) {
      currentFetch.current.abort();
    }
    currentFetch.current = abortController;
  
    if (chatId) {
      try {
        const resp = await dispatch(chatHistoryApi(userId, selectedModel, chatId, getUserType(), signal));
        if (resp && resp.length > 0) {
          const newMessages = resp.slice().reverse().flatMap((message: { Prompt: string; Reply: string }) => [
            { text: message.Prompt, sender: "user" as const, action: null, oldChat: true },
            { text: message.Reply, sender: "assistant" as const, action: null, oldChat: true }
          ]);
          setMessages(newMessages);
        } else {
          setMessages([{ text: 'No chat history found.', sender: 'assistant', action: null }]);
        }
      } catch (error) {
        if (!abortController.signal.aborted) {
          console.error('Failed to fetch chat history:', error);
          setMessages([{ text: 'Failed to load chat history.', sender: 'assistant', action: null }]);
        }
      }
    }
  };
  
  

useEffect(() => {
  return () => {
      if (currentFetch.current) {
          currentFetch.current.abort();
      }
  };
}, []);

return (
  <>
        <div className={`ai-assistant-container 
            ${(!isSidebarOpen && !isFolderStructureVisible) ? 'expanded' : ''} 
            ${isSidebarOpen && !isFolderStructureVisible ? 'expand-w-sidebar' : ''}`
        }>
      <div className={`reopen-folder-arrow ${isFolderStructureVisible ? 'hide' : 'show'} ${selectedModel === "Legal Case Mapping" || selectedModel === "Contract Generation" ? 'hide' : ''}`}>
        <i onClick={toggleFolderStructure} className="fas fa-chevron-left"></i>
      </div>

      <div className={`ai-assistant__chatbot-container ${selectedModel === "Legal Advisor" || selectedModel === "Case Analysis" ? '' : 'expanded'} ${!isSidebarOpen && !isFolderStructureVisible && 'expanded'} ${(isDocumentVisible && !isFolderStructureVisible && selectedModel === "Legal Advisor") || (isDocumentVisible && !isFolderStructureVisible && selectedModel === "Case Analysis") ? '' : 'shrink'}`}>
        <ChatBot
          selectedModel={selectedModel}
          isDocumentVisible={isDocumentVisible}
          isFolderStructureVisible={isFolderStructureVisible}
          messages={messages}
          onConfirm={onConfirm}
          isLoading={isLoading}
          setMessages={setMessages}
          setChatName={setChatName}
          setDocument1={setDocument1}
          setDocument2={setDocument2}
          Document1={Document1}
          Document2={Document2}
          loaderText={loaderText}
          speakText={speakText}
          latestResponse={latestResponse}
          initiateContractGeneration={initiateContractGeneration}
          hasSentFirstMessage={hasSentFirstMessage}
        />

        {selectedModel !== "Legal Case Mapping" && (selectedModel !== 'Contract Generation' || (selectedModel === 'Contract Generation' && hasSentFirstMessage)) && (
          <PromptBar onSend={onSend} isDocumentVisible={isDocumentVisible} uploadedFile={uploadedFile} handleFileDrop={handleFileDrop} selectedService={selectedModel} isFolderStructureVisible={isFolderStructureVisible} />
        )}
      </div>

      {((selectedFile && isDocumentVisible && selectedModel === "Legal Advisor") || ( selectedFile && isDocumentVisible && selectedModel === "Case Analysis") || (selectedFile && isDocumentVisible && selectedModel === "Company Agent")) && (
        <div className={`mini-mydoc-container ${isFolderStructureVisible ? "expanded" : ""}`}>
          <MiniMyDocument
            isFolderStructureVisible={isFolderStructureVisible}
            fileContent={fileContent}
            setFileContent={setFileContent}
            toggleDocumentExpansion={toggleDocumentExpansion}
            isDocumentVisible={isDocumentVisible}
            toggleFolderStructure={toggleFolderStructure}
            closeFileAndViewFolders={closeFileAndViewFolders}
            toggleViewMode={toggleViewMode}
            isEditing={isEditing}
            isDarkTheme={isDarkTheme}
            onEdit={handleEdit}
          />
        </div>
      )}

      <div className={`folder-chatbot ${isFolderStructureVisible ? "" : "hidden"}`}>
        {(selectedModel === "Legal Advisor" || selectedModel === "Case Analysis" || selectedModel === "Contract Analysis" || selectedModel === "Company Agent" ) && (
          <>
            <FolderStructure
              toggleFolderStructure={toggleTheme}
              openFile={openFile}
              folderState={folderState}
              isFolderStructureVisible={isFolderStructureVisible}
              onFileClick={onFileClick}
              selectedFile={selectedFile}
              isDocumentVisible={isDocumentVisible}
              fileContent={fileContent}
              setFileContent={setFileContent}
              onEditSaveClick={handleEditClick}
              onRevertClick={handleRevertClick}
              isEditing={isEditing}
              folders={structure}
              chatName={chatName}
              chatGroups={chatGroupss}
              handleChatHistory={handleChatHistory}
              setStartNewChat={setStartNewChat}
              userId={userId}
              handleSave={handleSave}
              setIsDarkTheme={toggleTheme}
              isDarkTheme={isDarkTheme}
              handleSelectAIVoice={handleSelectAIVoice}
              handleSelectAILanguage={handleSelectAILanguage}
              handleSelectFontSize={handleSelectFontSize}
              toggleTheme={handleThemeToggle}
              selectedFontSize={selectedFontSize}
              selectedAILanguage={selectedAILanguage}
              selectedVoice={selectedVoice}
              handleSaveChanges={handleSaveChanges}
              setDocumentVisible={toggleViewMode}
              selectedModel={selectedModel}
            />
          </>
        )}
      </div>
    </div>
  </>
);

}

export default AiAssistant;