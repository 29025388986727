import React, { useState } from 'react';
import '../Settings.css';
import { useAppDispatch } from '../../../store';
import { EditUser, userInformation } from '../../../store/actions/DataAction';
import * as yup from 'yup';
import { useFormik } from 'formik';
import ThreeDotsLoader from '../../ApiLoader';
import PreferencesSection from './Preferences';
import Reminder from './Reminder';
import { getUserType } from '../../../store/actions/ChatAction';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import dayjs, { Dayjs } from 'dayjs';
import { useStyles } from '../../SignUpSection/SignUpSection';
import { useTranslation } from 'react-i18next';
import { showPasswordProfileModal } from '../../../store/actions/modalActions';
import { showFeedbackModal } from '../../../store/actions/UserFeedbackActions';
import NotificationSettingsComponent from './Notifications';
import AISettingsComponent from './AISettings';

export interface UserProps {
  FirstName: string,
  LastName: string,
  PhoneNumber: string,
  StreetAddress: string,
  Email: string,
  Nationality: string,
  ZipCode?: string,
  ProfilePicture?: string,
  City?: string,
  Country?: string,
  State?: string,
  DateOfBirth?: string,
  Password?: string,
  Biography?: string,
  HourlyRate?: string 
}

interface MyComponentProps {
  data: UserProps;
  userId: string
}

const PersonalInfoAndProfile: React.FC<MyComponentProps> = ({ data, userId }) => {
  const [isEditing, setIsEditing] = useState(false);
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const userType = getUserType()
  const [personalInfo, setPersonalInfo] = useState({
    firstName: data?.FirstName,
    lastName: data.LastName,
    phoneNumber: data.PhoneNumber,
    streetAddress: data.StreetAddress,
    email: data.Email,
    zipCode: data.ZipCode,
    city: data.City,
    country: data.Country,
    state: data.State,
    dob: data.DateOfBirth,
    Nationality: data.Nationality
  });
  const { t } = useTranslation()

  const validationSchema = yup.object({
    FirstName: yup.string().required('Required'),
    LastName: yup.string().required('Required'),
    PhoneNumber: yup.string().required('Required'),
    Email: yup.string().email('Invalid email format').required('Required'),
    Address: yup.string(),
    Country: yup.string(),
    State: yup.string(),
    City: yup.string(),
    ZipCode: yup.string(),
    ProfilePicture: yup.string(),
    DateOfBirth: yup.string().required("Date of Birth is required")
  });

  const onSuccess = () => {
    setIsEditing(false);  
    dispatch(userInformation( userType))
    dispatch(showFeedbackModal({
      showModal: true,
      message: 'Successfully edited profile!',
      modalType: 'success',
      duration: 3000
    }));
  };

  const formik = useFormik({
    initialValues: {
        FirstName: data.FirstName,
        LastName: data.LastName,
        PhoneNumber: data.PhoneNumber,
        StreetAddress: data.StreetAddress,
        Email: data.Email,
        ZipCode: data.ZipCode,
        City: data.City,
        Country: data.Country,
        State: data.State,
        DateOfBirth: data.DateOfBirth,
        Nationality: data.Nationality
    },
    validationSchema,
    onSubmit: async (values: UserProps) => {
      setIsLoading(true);
        const formData = new FormData();
        formData.append("UserID", userId)
        formData.append("UserType", userType)
        formData.append("Country", data.Country ?? "")
        const resp = await dispatch(EditUser(formData, onSuccess));
        if (resp) {
            setIsLoading(false);
            setPersonalInfo(prevState => ({
                ...prevState,
                firstName: data.FirstName,
                lastName: data.LastName,
                phoneNumber: data.PhoneNumber,
                streetAddress: data.StreetAddress,
                email: data.Email,
                zipCode: data.ZipCode,
                city: data.City ? data.City : "",
                country: data.Country ? data.Country : "",
                state: data.State ? data.State : "",
                dob: data.DateOfBirth
            }));
            setIsEditing(false);
          } else {
            setIsLoading(false);
          }
    },
});

  const handleEdit = () => {
    setIsEditing(true);
  };

  const handleCancel = () => {
    setIsEditing(false);
    setIsLoading(false)
  };

  const getInitials = (firstname: string, lastname: string): string => {
    let name = firstname + ' ' + lastname;
    const parts = name.split(' ').filter(Boolean);
    const initials = parts.map(part => part.charAt(0).toUpperCase()).slice(0, 2).join('');
    return initials;
  };

  const handleDOBChange = (date: Dayjs | null) => {
    if (date) {
      const formattedDate = date.format('DD-MM-YYYY');
      formik.setFieldValue('DateOfBirth', formattedDate);
    } else {
      formik.setFieldValue('DateOfBirth', '');
    }
  }


  const handleEditSubmit = () => {
    dispatch(showPasswordProfileModal(formik.values, userId, userType, onSuccess));
    dispatch(userInformation(userType))
  };
  

  return (
    <div style={{ width: '100%', overflowY: 'auto', marginBottom: 10, position: 'relative', scrollbarWidth: 'thin', overflowX: 'hidden' }}>
      <div style={{ display: 'flex', flexDirection: 'column', gap: 10, position: 'relative', height: '100%' }}>
      <div style={{ display: 'flex', flexDirection: 'row', gap: 10, borderRadius: 10, flex: 1, height: '100%', position: 'relative' }}>
      <div style={{ display: 'flex', flexDirection: 'column', gap: 10, borderRadius: 10, flex: 1, height: '100%', position: 'relative' }}>
        <div className="personal-information-container">
          {isLoading && (
            <div style={{ position: "absolute", top: '50%' }}>
              <ThreeDotsLoader />
            </div>
          )}
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
          <h2>{t('Personal Information')}</h2>
              {isEditing ? (
              <div className='Buttons'>
              
                <button className='cancel-button' type="button" onClick={handleCancel}>{t('Cancel')}</button> 
                <button className='save-button' type="button" onClick={handleEditSubmit} disabled={isLoading}>{t('Save')}</button>

                </div>
              ) : (
                <button className='edit-button' onClick={handleEdit}>{t('Update')}</button>
              )}

              </div>
          {isEditing ? (
            <form style={{ display: 'flex', flexDirection: 'column', gap: '10px', }} onSubmit={formik.handleSubmit}>
              <div className="personal-info-section">
                <Section title={t('First Name')} type="text" {...formik.getFieldProps('FirstName')} />
                <Section title={t('Last Name')} type="text"  {...formik.getFieldProps('LastName')} />
                <Section title={t('Email')} type="text"  {...formik.getFieldProps('Email')} />
                <Section title={t('Street Address')} type="text"  {...formik.getFieldProps('StreetAddress')} />
                <Section title={t('Mobile Number')} type="text"  {...formik.getFieldProps('PhoneNumber')} />
                <Section title={t('Country')} type="text"  {...formik.getFieldProps('Country')} />
                {/* <Section title={t('State')} type="text"  {...formik.getFieldProps('State')} /> */}
                <Section title={t('City')} type="text"  {...formik.getFieldProps('City')} />
                <Section title={t('Zip Code')} type="text"  {...formik.getFieldProps('ZipCode')} />
                <Section
                  title={t('Date of Birth')}
                  type="date"
                  value={formik.values.DateOfBirth}
                  handleDateChange={(date) => handleDOBChange(date)}
                />
              </div>
            </form>
          ) : (
            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', gap: '10px' }}>
              <div className="personal-info-section">
                <SectionDisplay title={t('First Name')} value={personalInfo.firstName} disabled />
                <SectionDisplay title={t('Last Name')} value={personalInfo.lastName} disabled />
                <SectionDisplay title={t('Email')} value={personalInfo.email} disabled />
                <SectionDisplay title={t('Street Address')} value={personalInfo.streetAddress} disabled />
                <SectionDisplay title={t('Mobile Number')} value={personalInfo.phoneNumber} disabled />
                <SectionDisplay title={t('Country')} value={personalInfo.country} disabled />
                {/* <SectionDisplay title={t('State')} value={personalInfo.state} disabled /> */}
                <SectionDisplay title={t('City')} value={personalInfo.city} disabled />
                <SectionDisplay title={t('Zip Code')} value={personalInfo.zipCode} disabled />
                <SectionDisplay title={t('Date of Birth')} value={personalInfo.dob} disabled />
              </div>
            </div>
          )}
        </div>
      <Reminder />
        </div>
        <div style={{ display: 'flex', flexDirection: 'column', gap: 10, flex: 0.8, height: '100%' }}>
      <AISettingsComponent />
        <PreferencesSection />
      <NotificationSettingsComponent /> 
        </div>
      </div>
      {/* <div style={{ display: 'flex', flexDirection: 'row', gap: 10, position: 'relative', flex: 1, width: '100%' }}>
      </div> */}
          </div>
    </div>
  );
};

interface SectionProps {
  title: string;
  value?: string;
  type?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
  disabled?: boolean;
  name?: string;
  style?: React.CSSProperties;
  selectedFileName?: string | null;
  handleDateChange?: (date: Dayjs | null) => void;
}

const Section: React.FC<SectionProps> = ({ title, value, onChange, onBlur, name, type, selectedFileName, handleDateChange }) => {
  const darkTheme = createTheme({
    palette: {
      mode: 'dark',
    },
  });

  const classes = useStyles();

  if (type === 'date') {
    return (
      <div className='personal-info__display' >
        <span>{title}</span>
        <ThemeProvider theme={darkTheme}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <div className={classes.root}>
              <DatePicker
                value={value ? dayjs(value) : null}
                onChange={date => handleDateChange && handleDateChange(date)}
                format="DD-MM-YYYY"
              />
            </div>
          </LocalizationProvider>
        </ThemeProvider>
      </div>
    );
  } else {
    return (
      <div className='personal-info__display'>
        <span>{title}</span>
        <input type={type} name={name} value={value} onChange={onChange} onBlur={onBlur} placeholder={title} />
      </div>
    );
  }
};

const SectionDisplay: React.FC<SectionProps> = ({ title, value, onChange, onBlur, type, disabled }) => (
  <div className='personal-info__display'>
    <span>{title}</span>
    <input type={type ? type : "text"} value={value} onChange={onChange} onBlur={onBlur} disabled={disabled} placeholder={title} />
  </div>
);

export default PersonalInfoAndProfile;
