import React, { useEffect } from "react";
import { useState } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import FirstPage from "./pages/FirstPage";
import TermsConditions from "./components/SignUpRequirements/TermsConditions";
import PrivacyPolicy from "./components/SignUpRequirements/PrivacyPolicy";
import DashboardPage from "./pages/DashboardPage";
import AIAssistantPage from "./pages/AIAssistantPage";
import DocumentsPage from "./pages/DocumentsPage";
import ReportsPage from "./pages/ReportsPage";
import SettingsPage from "./pages/SettingsPage";
import HelpPage from "./pages/HelpPage";
import FeedbackPage from "./pages/FeedbackPage";
import { UserContext, UserContextProps } from "../src/contexts/UserContext";
import "../node_modules/flag-icon-css/css/flag-icons.min.css";
import { ForgotPasswordLink } from "./components/ForgotPasswordLink/ForgotPasswordLink";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import PrivateRoute from "./PrivateRoutes";
import ResetPassword from "./components/LoginSection/ResetPassword";
import { MsalProvider } from "@azure/msal-react";
import pca from "./msalInstance";
import { PayPalScriptProvider } from "@paypal/react-paypal-js";
import Marketplace from "./components/Marketplace/Marketplace";
import PrivateLayout from "./pages/PrivateLayout";
import LCMPost from "./components/AIAssistant/lcm/LCMPost";
import GlobalModal from "./components/shared/GlobalModal";
import './App.css'
import { PeerProvider } from "./contexts/Peer";
import PaymentResults from "./components/Payment/PaymentResults";
import UserFeedbackModal from "./components/shared/UserFeedbackModal";
import FinancePage from "./pages/FinancePage";
import OtpInput from "./components/LoginSection/OtpInput";
import {  isMobile,
  isTablet,
  isDesktop,
  browserName,
  browserVersion,
  engineName,
  engineVersion,
  getUA,
  osName,
  osVersion,
  mobileVendor,
  mobileModel,
  deviceDetect, } from 'react-device-detect';
import ProjectsPage from "./pages/ProjectsPage";
import { getUserType } from "./store/actions/ChatAction";
import MessageCenterPage from "./pages/MessageCenterPage";
import TimeKeepingPage from "./pages/TimeKeepingPage";
import ClientPortalPage from "./pages/ClientPortalPage";

const App: React.FC = () => {
  const [userDataState, setUserDataState] = useState<UserContextProps>({
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    address: {
      streetAddress: "",
      country: "",
      zipCode: "",
      city: "",
      state: "",
    },
    setUserData: () => { },
  });

  const [activeView, setActiveView] = useState('defaultView');
  const userType = getUserType();
  const setUserData = (data: Partial<UserContextProps>) => {
    setUserDataState((prevState) => ({ ...prevState, ...data }));
  };
  const initialOptions = {
    clientId: "AZpIad7HV6zBmtZMkg2jqRHXtizhVRKc7yn7rDOhtWfxvkDHV94i-2spkf9elfCa5JekwSRf96zy0uCS",
    currency: "USD",
    intent: "capture",
  };

  const getDeviceInfo = () => {
    const deviceInfo = {
      deviceType: isMobile ? 'Mobile' : isTablet ? 'Tablet' : isDesktop ? 'Desktop' : 'Unknown',
      userAgent: getUA,
      browser: browserName,
      browserVersion: browserVersion,
      engine: engineName,
      engineVersion: engineVersion,
      os: osName,
      osVersion: osVersion,
      mobileVendor: mobileVendor || 'Unknown Vendor',
      mobileModel: mobileModel || 'Unknown Model',
    };


    const uniqueId = btoa(JSON.stringify(deviceInfo));

    return {
      ...deviceInfo,
      uniqueId,
    };
  };
  getDeviceInfo()

  return (
    <PeerProvider>

      <PayPalScriptProvider options={initialOptions}>
        <MsalProvider instance={pca}>
          <UserContext.Provider value={{ ...userDataState, setUserData }}>
            <Router>
              <UserFeedbackModal />
              <ToastContainer position="top-right" theme="dark" autoClose={1500} />
              <Route path="/" exact component={FirstPage} />
              <Route path="/forgot-password" component={ForgotPasswordLink} />
              <Route path="/terms-conditions" component={TermsConditions} />
              <Route path="/privacy-policy" component={PrivacyPolicy} />
              <Route path="/auth/otp" component={OtpInput} />



              {/* Private routes */}
              <Switch>
                <PrivateRoute >
                  <PrivateLayout activeView={activeView} setActiveView={setActiveView}>
                    {userType === 'LawfirmAdmin' ? (
                      <Route path="/dashboard/admin" component={DashboardPage} />
                    ) : userType === 'Lawfirmlawyer' ? (
                      <Route path="/dashboard/panel" component={DashboardPage} />
                    ) : (
                      <Route path="/dashboard" component={DashboardPage} />
                    )}
                    <Route path="/documents" component={DocumentsPage} />
                    <Route path="/ai-assistant" component={AIAssistantPage} />
                    <Route path="/LCM" component={LCMPost} />
                    <Route path="/analytics" component={ReportsPage} />
                    <Route path="/help" component={HelpPage} />
                    <Route path="/feedback" component={FeedbackPage} />
                    <Route path="/marketplace" component={Marketplace} />
                    <Route path="/settings" component={SettingsPage} />
                    <Route path="/projects" component={ProjectsPage} />
                    <Route path="/dashboard/finance" component={FinancePage} />
                    <Route path={`${(userType === 'LawfirmAdmin' || userType === 'Lawfirmlawyer') ? '/dashboard/message-center' : '/message-center' }`} component={MessageCenterPage} />
                    <Route path="/payment-result" component={PaymentResults} />
                    <Route path="/dashboard/time-keeping" component={TimeKeepingPage} />
                    <Route path="/client-portal" component={ClientPortalPage} />
                  </PrivateLayout>
                </PrivateRoute>
              </Switch>

              <Route path="/reset-password/:token" component={ResetPassword} />
            </Router>
            <GlobalModal />
          </UserContext.Provider>
        </MsalProvider>
      </PayPalScriptProvider>
    </PeerProvider>

  );
};

export default App;
