import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState, useAppDispatch } from '../../store';
import './TimeTracker.css';
import { getAllLawfirmAssignedTask } from '../../store/actions/LawfirmActions';
import { fetchCaseList } from '../../store/actions/marketplace/caseActions';
import { getUserType } from '../../store/actions/ChatAction';
import { parse, format, isValid } from 'date-fns';
import { stopTimer, startTimer, pauseTimer, resumeTimer, logTimeEntry, setTimerRunningState, setTimer, setPausedState, getTimeEntries } from '../../store/actions/TimeManagementActions';

const TimeTracker: React.FC = () => {
  const dispatch = useAppDispatch();
  const [intervalId, setIntervalId] = useState<NodeJS.Timeout | null>(null);
  const [selectedItem, setSelectedItem] = useState<string | null>(null);
  const [activeList, setActiveList] = useState<'tasks' | 'cases'>('tasks');
  const userType = getUserType();

  const isRunning = useSelector((state: RootState) => state.time.isRunning);
  const time = useSelector((state: RootState) => state.time.time);
  const isPaused = useSelector((state: RootState) => state.time.isPaused);

  const tasks = useSelector((state: RootState) => state.lawfirm.tasks);
  const cases = useSelector((state: RootState) => state.case.caseList);

  useEffect(() => {
    dispatch(getAllLawfirmAssignedTask(userType));
    dispatch(fetchCaseList());
  }, [dispatch, userType]);

  useEffect(() => {
    if (isRunning && !isPaused) {
      const id = setInterval(() => {
        dispatch(setTimer(time + 1));
      }, 1000);
      setIntervalId(id);
    } else if (intervalId) {
      clearInterval(intervalId);
      setIntervalId(null);
    }

    return () => {
      if (intervalId) clearInterval(intervalId);
    };
  }, [isRunning, isPaused, time, dispatch]);

  const startTimerHandler = () => {
    if (!selectedItem) return;

    const case_id = activeList === 'cases' ? selectedItem : null;
    const task_id = activeList === 'tasks' ? selectedItem : null;

    dispatch(setTimer(0));
    dispatch(startTimer(case_id, task_id));
    dispatch(setTimerRunningState(true));
    dispatch(setPausedState(false));
  };

  const stopTimerHandler = () => {
    if (intervalId) {
      clearInterval(intervalId);
      setIntervalId(null);
    }

    const case_id = activeList === 'cases' ? selectedItem : null;
    const task_id = activeList === 'tasks' ? selectedItem : null;

    dispatch(stopTimer(case_id, task_id));
    attachTimeToItem();
    dispatch(setTimerRunningState(false));
    dispatch(setPausedState(false));
    dispatch(setTimer(0));
  };

  const pauseTimerHandler = () => {
    if (intervalId) {
      clearInterval(intervalId);
      setIntervalId(null);
    }

    const case_id = activeList === 'cases' ? selectedItem : null;
    const task_id = activeList === 'tasks' ? selectedItem : null;

    dispatch(pauseTimer(case_id, task_id));
    dispatch(setTimerRunningState(false));
    dispatch(setPausedState(true));
  };

  const resumeTimerHandler = () => {
    const case_id = activeList === 'cases' ? selectedItem : null;
    const task_id = activeList === 'tasks' ? selectedItem : null;

    dispatch(resumeTimer(case_id, task_id));
    dispatch(setTimerRunningState(true));
    dispatch(setPausedState(false));
  };

  // Format time in HH:MM:SS
  const formatTime = (seconds: number) => {
    const hrs = Math.floor(seconds / 3600);
    const mins = Math.floor((seconds % 3600) / 60);
    const secs = seconds % 60;
    return `${hrs.toString().padStart(2, '0')}:${mins.toString().padStart(2, '0')}:${secs.toString().padStart(2, '0')}`;
  };

  const attachTimeToItem = () => {
    if (selectedItem) {
      alert(`Attached ${formatTime(time)} to ${selectedItem}`);
      dispatch(logTimeEntry({
        case_id: activeList === 'cases' ? selectedItem : "",
        task_id: activeList === 'tasks' ? selectedItem : "",
        start_time: new Date().toISOString(),
        end_time: new Date(new Date().getTime() + time * 1000).toISOString(),
        duration: time,
        description: `Time entry for ${selectedItem}`
      })).then(() => {
        // Fetch the updated time entries after logging
        dispatch(getTimeEntries(
          activeList === 'cases' ? selectedItem : "",
          activeList === 'tasks' ? selectedItem : ""
        ));
      });
    }
  };

  const handleItemClick = (item: any) => {
    setSelectedItem(item.TaskID || item.CaseID);
  };

  const safeFormatDate = (dateStr: string, formatStr: string) => {
    const parsedDate = parse(dateStr, formatStr, new Date());
    return isValid(parsedDate) ? format(parsedDate, 'PP') : 'Invalid date';
  };

  const formatNumber = (value: string): string => {
    return value.replace(/\B(?=(\d{3})+(?!\d))/g, " ");
  };

  return (
    <div className="time-tracker">
      <div className="time-tracker__header">
        <span className="time-tracker__time" style={{ color: isRunning ? '#fff' : '#aaa' }}>{formatTime(time)}</span>
        <div style={{ display: 'flex', flexDirection: 'row', gap: 10 }}>
          <button
            className={`time-tracker__toggle-btn ${!selectedItem ? 'disabled' : ''}`}
            onClick={(isRunning || isPaused) ? stopTimerHandler : startTimerHandler}
            disabled={!selectedItem}
          >
            <i className={`fas ${(isRunning || isPaused) ? 'fa-stop' : 'fa-play'}`}></i>
          </button>
          <button
            className={`time-tracker__toggle-btn ${!selectedItem ? 'disabled' : ''}`}
            onClick={isPaused ? resumeTimerHandler : pauseTimerHandler}
            disabled={!selectedItem || (!isRunning && !isPaused)}
          >
            <i className={`fas ${isPaused ? 'fa-play' : 'fa-pause'}`}></i>
          </button>
        </div>
      </div>

      <div className="time-tracker__lists-toggle">
        <button
          className={`toggle-btn ${activeList === 'tasks' ? 'active' : ''}`}
          onClick={() => setActiveList('tasks')}
        >
          Tasks
        </button>
        <button
          className={`toggle-btn ${activeList === 'cases' ? 'active' : ''}`}
          onClick={() => setActiveList('cases')}
        >
          Cases
        </button>
      </div>

      <div className="time-tracker__list">
        {(activeList === 'tasks' ? tasks : cases).map((item: any) => (
          <div
            key={item.TaskID || item.CaseID}
            className={`time-tracker__item ${selectedItem === (item.TaskID || item.CaseID) ? 'selected' : ''}`}
            onClick={() => handleItemClick(item)}
          >
            {activeList === 'tasks' ? (
              <>
                <div className="item-header">
                  <p className="item-title">{item.TaskTitle}</p>
                  <span className="item-deadline">
                    {item.TaskDeadline ? `Due: ${safeFormatDate(item.TaskDeadline, 'dd-MM-yyyy HH:mm:ss')}` : 'No Deadline'}
                  </span>
                </div>
                <div>
                  <p className="item-description">{item.TaskDescription}</p>
                  <p className="item-status">{item.Status}</p>
                </div>
              </>
            ) : (
              <>
                <div className="item-header">
                  <div>
                    <p className="item-title">{`${item.CaseType} - ${formatNumber(item.MaxBudget)} ${item.Currency}`}</p>
                    <p className="item-country">{item.CountryOfJurisdiction}</p>
                  </div>
                  <div>
                    <p className="item-urgency">Urgency: {item.Urgency}</p>
                  </div>
                </div>
                <p className="item-status">{item.Status}</p>
              </>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default TimeTracker;
