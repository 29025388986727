import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import PaymentModal from '../../Modal/PaymentModal';
import { useSelector } from 'react-redux';
import { RootState  } from '../../../store';

interface PaymentMethod {
  id: number;
  method: string;
  details: string;
  isDefault: boolean;
}

interface InvoiceData {
  lawyerName: string;
  serviceDescription: string;
  amountDue: number;
  dueDate: string;
}

interface InvoiceDetailProps {
  label: string;
  value: string;
}

const InvoiceDetail: React.FC<InvoiceDetailProps> = ({ label, value }) => (
  <div className="invoice-detail">
    <span>{label} </span>
    <span>{value}</span>
  </div>
);

const InvoicePaymentScreen: React.FC = () => {
  const [depositPaid, setDepositPaid] = useState(false);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<number | null>(null);
  const preferences = useSelector((state: RootState) => state.lists.preferences)
  const { t } = useTranslation()
  const [isOpen, setIsOpen] = useState(false)
  const invoiceData: InvoiceData = {
    lawyerName: "John Doe",
    serviceDescription: "Consultation on corporate law",
    amountDue: 500.00,
    dueDate: "2024-01-30",
  };

  const [paymentMethods, setPaymentMethods] = useState<PaymentMethod[]>([
    { id: 1, method: 'Credit Card', details: '**** **** **** 7282', isDefault: true },
    { id: 2, method: 'PayPal', details: 'Sebastian@pons.io', isDefault: false },
    { id: 3, method: 'Bank Transfer', details: 'Bank of America', isDefault: false },

  ]);


  const getPaymentMethodIcon = (method: string) => {
    switch (method) {
      case 'Credit Card':
        return <i className="fas fa-credit-card"></i>;
      case 'PayPal':
        return <i className="fab fa-paypal"></i>;
      case 'Bank Transfer':
        return <i className="fas fa-university"></i>;
      default:
        return <i className="fas fa-question-circle"></i>;
    }
  };

  const handlePaymentMethodSelect = (methodId: number) => {
    setSelectedPaymentMethod(methodId);
  };

  const handlePayDeposit = () => {
    setIsOpen(true)
  };

  const onClose = () => {
    setIsOpen(false)
  }

  const userCurrency = () => {
    if(preferences.Currency) {
      return <span>{preferences.Currency}</span>
    } else {
      return <span></span>
    }
  }

  return (
    <div className="invoice-payment-container">
      <div className="deposit-container">
        <h2>{t('Deposit Requirement')}</h2>
        <p>{t("A 10% deposit of the total amount due is required to initiate the service.")}</p>
        <div className='depositum-container'>
          <div className='depositum-container__row'>
            <span className='depositum-container--span' style={{ backgroundColor: '#222', color: '#fff' }}>{t('Deposit Amount')}</span>
            <span className='depositum-container--span'>{userCurrency()} {invoiceData.amountDue * 0.1}</span>
          </div>
          <button onClick={handlePayDeposit} disabled={depositPaid}>
            {depositPaid ? 'Paid' : 'Pay Deposit'}

          </button>
          {
            <PaymentModal isOpen={isOpen} onClose={onClose} />
          }
        </div>
      </div>
      <div className="invoice-container">
        <div className='invoice-container__header'>
          <h2>{t('Invoice')}</h2>
          <button>{t('Download')}</button>
        </div>
        <InvoiceDetail label="Lawyer" value={invoiceData.lawyerName} />
        <InvoiceDetail label="Service" value={invoiceData.serviceDescription} />
        <InvoiceDetail label="Amount Due" value={`$${invoiceData.amountDue}`} />
        <InvoiceDetail label="Due Date" value={invoiceData.dueDate} />
      </div>
      <div className="payment-methods-container">
        <div className='payment-methods-container__header'>
          <h2>{t("Payment Methods")}</h2>
        </div>
        <div className="payment-methods-list">
          {paymentMethods.map((method) => (
            <div key={method.id} className={`payment-method ${selectedPaymentMethod === method.id ? "payment-selected" : ""}`} onClick={() => handlePaymentMethodSelect(method.id)}>
              <div className="method-info">
                <span className="method-details">{method.details}</span>
                <span className="payment-method-icon">{getPaymentMethodIcon(method.method)}</span>
              </div>
              <div className="method-actions">
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* Final Payment Button */}
      <div className="final-payment-container">
        <button className={`pay-button ${!depositPaid ? "disabled" : ""}`} onClick={handlePayDeposit} disabled={!depositPaid}>
          {t('Pay Full Amount')} ${invoiceData.amountDue}
        </button>
      </div>
    </div>
  );
};

export default InvoicePaymentScreen;
