import React, { useState } from 'react';
import './TimeTracker.css';
import HeaderStats from './HeaderStats';
import SidePanel from './SidePanel';
import TimekeepingTable from './TimeKeepingTable';
import UserInfo from './UserInfo';
import TimeTracker from './TimeTracker';


const Timekeeping = () => {
  const [selectedDay, setSelectedDay] = useState<string | null>(null);
  const [showSidePanel, setShowSidePanel] = useState(false);

  const stats = {
    activityThisWeek: '85%',
    tasksCompleted: 20,
    tasksTotal: 25,
    casesWorkedOn: 5
  };

  const handleDayClick = (day: string) => {
    setSelectedDay(day);
    setShowSidePanel(true);
  };

  const closeSidePanel = () => {
    setShowSidePanel(false);
    setSelectedDay(null);
  };

  return (
    <div className="timekeeping-page">
      {/* Header Stats */}
      <div className={`backdrop ${showSidePanel ? 'show' : ''}`} onClick={() => setShowSidePanel(false)}></div>
      <div className='timekeeping-page--row'>
        <div style={{ flex:  1 }}>
        <TimeTracker />
        </div>
      <HeaderStats stats={stats} />
      </div>
      {/* User Info */}
      {/* <UserInfo /> */}

      {/* Timekeeping Table */}
      <TimekeepingTable onDayClick={handleDayClick} />


       <div className={`side-panel ${showSidePanel ? 'open_panel' : ''}`}>
                <button className="close-btn--sidepanel" onClick={() => closeSidePanel()}><i className='fas fa-chevron-right'></i></button>
                <div style={{ display: 'flex', flexDirection: 'column', height: '100vh', justifyContent: 'space-between', overflowY: 'auto', scrollbarWidth: 'thin', scrollbarColor: '#64d2ff transparent' }}>
                    <h1 style={{ textAlign: "center", color: "#fff", marginTop: 0, width: "fit-content", alignSelf: "center" }}>Day activity</h1>
                        <SidePanel day={selectedDay} onClose={closeSidePanel}  /> 
            </div> 
            </div> 
    </div>
  );
};

export default Timekeeping;
