import React from 'react'
import ApexCharts from 'react-apexcharts';

interface GaugeChartProps {
    chartOptions: any;
    chartSeries: any;
}

const GaugeChart: React.FC<GaugeChartProps> = ({ chartOptions, chartSeries }) => {
    return (
        <div>
            <ApexCharts
                options={chartOptions}
                series={chartSeries}
                type="radialBar"
                width={650}
                height={370}
            />
        </div>
    )
}

export default GaugeChart
