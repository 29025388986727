import React, { useEffect, useRef, useState } from 'react';
import '../Marketplace.css';
import { LawyerData } from './ClientSection';
import CustomDropdown, { Option } from '../../CustomDropdownLCM.tsx/CustomDropdownLCM';
import { desiredOutcomeOptions, urgencyOptions } from './LCMUploadForm';
import { useTranslation } from 'react-i18next';
import DualThumbSliderNew from './DualThumSliderNew';
import { fetchLCMList, fetchStatusList, fetchUrgencyList, proposeCase } from '../../../store/actions/marketplace/caseActions';
import { RootState, useAppDispatch } from '../../../store';
import { useSelector } from 'react-redux';
import { ICaseItem } from '../Interfaces/ICaseItem';
interface LawyerProfileModalProps {
  lawyer: LawyerData | null;
}

interface StarsProps {
  rating: number;
}

export const Stars: React.FC<StarsProps> = ({ rating }) => {

  return (
    <div className="stars-container">
      {[...Array(5)].map((_, index) => {
        const roundedRating = Math.round(rating * 2) / 1.8;
        const starClass = index + 1 <= Math.floor(rating) ? 'fa-solid fa-star filled' : index + 0.5 < roundedRating ? 'fa-solid fa-star-half-stroke filled' : 'fa-regular fa-star empty';
        return (
          <i
            key={index}
            className={starClass}
          />
        );
      })}
    </div>
  );
};


export const ToggleSwitchReviews: React.FC<{ isOn: boolean; onToggle: () => void }> = ({ isOn, onToggle }) => {
  const { t } = useTranslation()
  return (
    <div className="toggle-switch__bid-type">
      <button className={`button left ${isOn ? '' : 'active'}`} onClick={onToggle}>{t('Details')}</button>
      <button className={`button right ${isOn ? 'active' : ''}`} onClick={onToggle}>{t('Reviews')}</button>
      <div className={`slider-otp ${isOn ? 'right' : ''}`}></div>
    </div>
  );
};



const LawyerProfileContent: React.FC<LawyerProfileModalProps> = ({ lawyer }) => {
  const [urgency, setUrgency] = useState<string>('Normal');
  const [showReviews, setShowReviews] = useState(false);
  const [isBidSelected, setIsBidSelected] = useState(false);
  const endOfComponentRef = useRef<HTMLDivElement>(null);
  const caseList = useSelector((root: RootState) => root.case.caseList);
  const [minPrice, setMinPrice] = useState(1000);
  const [maxPrice, setMaxPrice] = useState(5000);
  const [desiredOutcome, setDesiredOutcome] = useState('');
  const [selectedCase, setSelectedCase] = useState<string>('');
  const [selectedCaseLabel, setSelectedCaseLabel] = useState<string>('Select case to propose');
  const { t } = useTranslation()
  const dispatch = useAppDispatch();
  const LCMList = useSelector((state: RootState) => state.case.LCMList);
  const [message, setMessage] = useState<string>('');
  const [selectedLCMDisplayName, setSelectedLCMDisplayName] = useState("");

  useEffect(() => {
    dispatch(fetchLCMList());
  }, [dispatch]);

  const formatNumber = (value: string): string => {
    return value.replace(/\B(?=(\d{3})+(?!\d))/g, " ");
  };

  const formattedCaseOptions = caseList.filter((caseItem: ICaseItem) => caseItem.Status === "Published").map((caseItem: ICaseItem) => ({
    label: `${caseItem.CaseType} - ${caseItem.Currency} ${formatNumber(caseItem.MaxBudget)}`,
    value: caseItem.CaseID
  }));
  
  const handleCaseChange = (value: string | string[]) => {
    const selectedCaseItem = caseList.find((caseItem: ICaseItem) => caseItem.CaseID === value);
    if (selectedCaseItem) {
      const selectedDisplayName = selectedCaseItem.CaseType; // Directly use the CaseType
      setSelectedLCMDisplayName(selectedDisplayName || "");
      setSelectedCase(selectedCaseItem.CaseID); // Update the selected case ID
      setSelectedCaseLabel(selectedDisplayName || "Select case to propose"); // Update the label to reflect the selection
    }
  };
  
  
  
  const handleSubmitCase = (e: React.FormEvent) => {
    e.preventDefault();
    const proposalData = {
      CaseID: selectedCase,
      UserID: lawyer!.UserID!,
      UserType: 'Lawyer',
      ClientMaxBudget: maxPrice.toString(),
      ClientMinBudget: minPrice.toString(),
      CaseStatus: 'Published',
      ClientMessage: message
    };
    dispatch(proposeCase(proposalData));
  };

  useEffect(() => {
    const selectedCaseOption = formattedCaseOptions.find((option: Option) => option.value === selectedCase);
    setSelectedCaseLabel(selectedCaseOption ? selectedCaseOption.label : 'Select case to propose');
  }, [selectedCase, formattedCaseOptions]);

  useEffect(() => {
    dispatch(fetchUrgencyList());
    dispatch(fetchStatusList());
    dispatch(fetchLCMList())
  }, [dispatch]);

  useEffect(() => {
    if (isBidSelected) {
      endOfComponentRef.current?.scrollIntoView({ behavior: 'smooth' });
    }
  }, [isBidSelected]);

  const handleToggleReviews = () => {
    setShowReviews(!showReviews);
  };

  const handleOpenBidForm = () => {
    setIsBidSelected(!isBidSelected)
  }


  if (!lawyer) return null;


  const getOccupationTag = (lawyer: LawyerData) => {
    if (lawyer.LawfirmID) {
      return { text: t('Law Firm'), color: '#0f4f73' };
    } else {
      return { text: t('Independent'), color: '#7338a0' };
    }
  };

  const LCMOptions = LCMList.map((lcm: any) => {
    let displayText = "LCM";
    if (lcm.CaseType) displayText = lcm.CaseType;
    if (lcm.timestamps && lcm.timestamps.created) displayText += ` ${lcm.timestamps.created}`;
    return { value: lcm.LCMID, label: displayText };
  });

  return (

    <>
      <div className='modal-lawyer__profile'>
        <div style={{ position: 'relative', height: '80%' }}>
        <div className='lawyer-modal__profile--header'>
          <div className='profile-details__row'>
            <div className="lawyer-modal__profile-image">
              {lawyer.ProfilePicture === "" ? (

                <img src={lawyer.ProfilePicture!} alt="Profile" />
              ) : (
                <img src={`${lawyer.ProfilePicture ? lawyer.ProfilePicture : require("../../../resources/dummy-profile-pic-male1.jpg") }  `} alt="Profile"  />
              )}
            </div>

            <div>
              <h2>{lawyer.FirstName} {lawyer.LastName}</h2>

              <p style={{ margin: 0 }}>{`${lawyer.City}, ${lawyer.Country}`}</p>
              <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 10, marginTop: 5 }}>
                <p style={{ fontWeight: 500, margin: 0 }}>Hourly Rate</p>
                <div className='profile-details__pricetag'>
                  <p>{`${lawyer.Currency} ${lawyer.HourlyRate ? lawyer.HourlyRate : 0}`}</p>
                </div>
                </div>
            </div>
          </div>
          <div className='profile-details__topright'>
            <p className='' style={{ background: getOccupationTag(lawyer).color }}>
              {getOccupationTag(lawyer).text}</p>
            <div>
              <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 10 }}>
              </div>
            </div>
          </div>
        </div>


        {!showReviews ? (

          <div className='lawyer-profile__details'>

            <div className='lawyer-profile__details--wrapper'>
              <div>
                <h2 style={{ color: '#fff', fontSize: 18, margin: 0, marginBottom: 10 }}>{t('Stats')}</h2>
                <div className='profile-details__stats'>

                  <div className='profile-details__row' >
                    <p>{t('Rating')}</p>

                    <Stars rating={lawyer.Ratings!} />

                  </div>

                  <div className='profile-details__row'>
                    <p>{t('Cases Handled')}</p>
                    <p className='lawyer-profile__stat'>
                      {`${lawyer.CaseSolved ? lawyer.CaseSolved : 0}`}
                    </p>
                  </div>
                </div>
              </div>
              <div className='profile-details__expertise--container'>
                <h1 style={{  fontSize: 18, margin: 0, marginBottom: 0 }}>{t('Law Expertises')}</h1>
                <div className='profile-details__row--expertise'>
                  <p className='profile-details__expertise'>{lawyer.PracticeArea}</p>
                </div>
              </div>

            </div>
            <div>
              <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
              </div>
            </div>

            <div className='lawyer-profile__bio--container'>
              <h2>{t('Biography')}</h2>
              <p>{lawyer.Biography}</p>
            </div>
          </div>
        ) : showReviews && (

          <div className='lawyer-profile__reviews'>
            <h2>{t('Reviews')}</h2>
            {lawyer && lawyer.reviews && lawyer.reviews.length > 0 ? (
              <ul>
                {lawyer.reviews.map((review, index) => (
                  <li key={index}>
                    <div className="review-text">{review}</div>
                    <Stars rating={lawyer.Ratings!} />
                  </li>
                ))}
              </ul>
            ) : (
              <div className='no-reviews-container'>
              <p className='no-reviews-txt'>{t('This lawyer does not have any reviews on PONS.')}</p>
              </div>
            )}
          </div>
        )}
      </div>
        <div className='lawyer-profile__actions--row'>
          <ToggleSwitchReviews isOn={showReviews} onToggle={handleToggleReviews} />
          <button className='lcm-case-details__actions--btn' onClick={handleOpenBidForm}>{isBidSelected ? t('Close Proposal') : t('Propose Case')}</button>
        </div>

      </div>

      {isBidSelected && (
        <div className='modal-lawyer__case-form'>
          <h2 style={{ color: '#fff' }}>{t('Propose Legal Case')}</h2>
          <form className='modal-lawyer__form' onSubmit={handleSubmitCase}>
            <div className='modal-lawyer__form--column'>
            <div className='upload-case__row--top'>
            <i style={{ color: '#64d2ff', fontSize: 24, alignSelf: 'flex-end', marginBottom: 8 }} className='fa-solid fa-gavel'></i>
            <CustomDropdown
                label={t("Select Legal Case Mapping File")}
                options={formattedCaseOptions}
                value={selectedCaseLabel || selectedCase}
                onChange={(value) => handleCaseChange(value)}
                className="dropdown-legal-case"
              />

          </div>

              <DualThumbSliderNew
                min={10}
                max={500000}
                onChange={(values) => {
                  setMinPrice(values[0]);
                  setMaxPrice(values[1]);
                }}
                currencies={["NOK", "USD", "EUR"]}
                onCurrencyChange={(curr) => {}}
                error={''}
              />
              <div className='upload-case__row'>
                <CustomDropdown
                  label={t("Urgency Status")}
                  options={urgencyOptions}
                  value={urgency}
                  onChange={(value) => setUrgency(Array.isArray(value) ? value[0] : value)}
                  className='dropdown-urgency-status'
                />
                <CustomDropdown
                  label={t("Desired Outcome")}
                  options={desiredOutcomeOptions}
                  value={desiredOutcome}
                  onChange={(value) => setDesiredOutcome(Array.isArray(value) ? value[0] : value)}
                  className='dropdown-desired-outcome'
                />
              </div>
            </div>
            <label style={{ color: '#fff', marginBottom: 10, marginTop: 10 }}>{t('Message')}</label>
            <textarea
              className='lawyer-modal__form--description'
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              required
            />
            <button type='submit' className='submit-case-button'>{t('Submit case')}</button>
          </form>
        </div>
      )}
      <div ref={endOfComponentRef} />
    </>


  );
};

export default LawyerProfileContent;
