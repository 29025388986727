import React, { useCallback, useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TimePicker } from '@mui/x-date-pickers';
import { ThemeProvider } from '@emotion/react';
import { createTheme } from '@mui/material';
import { useStyles } from '../../SignUpSection/SignUpSection';
import dayjs, { Dayjs } from 'dayjs';
import './ScheduleMeeting.css'
import { getUserType } from '../../../store/actions/ChatAction';
import { useAppDispatch } from '../../../store';
import { createMeetingSchedule, getLatestMeeting } from '../../../store/actions/ScheduleMeetingActions';
import { useTranslation } from 'react-i18next';

interface CaseItem {
    CaseID: string;
}
interface ScheduleMeetingProps {
    caseItem: CaseItem;
    meeting: any;
    isMeetingTime: any;
    setIsMeetingTime: (val: boolean) => void

}
const ScheduleMeeting: React.FC<ScheduleMeetingProps> = ({ caseItem, meeting, isMeetingTime, setIsMeetingTime }) => {
    const [open, setOpen] = useState(false);
    const classes = useStyles();
    const userType = getUserType()
    const dispatch = useAppDispatch()
    const { t } = useTranslation()

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        formik.resetForm()
    };

    const parseMeetingDateTime = useCallback((dateTimeStr: string): Date => {
        const [datePart, timePart, hourFormat] = dateTimeStr.split(' ');
        const [day, month, year] = datePart.split('-').map(Number);
        const [hours24, minutes] = convertTo24HourFormat(timePart, hourFormat);

        const dateObj = new Date(year, month - 1, day, hours24, minutes);

        return dateObj;
    }, [])

    const checkMeetingTime = useCallback(() => {
        const currentDateTime = new Date();
        const meetingDateTimeStr = `${meeting.MeetingDate} ${meeting.MeetingTime}`;
        const meetingDateTime = parseMeetingDateTime(meetingDateTimeStr);
        if (currentDateTime >= meetingDateTime) {
            setIsMeetingTime(true);
        } else {
            setIsMeetingTime(false);
        }
    }, [meeting.MeetingDate, meeting.MeetingTime, parseMeetingDateTime, setIsMeetingTime])

    useEffect(() => {
        const interval = setInterval(() => {
            checkMeetingTime();
        }, 1000);

        checkMeetingTime();

        return () => clearInterval(interval);
    }, [meeting, checkMeetingTime]);



    const convertTo24HourFormat = (time12h: string, hourFormat: string): [number, number] => {
        const [time] = time12h.split(' ');
        let [hours, minutes] = time.split(':').map(Number);

        if (hours === 12) {
            hours = 0;
        }
        if (hourFormat === 'PM') {
            hours += 12;
        }

        return [hours, minutes];
    };


    const createMeeting = async (values: any) => {
        handleClose();
        dispatch(createMeetingSchedule(values.MeetingTitle, values.MeetingDate, values.MeetingTime, values.CaseID))
        dispatch(getLatestMeeting(values.CaseID))
    };

    const getValidationSchema = () => {
        let schemaFields = {
            MeetingTitle: Yup.string().required('Meeting title is required'),
            MeetingDate: Yup.string().required('Meeting date is required'),
            MeetingTime: Yup.string()
                .required('Meeting time is required')
                .test('is-time', 'Invalid time format', (value) => {
                    return dayjs(value, 'hh:mm A', true).isValid();
                }),
            CaseID: Yup.string().required("CaseId Required")
        }
        return Yup.object().shape(schemaFields);
    }
    const validationSchema = getValidationSchema();

    const formik = useFormik({
        initialValues: {
            MeetingTitle: '',
            MeetingDate: null,
            MeetingTime: null,
            CaseID: caseItem.CaseID
        },
        enableReinitialize: true,
        validationSchema: validationSchema,
        onSubmit: (values) => {
            createMeeting(values)
        }
    })
    const darkTheme = createTheme({
        palette: {
            mode: 'dark',
        },
    });

    const handleDate = (date: Dayjs | null, fromik: any) => {
        if (date) {
            const today = dayjs().startOf('day'); // Get today's date with time set to 00:00:00
            if (date.isBefore(today)) {
                formik.setFieldError('MeetingDate', 'The selected date is in the past. Please select a valid date.');
                formik.setFieldValue('MeetingDate', '');
            } else {
                const formattedDate = dayjs(date).format('DD-MM-YYYY');
                formik.setFieldValue('MeetingDate', formattedDate);
                formik.setFieldError('MeetingDate', '');
            }
        } else {
            formik.setFieldValue('MeetingDate', '');
        }
    }
    const handleTime = (time: Dayjs | null, formik: any) => {
        // if (time) {
        //     const formattedDate = dayjs(time).format('hh:mm A');;
        //     formik.setFieldValue('MeetingTime', formattedDate);
        // } else {
        //     formik.setFieldValue('MeetingTime', '');
        // }

        const selectedDate = formik.values.MeetingDate ? dayjs(formik.values.MeetingDate, 'DD-MM-YYYY') : null;

        if (time && selectedDate) {
            const now = dayjs();
            const currentTime = now.format('hh:mm A');
            const today = now.startOf('day');

            if (selectedDate.isSame(today, 'day') && dayjs(time).isBefore(now)) {
                formik.setFieldError('MeetingTime', 'The selected time is in the past. Please select a valid time.');
                formik.setFieldValue('MeetingTime', '');
            } else {
                const formattedTime = dayjs(time).format('hh:mm A');
                formik.setFieldValue('MeetingTime', formattedTime);
            }
        } else if (time) {
            const formattedTime = dayjs(time).format('hh:mm A');
            formik.setFieldValue('MeetingTime', formattedTime);
        } 
    }

    return (
        <div>
            {
                userType === "Client" ? (
                    // {
                    Object.keys(meeting).length > 0 ? (
                        <div className={`meetingDetails ${isMeetingTime && "meetingTime"}`}>
                            <p className='textItem'>{t("Meeting Scheduled")}</p>
                            <p className='textItem'>{meeting.MeetingTitle}</p>
                            <p className='textItem'>{meeting.MeetingDate}</p>
                            <p className='textItem'>{meeting.MeetingTime}</p>

                        </div>
                    ) : (<p className='marketplace-no-meeting'>{t("No Meeting Scheduled")}</p>)
                    // }

                ) : (
                    <div style={{ display: "flex", alignItems: "center", flexDirection: "row", }}>
                        {
                            Object.keys(meeting).length > 0 ? (
                                <div className={`meetingDetails ${isMeetingTime && "meetingTime"}`}>
                                    <p className='textItem'>{meeting.MeetingTitle}</p>
                                    <p className='textItem'>{meeting.MeetingDate}</p>
                                    <p className='textItem'>{meeting.MeetingTime}</p>

                                </div>
                            ) : (<p></p>)
                        }
                        {
                            isMeetingTime ? (<></>) : (
                                <Button variant="outlined" onClick={() => Object.keys(meeting).length > 0 ? "" : handleClickOpen()}>
                                    {Object.keys(meeting).length > 0 ? "Meeting Scheduled" : "Schedule Meeting"}
                                </Button>
                            )
                        }

                    </div>
                )
            }

            <Dialog open={open} onClose={handleClose}  >
                <DialogContent style={{ backgroundColor: "#111" }} >
                    <DialogTitle className='Text'>{t('Schedule a Meeting')}</DialogTitle>
                    <div className="meeting-group">
                        <label htmlFor="MeetingTitle" className="form-label">{t('Meeting Title')}<span>*</span></label>
                        <input
                            autoComplete="off"
                            type="text"
                            name="MeetingTitle"
                            maxLength={70}
                            tabIndex={1}
                            value={formik.values?.MeetingTitle}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            className={`form-control-input ${formik.errors?.MeetingTitle && formik.touched.MeetingTitle ? 'meeting-box-error' : ''}`}
                            id="MeetingTitle"
                        />
                        {formik.errors?.MeetingTitle && formik.touched.MeetingTitle && <div className="error-message-display">{formik.errors?.MeetingTitle}</div>}
                    </div>

                    <ThemeProvider theme={darkTheme}>
                        <div className="meeting-group">
                            <label htmlFor="date-of-birth" className="form-label">{t('Meeting Date')}<span>*</span></label>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <div className={classes.root}>
                                    <DatePicker
                                        value={formik.values.MeetingDate ? dayjs(formik.values.MeetingDate) : null}
                                        onChange={date => handleDate(date, formik)}
                                        format="DD-MM-YYYY"
                                        minDate={dayjs().startOf('day')}
                                        name="MeetingDate"

                                    />
                                </div>
                            </LocalizationProvider>
                            {formik.errors?.MeetingDate && formik.touched.MeetingDate && <div className="error-message-display">{formik.errors?.MeetingDate}</div>}
                        </div>
                    </ThemeProvider>

                    <ThemeProvider theme={darkTheme}>
                        <div className="meeting-group">
                            <label htmlFor="date-of-birth" className="form-label">{t("Meeting Time")} <span>*</span></label>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <div className={classes.root}>
                                    <TimePicker
                                        value={formik.values.MeetingTime ? dayjs(formik.values.MeetingTime) : null}
                                        onChange={time => handleTime(time, formik)}
                                        // format="DD-MM-YYYY"
                                        // minTime={minTime}
                                        name="MeetingTime"

                                    />
                                </div>
                            </LocalizationProvider>
                            {formik.errors?.MeetingTime && formik.touched.MeetingTime && <div className="error-message-display">{formik.errors?.MeetingTime}</div>}
                        </div>
                    </ThemeProvider>
                    <DialogActions>
                        <Button onClick={handleClose}>{t("Cancel")}</Button>
                        <Button type='submit' onClick={() => formik.handleSubmit()}>{t("Schedule")}</Button>
                    </DialogActions>
                </DialogContent>

            </Dialog>

        </div>
    )
}

export default ScheduleMeeting
