import Projects from "../components/Projects/Projects";

function ProjectsPage() {
    return (
        <div style={{ overflowY: 'auto', height: 'calc(100vh - 100px)', scrollbarWidth: 'thin', scrollbarColor: '#64d2ff #111' }}>
                <Projects />
        </div>
    )
}

export default ProjectsPage;