import { FETCH_LAWYERS_SUCCESS, FETCH_LAWYERS_FAILURE, GET_RATINGS_REQUEST, GET_RATINGS_FAILURE, GET_RATINGS_SUCCESS, GET_PUBLISHED_CASES_SUCCESS, GET_PUBLISHED_CASES_FAILURE,FETCH_PROPOSAL_SUCCESS, FETCH_PROPOSAL_FAILURE, FETCH_CASE_LIST_LAWYER_SUCCESS, FETCH_CASE_LIST_LAWYER_FAILURE } from "../../types";
import { LawyerData } from "../../../components/Marketplace/Client/ClientSection";
import { RatingsData } from "../../actions/marketplace/lawyerAction";
import { ICaseItem } from "../../../components/Marketplace/Interfaces/ICaseItem";
import { CaseData } from "../../../components/Marketplace/Interfaces/ICaseData";



const initialState = {
    lawyerList: [] as LawyerData[], 
    error: null,
    ratingsData: [] as RatingsData[],
    publishedCases: [] as ICaseItem[],
    proposals: [] as any,
    caseList: [] as CaseData[]
    
  };
  

export const lawyerReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case FETCH_LAWYERS_SUCCESS:
      return {
        ...state,
        lawyerList: action.payload,
      };
    case FETCH_LAWYERS_FAILURE:
      return {
        ...state,
        error: action.payload,
      };
    case FETCH_PROPOSAL_SUCCESS:
      return {
        ...state,
        proposals: action.payload,
      };
    case FETCH_PROPOSAL_FAILURE:
      return {
        ...state,
        error: action.payload,
      };
      case GET_RATINGS_REQUEST:
        return {
          ...state,
          isLoading: true, 
          error: null };
          case GET_RATINGS_SUCCESS:
            return {
          ...state,
          type: GET_RATINGS_SUCCESS,
          isLoading: false, 
          ratingsData: action.payload,
         };
         case FETCH_CASE_LIST_LAWYER_SUCCESS:
          return {
            ...state,
            caseList: action.payload,
          };
        case FETCH_CASE_LIST_LAWYER_FAILURE:
          return {
            ...state,
            error: action.payload,
          };
          case GET_RATINGS_FAILURE:
            return {
              ...state,
              isLoading: false,
              error: action.payload };
              case GET_PUBLISHED_CASES_SUCCESS:
                return {
                  ...state,
                  isLoading: false,
                  publishedCases: action.payload,
                  error: null
                };
                case GET_PUBLISHED_CASES_FAILURE:
                  return {
                    ...state,
                    isLoading: false,
                    error: action.payload
                  };

    default:
      return state;
  }
};
