import React from 'react';
import './ClientPortal.css';

interface SidePanelProps {
  client: any;
  onClose: () => void;
}

const SidePanel: React.FC<SidePanelProps> = ({ client, onClose }) => {
  if (!client) return null;

  return (
    <div className="client-portal--side-panel">
      {/* <button className="close-btn" onClick={onClose}>
        Close
      </button> */}
      <h3>Client Details - {client.name}</h3>
      <div className="sidepanel--client-details">
        {client.cases ? (
          <>
          <div>
            <p>Contact: {client.contact}</p>
            <p>Cases: {client.cases}</p>
            </div>
            <div>
            <p>Status: {client.status}</p>
            <p>Phone: {client.phone}</p>
            </div>
            <div>
            <p>Recent Activity: {client.recentActivity}</p>
            <p>Case Types: {client.caseTypes.join(', ')}</p>
            </div>
          </>
        ) : (
          <>
            <p>Rating: {client.rating}</p>
            <p>Cases Completed: {client.casesCompleted}</p>
            <p>Recent Activity: {client.recentActivity}</p>
          </>
        )}
      </div>
    </div>
  );
};

export default SidePanel;
