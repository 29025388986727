import React, { useEffect, useMemo, useState } from 'react';
import LCMUploadForm from './LCMUploadForm';
import { FilterSetting } from '../FilterDropdown';
import FilterDropdownLawyer from './FilterDropdownLawyer';
import LCMCaseDetailsContent from './LCMCaseDetailsContent';
import LawyerProfileContent from './LawyerProfileModal';
import { fetchBids, fetchCaseList, fetchFullCase, fetchLawyers, fetchLCMList } from '../../../store/actions/marketplace/caseActions';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../store';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { ClipLoader } from 'react-spinners';
import { selectCaseForViewing, setDetailsContent } from '../../../store/actions/marketplace/activeViewActions';
import { useTranslation } from 'react-i18next';
import { getPreferences } from '../../../store/actions/DataAction';
import { getUserID, getUserType } from '../../../store/actions/ChatAction';
import { IFullCaseData, LawyerDetails } from '../Interfaces/IMarketCase';
import { Route, Switch, useRouteMatch } from 'react-router-dom';


export interface LawyerData {
  FirstName: string;
  LastName: string;
  Email: string;
  City: string;
  Country: string;
  CreationDate: string;
  DateOfBirth: string;
  KYCStatus: string;
  LawyerID: string;
  LawyerLicenseLink: string;
  LawyerSerialNumber: number;
  Nationality: string;
  PhoneNumber: string;
  PracticeArea: string;
  LawfirmLawyerID: string;
  LawfirmID: string;
  ProfilePicture: string | null;
  State: string;
  Currency?: string;
  StreetAddress: string;
  ZipCode: string;
  Ratings?: number;
  CaseSolved?: number;
  UserID?: string;
  Biography?: string;
  occupation?: string;
  HourlyRate?: number;
  consultationPrice?: number;
  reviews?: string[];
}


export interface LCMData {
  selectedLCM: string;
  minPrice: number;
  maxPrice: number;
  urgency: string;
  description: string;
  status: string;
  country: string;
}

type ClientSectionProps = {
  activeView?: string;
}

const ClientSection: React.FC<ClientSectionProps> = ({ activeView }) => {
  const [selectedLCMCase, setSelectedLCMCase] = useState<IFullCaseData | null>(null);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [selectedLawyer, setSelectedLawyer] = useState<LawyerData | null>(null);
  const dispatch = useDispatch<ThunkDispatch<RootState, undefined, AnyAction>>();
  const cases = useSelector((state: RootState) => state.case.caseList);
  const [selectedCaseId, setSelectedCaseId] = useState<string | null>(null);
  const lawyers = useSelector((state: RootState) => state.lawyers.lawyerList);
  const [isLoading, setIsLoading] = useState(false);
  const selectedCase = useSelector((state: RootState) => state.activeView.selectedCase);
  const detailsContentRedux = useSelector((state: RootState) => state.activeView.detailsContent);
  const [filters, setFilters] = useState({ location: '', expertise: '', rating: 0, casesHandled: 0, lawyerType: '', hourlyRate: 0 });
  const [activeFilters, setActiveFilters] = useState<FilterSetting[]>([['none', 'No filters']]);
  const userId = getUserID();
  const userType = getUserType();
  const fullCase = useSelector((state: RootState) => state.case.fullCase);
  const { path } = useRouteMatch();

  const filteredLawyers = useMemo(() => {
    const list = Array.isArray(lawyers.Lawyers) ? lawyers.Lawyers : [];
    return list.filter((lawyer: LawyerData) => {
      const filterByType = (lawyerType: string, lawyer: LawyerData) => {
        return (lawyerType === 'lawfirm' ? !!lawyer.LawfirmID : !lawyer.LawfirmID);
      };

      const lawyerHourlyRate = typeof lawyer.HourlyRate === 'string' ? parseFloat(lawyer.HourlyRate) : lawyer.HourlyRate;
      return (!filters.location || lawyer.Country.includes(filters.location)) &&
        (!filters.expertise || lawyer.PracticeArea.includes(filters.expertise)) &&
        (!filters.rating || (lawyer.Ratings !== undefined && lawyer.Ratings >= filters.rating)) &&
        (!filters.casesHandled || (lawyer.CaseSolved !== undefined && lawyer.CaseSolved >= filters.casesHandled)) &&
        (!filters.hourlyRate || (lawyerHourlyRate !== undefined && lawyerHourlyRate <= filters.hourlyRate)) &&
        (!filters.lawyerType || filterByType(filters.lawyerType, lawyer));
    });

  }, [lawyers, filters]);


  useEffect(() => {
    const active = Object.entries(filters)
      .filter(([key, value]) => value)
      .map(([key, value]) => [key, value.toString()] as FilterSetting);

    setActiveFilters(active.length > 0 ? active : [['none', 'No filters']]);
  }, [filters]);


  const { t } = useTranslation()

  useEffect(() => {
    dispatch(setDetailsContent(detailsContentRedux));
  }, [detailsContentRedux, dispatch]);

  useEffect(() => {
    setIsLoading(true);
    dispatch(fetchLawyers()).finally(() => setIsLoading(false));
    if (activeView === "cases") {
      dispatch(fetchLCMList())
    }
  }, [dispatch, activeView]);

  useEffect(() => {
    dispatch(getPreferences(userType));
  }, [dispatch, userId, userType]);

  useEffect(() => {
    setIsLoading(true);
    dispatch(fetchCaseList()).finally(() => setIsLoading(false));
  }, [dispatch]);


  const handleNewCaseClick = () => {
    dispatch(setDetailsContent('NEW_CASE_FORM'));
  };


  useEffect(() => {

    if (selectedCase) {
      setSelectedLCMCase(selectedCase);
      dispatch(setDetailsContent('CASE_DETAILS'));
    }

    else {

      setSelectedLCMCase(null);
      dispatch(setDetailsContent('NONE'));
    }
  }, [selectedCase, dispatch]);

  const handleLCMClick = (caseItem: IFullCaseData) => {
    setSelectedLCMCase(caseItem);
    dispatch(setDetailsContent('CASE_DETAILS'));
    setSelectedCaseId(caseItem.CaseID);
    // dispatch(fetchBids(caseItem.CaseID))
    setSelectedLawyer(null);
    dispatch(fetchFullCase(caseItem.CaseID));
  };


  const handleLawyerClick = (lawyer: LawyerData) => {
    setSelectedLawyer(lawyer);
    dispatch(setDetailsContent('LAWYER_PROFILE'));
    setSelectedLCMCase(null);
    setSelectedCaseId(null);
  };

  const handleFormSubmit = (formData: FormData) => {
    const lcmData: any = Object.fromEntries(formData.entries());
    lcmData.minPrice = parseFloat(lcmData.minPrice as string);
    lcmData.maxPrice = parseFloat(lcmData.maxPrice as string);

    try {
      dispatch(fetchCaseList());

      const updatedCases = cases;
      const newCase = updatedCases[updatedCases.length - 1];

      if (newCase) {
        dispatch(selectCaseForViewing(newCase));
        dispatch(setDetailsContent('CASE_DETAILS'));
        setSelectedLCMCase(newCase);
      }

    } catch (error) {
      console.error("Failed to submit case:", error);
    }
  };


  const formatNumber = (value: string | undefined | null) => {
    if (value == null) return "";
    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
  };



  const getStatusColor = (status: string) => {
    switch (status) {
      case 'Published': return '#1b8ed0';
      case 'Pending': return '#FF9800';
      case 'Ongoing': return '#249e24';
      case 'Completed': return '#222';
      case 'Off Market': return '#9E9E9E';
      default: return '#9E9E9E';
    }
  };

  const applyFilters = (selectedFilters: { location: string; expertise: string; rating: number; casesHandled: number; lawyerType: string, hourlyRate: number }) => {
    setFilters(selectedFilters);
  };

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };


  const getOccupationTag = (lawyer: LawyerData) => {
    if (lawyer.LawfirmID) {
      return { text: t('Law Firm'), color: '#0f4f73' };
    } else {
      return { text: t('Independent'), color: '#7338a0' };
    }
  };

  return (
    <>
      <div className="client-section">

        <div className='marketplace-container'>
        <Switch>
        <Route exact path={`${path}/cases`}>
            <div className="lcm-list">
              <div className='lcm-list__head'>
                <h1>{t("My Cases")}</h1>
                <button onClick={handleNewCaseClick}><p>{t('Submit Case')}</p><i className='fa-solid fa-plus'></i></button>
              </div>


              <div className='lcm-list__wrapper'>

                {isLoading ? (
                  <div className="loader-container">
                    <ClipLoader color={"#fff"} />
                  </div>
                ) : (
                  <>
                    {cases && cases.length > 0 ? (
                      cases.map((caseItem: IFullCaseData, index: number) => (


                        <div key={index} className={`lcm-item ${caseItem.CaseID === selectedCaseId ? 'selectedcase' : ''} ${caseItem.CaseID === selectedCase ? 'selectedcase' : ''}`} onClick={() => handleLCMClick(caseItem)}>
                          <div className='lcm-list__row'>
                            <div>
                              <h3 className='lcm-type'>{t(`${caseItem.CaseType}`)}</h3>
                              <p style={{ fontSize: 16, color: '#ccc' }}>{caseItem.CountryOfJurisdiction}</p>
                            </div>
                            <div style={{ color: getStatusColor(caseItem.Status), borderRadius: 5, paddingLeft: 10, paddingRight: 10, backgroundColor: '#222' }} className='lcm-status'>
                                <p>{t(`${caseItem.Status}`) || 'N/A'}</p>
                            </div>
                          </div>
                          <div className='lcm-list__row'>
                            <div className='lcm-item__details'>
                              <p className='lcm-price-range'> {formatNumber(caseItem.MinBudget)} - {formatNumber(caseItem.MaxBudget)} {caseItem.Currency}</p>
                            </div>

                                <div className='lcm-urgency'>
                                  <p style={{ color: '#64d2ff' }}>{t(`${caseItem.Urgency}`)}</p>
                                </div>
                          </div>

                        </div>

                      ))
                    ) : (
                      <div className='no-action-selected__container'>
                        <p>{t('No Cases')}</p>
                        <i style={{ color: 'white', fontSize: 80 }} className='fa-regular fa-scale-balanced'></i>
                      </div>
                    )}
                  </>
                )}
              </div>
            </div>
            <div style={{ overflowY: (detailsContentRedux === "NEW_CASE_FORM" || detailsContentRedux === "LAWYER_PROFILE") ? "auto" : undefined }} className="details-container">
            {(!selectedLCMCase) && (detailsContentRedux !== 'NEW_CASE_FORM') && (
              <div className='no-action-selected__container'>
                <p className='non-access'>{t('No case selected')}</p>
              </div>
            )}
            {detailsContentRedux === 'NEW_CASE_FORM' && (
              <LCMUploadForm onSubmit={handleFormSubmit} setDetailsContent={setDetailsContent} setSelectedCase={setSelectedLCMCase} />
            )}
            {detailsContentRedux === 'CASE_DETAILS' && selectedLCMCase && (
              <LCMCaseDetailsContent
              lcmCase={selectedLCMCase}
              formatNumber={formatNumber}
              CaseItem={selectedLCMCase}
              fullCase={fullCase}
              />
            )}
            </div>
            </Route>
            
            <Route exact path={`${path}/lawyers`}>


            <div className="lawyer-list">
              <div className='lawyer-list__header'>
                <h1>{t('Lawyers')}</h1>
                <div style={{ position: 'relative' }}>
                  <button onClick={toggleDropdown}><p>{t('Filters')}</p><i className='fa-regular fa-bars-filter'></i></button>
                  {isDropdownOpen && (
                    <FilterDropdownLawyer applyFilters={applyFilters} toggleDropdown={toggleDropdown} />
                  )}
                </div>


              </div>
              <div className="active-filters">
                {activeFilters && activeFilters.map(([key, value], index) => (
                  <div key={index} className="filter-tag" title={key}>
                    {key === "minBudget" ? `Min ${formatNumber(value)}` : ""}
                    {key === "maxBudget" ? `Max ${formatNumber(value)}` : ""}
                    {key !== "minBudget" && key !== "maxBudget" ? value : ""}
                  </div>
                ))}
              </div>
              <div className='lawyer-list__wrapper'>
                {isLoading ? (
                  <div className="loader-container">
                    <ClipLoader color={"#fff"} />
                  </div>
                ) : (
                  <>
                    {filteredLawyers && filteredLawyers.length === 0 ? (
                      <div className="no-lawyers">
                        {t('No lawyers available')}
                      </div>
                    ) : (
                      filteredLawyers.map((lawyer: LawyerData, index: number) => (
                        <div key={index} className={`lawyer-item ${selectedLawyer && (selectedLawyer.UserID === lawyer.UserID) && (selectedLawyer.UserID === lawyer.UserID) ? 'selectedlawyer' : ''}`} onClick={() => handleLawyerClick(lawyer)}>

                          {lawyer.ProfilePicture === "" ? (

                            <img src={lawyer.ProfilePicture!} alt="Profile" className="lawyer-profile-image--client" />
                          ) : (
                            <img src={`${lawyer.ProfilePicture ? lawyer.ProfilePicture : require("../../../resources/dummy-profile-pic-male1.jpg")}  `} alt="Profile" className="lawyer-profile-image--client" />
                          )}

                          <div className='lawyer-stats__wrapper'>
                            <div style={{ display: 'flex', flexDirection: 'column', gap: 5, justifyContent: 'center' }}>
                              <div className="lawyer-info">
                                <p className="lawyer-name">{lawyer.FirstName} {lawyer.LastName}</p>
                                <p className="lawyer-location">{`${lawyer.City}, ${lawyer.Country}`}</p>
                              </div>
                              <div style={{ display: 'flex', flexDirection: 'row', gap: 3, flexWrap: 'wrap' }}>
                                <p className="lawyer-expertise">{lawyer.PracticeArea}</p>
                              </div>
                            </div>
                            <div >
                              <div style={{ display: 'flex', justifyContent: 'end', marginBottom: 5 }}>
                                <p className='lawyer-occupation__tag' style={{ background: getOccupationTag(lawyer).color }}>
                                  {getOccupationTag(lawyer).text}
                                </p>
                              </div>
                              <div className="lawyer-stats">
                                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                                  <p className="lawyer-rating">{t('Rating')}</p>
                                  <div className='lawyer-stat__item'>
                                    <p>{`${lawyer.Ratings ? lawyer.Ratings : 0}`}</p>
                                  </div>
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                                  <p className="lawyer-cases">{t('Cases')}</p>
                                  <div className='lawyer-stat__item'>
                                    <p>{`${lawyer.CaseSolved ? lawyer.CaseSolved : 0}`}</p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))
                    )}
                  </>
                )}
              </div>
            </div>
            <div style={{ overflowY: (detailsContentRedux === "NEW_CASE_FORM" || detailsContentRedux === "LAWYER_PROFILE") ? "auto" : undefined }} className="details-container">
            {!selectedLawyer && (
              <div className='no-action-selected__container'>
                <p className='non-access'>{t('No lawyer selected')}</p>
              </div>
            )}
              {detailsContentRedux === 'LAWYER_PROFILE' && selectedLawyer && (
                <LawyerProfileContent
                lawyer={selectedLawyer}
                
                />
              )}
              </div>
            </Route>
        </Switch>
        </div>

      </div>
    </>
  );
};

export default ClientSection;
