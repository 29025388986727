import { toast } from 'react-toastify';
import { FolderProps } from '../../components/Documents/Files';
import { AppThunk } from '../types';
import { getUserID, getUserType } from './ChatAction';
import axios, { AxiosError } from 'axios';
import apis from '../../utils/apis';
import { ErrorResponse } from './SignInAction';
import { Dispatch, AnyAction } from 'redux';
import { FileProps } from '../../pages/DocumentsPage';
import { showFeedbackModal } from './UserFeedbackActions';

export const FETCH_CATEGORIES_REQUEST = 'FETCH_DOCUMENTS_REQUEST';
export const FETCH_CATEGORIES_SUCCESS = 'FETCH_DOCUMENTS_SUCCESS';
export const FETCH_CATEGORIES_FAILURE = 'FETCH_DOCUMENTS_FAILURE';
export const FETCH_RECOMMENDED_DOCUMENTS_REQUEST = 'FETCH_RECOMMENDED_DOCUMENTS_REQUEST';
export const FETCH_RECOMMENDED_DOCUMENTS_FAILURE = 'FETCH_RECOMMENDED_DOCUMENTS_FAILURE';
export const FETCH_RECOMMENDED_DOCUMENTS_SUCCESS = 'FETCH_RECOMMENDED_DOCUMENTS_SUCCESS';
export const FETCH_DOCUMENTS_REQUEST = 'FETCH_DOCUMENTS_REQUEST';
export const FETCH_DOCUMENTS_SUCCESS = 'FETCH_DOCUMENTS_SUCCESS';
export const FETCH_DOCUMENTS_FAILURE = 'FETCH_DOCUMENTS_FAILURE';
export const UPDATE_FOLDERS_WITH_DOCUMENTS = 'UPDATE_FOLDERS_WITH_DOCUMENTS';
export const CREATE_DOCUMENT_REQUEST = 'CREATE_DOCUMENT_REQUEST';
export const CREATE_DOCUMENT_SUCCESS = 'CREATE_DOCUMENT_SUCCESS';
export const CREATE_DOCUMENT_FAILURE = 'CREATE_DOCUMENT_FAILURE';
export const DELETE_DOCUMENT_REQUEST = 'DELETE_DOCUMENT_REQUEST';
export const DELETE_DOCUMENT_SUCCESS = 'DELETE_DOCUMENT_SUCCESS';
export const DELETE_DOCUMENT_FAILURE = 'DELETE_DOCUMENT_FAILURE';
export const SELECT_DOCUMENT_FOR_EDITING = 'SELECT_DOCUMENT_FOR_EDITING';
export const RESET_SELECTED_DOCUMENT = 'RESET_SELECTED_DOCUMENT';
export const ADD_SIGNATURE_REQUEST = 'ADD_SIGNATURE_REQUEST';
export const ADD_SIGNATURE_SUCCESS = 'ADD_SIGNATURE_SUCCESS';
export const ADD_SIGNATURE_FAILURE = 'ADD_SIGNATURE_FAILURE';


export const addSignatureRequest = () => ({
  type: ADD_SIGNATURE_REQUEST,
});

export const addSignatureSuccess = (signature: string) => ({
  type: ADD_SIGNATURE_SUCCESS,
  payload: signature,
});

export const addSignatureFailure = (error: string) => ({
  type: ADD_SIGNATURE_FAILURE,
  payload: error,
});


export const deleteDocumentRequest = (): { type: string } => ({
  type: DELETE_DOCUMENT_REQUEST,
});

export const deleteDocumentSuccess = (documentId: string): { type: string; payload: string } => ({
  type: DELETE_DOCUMENT_SUCCESS,
  payload: documentId,
});

export const deleteDocumentFailure = (error: string): { type: string; payload: string } => ({
  type: DELETE_DOCUMENT_FAILURE,
  payload: error,
});

export const createDocumentRequest = (): { type: string } => ({
  type: CREATE_DOCUMENT_REQUEST,
});

export const createDocumentSuccess = (document: FileProps): { type: string; payload: FileProps } => ({
  type: CREATE_DOCUMENT_SUCCESS,
  payload: document,
});

export const createDocumentFailure = (error: string): { type: string; payload: string } => ({
  type: CREATE_DOCUMENT_FAILURE,
  payload: error,
});


export const selectDocumentForEditing = (document: FileProps) => ({
  type: SELECT_DOCUMENT_FOR_EDITING,
  payload: document,
});

export const resetSelectedDocument = () => ({
  type: RESET_SELECTED_DOCUMENT,
});


export const fetchDocumentsRequest = (): { type: string } => ({
  type: FETCH_DOCUMENTS_REQUEST,
});

export const fetchDocumentsSuccess = (documents: FileProps[]): { type: string; payload: FileProps[] } => ({
  type: FETCH_DOCUMENTS_SUCCESS,
  payload: documents,
});

export const fetchDocumentsFailure = (error: string): { type: string; payload: string } => ({
  type: FETCH_DOCUMENTS_FAILURE,
  payload: error,
});

export const fetchRecommendedDocumentsRequest = (): { type: string } => ({
  type: FETCH_RECOMMENDED_DOCUMENTS_REQUEST,
});

export const fetchRecommendedDocumentsSuccess = (recommendedDocuments: any[]): { type: string; payload: FileProps[] } => ({
  type: FETCH_RECOMMENDED_DOCUMENTS_SUCCESS,
  payload: recommendedDocuments,
});

export const fetchRecommendedDocumentsFailure = (error: string): { type: string; payload: string } => ({
  type: FETCH_RECOMMENDED_DOCUMENTS_FAILURE,
  payload: error,
});

export const updateFoldersWithDocuments = (folders: FolderProps[]): { type: string; payload: FolderProps[] } => ({
  type: UPDATE_FOLDERS_WITH_DOCUMENTS,
  payload: folders
});

export const fetchDocuments = (userId: string, folderId: string, userType: string) => async (dispatch: Dispatch<AnyAction>): Promise<any> => {
  try {
    const response = await apis({
      method: "POST",
      url: "document/getDocumentList",
      data: { UserID: userId, FolderID: folderId, UserType: userType },
    });

    if (response && response.status === 200) {
      dispatch(fetchDocumentsSuccess(response.data));
      return Promise.resolve(response.data);
    } else {
      return Promise.reject(new Error('Failed to fetch documents'));
    }
  } catch (error) {
    console.error("Error in fetching documents:", error);
    dispatch(fetchDocumentsFailure(error instanceof Error ? error.message : 'An unknown error occurred'));
    return Promise.reject(error);
  }
};

export const fetchRecommendedDocuments = () => async (dispatch: Dispatch<AnyAction>): Promise<any> => {
  const userId = getUserID()
  const userType = getUserType()
  dispatch(fetchRecommendedDocumentsRequest())
  try {
    const response = await apis({
      method: "POST",
      url: "document/getRecommendedDocuments",
      data: { UserID: userId, UserType: userType },
    });

    if (response && response.status === 200) {
      dispatch(fetchRecommendedDocumentsSuccess(response.data.RecommendedFiles));
      return Promise.resolve(response.data.RecommendedFiles);
    } else {
      return Promise.reject(new Error('Failed to fetch documents'));
    }
  } catch (error) {
    console.error("Error in fetching documents:", error);
    dispatch(fetchRecommendedDocumentsFailure(error instanceof Error ? error.message : 'An unknown error occurred'));
    return Promise.reject(error);
  }
};



export const createNewDocument = (
  FileType: string,
  FolderID: string,
  file: File | undefined,
  additionalFields: { [key: string]: string | string[] }
): AppThunk => async (dispatch) => {
  dispatch(createDocumentRequest());
  const UserID = getUserID();
  const UserType = getUserType();

  try {
    const formData = new FormData();
    formData.append('UserID', UserID);
    formData.append('UserType', UserType);
    formData.append('FileType', FileType);
    formData.append('FolderID', FolderID);

    if (file) {
      formData.append('File', file);
    }

    Object.keys(additionalFields).forEach((key) => {
      const feildValues = additionalFields[key]
      if (Array.isArray(feildValues)) {
        if (feildValues.length === 0) {
          formData.append(key, JSON.stringify(feildValues));
        } else {
          formData.append(key, JSON.stringify(feildValues));
          // (additionalFields[key] as string[]).forEach((value, index) => {
          // });
        }

      } else {
        formData.append(key, additionalFields[key] as string);
      }
    });

    const response = await apis({
      method: 'POST',
      url: 'document/createFile',
      data: formData,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

    if (response && response.status === 200) {
      const newDocument = response.data;
      dispatch(createDocumentSuccess(newDocument));
      dispatch(
        showFeedbackModal({
          showModal: true,
          message: 'Document successfully created!',
          modalType: 'success',
          duration: 3000,
        })
      );
      dispatch(fetchDocuments(UserID, FolderID, UserType));
      return response.data
    } else {
      if (axios.isAxiosError(response)) {
        const axiosError = response as AxiosError;
        if (axiosError.response && axiosError.response.data) {
          let errorMessage = axiosError.response.data as ErrorResponse;
          dispatch(
            showFeedbackModal({
              showModal: true,
              message: errorMessage.message || 'An unknown error occurred',
              modalType: 'error',
              duration: 3000,
            })
          );
        } else {
          dispatch(
            showFeedbackModal({
              showModal: true,
              message: 'Network error. Please try again later.',
              modalType: 'error',
              duration: 3000,
            })
          );
        }
      }
    }
  } catch (error) {
    console.error('Error in creating document:', error);
    dispatch(createDocumentFailure(error instanceof Error ? error.message : 'An unknown error occurred'));
    dispatch(
      showFeedbackModal({
        showModal: true,
        message: 'Failed to create document.',
        modalType: 'error',
        duration: 3000,
      })
    );
  }
};


export const deleteDocument = (documentId: string, userId: string, UserType: string, callback?: () => void): AppThunk => async (dispatch) => {

  try {
    const response = await apis({
      method: 'POST',
      url: "document/deleteDocument",
      data: { UserID: userId, FileID: documentId, UserType }
    });

    if (response && response.status === 200) {
      dispatch(deleteDocumentSuccess(documentId));
      dispatch(showFeedbackModal({
        showModal: true,
        message: 'Document successfully deleted!',
        modalType: 'success',
        duration: 3000
      }));
      if (callback) callback();
    } else {
      if (axios.isAxiosError(response)) {
        const axiosError = response as AxiosError;
        if (axiosError.response && axiosError.response.data) {
          let erroMessage = axiosError.response.data as ErrorResponse
          dispatch(showFeedbackModal({
            showModal: true,
            message: (erroMessage.message || 'An unknown error occurred'),
            modalType: 'error',
            duration: 3000
          }));
        } else {
          dispatch(showFeedbackModal({
            showModal: true,
            message: 'Network error. Please try again later',
            modalType: 'error',
            duration: 3000
          }));
        }
      }
    }
  } catch (error) {
    console.error("Error in deleting document:", error);
    dispatch(deleteDocumentFailure(error instanceof Error ? error.message : 'An unknown error occurred'));
    dispatch(showFeedbackModal({
      showModal: true,
      message: 'Failed to delete document.',
      modalType: 'error',
      duration: 3000
    }));
  }
};


export const editDocument = (UserID: string, FileID: string, Title: string, Content: string, UserType: string,): AppThunk => async (dispatch) => {
  try {

    let response = await apis({
      method: 'POST',
      url: "document/editDocument",
      headers: { 'Content-Type': 'application/json' },
      data: { UserID, FileID, Title, Content, UserType }
    })

    if (response && response.status === 200) {
      // showSuccessToast("File updated successfully")
      return true
    } else {
      if (axios.isAxiosError(response)) {
        const axiosError = response as AxiosError;
        if (axiosError.response && axiosError.response.data) {
          let erroMessage = axiosError.response.data as ErrorResponse
          dispatch(showFeedbackModal({
            showModal: true,
            message: (erroMessage.message || 'An unknown error occurred'),
            modalType: 'error',
            duration: 3000
          }));
        } else {
          dispatch(showFeedbackModal({
            showModal: true,
            message: 'Network error. Please try again.',
            modalType: 'error',
            duration: 3000
          }));
        }
      }
    }

  } catch (err) {
    console.error(err);
    // toast.
  }
}


export const fetchDocumentContent = (UserID: string, FileID: string, UserType: string): AppThunk => async (dispatch) => {
  try {

    let response = await apis({
      method: 'POST',
      url: "document/getDocumentContent",
      headers: { 'Content-Type': 'application/json' },
      data: { UserID, FileID, UserType }
    })

    if (response.status === 200) {
      // toast.success("File updated successfully")
      return response.data
    } else {
      if (axios.isAxiosError(response)) {
        const axiosError = response as AxiosError;
        if (axiosError.response && axiosError.response.data) {
          let erroMessage = axiosError.response.data as ErrorResponse
          toast.error(erroMessage.message || 'An unknown error occurred');
        } else {
          toast.error('Network error, please try again');
        }
      }
    }

  } catch (error) {
    if (error instanceof AxiosError) {

      if (error.response) {
        console.error('Server responded with:', error.response.data);
      }
    }
    throw error;
  }
}


export const fetchCategories = () => async (dispatch: Dispatch) => {
  dispatch({ type: FETCH_CATEGORIES_REQUEST });
  try {
    const response = await apis({
      method: "GET",
      url: "document/getCategories"
    });
    dispatch({
      type: FETCH_CATEGORIES_SUCCESS,
      payload: response.data
    });
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      dispatch({
        type: FETCH_CATEGORIES_FAILURE,
        payload: error.response.data
      });
    } else {
      dispatch({
        type: FETCH_CATEGORIES_FAILURE,
        payload: "An unknown error occurred"
      });
    }
  }
};

export const callAddSignatureAPI = async (formData: FormData): Promise<any> => {
  try {
    const response = await apis({
      method: 'POST',
      url: 'document/addSignature',
      data: formData
    });
    return response.data;
  } catch (error) {
    console.error('Error calling Add Signature API:', error);
    throw error;
  }
};

export const addSignature = (userId: string, userType: string, signature: File) => {
  return async (dispatch: any) => {
    dispatch(addSignatureRequest());
    const formData = new FormData();
    formData.append('UserID', userId);
    formData.append('UserType', userType);
    formData.append('Signature', signature);

    try {
      const response = await callAddSignatureAPI(formData);
      dispatch(addSignatureSuccess(response.signature));
      dispatch(showFeedbackModal({
        showModal: true,
        message: 'Signature successfully added!',
        modalType: 'success',
        duration: 3000
      }));
    } catch (error: any) {
      dispatch(addSignatureFailure(error.message));
      dispatch(showFeedbackModal({
        showModal: true,
        message: 'Failed to add signature! Please contact support.',
        modalType: 'error',
        duration: 3000
      }));
    }
  };
};


export const updateDocumentOrder = (documentsWithNewOrder: any) => {
  return async (dispatch: Dispatch) => {
    try {
      await apis({
        method: "POST",
        url: "document/updateDocumentOrder",
        data: documentsWithNewOrder
      })

    } catch (err) {
      console.error(err)
    }
  }
}

export const updateFolderOrder = (updateFolderOrder: any) => {
  return async (dispatch: Dispatch) => {
    try {
      await apis({
        method: "POST",
        url: "/document/updateFolderOrder",
        data: updateFolderOrder
      })

    } catch (err) {
      console.error(err)
    }
  }
}

export const fetchDocumentVersion = (FileID: string, UserType: string) => {
  return async (dispatch: Dispatch) => {
    try {
      const resp : any = await apis({
        method: "POST",
        url: "/document/getVersions",
        data: {
          FileID,
          UserType
        }
      })

      if(resp && resp.status === 200){
        console.log(resp.data);
        
        return resp.data
      }

    } catch (err) {
      console.error(err)
    }
  }
}

export const translateDocument = (fileURL: string, desiredLanguage: string) => {
  return async (dispatch: any) => {
    try {
      const payload = {
        FileURL: fileURL,
        DesiredLanguage: desiredLanguage
      };

      const resp: any = await apis({
        method: "POST",
        url: "/document/translate",
        data: JSON.stringify(payload),
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        }
      });

      if (resp && resp.status === 200) {
        dispatch(showFeedbackModal({
          showModal: true,
          message: resp.data.message || 'Document translated successfully!',
          modalType: 'success',
          duration: 3000
        }));
        alert(`The document has been translated and added to files. Filename: ${resp.data.filename}, URL: ${resp.data.url}`);
        return resp.data;
      } else {
        handleAxiosError(resp, dispatch);
      }

    } catch (err) {
      console.error(err);
      handleGenericError(err, dispatch);
    }
  };
};


// Function to handle document anonymization
export const anonymizeDocument = (fileURL: string) => {
  return async (dispatch: any) => {
    try {
      const resp: any = await apis({
        method: "POST",
        url: "/document/anonymize",
        data: JSON.stringify({ FileURL: fileURL }),
        headers: {
          'Content-Type': 'application/json'
        }
      });

      if (resp && resp.status === 200) {
        dispatch(showFeedbackModal({
          showModal: true,
          message: (resp.data.message || 'Document anonymized successfully!'),
          modalType: 'success',
          duration: 3000
        }));

        // Notify user about the anonymized document
        alert(`The document has been anonymized and added to files. Filename: ${resp.data.FileName}, URL: ${resp.data.blob_url}`);
        return resp.data;
      } else {
        handleAxiosError(resp, dispatch);
      }

    } catch (err) {
      console.error(err);
      handleGenericError(err, dispatch);
    }
  };
};


// Helper function to handle Axios errors
const handleAxiosError = (resp: any, dispatch: any) => {
  if (axios.isAxiosError(resp)) {
    const axiosError = resp as AxiosError;
    if (axiosError.response && axiosError.response.data) {
      let errorMessage = axiosError.response.data as ErrorResponse;
      dispatch(showFeedbackModal({
        showModal: true,
        message: (errorMessage.message ? errorMessage.message : errorMessage.error || 'An unknown error occurred'),
        modalType: 'error',
        duration: 3000
      }));
    } else {
      dispatch(showFeedbackModal({
        showModal: true,
        message: 'Network error, please try again',
        modalType: 'error',
        duration: 3000
      }));
    }
  }
};

// Helper function to handle generic errors
const handleGenericError = (err: any, dispatch: any) => {
  dispatch(showFeedbackModal({
    showModal: true,
    message: 'An error occurred, please try again later.',
    modalType: 'error',
    duration: 3000
  }));
};