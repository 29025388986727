import React, { useState, useEffect } from 'react';
import { Stripe } from '@stripe/stripe-js';
import { ClipLoader } from 'react-spinners';
import { useAppDispatch } from '../../../store';
import { createVerificationSession } from '../../../store/actions/StripeActions';


interface StripeVerificationButtonProps {
    stripePromise: any
}

const VerifyButton: React.FC<StripeVerificationButtonProps> = ({ stripePromise }) => {
    const [stripe, setStripe] = useState<Stripe | null>(null);
    const [loading, setLoading] = useState(false);
    const dispatch = useAppDispatch()
    useEffect(() => {
        const loadStripeAsync = async () => {
            const stripeInstance = await stripePromise;
            setStripe(stripeInstance);
        };
        loadStripeAsync();
    }, [stripePromise]);



    const handleClick = async (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();

        if (!stripe) {
            return;
        }
        setLoading(true);

        try {
            const response: any = await dispatch(createVerificationSession())
            const session = response;

            console.log(session, ",jnsdhjkd");
            
            const { error } = await stripe.verifyIdentity(session.client_secret);
            setLoading(false);

            if (error) {
            } else {
            }
        } catch (error) {
            console.error('Error during verification:', error);
        } finally {
            setLoading(false);
        }
    };

    return (
        <button className="dummy-btn" style={{ display: 'flex', alignItems: "center", gap: '5px' }} role="link" disabled={!stripe} onClick={handleClick}>
            Verify
            {loading && <ClipLoader color={"#64d2ff"} size={10} />}
        </button>
    );
};

export default VerifyButton