import './CancelPage.css';

const CancelPage = () => {
    return (
        <div className="result-container">
            <div className="result-tick">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
                    <circle className="result-tick__circle" cx="26" cy="26" r="25" fill="none" />
                    <path className="result-tick__cross" fill="none" d="M16 16 36 36 M36 16 16 36" />
                </svg>
            </div>
            <h1>Payment Canceled</h1>
            <p>Your payment has been canceled. If you have any questions, please contact our support team.</p>
            <p>You will be redirected to the home page shortly. Please wait...</p>
            <div className="payment-details">
                <h2>Cancellation Details:</h2>
                <p><strong>Transaction ID:</strong> 1234567890</p>
                <p><strong>Amount:</strong> $100.00</p>
                <p><strong>Date:</strong> 20th May 2024</p>
            </div>
        </div>
    );
};

export default CancelPage;
