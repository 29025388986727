import { Dispatch } from "redux";
import { UserFeedBackState } from "../reducer/UserFeedbackReducer";

export const SHOW_FEEDBACK_MODAL = 'SHOW_FEEDBACK_MODAL';
export const HIDE_FEEDBACK_MODAL = 'HIDE_FEEDBACK_MODAL';

export const showFeedbackModal = (payload: UserFeedBackState) => (dispatch: Dispatch) => {
  dispatch({
    type: SHOW_FEEDBACK_MODAL,
    payload
  });

  setTimeout(() => {
    dispatch(hideFeedbackModal());
  }, payload.duration);
};

export const hideFeedbackModal = () => ({
  type: HIDE_FEEDBACK_MODAL,
});
