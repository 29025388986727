import { } from 'react-router-dom'
import './DashboardPage.css'
import Dashboard from "../components/Dashboard/Dashboard";


const DashboardPage = () => {
  return (
    <>
      <Dashboard />
    </>
  );
};

export default DashboardPage;
