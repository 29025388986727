import React, { FC, useEffect } from 'react';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import './AddTeam.css';
import CustomDropdownLCM from '../../../CustomDropdownLCM.tsx/CustomDropdownLCM';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from '../../../../store';
import { RootState } from '../../../../store';
import { useSelector } from 'react-redux';
import { createTeam, getAllLawyers, getLegalTeams } from '../../../../store/actions/LawfirmActions';
import { getUserID } from '../../../../store/actions/ChatAction';

interface FormValues {
  teamName: string;
  teamLead: string;
  members: string[];
  legalSubjects: { LegalSubjectID: string; LegalSubjectName: string }[];
}

interface AddTeamProps {
  newTeam: (teamName: string, teamLead: string, members: { UserID: string, UserType: string }[], legalSubjects: { LegalSubjectID: string; LegalSubjectName: string }[]) => void;
  subjects: { LegalSubjectID: string; LegalSubjectName: string }[];
  setIsPanelOpen: (openPanel: string | null) => void;
}

const AddTeam: FC<AddTeamProps> = ({ subjects, setIsPanelOpen }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const userId = getUserID();
  const { lawyers } = useSelector((state: RootState) => state.lawfirm);

  useEffect(() => {
    dispatch(getAllLawyers(userId));
  }, [dispatch, userId]);

  const getValidationSchema = () => {
    return Yup.object().shape({
      teamName: Yup.string().required(t('Team Name is required')),
      teamLead: Yup.string().required(t('Team Lead is required')),
      members: Yup.array().min(2, t('Select at least 2 members')).required(t('Members are required')),
      legalSubjects: Yup.array().required(t('Select at least one legal subject')),
    });
  };

  const validationSchema = getValidationSchema();

  const formik = useFormik<FormValues>({
    initialValues: {
      teamName: '',
      teamLead: '',
      members: [],
      legalSubjects: [],
    },
    validationSchema,
    onSubmit: async (values, { resetForm }) => {
      const selectedMembers = lawyers.filter(lawyer => values.members.includes(`${lawyer.FirstName} ${lawyer.LastName}`));
      const newMembers = selectedMembers.map(lawyer => ({
        UserID: lawyer.LawfirmLawyerID,
        UserType: 'LawfirmLawyer' 
      }));
      const teamLead = selectedMembers.find(lawyer => `${lawyer.FirstName} ${lawyer.LastName}` === values.teamLead);
      const legalSubjectIDs = values.legalSubjects.map(subject => subject.LegalSubjectID);

      const payload = {
        LawfirmID: userId,
        TeamName: values.teamName,
        TeamLeadUserID: teamLead?.LawfirmLawyerID || '',
        TeamLeadUserType: 'LawfirmLawyer',
        LegalSubjects: legalSubjectIDs,
        Members: newMembers
      };

      await dispatch(createTeam(payload));
      resetForm();
      setIsPanelOpen(null);
      dispatch(getLegalTeams(userId));
    },
  });

  const handleLegalSubjectsChange = (selectedSubjects: { LegalSubjectID: string; LegalSubjectName: string }[]) => {
    formik.setFieldValue('legalSubjects', selectedSubjects);
  };

  const handleMembersChange = (value: string | string[]) => {
    const selectedMembers = Array.isArray(value) ? value : [value];
    formik.setFieldValue('members', selectedMembers);
    if (!selectedMembers.includes(formik.values.teamLead)) {
      formik.setFieldValue('teamLead', '');
    }
  };

  return (
    <div className='newteam-container'>
      <h2 style={{ color: '#fff', paddingLeft: 28, fontSize: '1.1rem'  }}>{t('Add new team')}</h2>
      <form onSubmit={formik.handleSubmit} className='newteam-grid-container'>
        <div className='team-group'>
          <label htmlFor='team-name' className='form-label' style={{ fontSize: '1rem' }}>{t('Team Name')}</label>
          <input
            type='text'
            name='teamName'
            maxLength={70}
            value={formik.values.teamName}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            style={{ fontSize: '0.9rem' }}
            className={`team-control-input ${formik.errors.teamName && formik.touched.teamName ? 'input-error' : ''}`}
            id='team-name'
          />
          {formik.errors.teamName && formik.touched.teamName && <div className='error-message-display'>{formik.errors.teamName}</div>}
        </div>
        <div className='team-group'>
          <CustomDropdownLCM
            label={t('Select Members')}
            options={lawyers.map(lawyer => `${lawyer.FirstName} ${lawyer.LastName}`)}
            value={formik.values.members}
            onChange={handleMembersChange}
            multiSelect={true}
            className={`dropdown-Members ${formik.errors.members && formik.touched.members ? 'input-error' : ''}`}
            error={formik.touched.members && formik.errors.members ? formik.errors.members : ''}
          />
        </div>

        <div className='team-group'>
          <CustomDropdownLCM
            label={t('Select Team Lead')}
            options={formik.values.members} 
            value={formik.values.teamLead}
            onChange={(value) => formik.setFieldValue('teamLead', value)}
            className={`dropdown-Members ${formik.errors.teamLead && formik.touched.teamLead ? 'input-error' : ''}`}
            error={formik.touched.teamLead && formik.errors.teamLead ? formik.errors.teamLead : ''}
          />
        </div>

        <div className='team-group'>
          <CustomDropdownLCM
            label={t('Select Legal Subject')}
            options={subjects.map(sub => sub.LegalSubjectName)}
            value={formik.values.legalSubjects.map(sub => sub.LegalSubjectName)}
            onChange={(selectedNames) => {
              const selectedSubjects = subjects.filter(sub => selectedNames.includes(sub.LegalSubjectName));
              handleLegalSubjectsChange(selectedSubjects);
            }}
            multiSelect={true}
            className={`dropdown-cases ${formik.errors.legalSubjects && formik.touched.legalSubjects ? 'input-error' : ''}`}
          />
        </div>
        <div className='lawfirm-team--card'>
        {formik.values.teamName.length > 0 ? (
          <h1 style={{ fontSize: '1rem', margin: 0, color: '#fff', fontWeight: '500' }}>{formik.values.teamName}</h1>
        ) : (
          <h1 style={{ fontSize: '1rem', margin: 0, color: '#999', fontWeight: '500' }}>{t('Team Name')}</h1>
        )}
        <div style={{ width: '100%', margin: '0px auto', display: 'flex', flexDirection: 'row', gap: 5 }}>
          <div className='selected-tags'>
            <div style={{ width: '30%' }}>
              <h4>{t('Members')}</h4>
              {formik.values.members.length > 0 && (
                <div className='tags'>
                  {formik.values.members.map((member, index) => (
                    <span key={index} className='tag'>{member}</span>
                  ))}
                </div>
              )}
            </div>
            <div style={{ width: '30%' }}>
              <h4>{t('Team Leader')}</h4>
              {formik.values.teamLead.length > 0 && (
                <div className='tags'>
                  <span style={{ color: 'rgb(66, 212, 66)' }} className='tag'>{formik.values.teamLead}</span>
                </div>
              )}
            </div>
            <div style={{ width: '30%' }}>
              <h4>{t('Legal Subjects')}</h4>
              {formik.values.legalSubjects.length > 0 && (
                <div className='tags'>
                  {formik.values.legalSubjects.map((subject, index) => (
                    <span key={index} className='tag'>{subject.LegalSubjectName}</span>
                  ))}
                </div>
              )}
            </div>
          </div>
        </div>
        </div>
        <button className='add-team-btn' type='submit'>{t('Add Team')}</button>
      </form>
    </div>
  );
};

export default AddTeam;
