import React from 'react';
import './CaseView.css';
import { Member, Team } from './ManageTeam';
import { IAdminCaseItem } from '../../../Marketplace/Interfaces/IAdminCaseItem';


interface CaseViewProps {
  caseItem: IAdminCaseItem;
  assignedTo?: Member | Team;
  formatNumber?: (value: string) => string; 
}

const CaseView: React.FC<CaseViewProps> = ({ caseItem, assignedTo, formatNumber }) => {
  const renderAssignedTo = () => {
    if (!assignedTo) return null;

    if ('teamName' in assignedTo) {
      return (
        <div className="assigned-to">
          <h3>Assigned Team</h3>
        </div>
      );
    } else if ('name' in assignedTo) {
      return (
        <div className="assigned-to">
          <h3>Assigned Member</h3>
        </div>
      );
    }
  };

  return (
    <div className="case-view-container">
      <div className="case-view__detail">
        <div className="case-view__detail-row">
        <span className='case-view__detail--casetype'>{caseItem.CaseType}</span>
        </div>
        <div className="case-view__detail-row">
          <div className='case-view__detail--jurisdiction--wrapper'>
            <label>Country of Jurisdiction</label>
            <span className='case-view__detail--jurisdiction'>{caseItem.CountryOfJurisdiction}</span>
            </div>
          <div className='case-view__detail--language--wrapper'>
            <label>Preferred Language</label>
            <span className='case-view__detail--language'>{caseItem.PreferredLanguage}
            </span>
            </div>
        </div>
        <div className="case-view__detail-row">
          <div className='case-view__detail--price--wrapper'>
          <label>Price</label> 
          <span className='case-view__detail--price'>EUR {formatNumber ? formatNumber(caseItem.ClientMinBudget) : caseItem.ClientMinBudget} - {formatNumber ? formatNumber(caseItem.ClientMaxBudget) : caseItem.ClientMaxBudget}</span>
          </div>
          <div className='case-view__detail--urgency--wrapper'>
            <label>Urgency</label>
            <span className='case-view__detail--urgency'>{caseItem.Urgency}</span>
            </div>
        </div>

        <div className='case-view__detail--description--wrapper'>
            <label>Description</label>
            <span className='case-view__detail--description'>{caseItem.Description}</span>
            </div>
            <div className='case-view__detail--desired--wrapper'>
            <label>Desired Outcome</label> 
            <span className='case-view__detail--desired'>{caseItem.DesiredOutcome}</span>
            </div>
            <div className='case-view__detail--expected--wrapper'>
            <label>Expected Outcome</label> 
            <span className='case-view__detail--expected'>{caseItem.ExpectedOutcome}</span>

        </div>
      </div>
      {renderAssignedTo()}
      <div className="case-view__attachments">
        <h3>Attachments</h3>
        {caseItem && caseItem.AttachmentsLink && caseItem.AttachmentsLink.length > 0 ? (
          caseItem.AttachmentsLink.map((link, index) => (
            <a key={index} href={link} target="_blank" rel="noopener noreferrer">
              Attachment {index + 1}
            </a>
          ))
        ) : (
          <span>No links</span>
        )}
      </div>
      <button className="marketplace-button">Open in marketplace</button>

    </div>
  );
};

export default CaseView;
