import MessageCenter from "../components/Dashboard/AdminFirm/MessageCenter/MessageCenter";

function MessageCenterPage() {
    return (
        <div style={{ overflowY: 'auto', height: 'calc(100vh - 120px)', scrollbarWidth: 'thin', scrollbarColor: '#64d2ff #111' }}>

            <MessageCenter />
        </div>
    )
}

export default MessageCenterPage;