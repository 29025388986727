import React from 'react';
import { useTranslation } from 'react-i18next';

type InvoiceDetailProps = {
    label: string;
    value: string;
    status?: string;
};

const invoice = {
    id: 1,
    clientName: "Sebastian Melbye",
    serviceType: "Consultation",
    amountDue: 450,
    dueDate: "04.03.2024",
    status: "Paid",
    depositPaid: true,
    depositAmount: 500 * 0.1,
    paymentHistory: [
        { date: "02.02.2024", amount: 50, method: "Credit Card" },
        { date: "03.02.2024", amount: 450, method: "Bank Transfer" },
    ],
};

const getStatusColor = (status: string) => {
    switch (status) {
        case 'Paid': return 'rgb(66, 212, 66)';
        case 'Pending': return '#ffd700';
        case 'Overdue': return '#ff4500';
        default: return '#d3d3d3';
    }
};

const getIconClass = (icon: string) => {
    switch (icon) {
        case 'Credit Card':
            return 'fas fa-credit-card';
          case 'PayPal':
            return 'fab fa-paypal';
          case 'Bank Transfer':
            return 'fas fa-university';
          default:
            return '';
    }
};



const InvoiceDetail: React.FC<InvoiceDetailProps> = ({ label, value, status }) => {
    return (
    <div className="invoice-detail">
            <span style={{ fontSize: 18, fontWeight: '500' }}>{label} </span>
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 20 }}>
            <span style={{ fontSize: 18, fontWeight: '500' }}>{value}</span>
            {status && (
                <>
                    <div style={{ backgroundColor: '#444', padding: '5px', borderRadius: '5px' }}>
                        <span style={{ color: getStatusColor(status), fontWeight: '600', fontSize: 18 }}>{status}</span>
                    </div>
                </>
            )}
        </div>
    </div>
    );
};

const FinanceInvoicing = () => {
    const { t } = useTranslation()


    const handleSendReminder = () => {
    };

    const handleViewInvoice = () => {
    };

    return (
        <div className="invoice-payment-container">
            <h2>{t('Invoicing for')} {invoice.clientName}</h2>

            <div className="deposit-container">
                <InvoiceDetail
                    label="Deposit Amount"
                    value={`$${invoice.depositAmount}`}
                    status={invoice.depositPaid ? 'Paid' : 'Pending'}
                />
            </div>

            <div className="invoice-container">
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}> 
                <div className="payment-methods-container">
                <i className='fa-solid fa-money-check' style={{ color: '#64d2ff', fontSize: 34 }}></i>
                <h3>{t('Invoice Details')}</h3>
                </div>
                <div className="payment-methods-container">
                    <button className='market-invoice--btn' onClick={handleSendReminder}>{t('Send Reminder')}</button>
                    <button className='market-invoice--btn' onClick={handleViewInvoice}>{t('View Invoice')}</button>
                </div>
                </div>
                <InvoiceDetail label={t("Service Type")} value={invoice.serviceType} status={undefined} />
                <InvoiceDetail label={t("Amount Due")} value={`$${invoice.amountDue}`} status={undefined} />
                <InvoiceDetail label={t("Due Date")} value={invoice.dueDate} status={undefined} />
                <InvoiceDetail label={t("Status")} value="" status={invoice.status} />

            </div>

            <div className="payment-methods-container">
                <h3>{t('Payment History')}</h3>
                <div className='market-invoice--history-container'>
                {invoice.paymentHistory.map((payment, index) => (
                    <div title='View Invoice' key={index} className='market-invoice--history-item'>
                            <span>{payment.date}</span>
                            <div style={{ display: 'flex', alignItems: 'center', gap: 30, flexDirection: 'row' }}>
                            <span> ${payment.amount} {t('via')} {payment.method}</span>
                            <i style={{ fontSize: 20, color: '#64d2ff' }} className={getIconClass(payment.method)}></i>
                            </div>
                    </div>
                ))}
                </div>
            </div>
        </div>
    );
};

export default FinanceInvoicing;
