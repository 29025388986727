import React from 'react';
import ReactDOM from 'react-dom';
import './ConfirmationDialog.css'
import { useTranslation } from 'react-i18next';

interface ConfirmationDialogProps {
    isOpen: boolean;
    onClose: () => void;
    onConfirm: () => void;
    message: string;
}

const ConfirmationDialog: React.FC<ConfirmationDialogProps> = ({ isOpen, onClose, onConfirm, message }) => {
    const { t } = useTranslation();
    if (!isOpen) return null;
  

    const modalRoot = document.getElementById('modal-root')!;

    return ReactDOM.createPortal(
        <div className="modal-backdrop-confirm">
            <div className="modal-confirm">
                <p>{message}</p>
                <div className="modal-actions-confirm">
                    <button style={{ backgroundColor: 'red' }} onClick={onClose}>{t('OK')}</button>
                </div>
            </div>
        </div>,
        modalRoot
    );
};

export default ConfirmationDialog;
