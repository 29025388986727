import { ThunkDispatch } from "redux-thunk";
import { RootState } from "..";
import { AnyAction } from "redux";
import apis from "../../utils/apis";
import { getUserID, getUserType } from "./ChatAction";

export const POST_COMPANY_FILES_REQUEST = 'POST_COMPANY_FILES_REQUEST';
export const POST_COMPANY_FILES_SUCCESS = 'POST_COMPANY_FILES_SUCCESS';
export const POST_COMPANY_FILES_FAILURE = 'POST_COMPANY_FILES_FAILURE';
export const POST_ADDITIONAL_COMPANY_FILES_REQUEST = 'POST_ADDITIONAL_COMPANY_FILES_REQUEST';
export const POST_ADDITIONAL_COMPANY_FILES_SUCCESS = 'POST_ADDITIONAL_COMPANY_FILES_SUCCESS';
export const POST_ADDITIONAL_COMPANY_FILES_FAILURE = 'POST_ADDITIONAL_COMPANY_FILES_FAILURE';
export const FETCH_INDEX_INFO_REQUEST = 'FETCH_INDEX_INFO_REQUEST';
export const FETCH_INDEX_INFO_SUCCESS = 'FETCH_INDEX_INFO_SUCCESS';
export const FETCH_INDEX_INFO_FAILURE = 'FETCH_INDEX_INFO_FAILURE';
export const FETCH_BLOB_DOCUMENTS_REQUEST = 'FETCH_BLOB_DOCUMENTS_REQUEST';
export const FETCH_BLOB_DOCUMENTS_SUCCESS = 'FETCH_BLOB_DOCUMENTS_SUCCESS';
export const FETCH_BLOB_DOCUMENTS_FAILURE = 'FETCH_BLOB_DOCUMENTS_FAILURE';
export const DELETE_BLOB_DOCUMENT_SUCCESS = 'DELETE_BLOB_DOCUMENT_SUCCESS';
export const DELETE_BLOB_DOCUMENT_FAILURE = 'DELETE_BLOB_DOCUMENT_FAILURE';
export const RERUN_INDEX_REQUEST = 'RERUN_INDEX_REQUEST';
export const RERUN_INDEX_SUCCESS = 'RERUN_INDEX_SUCCESS';
export const RERUN_INDEX_FAILURE = 'RERUN_INDEX_FAILURE';

export const POLL_INDEX_STATUS_START = 'POLL_INDEX_STATUS_START';
export const POLL_INDEX_STATUS_STOP = 'POLL_INDEX_STATUS_STOP';
export const UPDATE_INDEX_STATUS_MESSAGE = 'UPDATE_INDEX_STATUS_MESSAGE';

export const uploadCompanyFiles = (file: File) => async (dispatch: ThunkDispatch<RootState, undefined, AnyAction>) => {
    const formData = new FormData();
    formData.append('UserID', getUserID());
    formData.append('UserType', getUserType());
    formData.append('File', file);
    dispatch({ type: POST_ADDITIONAL_COMPANY_FILES_REQUEST })
    try {
        const response = await apis({
            method: 'POST',
            url: 'companyai/upload',
            data: formData,
            headers: { 'Content-Type': 'multipart/form-data' }
        });
        dispatch({
            type: POST_COMPANY_FILES_SUCCESS,
            payload: response.data
        });
        dispatch(startPollingIndexStatus());
    } catch (error: any) {
        dispatch({
            type: POST_COMPANY_FILES_FAILURE,
            payload: error
        });
    }
};


export const uploadAdditionalCompanyFiles = (file: File) => async (dispatch: ThunkDispatch<RootState, undefined, AnyAction>) => {
    const formData = new FormData();
    formData.append('UserID', getUserID());
    formData.append('UserType', getUserType());
    formData.append('File', file);
    dispatch({ type: POST_COMPANY_FILES_REQUEST })
    try {
        const response = await apis({ 
            method: 'POST',
            url: 'companyai/additional-upload',
            data: formData,
            headers: { 'Content-Type': 'multipart/form-data' }
        });
        dispatch({
            type: POST_ADDITIONAL_COMPANY_FILES_SUCCESS,
            payload: response.data
        });
        dispatch(startPollingIndexStatus());
    } catch (error: any) {
        dispatch({
            type: POST_ADDITIONAL_COMPANY_FILES_FAILURE,
            payload: error
        });
    }
};


export const getIndexInfo = () => async (dispatch: ThunkDispatch<RootState, undefined, AnyAction>) => {
    dispatch({ type: FETCH_INDEX_INFO_REQUEST });
    try {
        const response = await apis({
            method: 'POST',
            url: 'companyai/index-info',
            data: {
                UserID: getUserID(),
                UserType: getUserType(),
            }
        });

        if (response.data.message && response.data.message.includes("index")) {
            dispatch({
                type: FETCH_INDEX_INFO_FAILURE,
                payload: { indexExists: false, error: response.data.message },
            });
        } else {
            dispatch({
                type: FETCH_INDEX_INFO_SUCCESS,
                payload: response.data,
            });
        }
    } catch (error: any) {
        dispatch({
            type: FETCH_INDEX_INFO_FAILURE,
            payload: { indexExists: false, error: error.message },
        });
    }
};

    
export const getBlobDocuments = () => async (dispatch: ThunkDispatch<RootState, undefined, AnyAction>) => {
    dispatch({ type: FETCH_BLOB_DOCUMENTS_REQUEST });
    try {
        const response = await apis({
            method: 'POST',
            url: 'companyai/blob-documents',
            data: {
                UserID: getUserID(),
                UserType: getUserType(),
            }
        });

        if (response.data.message && response.data.message.includes("ContainerNotFound")) {
            dispatch({
                type: FETCH_BLOB_DOCUMENTS_SUCCESS,
                payload: [],
            });
        } else {
            dispatch({
                type: FETCH_BLOB_DOCUMENTS_SUCCESS,
                payload: response.data.documents,
            });
        }
    } catch (error: any) {
        dispatch({
            type: FETCH_BLOB_DOCUMENTS_FAILURE,
            payload: error.message,
        });
    }
};

    export const reRunIndex = () => async (dispatch: ThunkDispatch<RootState, undefined, AnyAction>) => {
        dispatch({ type: RERUN_INDEX_REQUEST });
        try {
        const response = await apis({
            method: 'POST',
            url: 'companyai/re-run-index',
            data: {
            UserID: getUserID(),
            UserType: getUserType(),
            }
        });
        dispatch({
            type: RERUN_INDEX_SUCCESS,
            payload: response.data
        });
        } catch (error: any) {
        dispatch({
            type: RERUN_INDEX_FAILURE,
            payload: error
        });
        }
    };

    export const deleteBlobDocument = (fileName: string) => async (dispatch: ThunkDispatch<RootState, undefined, AnyAction>) => {
        try {
        const response = await apis({
            method: 'POST',
            url: 'companyai/delete-document',
            data: {
            UserID: getUserID(),
            UserType: getUserType(),
            FileName: fileName
            }
        });
        dispatch({
            type: RERUN_INDEX_SUCCESS,
            payload: response.data
        });
        } catch (error: any) {
        dispatch({
            type: RERUN_INDEX_FAILURE,
            payload: error
        });
        }
    };


    export const startPollingIndexStatus = () => async (dispatch: ThunkDispatch<RootState, undefined, AnyAction>) => {
        dispatch({ type: POLL_INDEX_STATUS_START });
        const pollInterval = setInterval(async () => {
            try {
                const response = await apis({
                    method: 'POST',
                    url: 'companyai/index-info',
                    data: {
                        UserID: getUserID(),
                        UserType: getUserType(),
                    }
                });
    
                const { status } = response.data.index_status.lastResult;
    
                if (status === "success") {
                    clearInterval(pollInterval);
                    dispatch({ type: POLL_INDEX_STATUS_STOP });
                    dispatch({
                        type: FETCH_INDEX_INFO_SUCCESS,
                        payload: response.data,
                    });
                } else {
                    let statusMessage = "Building Agent...";
                    switch (status) {
                        case 'running':
                            statusMessage = "Building Agent...";
                            break;
                        case 'pending':
                            statusMessage = "Preparing Agent...";
                            break;
                        default:
                            statusMessage = "Building Agent...";
                            break;
                    }
    
                    dispatch({
                        type: UPDATE_INDEX_STATUS_MESSAGE,
                        payload: statusMessage,
                    });
                    dispatch({
                        type: FETCH_INDEX_INFO_SUCCESS,
                        payload: response.data,
                    });
                }
            } catch (error: any) {
                clearInterval(pollInterval);
                dispatch({ type: POLL_INDEX_STATUS_STOP });
                dispatch({
                    type: FETCH_INDEX_INFO_FAILURE,
                    payload: { indexExists: false, error: error.message },
                });
            }
        }, 5000); 
    };