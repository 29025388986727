import { useSelector } from "react-redux";
import CreditTracker from "../CreditTracker/CreditTracker";
import './ClientDashboard.css'
import { RootState, useAppDispatch } from "../../../store";
import { ICaseItem } from "../../Marketplace/Interfaces/ICaseItem";
import { fetchRecommendedDocuments, selectDocumentForEditing } from "../../../store/actions/DocumentsAction";
import { getUserID, getUserType, setSelectedModel } from "../../../store/actions/ChatAction";
import { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { hideModal, showShareModal, showSignModal } from "../../../store/actions/modalActions";
import { ClipLoader } from "react-spinners";
import { fetchCaseList } from "../../../store/actions/marketplace/caseActions";
import { selectCaseForViewing, setActiveView, setDetailsContent } from "../../../store/actions/marketplace/activeViewActions";
import { FileProps } from "../../../pages/DocumentsPage";
import { useTranslation } from 'react-i18next';
import SuggestedFilesContainer from "../../Documents/SuggestedFilesContainer";


const ClientDashboard = ({ }) => {
    const caseList = useSelector((root: RootState) => root.case.caseList)
    const userType = getUserType()
    const userId = getUserID();
    const dispatch = useAppDispatch()
    const history = useHistory();
    const [isScrollable, setIsScrollable] = useState(false);
    const listRef = useRef<HTMLDivElement>(null);
    const [loadingMarketplace, setLoadingMarketplace] = useState(false);
    const { t } = useTranslation();
    const [selectedFolderId, setSelectedFolderId] = useState(localStorage.getItem('selectedFolderId') || '');
    const [selectedFolderLabel, setSelectedFolderLabel] = useState(localStorage.getItem('selectedFolderLabel') || t('Select a folder'));
    const recommendedDocuments = useSelector((state: RootState) => state.documents.recommendedDocuments);

    useEffect(() => {
        if (selectedFolderId && selectedFolderLabel) {
            localStorage.setItem('selectedFolderId', selectedFolderId);
            localStorage.setItem('selectedFolderLabel', selectedFolderLabel);
        }
    }, [selectedFolderId, selectedFolderLabel]);



    useEffect(() => {
        dispatch(fetchRecommendedDocuments());
    }, [dispatch, userId, userType]);


    useEffect(() => {
        setLoadingMarketplace(true);
        dispatch(fetchCaseList()).finally(() => setLoadingMarketplace(false));
    }, [dispatch]);


    useEffect(() => {

        const checkScrollable = () => {
            const list = listRef.current;
            if (list) {
                setIsScrollable(list.scrollWidth > list.clientWidth);
            }
        };


        checkScrollable();
        window.addEventListener('resize', checkScrollable);
        return () => window.removeEventListener('resize', checkScrollable);
    }, []);


    const handleCaseClick = (caseItem: ICaseItem) => {
        dispatch(selectCaseForViewing(caseItem));
        history.push('/marketplace');
    };

    const handlePublishClick = () => {
        dispatch(setActiveView('cases'));
        dispatch(setDetailsContent('NEW_CASE_FORM'));
        history.push('/marketplace');
    };



    const handleServiceClick = (selectedModel: string) => {
        dispatch(setSelectedModel(selectedModel));
        history.push("/ai-assistant");
    };

    const handleShareContract = () => {
        dispatch(showShareModal((email) => {

            dispatch(hideModal());
        }));
    };


    const formatNumber = (value: string) => {
        return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    };



    const handleSignContract = () => {
        dispatch(showSignModal((signature) => {
            dispatch(hideModal());
        }));
    };


    const getStatusColor = (status: string) => {
        switch (status) {
            case 'Published': return '#1779b1';
            case 'Ongoing' : return 'rgb(66, 212, 66)';
            case 'Off Market': return '#1b8ed0';
            case 'Closed': return '#64d2ff';
            case 'BidProposed': return '#f2800a';
            case 'OnGoing': return 'rgb(66, 212, 66)';
            default: return '#fff';
        }
    };

    const handleDocumentClick = (document: FileProps) => {
        dispatch(selectDocumentForEditing(document));
        history.push('/documents');
    };


    return (

        <div className="dashboad-client__container">

            <div className="dashboad-client__top">
                <div className="dashboard-client__services">
                    <h1>
                        {t('Services')}
                    </h1>
                    <div className="dashboard-client__services--list">
                        <div className="dashboard-client__services--list--row">
                            <button onClick={() => handleServiceClick("Legal Case Mapping")}>{t('Legal Case Mapping')}</button>
                            <button onClick={() => handleServiceClick("Legal Advisor")}>{t('Legal Advisor')}</button>
                        </div>
                        <div className="dashboard-client__services--list--row">
                            <button onClick={() => handleServiceClick("Contract Generation")}>{t('Contract Generation')}</button>
                            <button onClick={() => handleServiceClick("Contract Analysis")}>{t('Contract Analysis')}</button>
                        </div>
                        <div className="dashboard-client__services--list--row">
                            <button onClick={handleShareContract}>{t('Share Contract')}</button>
                            <button onClick={handleSignContract}>{t('Sign Contract')}</button>
                        </div>
                    </div>
                </div >

                <div className="dashboard-client__tracker">
                    <CreditTracker totalCredits={0} />
                </div>

            </div >

            <div className="dashboard-client__bottom">

                <div className="dashboard-client__marketplace">
                    <div className="dashboard-client__marketplace--header">
                        <h1>{t('Published Cases')}</h1>
                        <button onClick={() => handlePublishClick()}><p>{t('Submit Case')}</p> <i className="fas fa-plus"></i></button>
                    </div >

                    <div className="dashboard-client__marketplace--list">
                        {loadingMarketplace ? (
                            <div className="spinner-container">
                                <ClipLoader color="#ffffff" loading={loadingMarketplace} size={30} />
                            </div>
                        ) : (
                            <>
                                {caseList && caseList.length > 0 ? (
                                    caseList.map((caseItem: ICaseItem, index: number) => (
                                        <div title={caseItem.Description} onClick={() => handleCaseClick(caseItem)} key={index} className="dashboard-client__marketplace--item">
                                            <div className="dashboard-client__marketplace--item--row">
                                                <div>
                                                    <p className="dashboard-client__marketplace--tag-casetype">{caseItem.CaseType}</p>
                                                    <p className="dashboard-client__marketplace--tag-jurisdiction">{caseItem.CountryOfJurisdiction}</p>
                                                    <p className="dashboard-client__marketplace--tag budget">{formatNumber(caseItem.MinBudget)} - {formatNumber(caseItem.MaxBudget)} {caseItem.Currency}</p>
                                                </div>
                                                <div>
                                                    <p className="dashboard-client__marketplace--tag status"
                                                        style={{

                                                            color: getStatusColor(caseItem.Status)
                                                        }}>
                                                        {caseItem.Status}
                                                    </p>
                                                    <p className="dashboard-client__marketplace--tag urgency">{caseItem.Urgency}</p>
                                                </div>
                                            </div>

                                        </div>
                                    ))
                                ) : (
                                    <>
                                        <div className="no-documents">{`${t('No published cases')}`}</div>
                                    </>


                                )}
                            </>
                        )}

                    </div>
                </div >

                <div className="dashboard-client__document">
                    {recommendedDocuments && recommendedDocuments.length > 0 ? (
                        <SuggestedFilesContainer setSelectedFile={handleDocumentClick} style={{ height: '90%', alignSelf: 'center' }} />
                    ) : (
                        <div className="no-documents">{`${t('No active documents')}`}</div>
                    )}
                </div>
            </div >
        </div >


    )


}


export default ClientDashboard;