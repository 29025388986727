// src/store/reducer/feedbackReducer.ts
import { SEND_FEEDBACK_REQUEST, SEND_FEEDBACK_SUCCESS, SEND_FEEDBACK_FAILURE, FETCH_FEEDBACK_TYPES_FAILURE, FETCH_FEEDBACK_TYPES_REQUEST, FETCH_FEEDBACK_TYPES_SUCCESS } from '../types';

interface Action {
  type: string;
  payload?: any; 
}

export interface FeedbackType {
  FeedbackTypeID: number;
  Name: string;
  Description: string;
}

interface FeedbackState {
  isLoading: boolean;
  feedbackData: any;
  feedbackTypes: FeedbackType[];
  error: string | null;
}

const initialState: FeedbackState = {
  isLoading: false,
  feedbackData: null,
  feedbackTypes: [],
  error: null,
};


const feedbackReducer = (state: FeedbackState = initialState, action: Action): FeedbackState => {
  switch (action.type) {
    case SEND_FEEDBACK_REQUEST:
      return { ...state, isLoading: true, error: null };
    case SEND_FEEDBACK_SUCCESS:
      return { ...state, isLoading: false, feedbackData: action.payload, error: null };
    case SEND_FEEDBACK_FAILURE:
      return { ...state, isLoading: false, error: action.payload };
      case FETCH_FEEDBACK_TYPES_REQUEST:
        return { ...state, isLoading: true };
      case FETCH_FEEDBACK_TYPES_SUCCESS:
        return { ...state, isLoading: false, feedbackTypes: action.payload };
      case FETCH_FEEDBACK_TYPES_FAILURE:
        return { ...state, isLoading: false, error: action.payload };
    default:
      return state;
  }
};

export default feedbackReducer;
