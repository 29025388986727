import './ApiLoader.css';
import { ClipLoader } from 'react-spinners';

const ApiLoader = () => {


  return (
    <div className="api-loader__container">
    <ClipLoader color={"#64d2ff"} size={50} />
    </div>
  );
}

export default ApiLoader;
