import React, { useState, KeyboardEvent, ChangeEvent, useEffect } from 'react';
import './EmailTagInput.css';

interface EmailTagsInputProps {
  value: string[];
  placeholder?: string;
  onChange: (tags: string[]) => void;
}

const EmailTagsInput: React.FC<EmailTagsInputProps> = ({ value, placeholder, onChange }) => {
  const [inputValue, setInputValue] = useState('');
  const [tags, setTags] = useState<string[]>(value || []);

  const isValidEmail = (email: string) => {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@(([^<>()[\]\.,;:\s@"]+\.)+[^<>()[\]\.,;:\s@"]{2,})$/i;
    return re.test(String(email).toLowerCase());
  };

  const addEmailToTags = () => {
    if (isValidEmail(inputValue)) {
      const newTags = [...tags, inputValue];
      setTags(newTags);
      onChange(newTags);
      setInputValue('');
    }
  };

  const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter' || e.key === ',' || e.key === ' ') {
      e.preventDefault();
      addEmailToTags();
    }
  };

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setInputValue(value);
  };

  const handleRemoveTag = (index: number) => {
    const newTags = tags.filter((_, i) => i !== index);
    setTags(newTags);
    onChange(newTags);
  };

  useEffect(() => {
    const lastChar = inputValue.slice(-1);
    if (lastChar === ' ' || lastChar === ',' || lastChar === ';') {
      addEmailToTags();
    }
  }, [inputValue]);

  return (
    <div className="email-tags-input">
      <div className="doc-tags">
        {tags.map((tag, index) => (
          <div key={index} className="doc-tag">
            {tag}
            <button type="button" onClick={() => handleRemoveTag(index)}>
              <i className='fas fa-xmark'></i>
            </button>
          </div>
        ))}
      </div>
      <input
        type="text"
        placeholder={placeholder}
        value={inputValue}
        onChange={handleInputChange}
        onKeyDown={handleKeyDown}
      />
    </div>
  );
};

export default EmailTagsInput;
