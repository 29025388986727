import React, { useEffect, useState } from "react";
import ChatInterface from "../../Client/ChatInterface";
import ClientDocumentation from "./ClientDocumentation";
import FinanceInvoicing from "./FinanceInvoicing";
import BidHistory from "./BidHistory";
import { useTranslation } from "react-i18next";
import { ICaseItem } from "../../Interfaces/ICaseItem";
import { useAppDispatch } from "../../../../store";
import { fetchCaseBidding } from "../../../../store/actions/marketplace/caseActions";
import { IAdminCaseItem } from "../../Interfaces/IAdminCaseItem";

interface MyCaseModalProps {
  isOpen: boolean;
  onClose: () => void;
  caseDetails: ICaseItem | IAdminCaseItem;
}

const MyCaseModal: React.FC<MyCaseModalProps> = ({ caseDetails }) => {
  const [activeContent, setActiveContent] = useState('CASE_DETAILS');
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const isICaseItem = (item: ICaseItem | IAdminCaseItem): item is ICaseItem => {
    return (item as ICaseItem).AttachmentsLink !== undefined;
  };

  const isRestrictedView = isICaseItem(caseDetails)
    ? caseDetails.Status === 'Case Proposal' || caseDetails.Status === 'Bid Proposed'
    : caseDetails.CaseStatus === 'Case Proposal' || caseDetails.CaseStatus === 'Bid Proposed';

  useEffect(() => {
    setActiveContent('CASE_DETAILS');
    dispatch(fetchCaseBidding(caseDetails.CaseID, "Approved"));
  }, [caseDetails, dispatch]);


  const handleSetActiveContent = (content: string) => {
    setActiveContent(content);
  };

  const handleSendMessage = (message: string) => {
  };

  const formatNumber = (value?: string) => {
    return value ? value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") : '';
  };

  const renderActiveContent = () => {
    switch (activeContent) {
      case 'BIDS':
        return <BidHistory caseData={caseDetails} />;
      case 'COMMUNICATION':
        return (
          <>
            <div className='inbox-header__client'>
            </div>
            <ChatInterface onSendMessage={handleSendMessage} CaseItem={caseDetails} />
          </>
        );
      case 'PAYMENT':
        return <FinanceInvoicing />;
      case 'DOCUMENTATION':
        return <ClientDocumentation />;
      case 'CASE_DETAILS':
      default:
        return (
          <div className='lcm-modal-content__mycase'>
          <div className="lcm-case-details">
            <div className='lcm-case-details__client--header'>
              <h2>{caseDetails.CaseType}</h2>
              <span className='lcm-case-details__client--status' style={caseDetails.Status === "Ongoing" ? { backgroundColor: 'green', color: '#fff' } : {}}>{t(`${caseDetails.Status}`)}</span>
            </div>
    
            <div className='lcm-case-details--info-wrapper'>
              <div className='lcm-case-details__row'>
                <p>{t('Urgency')}</p><p className='lcm-case-details__urgency'>{caseDetails.Urgency}</p>
              </div>
    
              <div className='lcm-case-details__row'>
                <div className='lcm-case-details__row' style={{ marginRight: 10 }}>
                  <p>{t('Jurisdiction')}</p>
                  <p className='lcm-case-details__div--default'>{caseDetails.CountryOfJurisdiction}</p>
                </div>
    
                <div className='lcm-case-details__row'>
                  <p>{t('Preferred Language')}</p>
                  <p className='lcm-case-details__div--default'>{caseDetails.PreferredLanguage}</p>
                </div>
              </div>
    
              <div className='lcm-case-details__row'>
                <p>{t('Budget Range')}</p>
                {isICaseItem(caseDetails) ? (
                  <p className='lcm-case-details__div--default price'>{formatNumber(caseDetails.MinBudget)} - {formatNumber(caseDetails.MaxBudget)} {caseDetails.Currency}</p>
                ) : (
                  <p className='lcm-case-details__div--default price'>{formatNumber(caseDetails.ClientMinBudget)} - {formatNumber(caseDetails.ClientMaxBudget)} {caseDetails.LawyerBudgetCurrency}</p>
                )}
              </div>
    
              <div className='lcm-case-details__row'>
                <p>{t('Desired Outcome')}</p>
                <p className='lcm-case-details__div--default'>{caseDetails.DesiredOutcome}</p>
              </div>
    
              <div className='lcm-case-details__row'>
                <p>{t('Expected Outcome')}</p>
                <p className='lcm-case-details__div--default'>{caseDetails.ExpectedOutcome}</p>
              </div>
    
              <div>
                <p>{t('Attachments')}</p>
                {isRestrictedView ? (
                  <p className='lcm-case-details__attachments--disable'>
                    {isICaseItem(caseDetails) ? (caseDetails.AttachmentsLink.length > 0 ? `${caseDetails.AttachmentsLink.length} files` : '0') : '0'}
                  </p>
                ) : (
                  <div className='lcm-case-details__row--documents'>
                    {isICaseItem(caseDetails) && caseDetails.AttachmentsLink.length > 0 ? (
                      caseDetails.AttachmentsLink.map((file: string, index: number) => (
                        <p key={index} className='lcm-case-detailes__attachments'>
                          {file}
                        </p>
                      ))
                    ) : (
                      <p className='lcm-case-detailes__attachments'>{t('No attachments')}</p>
                    )}
                  </div>
                )}
              </div>
            </div>
    
              <p>{t('Description')}</p>
            <div className='lcm-case-details__description'>
              <p className='lcm-case-details__description--p'>{caseDetails.Description}</p>
            </div>
            <div className='lcm-case-details__header'>
                <button>{t('View Legal Case Mapping')}</button>
              </div>
          </div>
        </div>
        );
    }
  };

  return (
    <div className='lcm-modal-content__mycase'>
      <div className='lcm-modal-details__top'>
        <div onClick={() => handleSetActiveContent('BIDS')}>
          <i className='fa-solid fa-money-check-dollar' style={{ opacity: activeContent === 'BIDS' ? 1 : 0.2 }}></i>
          <h2>{t('Bids')}</h2>
        </div>
        <div onClick={() => handleSetActiveContent('COMMUNICATION')}>
          <i className='fa-solid fa-comments' style={{ opacity: activeContent === 'COMMUNICATION' ? 1 : 0.2 }}></i>
          <h2 style={{ opacity: isRestrictedView ? 0.4 : 1 }}>{t('Inbox & Meetings')}</h2>
        </div>
        <div onClick={() => handleSetActiveContent('PAYMENT')}>
          <i className='fa-solid fa-hand-holding-circle-dollar' style={{ opacity: activeContent === 'PAYMENT' ? 1 : 0.2 }}></i>
          <h2 style={{ opacity: isRestrictedView ? 0.4 : 1 }}>{t('Finance & Invoice')}</h2>
        </div>
        <div onClick={() => handleSetActiveContent('DOCUMENTATION')}>
          <i className='fa-solid fa-gavel' style={{ opacity: activeContent === 'DOCUMENTATION' ? 1 : 0.2 }}></i>
          <h2 style={{ opacity: isRestrictedView ? 0.4 : 1 }}>{t('Case Documentation')}</h2>
        </div>
      </div>
      <div className='lcm-modal-details__bottom'>
        {activeContent !== 'CASE_DETAILS' && (
          <div className='back-to-case-details' onClick={() => handleSetActiveContent('CASE_DETAILS')}>
            <i className='fa-regular fa-chevron-left'></i>
          </div>
        )}
        {renderActiveContent()}
      </div>
    </div>
  );
};

export default MyCaseModal;
