import React, { useEffect, useRef, useState } from 'react';
import './TeamManagementWidget.css';
import AddTeam from './FirmComponents/AddTeam';
import { useTranslation } from 'react-i18next';
import ManageTeam, { Member, Team } from './FirmComponents/ManageTeam';
import EmployeeView from './FirmComponents/EmployeeView';
import Onboarding from './FirmComponents/Onboarding';
import TeamView from './FirmComponents/TeamView';
import CaseView from './FirmComponents/CaseView';
import { useSelector } from 'react-redux';
import { RootState, useAppDispatch } from '../../../store';
import { getUserID, getUserType } from '../../../store/actions/ChatAction';
import { getAllCases, getAllLawfirmAssignedTask, getAllLawyers, getLegalSubjects, getLegalTeams, getRoles, getTeam } from '../../../store/actions/LawfirmActions';
import { Lawyer } from './interface/IFirmLawyer';
import { ClipLoader } from 'react-spinners';
import { IAdminCaseItem } from '../../Marketplace/Interfaces/IAdminCaseItem';
import { getPreferences } from '../../../store/actions/DataAction';

export interface User {
    id: number;
    name: string;
    role: string;
}

const TeamManagementWidget: React.FC = () => {
    const endOfComponentRef = useRef<HTMLDivElement>(null);
    const [openPanel, setOpenPanel] = useState<string | null>(null); 
    const [selectedEmployee, setSelectedEmployee] = useState<Lawyer | null>(null); 
    const [selectedTeam, setSelectedTeam] = useState<Team | null>(null);
    const userID = getUserID();
    const dispatch = useAppDispatch();
    const subjects = useSelector((state: RootState) => state.lawfirm.subjects);
    const teams = useSelector((state: RootState) => state.lawfirm.teams);
    const lawFirmCases = useSelector((state: RootState) => state.lawfirm.cases);
    const { t } = useTranslation();
    const { loading, lawyers } = useSelector((state: RootState) => state.lawfirm);
    const [newTeams, setNewTeams] = useState<Team[]>(teams);
    const [newEmployees, setNewEmployees] = useState<Member[]>(lawyers); 
    const employeeLimit = 50;
    const [selectedCase, setSelectedCase] = useState<IAdminCaseItem | null>(null); 
    const [isOngoing, setIsOngoing] = useState(true);
    const userType = getUserType();
    const preferences = useSelector((state: RootState) => state.lists.preferences)

    const onToggle = () => {
        setIsOngoing(!isOngoing);
    };

    useEffect(() => {
        dispatch(getAllCases(userID));
        dispatch(getAllLawfirmAssignedTask(userType));
        dispatch(getPreferences(userType))
        dispatch(getRoles())

    }, [dispatch, userID]);

    useEffect(() => {
        if (userID) {
            dispatch(getAllLawyers(userID));
            dispatch(getLegalSubjects());
            dispatch(getLegalTeams(userID));
        }
    }, [dispatch, userID]);

    const handleSelectTeam = (team: Team) => {
        setSelectedTeam(team);
        if (team) {
            dispatch(getTeam(team.LegalTeamID));
        }
        setOpenPanel('viewTeam');
    };

    const handleSelectEmployee = (employee: Lawyer) => {
        setSelectedEmployee(employee);
        setOpenPanel('employee');
    };

    const handleSelectCase = (caseItem: IAdminCaseItem) => {
        setSelectedCase(caseItem);
        setOpenPanel('case');
    };

    const addTeam = (teamName: string, teamLead: string, members: { UserID: string, UserType: string }[], legalSubjects: { LegalSubjectID: string; LegalSubjectName: string }[]) => {
        if (teamName.trim() === '') return;
        const newTeam = {
            id: newTeams.length + 1,
            teamName: teamName,
            teamLead: teamLead,
            members: members,
            legalSubjects: legalSubjects.map(sub => sub.LegalSubjectID),
            cases: [],
        };
        setNewTeams([...teams, newTeam]);
        if (newTeam) {
            setOpenPanel(null); 
            dispatch(getLegalTeams(userID));
        }
    };

    const addEmployee = (employee: Member) => {
        setNewEmployees([...lawyers, employee]);
        if (newEmployees) {
            setOpenPanel(null); 
        }
    };

    const updateTeam = (updatedTeam: Team) => {
        setNewTeams(newTeams.map((team: Team) => team.LegalTeamID === updatedTeam.LegalTeamID ? updatedTeam : team));
    };

    const deleteTeam = (teamId: string) => {
        setNewTeams(newTeams.filter((team: Team) => team.LegalTeamID !== teamId));
    };

    const togglePanel = (panelName: string) => {
        if (openPanel === panelName) {
            setOpenPanel(null); 
        } else {
            setOpenPanel(panelName);
        }
    };

    const formatNumber = (value: string): string => {
        return value.replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    };

    const renderEmployee = (employee: Lawyer, index: number) => (
        <tr key={index} className="team-management--employee-item" onClick={() => handleSelectEmployee(employee)}>
            <td>{`${employee.FirstName} ${employee.LastName}`}</td>
            <td className="tagvalue">{(employee.CompanyRole !== " ") ? employee.CompanyRole : 'None'}</td>
            <td className="tagvalue">{(employee.PracticeArea !== " ") ? employee.PracticeArea : 'None'}</td>
            <td>{employee.Email}</td>
        </tr>
    );

    const renderTeam = (team: Team, index: number) => (
        <tr key={index} className="team-management--employee-item" onClick={() => handleSelectTeam(team)}>
            <td>{team.TeamName}</td>
            <td className="tagvalue">{team.Members ? team.Members.length : 0}</td>
            <td className="tagvalue">{team.Cases ? team.Cases.length : 0}</td>
            <td>{team.TeamLeadFirstName} {team.TeamLeadLastName}</td>
        </tr>
    );

    const renderCase = (clientCase: IAdminCaseItem, index: number) => (
        <tr key={index} className="team-management--employee-item" onClick={() => handleSelectCase(clientCase)}>
            <td className="tagvalue">{clientCase.CaseType}</td>
            <td className="tagvalue">
                {clientCase.LawyerFirstName && clientCase.LawyerLastName
                    ? `${clientCase.LawyerFirstName} ${clientCase.LawyerLastName}`
                    : clientCase.TeamName}
            </td>
            <td className="tagvalue caseprice" style={{ color: 'rgb(66, 212, 66)' }}>{formatNumber(clientCase.ClientMinBudget)} - {formatNumber(clientCase.ClientMaxBudget)}</td>
        </tr>
    );

    return (
        <div className="team-management-container">
                  <div className={`backdrop ${openPanel ? 'show' : ''}`} onClick={() => setOpenPanel(null)}></div>
            <div className="team-container">
                <div className="team-container-header">
                    <span>{t('Employees')}</span>
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', alignItems: 'center', gap: 10 }}>
                        <button onClick={() => togglePanel('onboard')}>{t('Onboard Employee')} <i className="fa-duotone fa-people-group"></i></button>
                    </div>
                </div>
                <div className="team-container-body">
                    <div className="team-container--wrapper">
                        <table className="team-table">
                            <thead>
                                <tr>
                                    <th>{t('Name')}</th>
                                    <th>{t('Role')}</th>
                                    <th>{t('Practice Area')}</th>
                                    <th>{t('Email')}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {loading ? (
                                    <tr style={{ height: '100%', justifyContent: 'center', alignItems: 'center' }}>
                                        <td colSpan={4} style={{ textAlign: 'center', height: '100%', justifyContent: 'center', alignItems: 'center' }}>
                                            <ClipLoader size={20} color='#64d2ff' />
                                        </td>
                                    </tr>
                                ) : lawyers.length > 0 ? (
                                    lawyers.map(renderEmployee)
                                ) : (
                                    <tr>
                                        <td colSpan={4} className="no-teams--placeholder">{t('No employees')}</td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>

            <div className='management-container'>
                <div className='team-container'>
                    <div className='team-container-header'>
                        <span>{t('Legal Teams')}</span>
                        <button onClick={() => togglePanel('team')}>{t('Manage Team')}</button>
                    </div>
                    <div className='team-container-body'>
                        <div className='team-container--wrapper'>
                            <table className="team-table">
                                <thead>
                                    <tr>
                                        <th>{t('Name')}</th>
                                        <th>{t('Members')}</th>
                                        <th>{t('Cases')}</th>
                                        <th>{t('Team Lead')}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {teams && teams.length > 0 ? (
                                        teams.map(renderTeam)
                                    ) : (
                                        <tr>
                                            <td colSpan={4} className='no-teams--placeholder'>{t('No teams')}</td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div className='team-container'>
                    <div className='team-container-header'>
                        <span>{t('Cases')}</span>
                        <div className="toggle-switch__status">
                            <button className={`button left ${isOngoing ? '' : 'active'}`} onClick={onToggle}>{t('Ongoing')}</button>
                            <button className={`button right ${!isOngoing ? 'active' : ''}`} onClick={onToggle}>{t('Completed')}</button>
                            <div className={`slider-status ${isOngoing ? 'right' : ''}`}></div>
                        </div>
                    </div>
                    <div className='team-container-body'>
                        <div className='team-container--wrapper'>
                            <table className="team-table">
                                <thead>
                                    <tr>
                                        <th>{t('Case Type')}</th>
                                        <th>{t('Assigned To')}</th>
                                        <th>  {t('Price')} {preferences?.Currency ? preferences.Currency : ''}
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {lawFirmCases.length > 0 ? (
                                        lawFirmCases.map(renderCase)
                                    ) : (
                                        <tr>
                                            <td colSpan={3} className='no-teams--placeholder'>{t('No Cases')}</td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>

            <div className={`side-panel ${openPanel === 'team' ? 'open_panel' : ''}`}>
                <button className="close-btn--sidepanel" onClick={() => togglePanel('team')}><i className='fas fa-chevron-right'></i></button>
                <div style={{ display: 'flex', flexDirection: 'column', height: '100vh', justifyContent: 'space-between', overflowY: 'auto', scrollbarWidth: 'thin', scrollbarColor: '#64d2ff transparent' }}>
                    <h1 style={{ textAlign: "center", color: "#fff", marginTop: 0, width: "fit-content", alignSelf: "center" }}>{t('Manage Team')}</h1>
                    <div style={{ height: '100%' }}>
                        <AddTeam subjects={subjects} newTeam={addTeam} setIsPanelOpen={setOpenPanel} />
                    </div>
                    <div style={{ height: '100%', zIndex: 1 }}>
                        <ManageTeam endOfComponentRef={endOfComponentRef} teams={teams} updateTeam={updateTeam} deleteTeam={deleteTeam} allMembers={lawyers} subjects={subjects} />
                    </div>
                </div>
            </div>

            <div className={`side-panel ${openPanel === 'onboard' ? 'open_panel' : ''}`}>
                <button className="close-btn--sidepanel" onClick={() => togglePanel('onboard')}><i className='fas fa-chevron-right'></i></button>
                <div style={{ minHeight: 300, overflowY: "auto", padding: 10, scrollbarWidth: 'thin', scrollbarColor: '#64d2ff transparent' }}>
                    <Onboarding addEmployee={addEmployee} employeeCount={lawyers.length} employeeLimit={employeeLimit} />
                </div>
            </div>

            <div className={`side-panel ${openPanel === 'employee' ? 'open_panel' : ''}`}>
                <button className="close-btn--sidepanel" onClick={() => togglePanel('employee')}><i className='fas fa-chevron-right'></i></button>
                <div style={{ minHeight: 300, overflowY: "auto", padding: 10, scrollbarWidth: 'thin', scrollbarColor: '#64d2ff transparent' }}>
                    {selectedEmployee && <EmployeeView employee={selectedEmployee} />}
                </div>
            </div>

            <div className={`side-panel ${openPanel === 'viewTeam' ? 'open_panel' : ''}`}>
                <button className="close-btn--sidepanel" onClick={() => togglePanel('viewTeam')}><i className='fas fa-chevron-right'></i></button>
                <div style={{ minHeight: 300, overflowY: "auto", padding: 10, scrollbarWidth: 'thin', scrollbarColor: '#64d2ff transparent' }}>
                    <h1 style={{ textAlign: "center", color: "#fff", marginTop: 0, width: "100%", alignSelf: "center", fontWeight: '500' }}>{selectedTeam?.TeamName}</h1>
                    {selectedTeam && <TeamView team={selectedTeam} />}
                </div>
            </div>

            <div className={`side-panel ${openPanel === 'case' ? 'open_panel' : ''}`}>
                <button className="close-btn--sidepanel" onClick={() => togglePanel('case')}>
                    <i className="fas fa-chevron-right"></i>
                </button>
                <div style={{ minHeight: 300, overflowY: 'auto', padding: 10, scrollbarWidth: 'thin', scrollbarColor: '#64d2ff transparent' }}>
                    <h1 style={{ textAlign: "center", color: "#fff", marginTop: 0, width: "100%", alignSelf: "center", fontWeight: '500' }}>{t('Case Details')}</h1>
                    {selectedCase && <CaseView formatNumber={formatNumber} caseItem={selectedCase} />}
                </div>
            </div>
        </div>
    );
};

export default TeamManagementWidget;
