import { SET_CITY, SET_COUNTRY, SET_CURRENCY, SET_STATE, SET_PREFERENCES, GET_PREFERENCES_SUCCESS, SET_AI_SETTINGS, GET_AI_SETTINGS_SUCCESS, SET_CHAT_LIST, SET_MESSAGE_LIST } from '../actions/DataAction';
import { SET_JURISDICTION_COUNTRY } from '../actions/SignUpAction';

export interface AISettings {
  UserID: string;
  UserType: string;
  FontSize: string;
  AIVoice: string;
  AILanguageResponse: string;
  ThemeColor: string;
  CountryOfJurisdiction: string;
  FontFamily: string;
}
export interface IPreferences {
  UserID: string;
  UserType: string;
  CurrencyName: string;
  Currency: string;
  Communication: string;
}

interface Country {
  countries: [] | null;
  states: [] | null;
  cities: [] | null;
  CountryOfJurisdictionList: [] | null;
  currencies: [] | null;
  preferences: IPreferences | null;
  aiSettings: AISettings | null;
  chatList: any[] | []
  MessageList: any[] | []
}

const initialState: Country = {
  countries: [],
  states: [],
  cities: [],
  CountryOfJurisdictionList: [],
  currencies: [],
  preferences: null,
  aiSettings: null,
  chatList: [],
  MessageList: []
};

const DataReducer = (state = initialState, action: { type: string; countries: [] | null; states: [] | null; cities: [] | null; CountryOfJurisdictionList: [] | null; currencies: [] | null; preferences: any | null; aiSettings: any | null; chat_list: any[] | [], message_list: any[] | [] }) => {
  switch (action.type) {
    case SET_COUNTRY:
      return {
        ...state,
        countries: action.countries,
      };
    case SET_CURRENCY:
      return {
        ...state,
        currencies: action.currencies,
      };
    case SET_STATE:
      return {
        ...state,
        states: action.states,
      };
    case SET_CITY:
      return {
        ...state,
        cities: action.cities,
      };
    case SET_PREFERENCES:
    case GET_PREFERENCES_SUCCESS:
      return {
        ...state,
        preferences: action.preferences[0],
      };
    case SET_AI_SETTINGS:
    case GET_AI_SETTINGS_SUCCESS:
      return {
        ...state,
        aiSettings: action.aiSettings[0],
      };
    case SET_JURISDICTION_COUNTRY:
      return {
        ...state,
        CountryOfJurisdictionList: action.CountryOfJurisdictionList
      }
    case SET_CHAT_LIST:
      return {
        ...state,
        chatList: action.chat_list
      }
    case SET_MESSAGE_LIST:
      return {
        ...state,
        MessageList: action.message_list
      }
    default:
      return state;
  }
};

export default DataReducer;
