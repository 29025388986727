import React, { Component, ReactNode } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

interface ErrorBoundaryProps {
  children: ReactNode;
}

interface ErrorBoundaryState {
  hasError: boolean;
  errorMessage: string | null;
}

class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false, errorMessage: null };
  }

  // This lifecycle method catches rendering errors in child components
  static getDerivedStateFromError(error: Error) {
    return { hasError: true, errorMessage: error.message }; // Update state so the next render shows fallback UI
  }

  // This method logs errors or performs custom actions
  componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    console.error("Error caught in ErrorBoundary:", error, errorInfo);
    // Optional: Log the error to an external service like Sentry or a custom logger

    // Display a toast notification instead of React's error overlay
    // toast.error("An error occurred. Please report this issue.");
  }

  render() {
    if (this.state.hasError) {
      // Show the user-friendly message here and return null or fallback UI
      return null; // Do not render anything when an error occurs
    }

    return this.props.children; // Render the child components if no error
  }
}

export default ErrorBoundary;
