export const SET_CHAT_THEME = 'SET_CHAT_THEME';

export interface SetChatThemeAction {
  type: typeof SET_CHAT_THEME;
  payload: { chatTheme: string };
}

export const setChatTheme = (chatTheme: string): SetChatThemeAction => ({
  type: SET_CHAT_THEME,
  payload: { chatTheme },
});
