import io, { Socket } from 'socket.io-client'
import { config } from './config';
const socketUrl = config.socket_url


class WSServices {
    private socket!: Socket; // Declare the socket property with its type

    initializeSocket = async () => {
        try {

            if (socketUrl) {
                this.socket = io(socketUrl)
            }

            this.socket.on('connect', () => {
            })


            this.socket.on('disconnect', (data: any) => {
            })

            this.socket.on('error', (data: any) => {
            })

        } catch (err) {
            console.error(err)
        }
    }

    emit(event: any, data = {}) {
        this.socket.emit(event, data)
    }

    on(event: any, cb: any) {
        this.socket.on(event, cb)
    }

    removeListener(listenerName: any) {
        this.socket.removeListener(listenerName)
    }
}

const SocketServices = new WSServices()

export default SocketServices
