import React from 'react';

interface ToggleButtonProps {
  isEmployeeView: boolean;
  setIsEmployeeView: (isEmployeeView: boolean) => void;
}

const ToggleButton: React.FC<ToggleButtonProps> = ({ isEmployeeView, setIsEmployeeView }) => {
  return (
    <div className="toggle-button">
      <button className={isEmployeeView ? 'active' : ''} onClick={() => setIsEmployeeView(true)}>Employees</button>
      <button className={!isEmployeeView ? 'active' : ''} onClick={() => setIsEmployeeView(false)}>Cases</button>
    </div>
  );
};

export default ToggleButton;
