import { useEffect, useState } from 'react';
import './LawFirm.css';
import { format, parse, isValid } from 'date-fns';
import { useTranslation } from 'react-i18next';
import SuggestedFilesContainer from '../../Documents/SuggestedFilesContainer';
import { FileProps } from '../../../pages/DocumentsPage';
import { RootState, useAppDispatch } from '../../../store';
import { selectDocumentForEditing } from '../../../store/actions/DocumentsAction';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { fetchCaseList } from '../../../store/actions/marketplace/caseActions';
import { selectCaseForViewing, setDetailsContent } from '../../../store/actions/marketplace/activeViewActions';
import { ClipLoader } from 'react-spinners';
import { ICaseItem } from '../../Marketplace/Interfaces/ICaseItem';
import { IAdminCaseItem } from '../../Marketplace/Interfaces/IAdminCaseItem';
import { ActiveTasks } from '../../../store/actions/marketplace/TasksActions';
import { getUserID, getUserType, setSelectedModel } from '../../../store/actions/ChatAction';
import { completeLawfirmAssignedTask, deleteLawfirmAssignedTask, getAllLawfirmAssignedTask } from '../../../store/actions/LawfirmActions';
import { hideModal, showAddTaskModal, showShareModal, showSignModal } from '../../../store/actions/modalActions';

const LawyerFirm = () => {
    const { t } = useTranslation()
    const [isTeamOpen, setIsTeamOpen] = useState<boolean>(false);
    const CaseList = useSelector((state: RootState) => state.case.caseList);
    const [isCasesLoading, setIsCasesLoading] = useState(false);
    const [isTasksLoading, setIsTasksLoading] = useState(false);
    const dispatch = useAppDispatch()
    const history = useHistory();
    const userId = getUserID();
    const userType = getUserType();
    const assignedTasks = useSelector((state: RootState) => state.lawfirm.tasks);

    useEffect(() => {
        const fetchData = async () => {
            setIsCasesLoading(true);
            setIsTasksLoading(true);
            try {
                await dispatch(ActiveTasks(userId, userType));
                await dispatch(getAllLawfirmAssignedTask(userType));
                await dispatch(fetchCaseList());
                // await dispatch(fetchProposals(userId, userType));
            } finally {
                setIsCasesLoading(false);
                setIsTasksLoading(false);
            }
        };
        fetchData();
    }, [dispatch]);

    const handleMarketClick = () => {
        // dispatch(setActiveView('market'));
        history.push('/marketplace/lawyer/market');
    };

    const handleCaseClick = (caseItem: ICaseItem | IAdminCaseItem) => {
        dispatch(selectCaseForViewing(caseItem));
        dispatch(setDetailsContent('MY_CASE_DETAILS'))
        // dispatch(setActiveView('cases'))
        history.push('/marketplace/lawyer/cases');
    };

    const formatNumber = (value: string) => {
        return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
      };

    const handleCompleteTask = async (taskId: string) => {
        try {
          await dispatch(completeLawfirmAssignedTask(userType, taskId));
          console.log(`Task ${taskId} marked as complete.`);
          // Refresh task list or update UI accordingly
          dispatch(getAllLawfirmAssignedTask(userType));
        } catch (error) {
          console.error('Error completing task:', error);
        }
      };
    

    const handleDocumentClick = (document: FileProps) => {
        dispatch(selectDocumentForEditing(document));
        history.push('/documents');
    };

    const handleDeleteTask = async (taskId: string) => {
        try {
          await dispatch(deleteLawfirmAssignedTask(userType, taskId));
          console.log(`Task ${taskId} deleted.`);
          // Refresh task list or update UI accordingly
          dispatch(getAllLawfirmAssignedTask(userType));
        } catch (error) {
          console.error('Error deleting task:', error);
        }
      };

  const handleAddTask = () => {
    dispatch(showAddTaskModal())
  };

  const handleServiceClick = (selectedModel: string) => {
    dispatch(setSelectedModel(selectedModel));
    history.push("/ai-assistant");
};

  const handleSignContract = () => {
    dispatch(showSignModal((signature) => {
        dispatch(hideModal());
    }));
  };

      const sortedTasks = [...assignedTasks].sort(
        (a: any, b: any) => new Date(a.TaskDeadline).getTime() - new Date(b.TaskDeadline).getTime()
      );
      const closestTask = sortedTasks[0];
      const otherTasks = sortedTasks.slice(1);    

    return (
        <div className="firm-main-container">
            <div className="firm-employee-container">
            <div className="dashboard-lawyer__services">
            <div className="dashboard-lawyer__services--header">
              <h2>{t('Cases')}</h2>
              <button className='browse-market' onClick={() => handleMarketClick()}>{('Browse Market')}</button>
            </div>
            <div className="todo-list">
              {isCasesLoading ? (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                  <ClipLoader color="#fff" loading={isCasesLoading} size={30} />
                </div>
              ) : (
                CaseList && CaseList.length > 0 ? CaseList.map((item: ICaseItem, index: number) => (
                  <div key={index} className="dashboard-lawyer__case-item" onClick={() => handleCaseClick(item)}>
                    <div className='dashboard-lawyer__case-item__row'>
                      <p className="dashboard-lawyer__case-item--casetype">{item.CaseType}</p>
                      <p className="caseUrgency">{item.Urgency}</p>
                    </div>
                    <div className='dashboard-lawyer__case-item__row'>
                      <p className="casePrice"> {formatNumber(item.MinBudget)} - {formatNumber(item.MaxBudget)} {item.Currency}</p>
                      <p className="caseCountry">{item.CountryOfJurisdiction}</p>
                    </div>
                  </div>
                )) : (
                  <p className="no-tasks">{t('No Active Cases')}</p>
                )
              )}
            </div>
          </div>
          <div className='firm-container' style={{ flex: 0.8, backgroundColor: 'transparent', border: '1px solid #64d2ff' }}>
  <div className='firm-container-header'>
    <span>{t('Task and Deadline Tracker')}</span>
  </div>
  <div style={{ height: '100%', position: 'relative' }}>
    {isTasksLoading ? (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
        <ClipLoader color="#fff" loading={isTasksLoading} size={30} />
      </div>
    ) : (
      assignedTasks && assignedTasks.length > 0 ? (
        <div className='task-and-deadline-tracker-widget'>
          {/* Add Task Button and Closest Deadline Task */}
          <div className="task-header">
            <button className="firmlawyer--add-task-btn" onClick={handleAddTask}>
              <i className='far fa-plus'></i>
              <span>{t('Add Task')}</span>
            </button>
            {closestTask && (
              <div className="task-and-deadline__item closest-task">
                <div className='task-and-deadline__item'>
                <span>
                  {isValid(parse(closestTask.TaskDeadline, 'dd-MM-yyyy HH:mm:ss', new Date())) ? `due ${format(parse(closestTask.TaskDeadline, 'dd-MM-yyyy HH:mm:ss', new Date()), 'PP')}` : 'Invalid date'}
                </span>
                <p>{closestTask.TaskTitle}</p>
                </div>
                <div className='task-and-deadline__item--icons'>
                    <i 
                      onClick={() => handleCompleteTask(closestTask.TaskID)} 
                      className={`fas fa-circle-${closestTask.Status === 'Active' ? 'check' : 'times'}`}>
                    </i>
                    <i 
                      onClick={() => handleDeleteTask(closestTask.TaskID)} 
                      className='fas fa-trash'>
                    </i>
                  </div>
              </div>
            )}
          </div>

          {/* Other Tasks */}
          <ul className='all-task--ul'>
            {otherTasks.map((task: any) => {
              const taskDeadline = parse(task.TaskDeadline, 'dd-MM-yyyy HH:mm:ss', new Date());
              const isValidDate = isValid(taskDeadline);
              return (
                <li key={task.TaskID}>
                  <div className='task-and-deadline__item'>
                    <p>{task.TaskTitle}</p>
                    <span>
                      {isValidDate ? `due ${format(taskDeadline, 'PP')}` : 'Invalid date'}
                    </span>
                  </div>
                  <div className='task-and-deadline__item--icons'>
                    <i 
                      onClick={() => handleCompleteTask(task.TaskID)} 
                      className={`fas fa-circle-${task.Status === 'Active' ? 'check' : 'times'}`}>
                    </i>
                    <i 
                      onClick={() => handleDeleteTask(task.TaskID)} 
                      className='fas fa-trash'>
                    </i>
                  </div>
                </li>
              );
            })}
          </ul>
        </div>
      ) : (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} className='non-access'>
          {t('No tasks')}
        </div>
      )
    )}
      </div>
    </div>
    </div>

            <div className="firm-employee-container">
            <div className='firm-container' style={{ flex: 1 }}>
                    <div className='firm-container-body'>
                    {/* <div className='firm-container-header'>
                            <span>{t('My Activity')}</span>
                        </div> */}

                        {/* <WorkActivityChart height={190} /> */}
                        <div className="dashboard-client__services">
                    <h1>
                        {t('Services')}
                    </h1>
                    <div className="dashboard-client__services--list">
                        <div className="dashboard-client__services--list--row">
                            <button onClick={() => handleServiceClick('Company Agent')}>{t('Company Agent')}</button>
                            <button onClick={() => handleServiceClick("Case Analysis")}>{t('Case Analysis')}</button>
                        </div>
                        <div className="dashboard-client__services--list--row">
                            <button onClick={() => handleServiceClick("Legal Advisor")}>{t('Legal Advisor')}</button>
                            <button onClick={() => handleServiceClick("Contract Generation")}>{t('Contract Generation')}</button>
                        </div>
                        <div className="dashboard-client__services--list--row">
                            <button onClick={() => handleServiceClick("Contract Analysis")}>{t('Contract Analysis')}</button>
                            <button onClick={handleSignContract}>{t('Sign Contract')}</button>
                        </div>
                    </div>
                </div>
                        </div>
                </div>

                <div className='firm-container' style={{ flex: 1 }}>
                    <SuggestedFilesContainer setSelectedFile={handleDocumentClick} />
                </div>
            </div>
            <div className={`side-panel ${isTeamOpen ? 'open_panel' : ''}`}>
        <button className="close-btn" onClick={() => setIsTeamOpen(false)}>
          <i className="fas fa-chevron-right"></i>
        </button>
        <div style={{ minHeight: 300, overflowY: 'auto', padding: 10, scrollbarWidth: 'thin', scrollbarColor: '#64d2ff #333' }}>
        <h1 style={{ textAlign: "center", color: "#fff", marginTop: 0, width: "100%", alignSelf: "center", fontWeight: '500' }}>{t('Case Details')}</h1>
        </div>
      </div>
        </div>
    )
}


export default LawyerFirm;