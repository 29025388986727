import React, { useState } from 'react';
import './TimeTracker.css';

interface TimekeepingTableProps {
  onDayClick: (day: string) => void;
}

const generateTimekeepingData = (type: 'weekly' | 'monthly') => {
  const data = [];
  if (type === 'weekly') {
    data.push(
      { day: 'Monday', date: '2024-08-19', clockedHours: 7, negativeHours: 1, overtime: 0, totalHours: 8, tasks: 5 },
      { day: 'Tuesday', date: '2024-08-20', clockedHours: 8, negativeHours: 0, overtime: 1, totalHours: 9, tasks: 4 },
      { day: 'Wednesday', date: '2024-08-21', clockedHours: 6, negativeHours: 2, overtime: 0, totalHours: 8, tasks: 3 },
      { day: 'Thursday', date: '2024-08-22', clockedHours: 9, negativeHours: 0, overtime: 2, totalHours: 11, tasks: 6 },
      { day: 'Friday', date: '2024-08-23', clockedHours: 8, negativeHours: 0, overtime: 1, totalHours: 9, tasks: 4 }
    );
  } else {
    for (let week = 1; week <= 4; week++) {
      data.push(
        {
          day: `Week ${week}`,
          date: `2024-08-W${week}`,
          clockedHours: 40 - week * 2, 
          negativeHours: week % 2 === 0 ? 2 : 0,
          overtime: week % 3 === 0 ? 5 : 0,
          totalHours: 40 - week * 2 + (week % 3 === 0 ? 5 : 0),
          tasks: 15 - week * 2,
        }
      );
    }
  }
  return data;
};

const TimekeepingTable: React.FC<TimekeepingTableProps> = ({ onDayClick }) => {
  const [viewMode, setViewMode] = useState<'weekly' | 'monthly'>('weekly');
  const [timekeepingData, setTimekeepingData] = useState(generateTimekeepingData('weekly'));

  // Function to handle toggle between weekly and monthly views
  const handleToggleView = (mode: 'weekly' | 'monthly') => {
    setViewMode(mode);
    setTimekeepingData(generateTimekeepingData(mode));
  };

  return (
    <div className="timekeeping-management-container">
      <div className="timekeeping-container">
        <div className="timekeeping-container-header">
          <div style={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
          <span>{viewMode === 'weekly' ? 'Weekly Timekeeping' : 'Monthly Timekeeping'}</span>
          <div className="view-toggle-buttons">
            <button
              className={`toggle-btn ${viewMode === 'weekly' ? 'active' : ''}`}
              onClick={() => handleToggleView('weekly')}
            >
              Weekly
            </button>
            <button
              className={`toggle-btn ${viewMode === 'monthly' ? 'active' : ''}`}
              onClick={() => handleToggleView('monthly')}
            >
              Monthly
            </button>
          </div>
          </div>
              <button className="timekeeping-print-report-btn">Print Report</button>
        </div>
        <div className="timekeeping-container-body">
          <div className="timekeeping-table-wrapper">
            <table className="timekeeping-table">
              <thead>
                <tr>
                  <th>{viewMode === 'weekly' ? 'Day' : 'Week'}</th>
                  <th>Date</th>
                  <th>Clocked Hours</th>
                  <th>Negative Hours</th>
                  <th>Overtime</th>
                  <th>Total Hours Worked</th>
                  <th>Tasks</th>
                </tr>
              </thead>
              <tbody>
                {timekeepingData.map((dayData) => (
                  <tr key={dayData.date} onClick={() => onDayClick(dayData.day)}>
                    <td>{dayData.day}</td>
                    <td>{dayData.date}</td>
                    <td>{dayData.clockedHours}</td>
                    <td>{dayData.negativeHours}</td>
                    <td>{dayData.overtime}</td>
                    <td>{dayData.totalHours}</td>
                    <td>{dayData.tasks}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TimekeepingTable;
