import React, { CSSProperties, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState, useAppDispatch } from '../../../store';
import { getUserID, getUserType } from '../../../store/actions/ChatAction';
import { updateAISettings, getAISettings } from '../../../store/actions/DataAction';
import { setFontSize } from '../../../store/actions/FontSizeAction';
import { setVoice } from '../../../store/actions/SpeechAction';
import GlobalDropdown from '../../shared/GlobalDropdown';
import './../Settings.css'; 
import { useTranslation } from 'react-i18next';
import { AISettings } from '../../../store/reducer/DataReducer';
import { useLocation } from 'react-router-dom';

const AISettingsComponent: React.FC<{style?: CSSProperties}> = ({style}) => {
  const dispatch = useAppDispatch();
  const aiSettings = useSelector((state: RootState) => state.lists.aiSettings);
  const userInfo = useSelector((state: RootState) => state.user.userInfo);
  const { t } = useTranslation();
  const location = useLocation();
  const [selectedAILanguage, setSelectedAILanguage] = useState('');
  const [selectedVoice, setSelectedVoiceState] = useState('');
  const [selectedFontSize, setSelectedFontSizeState] = useState('');
  const [selectedJurisdiction, setSelectedJurisdiction] = useState('');
  const [isDarkTheme, setIsDarkTheme] = useState(false);

  useEffect(() => {
    const userId = getUserID();
    const userType = getUserType();
    dispatch(getAISettings(userType));
  }, [dispatch]);

  useEffect(() => {
    if (aiSettings) {
      setSelectedAILanguage(aiSettings.AILanguageResponse);
      setSelectedVoiceState(aiSettings.AIVoice);
      setSelectedFontSizeState(aiSettings.FontSize);
      setSelectedJurisdiction(aiSettings.CountryOfJurisdiction || '');
      setIsDarkTheme(aiSettings.ThemeColor === 'dark');
    }
  }, [aiSettings]);
  
  const handleSelectAILanguage = (value: string) => {
    setSelectedAILanguage(value);
    updateAISettingsOnServer({ AILanguageResponse: value });
  };

  const handleSelectAIVoice = (value: string) => {
    setSelectedVoiceState(value);
    dispatch(setVoice(value));
    updateAISettingsOnServer({ AIVoice: value });
  };

  const handleSelectFontSize = (value: string) => {
    setSelectedFontSizeState(value);
    dispatch(setFontSize(value));
    updateAISettingsOnServer({ FontSize: value });
  };

  const handleSelectJurisdiction = (value: string) => {
    setSelectedJurisdiction(value);
    updateAISettingsOnServer({ CountryOfJurisdiction: value });
  };

  const toggleTheme = () => {
    setIsDarkTheme(!isDarkTheme);
    updateAISettingsOnServer({ ThemeColor: !isDarkTheme ? 'dark' : 'light' });
  };

  const updateAISettingsOnServer = (updatedFields: Partial<AISettings>) => {
    const updatedAISettings = {
      UserID: getUserID(),
      UserType: getUserType(),
      AIVoice: updatedFields.AIVoice || selectedVoice,
      AILanguageResponse: updatedFields.AILanguageResponse || selectedAILanguage,
      FontSize: updatedFields.FontSize || selectedFontSize,
      CountryOfJurisdiction: updatedFields.CountryOfJurisdiction || selectedJurisdiction,
      ThemeColor: updatedFields.ThemeColor || (isDarkTheme ? 'dark' : 'light'),
      Nationality: userInfo?.Nationality || '',
      FontFamily: '"Poppins", sans-serif',
    };
    dispatch(updateAISettings(updatedAISettings));
  };

  return (
    <div className="ai-settings-container">
      {location.pathname === '/settings/account' && (
      <h1 className="ai-settings-title">{t('AI Settings')}</h1>
      )}
      <div className="ai-settings-form">
        <div className="ai-settings-field" style={style}>
        <div className='ai-settings-field--div'>
          <label className="ai-settings-label">{t('Jurisdiction')}</label>
          {location.pathname === '/settings/account' && (
          <span>{t('AI Jurisdiction')}</span>
          )}
          </div>
          <GlobalDropdown
            options={[
              { label: 'Norway', id: 'Norway' },
              { label: 'United Kingdom', id: 'United Kingdom' },
              { label: 'United States', id: 'United States' },
              { label: 'Sweden', id: 'Sweden' },
              { label: 'Denmark', id: 'Denmark' },
              { label: 'Finland', id: 'Finland' }
            ]}
            selectedLabel={
              [
                { label: 'Norway', id: 'Norway' },
                { label: 'United Kingdom', id: 'United Kingdom' },
                { label: 'United States', id: 'United States' },
                { label: 'Sweden', id: 'Sweden' },
                { label: 'Denmark', id: 'Denmark' },
                { label: 'Finland', id: 'Finland' }
              ].find(option => option.id === selectedJurisdiction)?.label || 'Select Jurisdiction'
            }
            onSelect={handleSelectJurisdiction}
          />
        </div>
        <div className="ai-settings-field" style={style}>
        <div className='ai-settings-field--div'>
          <label className="ai-settings-label">{t('Language')}</label>
          {location.pathname === '/settings/account' && (
          <span>{t('AI Language')}</span>
          )}
          </div>
          <GlobalDropdown
            options={[
              { label: 'Norwegian', id: 'Norwegian' },
              { label: 'English', id: 'English' },
              { label: 'Spanish', id: 'Spanish' },
              { label: 'Swedish', id: 'Swedish' },
              { label: 'Danish', id: 'Danish' },
              { label: 'Finnish', id: 'Finnish' }
            ]}
            selectedLabel={
              [
                { label: 'Norwegian', id: 'Norwegian' },
                { label: 'English', id: 'English' },
                { label: 'Spanish', id: 'Spanish' },
                { label: 'Swedish', id: 'Swedish' },
                { label: 'Danish', id: 'Danish' },
                { label: 'Finnish', id: 'Finnish' }
              ].find(option => option.label === selectedAILanguage)?.label || 'Select Language'
            }
            onSelect={handleSelectAILanguage}
          />
        </div>
        <div className="ai-settings-field" style={style}>
        <div className='ai-settings-field--div'>
          <label className="ai-settings-label">{t('Voice')}</label>
          {location.pathname === '/settings/account' && (
          <span>{t('AI Voice')}</span>
          )}
          </div>
          <GlobalDropdown
            options={[
              { label: 'Norwegian Male', id: 'nb-NO-FinnNeural' },
              { label: 'Norwegian Female', id: 'nb-NO-PernilleNeural' },
              { label: 'Spanish Male', id: 'es-ES-DarioNeural' },
              { label: 'Spanish Female', id: 'es-ES-ElviraNeural' },
              { label: 'English Male', id: 'en-US-AndrewMultilingualNeural' },
              { label: 'English Female', id: 'en-US-AvaMultilingualNeural' },
              { label: 'Danish Male', id: 'da-DK-JeppeNeural' },
              { label: 'Danish Female', id: 'da-DK-ChristelNeural' },
              { label: 'Swedish Male', id: 'sv-SE-MattiasNeural' },
              { label: 'Swedish Female', id: 'sv-SE-HilleviNeural' },
              { label: 'Finnish Male', id: 'fi-FI-HarriNeural' },
              { label: 'Finnish Female', id: 'fi-FI-NooraNeural' }
            ]}
            selectedLabel={
              [
                { label: 'Norwegian Male', id: 'nb-NO-FinnNeural' },
                { label: 'Norwegian Female', id: 'nb-NO-PernilleNeural' },
                { label: 'Spanish Male', id: 'es-ES-DarioNeural' },
                { label: 'Spanish Female', id: 'es-ES-ElviraNeural' },
                { label: 'English Male', id: 'en-US-AndrewMultilingualNeural' },
                { label: 'English Female', id: 'en-US-AvaMultilingualNeural' },
                { label: 'Danish Male', id: 'da-DK-JeppeNeural' },
                { label: 'Danish Female', id: 'da-DK-ChristelNeural' },
                { label: 'Swedish Male', id: 'sv-SE-MattiasNeural' },
                { label: 'Swedish Female', id: 'sv-SE-HilleviNeural' },
                { label: 'Finnish Male', id: 'fi-FI-HarriNeural' },
                { label: 'Finnish Female', id: 'fi-FI-NooraNeural' }
              ].find(option => option.id === selectedVoice)?.label || 'Select Voice'
            }
            onSelect={handleSelectAIVoice}
          />
        </div>
        <div className="ai-settings-field" style={style}>
        <div className='ai-settings-field--div'>
          <label className="ai-settings-label">{t('Font Size')}</label>
          {location.pathname === '/settings/account' && (
          <span>{t('AI Font Size')}</span>
          )}
          </div>
          <GlobalDropdown
            options={[
              { label: '14px', id: '14' },
              { label: '16px', id: '16' },
              { label: '18px', id: '18' },
              { label: '20px', id: '20' },
              { label: '24px', id: '24' }  
            ]}
            selectedLabel={`${selectedFontSize || 'Select Font Size'}px`}
            onSelect={handleSelectFontSize}
          />
        </div>
      </div>
    </div>
  );
};

export default AISettingsComponent;
