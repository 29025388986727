import { toast } from "react-toastify";
import apis from "../../utils/apis";
import axios, { AxiosError } from "axios";
import { showSuccessToast } from "./toastAction";
import { showFeedbackModal } from "./UserFeedbackActions";

export const SET_JURISDICTION_COUNTRY = "SET_JURISDICTION_COUNTRY"

export const FETCH_PRICING_SUCCESS = 'FETCH_PRICING_SUCCESS';
export const TOGGLE_PRICING_MODE = 'TOGGLE_PRICING_MODE';
export const TOGGLE_SUBSCRIPTION_PERIOD = 'TOGGLE_SUBSCRIPTION_PERIOD';


export const setJurisdictionCountries = (CountryOfJurisdictionList: string) => {
  return {
    type: SET_JURISDICTION_COUNTRY,
    CountryOfJurisdictionList
  }
}

export const fetchPricingSuccess = (pricingData: any[]) => ({
  type: FETCH_PRICING_SUCCESS,
  payload: pricingData
});

export const togglePricingMode = () => ({
  type: TOGGLE_PRICING_MODE
});

export const toggleSubscriptionPeriod = () => ({
  type: TOGGLE_SUBSCRIPTION_PERIOD
});

export interface SignUpProps {
  FirstName: string,
  LastName: string,
  Email: string,
  Password: string,
  CPassword: string,
  AgreeToTerms?: string,
  PhoneNumber?: string,
  LawfirmID?: string,
  Country?: string,
  State?: string,
  City?: string,
  ZipCode?: string,
  StreetAddress?: string
  CountryOfJurisdiction?: string
  KycDocumentType?: string,
  DeviceId?: string,
  DeviceName?: string,
  PracticeArea?: string,
  DateOfBirth: string,
  LawyerLicenseCode?: string,
  image?: Blob | null,
  KycDocument?: Blob | null,
  LawyerLicense?: Blob | null,
  BusinessName?: string,
  ContactName?: string,
  OrganizationNumber?: string,
  InterestedSolutions?: string,
  UserType: string,
  EstdYear?: string,
  OfficeAddress?: string,
  CountryOfRegistration?: string,
  BusinessIdentificationNumber?: string,
  BusinessContact?: string,
  BusinessEmail?: string,
  Industry?: string,
  RegistrationDocument?: Blob | null,
  NumberofUsers?: number,
  FullName?: string,
  Address?: string,
  PrimaryContact?: string
  LawfirmName?: string,
  LawfirmPhoneNumber?: string
  LawfirmEmail?: string,
  LicenseNumber?: string,
  LegalStructure?: string,
  LicenseDocument?: Blob | null,
  Website?: string,
  LawfirmOfficeAddress?: string,
  PractiseArea?: string,
  NumberOfLicenses?: number,
  ComanyRole?: string,
}

interface ErrorResponse {
  message: string;
}

export interface KYCDocumentsProps {
  KycDocumentType?: string,
  KYCSelfie?: Blob | null,
  KYCDocument?: Blob | null,
  IDocument?: Blob | null,
}

export const SignUp = (values: FormData, userType: string) => async (dispatch: any) => {
  try {
    let endPoint = '';
    switch (userType) {
      case "client":
        endPoint = "user/signup/client"
        break;
      case 'lawyer':
        endPoint = "user/signup/lawyer"
        break;
      case "business":
        endPoint = "business/signup";
        break;
      case "business_employee":
        endPoint = "business/employee/signup";
        break;
      case "lawFirm":
        endPoint = "lawfirm/signup"
        break;
      case "lawFirmLawyer":
        endPoint = "lawfirm/lawyer/signup"
        break;
      default: break;
    }
    let response = await apis({
      method: 'POST',
      url: endPoint,
      data: values
    })

    if (response && response.status === 200) {
      showSuccessToast(response.data.message)
      return response.data
    }
    if (axios.isAxiosError(response)) {
      const axiosError = response as AxiosError;
      if (axiosError.response && axiosError.response.data) {
        let errorMessage = axiosError.response.data as ErrorResponse
        dispatch(showFeedbackModal({
          showModal: true,
          message: (errorMessage.message || 'An unknown error occurred'),
          modalType: 'error',
          duration: 3000
        }));
      } else {
        dispatch(showFeedbackModal({
          showModal: true,
          message: ('Network error, please try again'),
          modalType: 'error',
          duration: 3000
        }));
      }
    }
  } catch (err) {
    console.error(err);
    toast.error(`An unexpected error occurred`);
  }
};



export const uploadKycDocuments = (values: FormData) => async () => {
  try {
    let response = await apis({
      method: "POST",
      url: "/uploadKYC",
      data: values
    })

    if (response.status === 200 && response) {
      return response.data
    }
    else {
      if (axios.isAxiosError(response)) {
        const axiosError = response as AxiosError;
        if (axiosError.response && axiosError.response.data) {
          let erroMessage = axiosError.response.data as ErrorResponse
          toast.error(erroMessage.message || 'An unknown error occurred');
        } else {
          toast.error('Network error, please try again');
        }
      }
    }
  } catch (err) {
    if (err instanceof AxiosError) {
      toast.error(err.message);
    } else {
      console.error(err);
    }
  }
}


export const getCountryOfJurisdictionList = () => async (dispatch: any) => {
  try {
    let response = await apis({
      method: "GET",
      url: "territory/getCountryOfJurisdiction",
    })

    if (response.status === 200 && response) {
      dispatch(setJurisdictionCountries(response.data))
    }

  } catch (err) {
    if (err instanceof AxiosError) {
      console.error(err);

      toast.error(err.message);
    } else {
      console.error(err);

    }
  }
}

export const getKycCountryList = () => async () => {
  try {
    let response = await apis({
      method: "GET",
      url: "getKYCCountryList",
    })

    if (response.status === 200 && response) {
      return response.data
    }

  } catch (err) {
    if (err instanceof AxiosError) {
      toast.error(err.message);
    } else {
      console.error(err);

    }
  }
}

export const fetchPricing = () => async (dispatch: any) => {
  try {
    let response = await apis({
      method: "GET",
      url: "pricing/getAll",
    });
    if (response && response.data && Array.isArray(response.data.PricingList)) {
      dispatch(fetchPricingSuccess(response.data.PricingList));
    } else {
      console.error('Expected an array of pricing plans but received:', response.data);
      dispatch(fetchPricingSuccess([]));
    }
  } catch (error) {
    console.error('Failed to fetch pricing:', error);
    dispatch(fetchPricingSuccess([]));
  }
};
