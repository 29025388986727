import React, { useEffect, useState } from "react";
import './Feedback.css';
import { fetchFeedbackTypes, sendFeedback } from "../../store/actions/FeedbackAction";
import { getUserID, getUserType } from "../../store/actions/ChatAction";
import { ThunkDispatch } from "redux-thunk";
import { RootState } from "../../store";
import { AnyAction } from "redux";
import { useDispatch, useSelector } from "react-redux";
import { FeedbackType } from "../../store/reducer/feedbackReducer";
import { useTranslation } from "react-i18next";


const Feedback: React.FC = () => {
  const [feedback, setFeedback] = useState('');
  const [isOverLimit, setIsOverLimit] = useState(false);
  const [selectedFeedback, setSelectedFeedback] = useState<FeedbackType | null>(null);
  const dispatch = useDispatch<ThunkDispatch<RootState, undefined, AnyAction>>();
  const userID = getUserID();
  const userType = getUserType();
  const UserID = useSelector((state: RootState) => state.auth.userId);
  const { t } = useTranslation()


  const feedbackTypes = useSelector((state: RootState) => state.feedback.feedbackTypes);


  useEffect(() => {
    dispatch(fetchFeedbackTypes());
  }, [dispatch]);

  useEffect(() => {
    
    if (feedbackTypes.length > 0 && !selectedFeedback) {
      setSelectedFeedback(feedbackTypes[0]);
    }
  }, [feedbackTypes, selectedFeedback]);


  const handleSubmitFeedback = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (selectedFeedback && feedback.trim()) {

      try {
        await dispatch(sendFeedback({
          FeedbackContent: feedback,
          UserID: userID!,
          UserType: userType,
          FeedbackTypeID: selectedFeedback.FeedbackTypeID
        }));
        setFeedback('');
      } catch (error) {
        console.error('Failed to send feedback:', error);
      }
    }
  }

  const handleFeedbackTypeClick = (type: FeedbackType) => {
    setSelectedFeedback(type);
  };

  return (
    <div className="feedback-container">
      <div className="feedback-top">
        <div className="feedback-types">
          {feedbackTypes.map((type) => (
            <button
              key={type.FeedbackTypeID}
              className={`feedback-type--btn ${selectedFeedback && selectedFeedback.FeedbackTypeID === type.FeedbackTypeID ? 'active' : ''}`}
              onClick={() => handleFeedbackTypeClick(type)}
            >
              {type.Name}
            </button>
          ))}
        </div>
        <i style={{ fontSize: 100, display: 'flex', color: '#64d2ff' }} className="fa-kit fa-logo"></i>
      </div>
      <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', marginLeft: 'auto', marginRight: 'auto' }}>
      <p style={{ fontSize: '0.9rem', width: 700, fontWeight: '400', color: '#fff' }}>{selectedFeedback ? selectedFeedback.Description : ''}</p>
        <form className="feedback-form" onSubmit={handleSubmitFeedback}>
          <textarea className="feedback--input" onChange={(e) => setFeedback(e.target.value)} value={feedback} />
          <p className="word-count">{feedback.split(/\s+/).length} / 500</p>
          {isOverLimit && (
            <p className="error-message">Your feedback is over the 500 words limit.</p>
          )}
          {!isOverLimit && (
            <button type="submit" className="send-feedback--btn">{t('Send')}</button>
          )}
        </form>
      </div>
    </div>
  );
};


export default Feedback;
