import React, { useEffect } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs, { Dayjs } from 'dayjs';
import { useSelector } from 'react-redux';
import { RootState, useAppDispatch } from '../../../store';
import { t } from 'i18next';
import { CreateTask, ActiveTasks } from '../../../store/actions/marketplace/TasksActions';
import GlobalDropdown from '../../shared/GlobalDropdown';
import { hideModal } from '../../../store/actions/modalActions';
import { getUserID, getUserType } from '../../../store/actions/ChatAction';
import '../Marketplace.css';
import { ICaseItem } from '../Interfaces/ICaseItem';
import { getAllCases, getLawfirmLawyerTasks, lawfirmAssignTask } from '../../../store/actions/LawfirmActions';
import { IAdminCaseItem } from '../Interfaces/IAdminCaseItem';

interface AddTaskProps {
  selectedEmployeeEmail?: string; 
  onCancel?: () => void;
  onAdd?: () => void;
}

const AddTask: React.FC<AddTaskProps> = ({ selectedEmployeeEmail, onCancel, onAdd }) => {
  const userType = getUserType();
  const caseList = useSelector((root: RootState) => {
    return userType === "LawfirmAdmin" ? root.lawfirm.cases : root.case.caseList || [];
  }); 
  const dispatch = useAppDispatch();
  const userId = getUserID();

  console.log(caseList)

  // useEffect(() => {

  //   if(userType === "LawfirmAdmin") {
  //     dispatch(getAllCases(userId))
  //   }

  // }, [caseList])

  const darkTheme = createTheme({
    palette: {
      mode: 'dark',
    },
  });

  const validationSchema = Yup.object().shape({
    TaskTitle: Yup.string().required(t("Title for the task required")),
    TaskDescription: Yup.string().required(t("Description needed")),
    TaskDeadline: Yup.string().required(t("Deadline for the task required")),
  });

  const formik = useFormik({
    initialValues: {
      TaskTitle: "",
      TaskDescription: "",
      TaskDeadline: "",
      CaseID: ""
    },
    validationSchema,
    enableReinitialize: true,
    onSubmit: async (values, { resetForm }) => {
      const dataValues: any = {
        UserType: userType,
        ...values
      };

      // Remove CaseID if not selected
      if (!dataValues.CaseID) {
        delete dataValues.CaseID;
      }

      // Only include Assignees if an employee email is provided
      if (selectedEmployeeEmail && userType === 'LawfirmAdmin') {
        dataValues.Assignees = [selectedEmployeeEmail];
      }

      // Create the task and handle assignment internally
      if (userType === "LawfirmAdmin" || userType === "Lawfirmlawyer")  {
        await dispatch(lawfirmAssignTask(dataValues));
        dispatch(getLawfirmLawyerTasks(userType, selectedEmployeeEmail as string))
        if(onAdd) {
          onAdd();
        }
      } else { 
        await dispatch(CreateTask(dataValues));
        dispatch(ActiveTasks(userId, userType));
      }
      dispatch(hideModal());
      resetForm();
    }
  });

  const cancelTask = () => {
    if (onCancel) {
      onCancel();  
    }
    dispatch(hideModal());
  };

  const formattedCaseOptions = caseList
  // .filter((caseItem: ICaseItem | IAdminCaseItem) => caseItem.Status === "Ongoing")
  .map((caseItem: ICaseItem) => ({
    label: `${caseItem.CaseType} - ${caseItem.MaxBudget}`,
    id: caseItem.CaseID
  }));

  return (
    <div className="add-task-container">
      <div className='add-task__wrapper'>
        <h2>{t('Add New Task')}</h2>
        <div className='add-task__bottom'>
          <div className='add-task-div'>
            <label style={{ textAlign: 'left' }} htmlFor="title">{t('Title')}</label>
            <input
              type="text"
              name="TaskTitle"
              placeholder={t("Enter task title...")}
              value={formik.values.TaskTitle}
              onChange={formik.handleChange("TaskTitle")}
              onBlur={formik.handleBlur("TaskTitle")}
              className="new-task-input"
            />
            {formik.errors?.TaskTitle && formik.touched.TaskTitle && <div style={{ textAlign: 'left' }} className="error-message-display">{formik.errors?.TaskTitle}</div>}
          </div>
          <div className='add-task-div'>
            <label style={{ textAlign: 'left' }} htmlFor="case">{t('Case')}</label>
            <GlobalDropdown
              options={formattedCaseOptions}
              onSelect={(id) => formik.setFieldValue("CaseID", id)}
              selectedLabel={formattedCaseOptions.find((option: any) => option.id === formik.values.CaseID)?.label || ''}
              style={{ width: '100%' }}
              name='CaseID'
            />
            {formik.errors?.CaseID && formik.touched.CaseID && <div style={{ textAlign: 'left' }} className="error-message-display">{formik.errors?.CaseID}</div>}
          </div>
        </div>

        <div style={{ width: '100%', display: 'flex', flexDirection: 'column', gap: 10 }}>
          <label style={{ textAlign: 'left' }} htmlFor="deadline">{t('Deadline')}</label>
          <ThemeProvider theme={darkTheme}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DateTimePicker
                label=""
                ampm={false}
                value={formik.values.TaskDeadline ? dayjs(formik.values.TaskDeadline) : null}
                name="TaskDeadline"
                onChange={(newValue: Dayjs | null) => {
                  if (newValue) {
                    formik.setFieldValue('TaskDeadline', newValue.format("DD-MM-YYYY HH:mm"));
                  }
                }}
              />
            </LocalizationProvider>
          </ThemeProvider>
          {formik.errors?.TaskDeadline && formik.touched.TaskDeadline && <div style={{ textAlign: 'left' }} className="error-message-display">{formik.errors?.TaskDeadline}</div>}
        </div>

        <label style={{ textAlign: 'left' }} htmlFor="description">{t('Description')}</label>
        <textarea
          name="TaskDescription"
          placeholder={t("Enter task description...")}
          value={formik.values.TaskDescription}
          onChange={formik.handleChange("TaskDescription")}
          className="new-task-input description"
        />
        {formik.errors?.TaskDescription && formik.touched.TaskDescription && <div style={{ textAlign: 'left' }} className="error-message-display">{formik.errors?.TaskDescription}</div>}
      </div>
      <div style={{ display: "flex", alignItems: "center", justifyContent: "space-evenly", width: "100%", gap: 10, marginBottom: 50 }}>
        <button type='submit' onClick={() => { formik.handleSubmit(); }} className='add-task__action--button add'>
          {t('Add')}
        </button>
        <button onClick={cancelTask} className='add-task__action--button cancel'>
          {t('Cancel')}
        </button>
      </div>
    </div>
  );
};

export default AddTask;
