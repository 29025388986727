import React, { useState, useEffect, useRef, useMemo } from 'react';
import './MessageCenter.css';
import dayjs from 'dayjs';
import { useSwipeable } from 'react-swipeable';
import { getUserID } from '../../../../store/actions/ChatAction';

interface Message {
  id: string,
  sender: string;
  content: string;
  timestamp: Date;
}

interface ChatWindowProps {
  messages: Message[];
  chatName: string;
  deadline?: string;
  jurisdiction?: string;
  price?: string;
  onSendMessage: (message: string) => void;
  chatId: number | string;
}




const ChatWindow: React.FC<ChatWindowProps> = ({
  messages,
  chatName,
  deadline,
  jurisdiction,
  price,
  onSendMessage,
  chatId,
}) => {
  const [newMessage, setNewMessage] = useState('');
  const messagesEndRef = useRef<HTMLDivElement>(null);
  const userID = getUserID()
  console.log(messages);


  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  const handleSendMessage = () => {
    if (newMessage.trim() !== '') {
      const currentTime = new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
      const message = { sender: 'You', content: newMessage, timestamp: currentTime };
      onSendMessage(newMessage);
      setNewMessage('');
    }
  };

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      handleSendMessage();
    }
  };

  function formatDate(timestamp: Date) {
    const date: any = new Date(timestamp);
    const now: any = new Date();

    const differenceInDays = Math.floor((now - date) / (1000 * 60 * 60 * 24));

    if (differenceInDays === 0) {
      return 'Today';
    } else if (differenceInDays === 1) {
      return 'Yesterday';
    } else if (differenceInDays <= 3) {
      const options: Intl.DateTimeFormatOptions = { weekday: 'long' };
      return date.toLocaleDateString('en-GB', options);
    } else {
      const options: Intl.DateTimeFormatOptions = { month: 'short', day: '2-digit' };
      return `${date.toLocaleDateString('en-GB', options)}, ${date.getFullYear()}`;
    }
  }

  const groupedMessages = useMemo(() => {
    const groups: { [date: string]: any[] } = {};
    messages.forEach((msg: any) => {
      const date = formatDate(msg.timestamp);
      if (!groups[date]) {
        groups[date] = [];
      }
      groups[date].push(msg);
    });
    return groups;
  }, [messages]);



  const formatTime = (timestamp: number) => {
    const date = new Date(timestamp);
    return date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: true }); // Customize the time format as needed
  };

  const Message: React.FC<any> = ({ msg }) => {
    const [isSwiping, setIsSwiping] = useState(false);
    const [swipeDirection, setSwipeDirection] = useState('');
    const [isExpanded, setIsExpanded] = useState(false);
    const messageLimit = 100;
    const [duration, setDuration] = useState("0s")
    useEffect(() => {
      if (msg.Type === "Call") {
        const start = dayjs(msg.callStartedAt);
        const end = dayjs(msg.callEndedAt);

        const callDuration = dayjs.duration(end.diff(start));
        let formattedDuration = "";
        if (callDuration.hours() > 0) {
          formattedDuration += `${callDuration.hours()}h `;
        }
        if (callDuration.minutes() > 0) {
          formattedDuration += `${callDuration.minutes()}m `;
        }
        formattedDuration += `${callDuration.seconds()}s`;

        setDuration(formattedDuration);
      }
    }, [msg.Type, msg.callStartedAt, msg.callEndedAt]);


    const handlers = useSwipeable({
      onSwipedLeft: () => {
        setSwipeDirection('left');
        setIsSwiping(true);
        // setMessageReply(msg)

      },
      onSwipedRight: () => {
        // setSwipeDirection('right');
        // setIsSwiping(true);
        // setMessageReply(msg)
      },
      onSwiping: (eventData) => {
        setSwipeDirection(eventData.dir);
        setIsSwiping(true);
      },
      onSwiped: () => {
        setTimeout(() => {
          setIsSwiping(false);
          setSwipeDirection('');
        }, 1000);
      },
      delta: 10,
      preventScrollOnSwipe: false,
      trackMouse: true,
    });

    useEffect(() => {
      if (isSwiping) {
        const timer = setTimeout(() => {
          setIsSwiping(false);
          setSwipeDirection('');
        }, 1000);
        return () => clearTimeout(timer);
      }
    }, [isSwiping]);
    const messageClass = msg.sender &&  msg.sender ? 'self' : 'other'

    return (
      <>
        {
          msg.Type ? (

            msg.Type === "Text" ? (
              <div
                key={msg.id}
                {...handlers}
                className={`message__marketplace ${messageClass} ${isSwiping ? 'swiping' : ''} ${swipeDirection}`}
              >
                {msg.attachments && msg.attachments.length > 0 && (
                  // msg.attachments && <ChatImages attachments={msg.attachments} />
                  <></>
                )}
                <div className="message-content">
                  {isExpanded || msg.content.length <= messageLimit
                    ? msg.content
                    : `${msg.content.substring(0, messageLimit)}...`
                  }
                  {!isExpanded && msg.content.length > messageLimit && (
                    <span className="read-more" onClick={() => setIsExpanded(true)}>
                      Read more
                    </span>
                  )}
                  {isExpanded && <span className="read-more" onClick={() => setIsExpanded(false)}>
                    Read less
                  </span>
                  }
                </div>
                <div className="message-timestamp">{formatTime(msg.timestamp)}</div>
              </div>
            ) : (
              <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center", gap: "10px", marginBottom: "10px" }}>
                <i className='fa-regular fa-video' style={{ color: "grey", fontSize: '16px' }}></i>
                <span style={{ color: "grey", fontSize: '16px' }}>{msg.content}</span>
                <span style={{ color: "grey", fontSize: '16px' }}>{duration}</span>

              </div>
            )
          )
            : (
              <div
                key={msg.id}
                {...handlers}
                className={`chat-message ${messageClass} ${isSwiping ? 'swiping' : ''} ${swipeDirection}`}
              >
                {/* {msg.attachments && <ChatImages attachments={msg.attachments} />} */}
                <div className="message-content">
                  {isExpanded || msg.content.length <= messageLimit
                    ? msg.content
                    : `${msg.content.substring(0, messageLimit)}...`
                  }
                  {!isExpanded && msg.content.length > messageLimit && (
                    <span className="read-more" onClick={() => setIsExpanded(true)}>
                      Read more
                    </span>
                  )}
                  {isExpanded && <span className="read-more" onClick={() => setIsExpanded(false)}>
                    Read less
                  </span>
                  }
                </div>
                <div className="message-timestamp">{formatTime(msg.timestamp)}</div>
              </div>
            )
        }
      </>
    );
  };

  return (
    <div className="chat-window-content">
      <div className="chat-window-header">
        <h3>{chatName}</h3>
        {deadline || jurisdiction || price ? (
          <div className="chat-case-details">
            {deadline && <span>Deadline: {deadline}</span>}
            {jurisdiction && <span>Jurisdiction: {jurisdiction}</span>}
            {price && <span>Price: {price}</span>}
          </div>
        ) : null}
        {/* {!isEmployee(chat) && (
            <div className="chat-item-details">
              <div>Deadline: {chat.deadline}</div>
              <div>Jurisdiction: {chat.jurisdiction}</div>
              <div>Price: {chat.price}</div>
            </div>
          )} */}
      </div>
      <div className="chat-messages">

        {Object.keys(groupedMessages).map((date) => (
          <React.Fragment key={date}>
            <div className="message-date">{date}</div>
            {groupedMessages[date].map((msg: any) => (
              <Message key={msg.id} msg={msg} />
            ))}
          </React.Fragment>
        ))}
        <div ref={messagesEndRef} />
      </div>
      <div className="chat-input">
        <input
          type="text"
          value={newMessage}
          onChange={(e) => setNewMessage(e.target.value)}
          onKeyPress={handleKeyPress}
          placeholder="Type a message..."
        />
        <button onClick={handleSendMessage}>
          <i className="fas fa-paper-plane"></i> Send
        </button>
      </div>
    </div>
  );
};

export default ChatWindow;
