import axios, { AxiosError } from "axios"
import apis from "../../utils/apis"
import { ErrorResponse } from "./SignInAction"
import { showFeedbackModal } from "./UserFeedbackActions"

export const createMeetingSchedule = (MeetingTitle: string, MeetingDate: string, MeetingTime: string, CaseID: string) => {
    return async (dispatch: any) => {
        try {

            const response = await apis({
                method: 'POST',
                url: "meetings/schedule",
                data: {
                    CaseID,
                    MeetingTime,
                    MeetingDate,
                    MeetingTitle
                }
            })
            if (response && response.data === 200) {
                dispatch(showFeedbackModal({
                    showModal: true,
                    message: ("Meeting scheduled successfully!"),
                    modalType: 'success',
                    duration: 3000
                }));
                return response.data
            } else {
                if (axios.isAxiosError(response)) {
                    const axiosError = response as AxiosError;
                    if (axiosError.response && axiosError.response.data) {
                        let errorMessage = axiosError.response.data as ErrorResponse
                        dispatch(showFeedbackModal({
                            showModal: true,
                            message: (errorMessage.message || 'An unknown error occurred'),
                            modalType: 'error',
                            duration: 3000
                        }));
                    } else {
                        dispatch(showFeedbackModal({
                            showModal: true,
                            message: ('Network error, please try again'),
                            modalType: 'error',
                            duration: 3000
                        }));
                    }
                }
            }

        } catch (err) {
            console.error(err)
        }
    }
}

export const getLatestMeeting = (CaseID: string) => {
    return async () => {
        try {

            const resp = await apis({
                method: 'POST',
                url: "meetings/getMeeting",
                data: {
                    CaseID,
                }
            })
            if (resp && resp.status === 200) {
                return resp.data
            }

        } catch (err) {
            console.error(err)
        }
    }
}

export const updateMeetingStatus = (CaseID: string, MeetingID: string) => {
    return async () => {
        try {

            const resp = await apis({
                method: 'POST',
                url: "meetings/updateStatus",
                data: {
                    CaseID,
                    MeetingID
                }
            })
            if (resp && resp.data === 200) {
                return resp.data
            }

        } catch (err) {
            console.error(err)
        }
    }
}