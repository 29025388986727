import React, { useRef, useState } from 'react';
import './ClientPortal.css';

interface AddClientFormProps {
  onClose: () => void;
}

const AddClientForm: React.FC<AddClientFormProps> = ({ onClose }) => {
  const [clientType, setClientType] = useState<'individual' | 'business'>('individual');
  const [formData, setFormData] = useState<any>({});
  const [attachments, setAttachments] = useState<File[]>([]);
  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      setAttachments(Array.from(e.target.files));
    }
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    console.log('New Client Data:', formData, 'Attachments:', attachments);
    onClose(); 
  };

  const handleFileButtonClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  return (
    <div className="add-client-form">
        <div className='add-client-form--header'>
      <h3>Add New Client</h3>
      
      {/* Client Type Selection */}
      <div className="client-type-toggle">
        <button
          className={`client-type-btn ${clientType === 'individual' ? 'active' : ''}`}
          onClick={() => setClientType('individual')}
        >
          Individual
        </button>
        <button
          className={`client-type-btn ${clientType === 'business' ? 'active' : ''}`}
          onClick={() => setClientType('business')}
        >
          Business
        </button>
      </div>
      </div>

      <form onSubmit={handleSubmit}>
        {clientType === 'individual' ? (
          <>
            <label>
              Name
              <input type="text" name="name" onChange={handleInputChange} required />
            </label>
            <label>
              Contact Email
              <input type="email" name="contactEmail" onChange={handleInputChange} required />
            </label>
            <label>
              Phone
              <input type="tel" name="phone" onChange={handleInputChange} />
            </label>
            <label>
              Address
              <textarea name="address" onChange={handleInputChange} />
            </label>
            <label>
              Case Type
              <input type="text" name="caseType" onChange={handleInputChange} />
            </label>
          </>
        ) : (
          <>
            <label>
              Business Name
              <input type="text" name="businessName" onChange={handleInputChange} required />
            </label>
            <label>
              Contact Person
              <input type="text" name="contactPerson" onChange={handleInputChange} required />
            </label>
            <label>
              Contact Email
              <input type="email" name="contactEmail" onChange={handleInputChange} required />
            </label>
            <label>
              Phone
              <input type="tel" name="phone" onChange={handleInputChange} />
            </label>
            <label>
              Business Address
              <textarea name="businessAddress" onChange={handleInputChange} />
            </label>
            <label>
              Industry
              <input type="text" name="industry" onChange={handleInputChange} />
            </label>
            <label>
              Legal Representative
              <input type="text" name="legalRepresentative" onChange={handleInputChange} />
            </label>
          </>
        )}

        {/* Custom File Upload Button */}
        <div className="file-upload-container">
          <button type="button" className="upload-btn" onClick={handleFileButtonClick}>
            Upload Extra Documents
          </button>
          <input
            type="file"
            name="documents"
            onChange={handleFileChange}
            multiple
            ref={fileInputRef}
            style={{ display: 'none' }}
          />
        </div>

        {/* Display Attachments */}
        <div className="attachments-list">
          {attachments.map((file, index) => (
            <div key={index} className="attachment-item" title={file.name}>
              {file.name}
            </div>
          ))}
        </div>
        <div className='add-client--buttons'>
        <button type="submit" className="add-client--submit-btn">Add Client</button>
        <button type="button" className="add-client--cancel-btn" onClick={onClose}>Cancel</button>
        </div>
      </form>
    </div>
  );
};

export default AddClientForm;
