export const SET_VOICE = 'SET_VOICE';
export const SET_SPEECH_CONFIG = 'SET_SPEECH_CONFIG';

interface SetVoiceAction {
  type: typeof SET_VOICE;
  payload: { voice: string };
}

interface SetSpeechConfigAction {
  type: typeof SET_SPEECH_CONFIG;
  payload: { speechKey: string; serviceRegion: string; voice: string };
}

export type SpeechActions = SetVoiceAction | SetSpeechConfigAction;

export const setVoice = (voice: string): SetVoiceAction => ({
  type: SET_VOICE,
  payload: { voice },
});

export const setSpeechConfig = (config: { speechKey: string; serviceRegion: string; voice: string }): SetSpeechConfigAction => ({
  type: SET_SPEECH_CONFIG,
  payload: config,
});
