import React, { useEffect, useState } from 'react';
import '../Marketplace.css';
import CustomDropdown from '../../CustomDropdownLCM.tsx/CustomDropdownLCM';
import { useSelector } from 'react-redux';
import store, { RootState, useAppDispatch } from '../../../store';
import { fetchCaseList, fetchLCMList, submitCase } from '../../../store/actions/marketplace/caseActions';
import * as Yup from "yup"
import { useFormik } from 'formik';
import { getUserID, getUserType } from '../../../store/actions/ChatAction';
import DualThumbSliderNew from './DualThumSliderNew';
import { useTranslation } from 'react-i18next';
import { showFeedbackModal } from '../../../store/actions/UserFeedbackActions';
import { IFullCaseData } from '../Interfaces/IMarketCase';

export const mockLcmFiles = [
  'Contract Dispute - Vendor XYZ',
  'Employment Law - Worker Compensation',
  'Real Estate - Lease Agreement Review',
  'Intellectual Property - Trademark Case',
  'Family Law - Divorce Settlement',
  'Criminal Law - DUI Defense Strategy',
  'Business Law - Merger and Acquisition',
  'Environmental Law - Compliance Audit',
  'Consumer Rights - Product Liability Case',
  'Digital Law - Data Privacy Breach'
];


export const urgencyOptions = [
  "within 24-48 hours",
  "3-5 days",
  "5-10 days",
  "10-19 days",
  "20-35 days",
  "more than 35 days"
];

export const countries = [
  'Norway',
  'United States',
  'United Kingdom',
  'Sweden',
  'Denmark',
  'Spain'
];



export const desiredOutcomeOptions = [
  "Lawsuit Win/Legal Victory",
  "Settlement/Negotiation",
  "Legal Advice/Consultation",
  "Contract Drafting/Review",
  "Compliance/Regulatory Guidance",
  "Dispute Resolution",
  "Mediation",
  "Arbitration",
  "Other"
];

export const caseTypeOptions = [
  "Business Law",
  "Family Law",
  "Criminal Law",
  "Estate Law",
  "Maritime Law",
  "Intellectual Property",
  "Real Estate",
  "Immigration Law",
  "Corporate Law",
  "Bankruptcy Law",
  "Environmental Law",
  "Healthcare Law",
  "Commercial Law",
  "Other"
];

export const preferredLanguageOptions = [
  "Norwegian",
  "English",
  "Spanish",
  "French",
  "Mandarin",
  "Arabic",
  "German",
  "Russian",
  "Portuguese",
  "Japanese",
  "Other"
];

const expectedOutcomeOptions = [
  "Full Resolution of the Issue",
  "Partial Settlement/Compromise",
  "Delay or Postponement of Legal Action",
  "Financial Compensation or Recovery",
  "Clearing of Charges/Acquittal",
  "Legal Protection or Prevention of Future Issues",
  "Understanding or Knowledge about Legal Rights",
  "Documentation or Official Agreements",
  "Other"
];

const LCMUploadForm: React.FC<{ onSubmit: (data: FormData) => void, setDetailsContent: (detailsContent: string) => void, setSelectedCase: (selectedCase: IFullCaseData | null) => void }> = ({ onSubmit, setDetailsContent, setSelectedCase }) => {
  const [attachments, setAttachments] = useState<File[]>([]);
  const dispatch = useAppDispatch();
  const { urgencyList } = useSelector((state: RootState) => state.case);
  const [selectedLCMDisplayName, setSelectedLCMDisplayName] = useState("");
  const userType = getUserType();
  const userId = getUserID();
  const { t } = useTranslation();
  const [checked, setChecked] = useState(false);
  const [isOverlayVisible, setIsOverlayVisible] = useState(true);
  const [insuranceAttachments, setInsuranceAttachments] = useState<File[]>([]);
  const LCMList = useSelector((state: RootState) => state.case.LCMList);

  useEffect(() => {
    dispatch(fetchLCMList());
  }, [dispatch]);

  const getValidationSchema = () => {
    let schemaFields = {
      CaseType: Yup.string().required(t("Case type is required")),
      MinBudget: Yup.string().required(t("MinBudget is required")),
      MaxBudget: Yup.string().required(t("MaxBudget is required")),
      CountryOfJurisdiction: Yup.string().required(t("Country of Jurisdiction is required")),
      PreferredLanguage: Yup.string().required(t("Preferred language is required")),
      Urgency: Yup.string().required("Urgency is required"),
      DesiredOutcome: Yup.string().required(t("Desired outcome is required")),
      ExpectedOutcome: Yup.string().required(t("Expected outcome is required")),
      Description: Yup.string().required(t("Description required")),
      Currency: Yup.string().required(t("Currency is required")),
      Attachments: Yup.mixed().notRequired(),
      LCMID: Yup.string().required(t("LCM File is required"))
    };

    return Yup.object().shape(schemaFields);
  };

  const validationSchema = getValidationSchema();

  const formik = useFormik({
    initialValues: {
      CaseType: "",
      MinBudget: 100,
      MaxBudget: 1000000,
      CountryOfJurisdiction: "",
      PreferredLanguage: "",
      Urgency: "",
      DesiredOutcome: "",
      ExpectedOutcome: "",
      Description: "",
      Currency: "",
      Attachments: null,
      LCMID: ""
    },
    validationSchema,
    enableReinitialize: true,
    onSubmit: async (values, { resetForm }) => {
      const formData = new FormData();
      formData.append("UserType", userType);
      formData.append("UserID", userId);
      formData.append("DataID", userId);
      formData.append("Status", "Off Market");
      formData.append("LCMID", values.LCMID);

      Object.entries(values).forEach(([key, value]) => {
        if (value !== undefined && value !== null && value !== "") {
          if (typeof value === 'boolean') {
            formData.append(key, value ? 'yes' : 'no');
          } else {
            if (key === "MinBudget" || key === "MaxBudget") {
              const numberOfUsersInt = parseInt(value.toString(), 10);

              if (!isNaN(numberOfUsersInt)) {
                formData.append(key, numberOfUsersInt.toString());
              }
            } else {
              formData.append(key, value.toString());
            }
          }
        }
      });

      attachments.forEach((file, index) => {
        formData.append(`Attachments`, file);
      });

      try {
        let resp = await dispatch(submitCase(formData));
        if (resp) {
          await dispatch(fetchCaseList());
          resetForm();

          const updatedCases = store.getState().case.caseList;
          const updatedLastCase = updatedCases.length > 0 ? updatedCases[updatedCases.length - 1] : null;

          if (updatedLastCase) {
            setSelectedCase(updatedLastCase);
            setDetailsContent('CASE_DETAILS');
          } else {
          }
        }
      } catch (error) {
        console.error("Error during operation: ", error);
        dispatch(showFeedbackModal({
          modalType: 'error',
          duration: 3000,
          message: 'An error has occurred. Please contact support.',
          showModal: true
        }));
      }
    }
  });

  useEffect(() => {
    if (attachments.length > 0) {
      formik.setFieldValue("Attachments", attachments);
    }
  }, [attachments,formik]);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      const newFilesArray = Array.from(event.target.files);
      setAttachments((prevAttachments) => [...prevAttachments, ...newFilesArray]);
    }
  };

  const handleInsuranceFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      const newFilesArray = Array.from(event.target.files);
      setInsuranceAttachments((prevAttachments) => [...prevAttachments, ...newFilesArray]);
    }
  };

  const handleRemoveAttachment = (index: number) => {
    setAttachments((prevAttachments) => prevAttachments.filter((_, i) => i !== index));
  };

  const handleRemoveInsuranceAttachment = (index: number) => {
    setInsuranceAttachments((prevAttachments) => prevAttachments.filter((_, i) => i !== index));
  };

  let formikAttachments = formik.values.Attachments || [];
  const currencies = ["NOK", "USD", "EUR", "GBP"];

  const LCMOptions = LCMList.map((lcm: any) => {
    let displayText = "LCM";
    if (lcm.CaseType) displayText = lcm.CaseType;
    if (lcm.timestamps && lcm.timestamps.created) displayText += ` ${lcm.timestamps.created}`;
    return { value: lcm.LCMID, label: displayText };
  });

  const handleLCMChange = (value: string | string[]) => {
    const selectedLCM = LCMList.find((lcm: any) => lcm.LCMID === value);
    if (selectedLCM) {
      const selectedDisplayName = LCMOptions.find((option: any) => option.value === value)?.label;
      setSelectedLCMDisplayName(selectedDisplayName || "");
      formik.setFieldValue('LCMID', selectedLCM.LCMID);
      formik.setFieldValue('CaseType', selectedLCM.CaseType);
      formik.setFieldValue('Description', selectedLCM.BriefCaseDescription);
      formik.setFieldValue('CountryOfJurisdiction', selectedLCM.CountryOfJurisdiction);
      handleOverlayVisibility();
    }
  };
  
  const handleOverlayVisibility = () => {
    setIsOverlayVisible(false);
  };

  return (
    <div className='modal-case__container'>
      <div className={`modal-case__container--overlay ${!isOverlayVisible ? 'hidden' : ''}`}>
        <p>{t('Please select a Legal Case Mapping to continue.')}</p>
      </div>
      <div className='publish-marketplace--header'>
      <h1>{t('Submit your case')}</h1>
      <i className='fa-regular fa-scale-balanced upload-case__i'></i>
      </div>
      <form className='form-group' onSubmit={formik.handleSubmit}>
        <div className='upload-case__row--top'>
          <CustomDropdown
            label={`${t('Select Legal Case Mapping File')}`}
            options={LCMOptions}
            value={selectedLCMDisplayName || formik.values.LCMID}
            onChange={handleLCMChange}
            className={`dropdown-legal-case ${formik.errors?.LCMID && formik.touched.LCMID ? "input-error" : ""}`}
            error={formik.touched.LCMID && formik.errors.LCMID ? formik.errors.LCMID : ""}
            />
          <i style={{ color: '#64d2ff', fontSize: 24, alignSelf: 'flex-end', marginBottom: 8 }} className='fa-solid fa-gavel '></i>
        </div>
            {/* {!isOverlayVisible && (
                <> */}
        <div className='upload-case__row'>
          {/* <CustomDropdown
            label={`${t('Country of Jurisdiction')}`}
            options={countries}
            value={formik.values.CountryOfJurisdiction}
            onChange={(value) => formik.setFieldValue('CountryOfJurisdiction', value)}
            className={`dropdown-jurisdiction ${formik.errors?.CountryOfJurisdiction && formik.touched.CountryOfJurisdiction ? "input-error" : ""}`}
            error={formik.touched.CountryOfJurisdiction && formik.errors.CountryOfJurisdiction ? formik.errors.CountryOfJurisdiction : ""}
          /> */}
          <div>
          <label htmlFor="attachments">{t('Country of Jurisdiction')}</label>
          <p>{formik.values.CountryOfJurisdiction}</p>
          </div>

          <CustomDropdown
            label={`${t('Preferred Language')}`}
            options={preferredLanguageOptions}
            value={formik.values.PreferredLanguage}
            onChange={(value) => formik.setFieldValue('PreferredLanguage', value)}
            className={`dropdown-language ${formik.errors?.PreferredLanguage && formik.touched.PreferredLanguage ? "input-error" : ""}`}
            error={formik.touched.PreferredLanguage && formik.errors.PreferredLanguage ? formik.errors.PreferredLanguage : ""}
          />
        </div>

        <div className='upload-case__row'>
          {/* <CustomDropdown
            label={`${t('Case Type')}`}
            options={caseTypeOptions}
            value={formik.values.CaseType}
            onChange={(value) => formik.setFieldValue('CaseType', value)}
            className={`dropdown-case-type ${formik.touched.CaseType && formik.errors.CaseType ? 'input-error' : ""}`}
            error={formik.touched.CaseType && formik.errors.CaseType ? formik.errors.CaseType : ""}
          /> */}
          <div>
          <label htmlFor="attachments">{t('Case Type')}</label>
          <p>{formik.values.CaseType}</p>
          </div>

          <CustomDropdown
            label={`${t('Urgency Status')}`}
            options={urgencyList}
            value={formik.values.Urgency}
            onChange={(value) => formik.setFieldValue('Urgency', value)}
            className={`dropdown-urgency-status ${formik.errors?.Urgency && formik.touched.Urgency ? "input-error" : ""}`}
            error={formik.touched.Urgency && formik.errors.Urgency ? formik.errors.Urgency : ""}
          />
        </div>

        <DualThumbSliderNew
          min={10}
          max={500000}
          onChange={(values) => {
            formik.setFieldValue('MinBudget', values[0]);
            formik.setFieldValue('MaxBudget', values[1]);
          }}
          currencies={currencies}
          onCurrencyChange={formik.handleChange("Currency")}
          error={formik.errors.Currency && formik.touched.Currency ? formik.errors.Currency : ""}
        />

        <div className='upload-case__row'>
          <CustomDropdown
            label={`${t('Desired Outcome')}`}
            options={desiredOutcomeOptions}
            value={formik.values.DesiredOutcome}
            onChange={(value) => formik.setFieldValue("DesiredOutcome", value)}
            className={`dropdown-desired-outcome ${formik.errors?.DesiredOutcome && formik.touched.DesiredOutcome ? "input-error" : ""}`}
            error={formik.touched.DesiredOutcome && formik.errors.DesiredOutcome ? formik.errors.DesiredOutcome : ""}
          />
          <CustomDropdown
            label={`${t('Expected Outcome')}`}
            options={expectedOutcomeOptions}
            value={formik.values.ExpectedOutcome}
            onChange={(value) => formik.setFieldValue("ExpectedOutcome", value)}
            className={`dropdown-expected-outcome ${formik.errors?.ExpectedOutcome && formik.touched.ExpectedOutcome ? "input-error" : ""}`}
            error={formik.touched.ExpectedOutcome && formik.errors.ExpectedOutcome ? formik.errors.ExpectedOutcome : ""}
          />
        </div>
        <div className='lcm-upload__dscription'>
          <label>{t('Description')}</label>
          <p>{formik.values.Description}</p>
          {formik.errors.Description && formik.touched.Description ? <span className='error-message'>{formik.errors.Description}</span> : ""}
        </div>

        <div className='attachments-container'>
          <label htmlFor="attachments">{t('Attachments')}</label>
          <div className='attachments-input-row'>
            {formikAttachments && formikAttachments.map((file: { name: string }, index) => (
              <div key={index} className='attachment-item'>
                {file.name}
                <span className='remove-attachment' onClick={() => handleRemoveAttachment(index)}>
                  <i className='fa-solid fa-xmark'></i>
                </span>
              </div>
            ))}
            <label className='attachment-upload-label'>
              <i className='fa-solid fa-plus'></i>
              <input
                type="file"
                id="attachments"
                name="attachments"
                multiple
                onChange={handleFileChange}
                style={{ display: 'none' }}
              />
            </label>
          </div>
        </div>


        <div className="legal-aid__check">
          <input
            id="Insurance"
            className="checkbox-insurance-conditions"
            type="checkbox"
            name="Insurance"
            onChange={() => setChecked(!checked)}
            style={{ width: 15, height: 15 }}
          />
          <label htmlFor="Insurance" className="check-insurance-conditions">
            {t('Insurance/Legal Aid')}
          </label>
        </div>

        {checked && (
          <div className='attachments-container'>
            <label htmlFor="attachments">{t('Upload Document')}</label>
            <div className='attachments-input-row'>
              {insuranceAttachments && insuranceAttachments.map((file: { name: string }, index) => (
                <div key={index} className='attachment-item'>
                  {file.name}
                  <span className='remove-attachment' onClick={() => handleRemoveInsuranceAttachment(index)}>
                    <i className='fa-solid fa-xmark'></i>
                  </span>
                </div>
              ))}
              <label className='attachment-upload-label'>
                <i className='fa-solid fa-plus'></i>
                <input
                  type="file"
                  id="attachments"
                  name="attachments"
                  multiple
                  onChange={handleInsuranceFileChange}
                  style={{ display: 'none' }}
                />
              </label>
            </div>
          </div>
        )}
        <button className='lcm-case__submit-button' type="submit">{t('Submit Case')}</button>
        {/* </>
      )} */}
      </form>
    </div>
  );
};

export default LCMUploadForm;
