import { toast } from 'react-toastify';

export const showSuccessToast = (message: string) => {
  toast.success(message, {
    position: "bottom-center",
    autoClose: 1000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progressStyle: { background: '#64d2ff' },
    progress: undefined,
    style: {
      background: "#333",
      color: "#fff"
    },
    icon: <i style={{ fontSize: 24 }} className="fas fa-check-circle" />,
  });
};

export const showErrorToast = (message: string) => {
  toast.success(message, {
    position: "bottom-center",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progressStyle: { background: 'red' },
    progress: undefined,
    style: {
      background: "#333",
      color: "red"
    },
    icon: <i style={{ fontSize: 24 }} className="fa-sharp fa-solid fa-circle-exclamation" />,
  });
};