import PerformanceSection from "./PerformanceSection";
import TeamManagementWidget from "./TeamManagementWidget";
import './AdminFirm.css';
import { RootState } from '../../../store';
import { useSelector } from 'react-redux';

const AdminFirm = () => {
    const activeAdminLView = useSelector((state: RootState) => state.adminView.activeItem);


    return (
        <div className="admin-firm-container">
            <div className='component-render-container'>
                {activeAdminLView === "Finance" ? <PerformanceSection /> : <TeamManagementWidget />}
            </div>
        </div>
    );
}

export default AdminFirm;
