import React, { useEffect, useRef } from 'react';
import Divider from '@mui/material/Divider';
import './SignUpRequirements.css';

interface Props {
  changeSection: (section: string) => void;
}

const PrivacyPolicy: React.FC<Props> = ({ changeSection }) => {

    const topElement = useRef<HTMLDivElement>(null);

  useEffect(() => {
    topElement.current?.scrollIntoView();
  }, []);
  
  return (
    <div ref={topElement}>
      <div className='requirements-section'>
        <div className='requirements-container'>
          <div className='requirements__company-stamp'>
          {/* <Logo className="company-logo-requirements" /> */}
          <h1 className='pons-text'>PONS LABS</h1>
          </div>
          <h1 className='requirements-header'>Privacy & Policy</h1>
          <div className='info-placement'>
          <h2>1. Introduction</h2> <p>Briefly introduce your platform, its purpose, and the scope of the Privacy Policy.</p> 
          <h2>2. Information Collection</h2> <p>Explain the types of personal information your platform collects from users (e.g., name, email address, phone number, etc.).Detail any non-personal information collected, such as usage data, cookies, and device information.</p>
          <h2>3. Information Use</h2> <p>Describe the purposes for which the collected information will be used, such as providing services, improving the platform, communicating with users, or ensuring platform security.</p>
          <h2>4. Information Sharing and Disclosure</h2><p>Explain the circumstances under which you may share users' personal information with third parties, such as service providers, business partners, or legal authorities.</p>
          <h2>5. Data Retention</h2> <p>Describe your platform's data retention policy, including how long personal information will be kept and how it will be securely deleted or destroyed.</p>
          <h2>6. User Rights</h2> <p>Explain the rights that users have regarding their personal information, such as the right to access, correct, or delete their data. Provide instructions on how users can exercise these rights.</p>
          <h2>7. Data Security</h2> <p>Describe the measures your platform takes to protect user data, such as encryption, firewalls, and access controls. Detail any third-party services or providers that are used to enhance data security.</p>
          <h2>8. Changes to the Privacy Policy</h2> <p>Detail how changes to the Privacy Policy will be communicated to users and how users can provide feedback or request clarification.</p>
          <h2>9. Contact Information</h2> <p>Provide contact information for users to submit questions or concerns about the Privacy Policy or the handling of their personal information, such as an email address or phone number.</p>        

          </div>
          <Divider variant="middle" />

          <div className='requirements-btns'>
          <button onClick={() => changeSection("signup")} className='back-to-sign-up__btn'>I Understand</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
