import React, { useState, FormEvent, useEffect } from 'react';
import { useStripe, useElements, IbanElement, CardElement } from '@stripe/react-stripe-js';
import './BankForm.css'
import { Button } from '@mui/material';
import { RootState, useAppDispatch } from '../../../store';
import { createConnectedAccount, listRecieversAccount } from '../../../store/actions/StripeActions';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { currencyList, getPreferences } from '../../../store/actions/DataAction';
import { getUserType } from '../../../store/actions/ChatAction';

const BankForm: React.FC = () => {
  const stripe = useStripe();
  const elements = useElements();
  const [loading, setLoading] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState<'card' | 'iban' | 'account'>('card');
  const [focusedElement, setFocusedElement] = useState<string | null>(null);
  const [verificationURL, setVerificationURL] = useState("")
  const dispatch = useAppDispatch()
  const { t } = useTranslation()
  const userInfo = useSelector((state: RootState) => state.user.userInfo)
  const [selectedCurrency, setSelectedCurrency] = useState<{ code: string; name: string }>({ code: '', name: '' });
  const [isCurrencyOpen, setIsCurrencyOpen] = useState(false);
  const currencies = useSelector((state: RootState) => state.lists.currencies);
  const preferences = useSelector((state: RootState) => state.lists.preferences);
  const [accountDetails, setAccountDetails] = useState({
    routingNumber: '',
    accountNumber: '',
    accountHolderName: userInfo.FirstName + " " + userInfo.LastName,
  });
  const userType = getUserType()

  useEffect(() => {
    dispatch(getPreferences(userType));
    dispatch(currencyList());
  }, [dispatch, userType]);


  useEffect(() => {
    if (preferences) {
      setSelectedCurrency({ code: preferences.Currency, name: preferences.CurrencyName });
    }
  }, [preferences]);

  const handleVerification = async () => {
    if (!stripe) {
      setLoading(false);
      return;
    }
    if (verificationURL) {
      await stripe.verifyIdentity(verificationURL);
    }
  }

  const handleSubmit = async (event: FormEvent) => {
    event.preventDefault();
    setLoading(true);
    if (!stripe) {
      setLoading(false);
      return;
    }

    let token;
    let error;

    if (paymentMethod === 'card') {
      const cardNumberElement = elements?.getElement(CardElement);

      if (!cardNumberElement || !stripe) {
        setLoading(false);
        return;
      }

      ({ token, error } = await stripe.createToken(cardNumberElement, {
        'currency': selectedCurrency.code,
      }));

    } else if (paymentMethod === 'iban') {
      const ibanElement = elements?.getElement(IbanElement);
      if (!ibanElement || !stripe) {
        setLoading(false);
        return;
      }

      ({ token, error } = await stripe.createToken(ibanElement, {
        currency: selectedCurrency.code,
        account_holder_name: accountDetails.accountHolderName,
        account_holder_type: 'individual',
      }));
    } else if (paymentMethod === 'account') {
      if (!accountDetails.routingNumber || !accountDetails.accountNumber) {
        setLoading(false);
        return;
      }


      ({ token, error } = await stripe.createToken('bank_account', {
        country: 'NO',
        currency: selectedCurrency.code,
        routing_number: accountDetails.routingNumber,
        account_number: accountDetails.accountNumber,
        account_holder_name: accountDetails.accountHolderName,
        account_holder_type: 'individual',
      }));
    }

    if (error) {
      setLoading(false);
      return;
    }

    const response = await dispatch(createConnectedAccount(token?.id, userInfo.Email, userInfo.CustomerID))

    if (response) {
      await dispatch(listRecieversAccount(userInfo.Email))
      const resp_data: any = response
      if (resp_data.verification_needed) {
        setVerificationURL(resp_data.verification_url)
      }

      setLoading(false);
    } else {

      setLoading(false);
    }
  };

  const elementStyles = {
    base: {
      fontSize: '16px',
      color: '#FFF',
      width: '100%',
      '::placeholder': {
        color: '#aab7c4',
      },
      iconColor: "#fff"
    },
    invalid: {
      color: '#fa755a',
      iconColor: '#fff',
    },
  };

  const elementClasses = {
    base: 'StripeElementBase',
    complete: 'StripeElementComplete',
    empty: 'StripeElementEmpty',
    focus: 'StripeElementFocus',
    invalid: 'StripeElementInvalid',
    webkitAutofill: 'StripeElementAutofill',
  };

  const handleElementChange = (event: any) => {
    if (event.empty) {
      setFocusedElement(null);
    } else if (event.complete) {
      setFocusedElement(null);
    } else if (event.error) {
      setFocusedElement(null);
    } else {
      setFocusedElement(event.elementType);
    }
  };

  const handleCurrencyChange = (code: string, name: string) => {
    setSelectedCurrency({ code, name });
    setIsCurrencyOpen(false)
  }

  return (
    <div className='fbank-form-conatiner'>
      {
        verificationURL ? (<>
          <Button type="submit" variant="outlined" style={{ color: "#fff", }} onClick={handleVerification}>
            Verify
          </Button>
        </>) : (
          <>
            <form onSubmit={handleSubmit}>
              <div className="payment-methods">
                <div className={`payment-card ${paymentMethod === 'card' ? 'payment-card-selected' : ''}`} onClick={() => setPaymentMethod('card')}>
                  <span className='payment-card-text'>{t('Card')}</span>
                </div>
                <div className={`payment-card ${paymentMethod === 'iban' ? 'payment-card-selected' : ''}`} onClick={() => setPaymentMethod('iban')}>
                  <span className='payment-card-text'>{t('IBAN')}</span>
                </div>
                <div className={`payment-card ${paymentMethod === 'account' ? 'payment-card-selected' : ''}`} onClick={() => setPaymentMethod('account')}>
                  <span className='payment-card-text'>{t('Bank Account')}</span>
                </div>
              </div>
              {paymentMethod === 'card' && (
                <div className='account-info-container'>
                  <div className='card-input-container'>
                    <span className='card-input-container-text'>{t('Card Number')}</span>
                    <div className={`card-input-container-input ${focusedElement === 'cardNumber' ? 'focused' : ''} `}>
                      <CardElement options={{ style: elementStyles, classes: elementClasses }} onChange={handleElementChange} onFocus={handleElementChange} />
                    </div>
                  </div>

                </div>
              )}
              {paymentMethod === 'iban' && (
                <div className='account-info-container'>
                  <div className="currency-preference">
                    <div className="preference-text">
                      <p>{t('Currency')}</p>
                    </div>
                    <div>
                      <div className="global-dropdown currency">
                        <div className={`global-dropdown-header language`} onClick={() => setIsCurrencyOpen(!isCurrencyOpen)}>
                          <span style={{ fontSize: "0.9rem" }}>{selectedCurrency.name || t('Select currency')}</span>
                          <i className={`fas fa-chevron-${isCurrencyOpen ? 'up' : 'down'}`} style={{ fontSize: "18px" }}></i>
                        </div>
                        {isCurrencyOpen && (
                          <div className="global-dropdown-list">
                            {currencies?.map((option: { Currency: string; Name: string }) => (
                              <div key={option.Currency} className="global-dropdown-item" style={{ fontSize: '0.8rem' }} onClick={() => handleCurrencyChange(option.Currency, option.Name)}>
                                {option.Name}
                              </div>
                            ))}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className='card-input-container'>
                    <span className='card-input-container-text'>{t('Account Holder Name')}</span>
                    <input
                      type="text"
                      value={accountDetails.accountHolderName}
                      onChange={(e) => setAccountDetails({ ...accountDetails, accountHolderName: e.target.value })}
                      className={`card-input-container-input ${focusedElement === 'cardNumber' ? 'focused' : ''} `}
                      onFocus={handleElementChange}
                    />
                  </div>
                  <div className='card-input-container'>
                    <span className='card-input-container-text'>IBAN</span>
                    <div className={`card-input-container-input ${focusedElement === 'cardNumber' ? 'focused' : ''} `}>

                      <IbanElement
                        options={{ supportedCountries: ['SEPA'], style: elementStyles, classes: elementClasses }}
                        onFocus={handleElementChange}
                        onChange={(event) => {
                          if (event.error) {
                            console.error(event.error)
                          } else {
                          }
                        }}
                        className="StripeElement"
                      />
                    </div>
                  </div>
                </div>
              )}
              {paymentMethod === 'account' && (
                <div className='account-info-container'>
                  <div className="currency-preference">
                    <div className="preference-text">
                      <p>{t('Currency')}</p>
                    </div>
                    <div>
                      <div className="global-dropdown currency">
                        <div className={`global-dropdown-header language`} onClick={() => setIsCurrencyOpen(!isCurrencyOpen)}>
                          <span style={{ fontSize: "0.9rem" }}>{selectedCurrency.name || t('Select currency')}</span>
                          <i className={`fas fa-chevron-${isCurrencyOpen ? 'up' : 'down'}`} style={{ fontSize: "18px" }}></i>
                        </div>
                        {isCurrencyOpen && (
                          <div className="global-dropdown-list">
                            {currencies?.map((option: { Currency: string; Name: string }) => (
                              <div key={option.Currency} className="global-dropdown-item" style={{ fontSize: '0.8rem' }} onClick={() => handleCurrencyChange(option.Currency, option.Name)}>
                                {option.Name}
                              </div>
                            ))}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className='card-input-container'>
                    <span className='card-input-container-text'>{t('Routing Number')}</span>
                    <input
                      type="text"
                      value={accountDetails.routingNumber}
                      onChange={(e) => setAccountDetails({ ...accountDetails, routingNumber: e.target.value })}
                      className={`card-input-container-input ${focusedElement === 'cardNumber' ? 'focused' : ''} `}
                      onFocus={handleElementChange}
                    />
                  </div>
                  <div className='card-input-container'>
                    <span className='card-input-container-text'>{t('Account Number')}</span>
                    <input
                      type="text"
                      value={accountDetails.accountNumber}
                      onChange={(e) => setAccountDetails({ ...accountDetails, accountNumber: e.target.value })}
                      className={`card-input-container-input ${focusedElement === 'cardNumber' ? 'focused' : ''} `}
                      onFocus={handleElementChange}
                    />
                  </div>
                  <div className='card-input-container'>
                    <span className='card-input-container-text'>{t('Account Holder Name')}</span>
                    <input
                      type="text"
                      value={accountDetails.accountHolderName}
                      onChange={(e) => setAccountDetails({ ...accountDetails, accountHolderName: e.target.value })}
                      className={`card-input-container-input ${focusedElement === 'cardNumber' ? 'focused' : ''} `}
                      onFocus={handleElementChange}
                    />
                  </div>
                </div>
              )}
              <Button type="submit" variant="outlined" style={{ color: "#fff", }}>
                {loading ? 'Processing...' : 'Submit'}
              </Button>
            </form>
          </>
        )
      }


    </div>
  );
};

export default BankForm;
