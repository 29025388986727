import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import './lcmStyles.css'

export type Message = {
  id?: number;
  text: string;
  sender: 'user' | 'assistant';
  confirmationMessage?: string | null;
  confirmationState?: 'pending' | 'confirmed' | 'rejected';
  animationComplete?: boolean;
  customClass?: string;
  section?: string | null;
};

interface CustomMessageListProps {
  messages: Message[];
  setMessages: React.Dispatch<React.SetStateAction<Message[]>>;
  handleConfirmationClick: (response: string, index: number) => void;
  loaderText: string;
  currentSection: string | null; 
}

const CustomMessageList: React.FC<CustomMessageListProps> = ({ messages, setMessages, handleConfirmationClick, loaderText, currentSection }) => {
  const messagesEndRef = useRef<HTMLDivElement>(null);
  const [displayedTexts, setDisplayedTexts] = useState<(string | JSX.Element)[]>([]);
  const [completedTexts, setCompletedTexts] = useState<boolean[]>([]);
  const [showScrollDownArrow, setShowScrollDownArrow] = useState(false);
  const messageEndRef = useRef<HTMLDivElement | null>(null);
  const [confirmationStates, setConfirmationStates] = useState<{ [key: number]: 'confirmed' | 'rejected' | 'pending' }>({});
  const { t } = useTranslation();

  const scrollToBottomImmediate = () => {
    if (messageEndRef.current) {
      messageEndRef.current.scrollIntoView({ behavior: 'auto' });
    }
  };

  const scrollToBottom = () => {
    if (messageEndRef.current) {
      messageEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const scrollToBottomIfNeeded = () => {
    const container = messageEndRef.current?.parentElement;
    if (!container) return;

    const scrollPosition = container.scrollTop + container.clientHeight;
    const threshold = container.scrollHeight - container.clientHeight - 50;
    if (scrollPosition >= threshold) {
      scrollToBottomImmediate();
    }
  };

  useEffect(() => {
    scrollToBottomImmediate();
  }, [messages.length]);

  useEffect(() => {
    function handleScroll() {
      const element = messageEndRef.current;
      if (element && element.parentElement) {
        const show = element.parentElement.scrollHeight - element.parentElement.scrollTop > element.parentElement.clientHeight + 100;
        setShowScrollDownArrow(show);
      }
    }

    const container = messageEndRef.current?.parentElement;
    if (container) {
      container.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (container) {
        container.removeEventListener('scroll', handleScroll);
      }
    };
  }, [messages]);

  useEffect(() => {
    const lastAssistantMessageIndex = messages.map((m, index) => ({ ...m, index }))
      .reverse()
      .find(m => m.sender === 'assistant')?.index;

    const updatedMessages: Message[] = [...messages];

    messages.forEach((message, index) => {
      if (typeof message.text === 'string' && message.sender === 'assistant' && index === lastAssistantMessageIndex && !message.animationComplete) {
        let currentLength = 0;
        let fullText = message.text;

        const timer = setInterval(() => {
          currentLength++;
          if (currentLength <= fullText.length) {
            const textSegment = fullText.substring(0, currentLength);
            setDisplayedTexts(prev => {
              const updatedTexts: (string | JSX.Element)[] = [...prev];
              updatedTexts[index] = formatAssistantMessage(textSegment);
              return updatedTexts;
            });
            scrollToBottomImmediate();
          } else {
            setDisplayedTexts(prev => {
              const updatedTexts: (string | JSX.Element)[] = [...prev];
              updatedTexts[index] = formatAssistantMessage(fullText);
              return updatedTexts;
            });
            clearInterval(timer);
            setMessages(prevMessages => {
              const newMessages = [...prevMessages];
              newMessages[index].animationComplete = true;
              if (newMessages[index].confirmationMessage) {
                const confirmationMessageIndex = newMessages.length;
                newMessages.push({
                  text: newMessages[index].confirmationMessage || '',
                  sender: 'assistant',
                  customClass: 'centered-message-confirm',
                  animationComplete: false,
                  section: currentSection
                });
                newMessages.push({
                  text: '',
                  sender: 'assistant',
                  customClass: 'confirmation-buttons',
                  animationComplete: false,
                  section: currentSection
                });
              }
              return newMessages;
            });
            scrollToBottomImmediate();
          }
        }, 10);
      } else if (message.sender === 'assistant' && !message.animationComplete) {
        setDisplayedTexts(prev => {
          const updatedTexts: (string | JSX.Element)[] = [...prev];
          updatedTexts[index] = formatAssistantMessage(message.text);
          return updatedTexts;
        });
      }
    });
  }, [messages]);

  useEffect(() => {
    const lastConfirmationMessageIndex = messages.map((m, index) => ({ ...m, index }))
      .reverse()
      .find(m => m.customClass === 'centered-message-confirm')?.index;

    messages.forEach((message, index) => {
      if (message.customClass === 'centered-message-confirm' && !message.animationComplete && index === lastConfirmationMessageIndex) {
        let currentLength = 0;
        let fullText = message.text;

        const timer = setInterval(() => {
          currentLength++;
          if (currentLength <= fullText.length) {
            const textSegment = fullText.substring(0, currentLength);
            setDisplayedTexts(prev => {
              const updatedTexts: (string | JSX.Element)[] = [...prev];
              updatedTexts[index] = formatAssistantMessage(textSegment);
              return updatedTexts;
            });
            scrollToBottomImmediate();
          } else {
            setDisplayedTexts(prev => {
              const updatedTexts: (string | JSX.Element)[] = [...prev];
              updatedTexts[index] = formatAssistantMessage(fullText);
              return updatedTexts;
            });
            clearInterval(timer);
            setMessages(prevMessages => {
              const newMessages = [...prevMessages];
              newMessages[index].animationComplete = true;
              newMessages[index + 1].animationComplete = true;
              return newMessages;
            });
            scrollToBottomImmediate();
          }
        }, 10);
      }
    });
  }, [messages]);

  const formatAssistantMessage = (message: any) => {
    if (typeof message !== 'string') {
      if (message.DataURL) {
        return (
          <div className="formatted-assistant-message--column">
            <p style={{ fontSize: 24 }}>{message.message}</p>
            <a href={message.DataURL} target="_blank" rel="noopener noreferrer">
              <div className='formatted-assistant-message--row'>
                <i className="fas fa-file-download"></i><p>DOWNLOAD DOCUMENT</p>
              </div>
            </a>
          </div>
        );
      }
      return formatMessageText(message.message);
    }
    return formatMessageText(message);
  };

  const formatMessageText = (text: string) => {

    const formattedText = text
      .replace(/\\n/g, '\n')  
      .replace(/\\t/g, '\t'); 
  
    const paragraphs = formattedText.split('/n/n').map((paragraph, paragraphIndex) => {
      const lines = paragraph.split('\n').map((line, lineIndex) => {
        let jsxElements: (JSX.Element | string)[] = [];
  
        // Handling different heading levels
        if (line.startsWith('### ')) {
          jsxElements.push(<h3 key={`h3-${paragraphIndex}-${lineIndex}`}><strong>{line.substring(4)}</strong></h3>);
        } else if (line.startsWith('#### ')) {
          jsxElements.push(<h4 key={`h4-${paragraphIndex}-${lineIndex}`}><strong>{line.substring(5)}</strong></h4>);
        } else if (line.startsWith('##### ')) {
          jsxElements.push(<h5 key={`h5-${paragraphIndex}-${lineIndex}`}><strong>{line.substring(6)}</strong></h5>);
        } else if (line.startsWith('###### ')) {
          jsxElements.push(<h6 key={`h6-${paragraphIndex}-${lineIndex}`}><strong>{line.substring(7)}</strong></h6>);
        } 
        // Handling ordered lists (e.g., "1. List item")
        else if (line.match(/^\d+\.\s/)) {
          const tokens = line.split(/(\*\*.*?\*\*|\*.*?\*|\[doc\d+\])/g);
          tokens.forEach((token, i) => {
            if (token.startsWith('**')) {
              jsxElements.push(<strong key={`strong-${paragraphIndex}-${lineIndex}-${i}`}>{token.slice(2, -2)}</strong>);
            } else if (token.startsWith('*')) {
              jsxElements.push(<em key={`em-${paragraphIndex}-${lineIndex}-${i}`}>{token.slice(1, -1)}</em>);
            } else if (token.match(/\[doc\d+\]/)) {
              jsxElements.push(<i key={`icon-${paragraphIndex}-${lineIndex}-${i}`} title='source' style={{ color: '#64d2ff' }} className="fas fa-file-lines"></i>);
            } else {
              jsxElements.push(token);
            }
          });
          jsxElements = [<li key={`li-${paragraphIndex}-${lineIndex}`}>{jsxElements}</li>];
        } 
        // Handling unordered lists (e.g., "- List item")
        else if (line.startsWith('- ')) {
          const tokens = line.substring(2).split(/(\*\*.*?\*\*|\*.*?\*|\[doc\d+\])/g);
          tokens.forEach((token, i) => {
            if (token.startsWith('**')) {
              jsxElements.push(<strong key={`strong-${paragraphIndex}-${lineIndex}-${i}`}>{token.slice(2, -2)}</strong>);
            } else if (token.startsWith('*')) {
              jsxElements.push(<em key={`em-${paragraphIndex}-${lineIndex}-${i}`}>{token.slice(1, -1)}</em>);
            } else if (token.match(/\[doc\d+\]/)) {
              jsxElements.push(<i key={`icon-${paragraphIndex}-${lineIndex}-${i}`} title='source' style={{ color: '#64d2ff' }} className="fas fa-file-lines"></i>);
            } else {
              jsxElements.push(token);
            }
          });
          jsxElements = [<li key={`li-${paragraphIndex}-${lineIndex}`}>{jsxElements}</li>];
        } 
        // Handling regular text with inline formatting (bold, italic, document icons)
        else {
          const tokens = line.split(/(\*\*.*?\*\*|\*.*?\*|\[doc\d+\])/g);
          tokens.forEach((token, i) => {
            if (token.startsWith('**')) {
              jsxElements.push(<strong key={`strong-${paragraphIndex}-${lineIndex}-${i}`}>{token.slice(2, -2)}</strong>);
            } else if (token.startsWith('*')) {
              jsxElements.push(<em key={`em-${paragraphIndex}-${lineIndex}-${i}`}>{token.slice(1, -1)}</em>);
            } else if (token.match(/\[doc\d+\]/)) {
              jsxElements.push(<i key={`icon-${paragraphIndex}-${lineIndex}-${i}`} title='source' style={{ color: '#64d2ff' }} className="fas fa-file-lines"></i>);
            } else {
              jsxElements.push(token);
            }
          });
        }
  
        return <div key={`${paragraphIndex}-${lineIndex}`}>{jsxElements}</div>;
      });
  
      return (
        <div key={paragraphIndex} style={{ marginBottom: '15px' }}>
          {lines.map(lineElement => <React.Fragment key={lineElement.key}>{lineElement}<br /></React.Fragment>)}
        </div>
      );
    });
  
    return (
      <div className="formatted-assistant-message">
        {paragraphs}
      </div>
    );
  };  

  const handleButtonClick = (response: string, index: number) => {
    const newState = response === 'Yes' ? 'confirmed' : 'rejected';
    setConfirmationStates(prev => ({ ...prev, [index]: newState }));
    handleConfirmationClick(response, index);
  };

  const formatUserMessage = (text: string | JSX.Element): JSX.Element | string => {
    if (typeof text === 'string') {
      const initiatingRegex = /^Starting (.+?)\.\.\./;
      const match = text.match(initiatingRegex);

      if (match) {
        const contractType = match[1];
        return (
          <div className="formatted-user-message">
            <span style={{ background: '#444', padding: '3px 10px', borderRadius: '5px', display: 'inline-block', marginBottom: '10px', marginTop: 10, color: '#64d2ff', fontSize: '0.8rem' }}>
              {contractType}
            </span>
          </div>
        );
      }

      const formattedText = text.replace(/\n/g, '<br />');
      return <div className="formatted-user-message" dangerouslySetInnerHTML={{ __html: formattedText }} />;
    }
    return text;
  };

  return (
    <>
      <div className="custom-message-list">
        {messages.map((message, index) => (
          <div key={index} className={`message ${message.sender} ${message.customClass || ''} ${currentSection === 'Issue' && message.customClass !== 'confirmation-buttons' ? 'centered-message' : ''}`}>
            {message.sender === 'user' && (
              <div className="user-message-content">{formatUserMessage(message.text)}</div>
            )}
            {message.text === 'Loading...' && message.sender === 'assistant' && (
              <div className="loader">
                <i className="fa-kit fa-logo spin" style={{ fontSize: 40, color: '#64d2ff' }}></i>
                <p>{loaderText}</p>
              </div>
            )}
            {message.sender === 'assistant' && message.text !== 'Loading...' && (
              completedTexts[index] ?
                formatAssistantMessage(displayedTexts[index] as string) :
                displayedTexts[index] || formatAssistantMessage(message.text)
            )}
            {message.customClass === 'confirmation-buttons' && message.animationComplete && (
              <div className="confirmation-buttons">
                {message.section === 'RelevantLegislationConfirm' || message.section === 'InterpretationConfirm' || message.section === 'StrategyConfirm' ? (
                  <button
                    className={`confirm-button ${confirmationStates[index] === 'confirmed' ? 'confirmed' : ''}`}
                    onClick={() => handleButtonClick('Yes', index)}
                  >
                    {message.section === "StrategyConfirm" ? t('Generate document') : t('Continue')}
                  </button>
                ) : (
                  <>
                    <button
                      className={`confirm-button ${confirmationStates[index] === 'confirmed' ? 'confirmed' : ''}`}
                      onClick={() => handleButtonClick('Yes', index)}
                    >
                      <i className="fas fa-check fa-duotone-check"></i>
                    </button>
                    <button
                      className={`reject-button ${confirmationStates[index] === 'rejected' ? 'rejected' : ''}`}
                      onClick={() => handleButtonClick('No', index)}
                    >
                      <i className="fas fa-xmark fa-duotone-xmark"></i>
                    </button>
                  </>
                )}
              </div>
            )}
          </div>
        ))}
        <div style={{ margin: '10px' }}></div>
        <div ref={messageEndRef}></div>
      </div>
      {showScrollDownArrow && (
        <i onClick={scrollToBottom} className="fa-duotone fa-circle-chevron-down scroll-down-arrow"></i>
      )}
    </>
  );
};

export default CustomMessageList;

