import React from 'react';
import './TimeTracker.css';
import WorkActivityChart from '../Dashboard/LawyerFirm/WorkActivityChart';

interface HeaderStatsProps {
  stats: {
    activityThisWeek: string;
    tasksCompleted: number;
    tasksTotal: number;
    casesWorkedOn: number;
  };
}

const HeaderStats: React.FC<HeaderStatsProps> = ({ stats }) => {
  return (
    <div className="time-keeper--header-stats">
      <div className="stat-card">
        {/* <h4>Activity This Week</h4>
        <p>{stats.activityThisWeek}</p> */}
        <WorkActivityChart />
      </div>
      <div style={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
      <div className="stat-card">
        <h4>Tasks Completed</h4>
        <p>{stats.tasksCompleted} / {stats.tasksTotal}</p>
      </div>
      <div className="stat-card">
        <h4>Cases Worked On</h4>
        <p>{stats.casesWorkedOn}</p>
      </div>
      </div>
    </div>
  );
};

export default HeaderStats;
