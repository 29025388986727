import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import './EmployeeView.css';
import { Lawyer } from '../interface/IFirmLawyer';
import { RootState, useAppDispatch } from '../../../../store';
import { fetchCaseList } from '../../../../store/actions/marketplace/caseActions';
import { getAllLawfirmAssignedTask, completeLawfirmAssignedTask, deleteLawfirmAssignedTask, getLawfirmLawyerTasks, getLawfirmLawyerCases } from '../../../../store/actions/LawfirmActions';
import { Task } from '../../../Marketplace/Lawyer/LawyerSection';
import AddTask from '../../../Marketplace/Lawyer/AddTaskComponent';
import { getUserType } from '../../../../store/actions/ChatAction';
import { useSelector } from 'react-redux';
import { getLawfirmLawyerTask } from '../../../../store/actions/LawfirmLawyerActions';
import { ClipLoader } from 'react-spinners';
import { useHistory, useLocation } from 'react-router-dom';
import { ICaseItem } from '../../../Marketplace/Interfaces/ICaseItem';
import { IAdminCaseItem } from '../../../Marketplace/Interfaces/IAdminCaseItem';
import { selectCaseForViewing, setDetailsContent } from '../../../../store/actions/marketplace/activeViewActions';

interface EmployeeViewProps {
  employee: Lawyer;
  setSelectedMyCase?: (value: string | null) => void; 
  // handleMyCaseClick?: (caseItem: ICaseItem | IAdminCaseItem) => void;
}

const EmployeeView: React.FC<EmployeeViewProps> = ({ employee, setSelectedMyCase }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [tasks, setTasks] = useState<Task[]>([]);
  const [cases, setCases] = useState<ICaseItem[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [showAddTask, setShowAddTask] = useState(false);
  const endRef = useRef<HTMLDivElement>(null);
  const userType = getUserType();
  const location = useLocation();
  const history = useHistory();
  
  useEffect(() => {
    if (!employee) return; 
    if (location.pathname === '/marketplace/lawyer/profile') {
      fetchCases();
    } else {
      fetchTasks();
    }
  }, [dispatch, employee?.Email, userType, location.pathname]); 

  const fetchTasks = async () => {
    if (!employee) return; 
    setIsLoading(true);
    const response = await dispatch(getLawfirmLawyerTasks(userType, employee.Email));
    if (response) {
      setTasks(response);
      setIsLoading(false);
    }
  };

  const fetchCases = async () => {
    if (!employee) return; 
    setIsLoading(true);
    const response = await dispatch(getLawfirmLawyerCases(userType, employee.Email));
    if (response) {
      setCases(response);
      setIsLoading(false);
    }
  };

  const handleCompleteTask = async (taskID: string) => {
    await dispatch(completeLawfirmAssignedTask(userType, taskID));
    fetchTasks();
  };

  const handleDeleteTask = async (taskID: string) => {
    await dispatch(deleteLawfirmAssignedTask(userType, taskID));
    fetchTasks();
  };

  const getStatusColor = (status: string) => {
    switch (status) {
      case 'Available':
        return 'green';
      case 'Busy':
        return 'orange';
      case 'Unavailable':
        return 'red';
      default:
        return 'grey';
    }
  };

  const handleAddTaskClick = () => {
    setShowAddTask(true);
    setTimeout(() => {
      endRef.current?.scrollIntoView({ behavior: 'smooth' });
    }, 100);
  };

  const onAddTask = async () => {
    setShowAddTask(false);
    fetchTasks();
  }

  if (!employee) {
    return <div style={{ height: '20vh' }} className='non-access'><ClipLoader size={20} color='#64d2ff' /></div>;
  }

  const isIAdminCaseItem = (item: ICaseItem | IAdminCaseItem): item is IAdminCaseItem => {
    return (item as IAdminCaseItem).ClientMaxBudget !== undefined;
  };

  const handleMyCaseClick = (caseItem: ICaseItem | IAdminCaseItem) => {
    const caseId = isIAdminCaseItem(caseItem) ? caseItem.CaseID : caseItem.id;
    if (location.pathname !== '/marketplace/lawyer/cases') {
      history.push('/marketplace/lawyer/cases');
    }
    if (setSelectedMyCase) {
      setSelectedMyCase(caseId);
    }
    dispatch(selectCaseForViewing(caseItem));
    dispatch(setDetailsContent('MY_CASE_DETAILS'));
  };

  return (
    <div className="employee-view-container">
      <h2>{t('Employee Details')}</h2>
      <div className="employee-profile">
        <div className="employee-profile--header">
          <div className='employee-profile--header__first'>
            <img src={employee.ProfilePicture || require('../../../../resources/dummy-profile-pic-male1.jpg')} alt={`${employee.FirstName} ${employee.LastName}'s profile`} className="profile-picture" />
            <div className="employee-view--column">
              <p className="employeename">{`${employee.FirstName} ${employee.LastName}`}</p>
              <p className="employeeemail">{employee?.Email}</p>
              <p className="employeephone">{employee.PhoneNumber}</p>
            </div>
          </div>
          <div className="availability-container">
            <div>
              <span className="status-indicator--firm-employee" style={{ backgroundColor: getStatusColor('Available') }}></span>
              {t('Available')}
            </div>
            <p><strong></strong> {employee.CompanyRole}</p>
          </div>
        </div>

        <div className="employee-view--row">
          <div style={{ display: 'flex', flexDirection: 'column', gap: 5 }}>
            <p>{t('Practice Area')}</p>
            <div className="employee-profile__practicearea--wrapper">
              <span>{(employee.PracticeArea !== " ") ? employee.PracticeArea : 'None'}</span>
            </div>
          </div>
          <div style={{ display: 'flex', flexDirection: 'column', gap: 5 }}>
            <div>
              <p>{t('Hire Date')}</p>
              <p style={{ color: '#64d2ff' }}>{employee.CreationDate}</p>
            </div>
            <p>{employee.City}</p>
          </div>
        </div>
      </div>

      <div className="firm-tasks">
        <div className="firm-tasks--header">
          <h3>{location.pathname === '/marketplace/lawyer/profile' ? t('Assigned Cases') : t('Assigned Tasks')}</h3>
          {location.pathname !== '/marketplace/lawyer/profile' && (
            <button onClick={handleAddTaskClick} className="add-task-button">
              {t('Add Task')}
            </button>
          )}
        </div>

        {location.pathname === '/marketplace/lawyer/profile' ? (
          // Display cases if URL is '/marketplace/lawyer/profile'
          cases && cases.length > 0 ? (
            <ul className='firm-tasks--ul'>
              {cases.map((caseItem: ICaseItem | IAdminCaseItem) => (
                <div className='firm-cases--item' key={caseItem.CaseID}>
                  <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                    <h2 className='firm-case--title'>{caseItem.CaseType}</h2>
                    <p className='firm-case--budget'>{caseItem.MaxBudget}</p>
                    {/* <p className='firm-task--deadline'>{caseItem.Urgency}</p> */}
                  </div>
                  <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                    <p className='firm-case--status'>{caseItem.Status}</p>
                  <button onClick={() => handleMyCaseClick(caseItem)} className='firm-case--cta-button'>{t('View case')}</button>
                  </div>
                </div>
              ))}
            </ul>
          ) : isLoading ? (
            <div style={{ height: '20vh' }} className='non-access'>
              <ClipLoader size={20} color='#64d2ff' />
            </div>
          ) : (
            <div style={{ height: '20vh' }} className='non-access'>{t('No cases assigned')}</div>
          )
        ) : (
          // Display tasks if URL is not '/marketplace/lawyer/profile'
          tasks && tasks.length > 0 ? (
            <ul className='firm-tasks--ul'>
              {tasks.map(task => (
                <li key={task.TaskID}>
                  <div>
                    <h2 className='firm-task--title'>{task.TaskTitle}</h2>
                    <p className='firm-task--desc'>{task.TaskDescription}</p>
                    <p className='firm-task--deadline'>{task.TaskDeadline}</p>
                  </div>
                  <div className="firm-tasks-actions">
                    <button onClick={() => handleCompleteTask(task.TaskID)}>{t('Complete')}</button>
                    <button onClick={() => handleDeleteTask(task.TaskID)}>{t('Delete')}</button>
                  </div>
                </li>
              ))}
            </ul>
          ) : isLoading ? (
            <div style={{ height: '20vh' }} className='non-access'>
              <ClipLoader size={20} color='#64d2ff' />
            </div>
          ) : (
            <div style={{ height: '20vh' }} className='non-access'>{t('No tasks assigned')}</div>
          )
        )}
      </div>

      {showAddTask && (
        <div className="add-task-section">
          <AddTask selectedEmployeeEmail={employee.Email} onCancel={() => setShowAddTask(false)} onAdd={onAddTask} />
        </div>
      )}

      <div ref={endRef}></div>
    </div>
  );
};

export default EmployeeView;

function setSelectedMyCase(caseId: any) {
  throw new Error('Function not implemented.');
}
