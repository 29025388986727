import React, { useEffect, useState } from 'react';
import './ManageTeam.css';
import { useTranslation } from 'react-i18next';
import CustomDropdownLCM from '../../../CustomDropdownLCM.tsx/CustomDropdownLCM';
import { useAppDispatch } from '../../../../store';
import { getUserID } from '../../../../store/actions/ChatAction';
import { getLegalSubjects, getLegalTeams } from '../../../../store/actions/LawfirmActions';

export interface Member {
  MemberID: string;
  MemberFirstName: string;
  MemberLastName: string;
  MemberRole: string;
  MemberType: string;
}

export interface Team {
  LegalTeamID: string;
  TeamName: string;
  TeamLeadID: string;
  TeamLeadFirstName: string;
  TeamLeadLastName: string;
  TeamLeadType: string;
  Members: Member[];
  LegalSubjects: { LegalSubjectID: string; LegalSubjectName: string }[];
  Cases: string[];  
  CreationDate: string;
  LawfirmID: string;
}

interface ManageTeamProps {
  teams: Team[];
  updateTeam: (team: Team) => void;
  deleteTeam: (teamId: string) => void;
  endOfComponentRef: React.RefObject<HTMLDivElement>;
  allMembers: Member[];
  subjects: any[];
}

const ManageTeam: React.FC<ManageTeamProps> = ({ teams, updateTeam, deleteTeam, endOfComponentRef, allMembers, subjects }) => {
  const [selectedTeam, setSelectedTeam] = useState<Team | null>(null);
  const [teamName, setTeamName] = useState('');
  const [teamLead, setTeamLead] = useState('');
  const userID = getUserID();
  const dispatch = useAppDispatch()
  const { t } = useTranslation();

  useEffect(() => {
    if (userID) {
      dispatch(getLegalSubjects())
      dispatch(getLegalTeams(userID))
    }
  }, [dispatch, userID]);


  const handleSelectTeam = (team: Team) => {
    setSelectedTeam({
      ...team,
      Members: team.Members || [],
      LegalSubjects: team.LegalSubjects || [],
      Cases: team.Cases || [],
    });
    setTeamName(team.TeamName);
    setTeamLead(team.TeamLeadFirstName + ' ' + team.TeamLeadLastName);
    setTimeout(() => {
      endOfComponentRef.current?.scrollIntoView({ behavior: 'smooth' });
    }, 0);
  };

  const handleUpdateTeam = (e: React.FormEvent) => {
    e.preventDefault();
    if (selectedTeam) {
      updateTeam({
        ...selectedTeam,
        TeamName: teamName,
        TeamLeadID: teamLead
      });
    }
  };

  const handleDeleteTeam = () => {
    if (selectedTeam) {
      deleteTeam(selectedTeam.LegalTeamID);
      setSelectedTeam(null);
      setTeamName('');
      setTeamLead('');
    }
  };

  const handleRemoveMember = (memberId: string) => {
    if (selectedTeam) {
      const updatedMembers = selectedTeam.Members.filter(member => member.MemberID !== memberId);
      const updatedTeamLead = teamLead === selectedTeam.Members.find(member => member.MemberID === memberId)?.MemberFirstName ? '' : teamLead;

      setSelectedTeam({
        ...selectedTeam,
        Members: updatedMembers,
        TeamLeadID: updatedTeamLead
      });

      updateTeam({
        ...selectedTeam,
        Members: updatedMembers,
        TeamLeadID: updatedTeamLead
      });
    }
  };

  const handleAddMember = (memberName: string | string[]) => {
    if (selectedTeam && typeof memberName === 'string') {
      const memberToAdd = allMembers.find(member => member.MemberFirstName + ' ' + member.MemberLastName === memberName);
      if (memberToAdd && !selectedTeam.Members.find(member => member.MemberID === memberToAdd.MemberID)) {
        const updatedMembers = [...selectedTeam.Members, memberToAdd];
        setSelectedTeam({
          ...selectedTeam,
          Members: updatedMembers
        });

        updateTeam({
          ...selectedTeam,
          Members: updatedMembers
        });
      }
    }
  };

  const handleRemoveSubject = (subject: { LegalSubjectID: string; LegalSubjectName: string }) => {
    if (selectedTeam) {
      const updatedSubjects = selectedTeam.LegalSubjects.filter(sub => sub !== subject);

      setSelectedTeam({
        ...selectedTeam,
        LegalSubjects: updatedSubjects
      });

      updateTeam({
        ...selectedTeam,
        LegalSubjects: updatedSubjects
      });
    }
  };

  const handleAddSubject = (subject: { LegalSubjectID: string; LegalSubjectName: string }[]) => {
    if (selectedTeam) {
      const updatedSubjects = [...selectedTeam.LegalSubjects, ...subject];
      setSelectedTeam({
        ...selectedTeam,
        LegalSubjects: updatedSubjects
      });

      updateTeam({
        ...selectedTeam,
        LegalSubjects: updatedSubjects
      });
    }
  };

  return (
    <div className='newteam-container'>
      <h2 style={{ color: '#fff', paddingLeft: 20 }}>{t('Manage existing teams')}</h2>
      <div className='team-list'>
        {teams && teams.length > 0 ? (
          teams.map((team) => (
            <div
              key={team.LegalTeamID}
              className={`team-item ${selectedTeam && selectedTeam.LegalTeamID === team.LegalTeamID ? 'selected' : ''}`}
              onClick={() => handleSelectTeam(team)}
            >
              {team.TeamName}
            </div>
          ))
        ) : (
          <div className='no-teams--placeholder'>{t('No teams')}</div>
        )}
      </div>
      {selectedTeam && (
        <form onSubmit={handleUpdateTeam} className='newteam-grid-container'>
          <div className='team-group'>
            <label htmlFor='team-name' className='form-label'>{t('Team Name')}</label>
            <input
              type='text'
              name='teamName'
              maxLength={70}
              value={teamName}
              onChange={(e) => setTeamName(e.target.value)}
              className={`team-control-input`}
              id='team-name'
            />
          </div>

          <div className='team-group'>
            <CustomDropdownLCM
              label={t('Select Members')}
              options={allMembers.map(val => val.MemberFirstName + ' ' + val.MemberLastName)}
              value={selectedTeam.Members.map(val => val.MemberFirstName + ' ' + val.MemberLastName)}
              onChange={handleAddMember}
              multiSelect={true}
              className={`dropdown-Members`}
            />
          </div>

          <div className='team-group'>
            <CustomDropdownLCM
              label={t('Select Team Lead')}
              options={selectedTeam.Members.map(member => member.MemberFirstName + ' ' + member.MemberLastName)}
              value={teamLead}
              onChange={(value) => setTeamLead(value as string)}
              className={`dropdown-Members`}
            />
          </div>

          <div className='team-group'>
            <CustomDropdownLCM
              label={t('Add Legal Subject')}
              options={subjects.map(sub => sub.LegalSubjectName)}
              value={''}
              onChange={(selectedNames) => {
                const selectedSubjects = subjects.filter(sub => selectedNames.includes(sub.LegalSubjectName));
                handleAddSubject(selectedSubjects);
              }}
              className={`dropdown-Subjects`}
            />
          </div>
          <div className='lawfirm-team--card'>
          <div className='selected-tags'>
            <div style={{ width: '30%' }}>
              <h4>{t('Members')}</h4>
              <div className='tags'>
                {selectedTeam.Members.map((member) => (
                  <span key={member.MemberID} className='tag'>
                    {member.MemberFirstName} {member.MemberLastName}
                    <i className="fas fa-xmark" onClick={() => handleRemoveMember(member.MemberID)}></i>
                  </span>
                ))}
              </div>
            </div>
            <div style={{ width: '30%' }}>
              <h4>{t('Team Leader')}</h4>
              {teamLead && (
                <div className='tags'>
                  <span className='tag' style={{ color: 'rgb(66, 212, 66)' }}>{teamLead}</span>
                </div>
              )}
            </div>
            <div style={{ width: '30%' }}>
              <h4>{t('Legal Subjects')}</h4>
              <div className='tags'>
                {selectedTeam.LegalSubjects?.map((subject, index) => (
                  <span key={index} className='tag'>
                    {subject.LegalSubjectName}
                    <i className="fas fa-xmark" onClick={() => handleRemoveSubject(subject)}></i>
                  </span>
                ))}
              </div>
            </div>
          </div>
          </div>

          <div className='team-details--buttons'>
            <button type="submit">{t('Update Team')}</button>
            <button type="button" onClick={handleDeleteTeam}>{t('Delete Team')}</button>
          </div>
          <div ref={endOfComponentRef} />
        </form>
      )}
    </div>
  );
};

export default ManageTeam;
