import React, { useEffect, useState } from 'react'
import { Line } from 'react-chartjs-2';

interface LineChartProps {
    chartData: any
    chartName: string
    windowWidth: number;
}

const LineChart: React.FC<LineChartProps> = ({ chartData, chartName, windowWidth }) => {
    
    return (
        <div className="" style={{ width: '100%', height: '100%' }} key={windowWidth}>
            <Line
                data={chartData}
                options={{
                    responsive: true,
                    maintainAspectRatio: false,
                    // fill: false,
                    backgroundColor: '#64d2ff',
                    borderColor: '#64d2ff',
                    scales: {
                        x: {
                            grid: {
                                display: true,
                                drawOnChartArea: true,
                                color: '#222'

                            },
                            ticks: {
                                color: 'rgba(255, 255, 255, 1)',
                                font: {
                                    weight: 'bold',
                                },
                            },

                        },
                        y: {
                            beginAtZero: false,
                            grid: {
                                display: true,
                                drawOnChartArea: true,
                                color: '#222'
                            },
                            ticks: {
                                color: 'rgba(255, 255, 255, 1)',
                                font: {
                                    weight: 'bold',
                                },
                            }
                        }
                    },
                    plugins: {
                        title: {
                            display: true,
                            text: chartName
                        },
                        legend: {
                            display: false,
                            position: "bottom"
                        }
                    }
                }}
            />
        </div>
    );
}

export default LineChart
