import React, { useEffect, useState } from 'react';
import './FeedbackWidget.css';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RootState, useAppDispatch } from '../../../store';
import { getUserID, getUserType } from '../../../store/actions/ChatAction';
import { fetchRatings } from '../../../store/actions/marketplace/lawyerAction';
import { Stars } from '../../Marketplace/Client/LawyerProfileModal';
import { ClipLoader } from 'react-spinners';
import { Review } from '../../Marketplace/Lawyer/Admin/LawyerRating';
import { fetchFullCase } from '../../../store/actions/marketplace/caseActions';

const FeedbackWidget: React.FC = () => {
  const dispatch = useAppDispatch();
  const userId = getUserID();
  const userType = getUserType();
  const { t } = useTranslation();
  const reviews = useSelector((state: RootState) => state.lawyers.ratingsData?.AllReviews) || [];
  const [isLoading, setIsLoading] = useState(false);
  const [caseDetails, setCaseDetails] = useState<{ [key: string]: any }>({});

  useEffect(() => {
    if (userId && userType) {
      setIsLoading(true);
      dispatch(fetchRatings(userId, userType)).finally(() => setIsLoading(false));
    }
  }, [userId, userType, dispatch]);

  useEffect(() => {
    if (reviews.length > 0) {
      reviews.forEach((review: Review) => {
        if (!caseDetails[review.CaseID]) {
          dispatch(fetchFullCase(review.CaseID))
            .then((response) => {
              setCaseDetails((prevDetails) => ({
                ...prevDetails,
                [review.CaseID]: response,
              }));
            })
            .catch((error) => {
              console.error(`Error fetching case details for CaseID: ${review.CaseID}`, error);
            });
        }
      });
    }
  }, [reviews, dispatch,caseDetails]);

  return (
    <div className="feedback-Container">
      <h2 className="feedback-title">{t('Client Feedback')}</h2>
      {!isLoading ? (
        <div className="feedback-list">
          {reviews.length > 0 ? (
            reviews.map((review: Review, index: number) => (
              <div key={index} className='feedback-item'>
                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                    <h4>{caseDetails[review.CaseID]?.CaseType || t('Loading case details...')}</h4>
                  <div className="star-rating">
                    <Stars rating={parseInt(review.Rating)} />
                  </div>
                </div>
                  <div>
                    <p>{review.Review}</p>
                  </div>
              </div>
            ))
          ) : (
            <p className="no-tasks">{t('No feedbacks')}</p>
          )}
        </div>
      ) : (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <ClipLoader color="#fff" size={30} />
        </div>
      )}
    </div>
  );
};

export default FeedbackWidget;
