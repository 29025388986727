import React, { useState } from 'react';
import '../../Marketplace.css'
import { useTranslation } from 'react-i18next';
import { ICaseItem } from '../../Interfaces/ICaseItem';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../store';
import { BiddingDataProps, BiddingState } from '../../../../store/reducer/marketplace/bidReducer';
import { IAdminCaseItem } from '../../Interfaces/IAdminCaseItem';

const BidHistory: React.FC<{ caseData: ICaseItem | IAdminCaseItem }> = ({ caseData }) => {
  const [selectedBidIndex, setSelectedBidIndex] = useState<number | null>(null);
  const userInfo = useSelector((state: RootState) => state.user.userInfo)
  const bid = useSelector((state: RootState) => state.bid as BiddingState);
  const bids = bid.biddingData


  const handleBidClick = (index: number) => {
    setSelectedBidIndex(selectedBidIndex === index ? null : index);
  };
  const { t } = useTranslation()

  const getApprovalColor = (approval?: string) => {
    switch (approval) {
      case 'Approved': return 'green';
      case 'Pending': return 'orange';
      case 'Rejected': return '#f44336';
      case 'Case Proposal': return '#1b8ed0';
      default: return '#00BCD4';
    }
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column', flex: 1, overflowY: 'auto', justifyContent: 'space-between' }}>
      <div className='bid-list__lawyer'>
      {bids && bids.length === 0 ? (
              <div className='no-cases-available'>
              <p>{t('No bids found')}</p>
            </div>
            ) : (
        bids.map((bid: BiddingDataProps, index: number) => (
          <React.Fragment key={index}>
            <div className="bid-item__container--lawyer" onClick={() => handleBidClick(index)}>
              <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%' }}>
                <p className='bid-item__lawyer--amount'>{bid.LawyerMinBudget} - {bid.LawyerMinBudget} {userInfo.Currency}</p>
                <p style={{ backgroundColor: getApprovalColor(bid.Status) }} className='bid-approval__status'>{bid.Status}</p>
              </div>
            </div>
            {selectedBidIndex === index && (
              <div className="bid-full-view">
                <div className='bit-item__wrapper'>
                  <div >
                    <p>{t('Message')}</p>
                    <p className='bid-item__message'>{bid.Message}</p>
                  </div>
                </div>
              </div>
            )}
          </React.Fragment>
        ))
      )}
      </div>
    </div>
  );
};

export default BidHistory;
