import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import CustomDropdownLCM from '../../../CustomDropdownLCM.tsx/CustomDropdownLCM';
import './Onboarding.css';
import * as Yup from 'yup';
import { useAppDispatch } from '../../../../store';
import { showPasswordModal } from '../../../../store/actions/modalActions';
import { SignUp } from '../../../../store/actions/SignUpAction';
import { getUserID } from '../../../../store/actions/ChatAction';
import { getAllLawyers } from '../../../../store/actions/LawfirmActions';
import { useFormik } from 'formik';

interface OnboardingProps {
  addEmployee: (employee: Member) => void;
  employeeCount: number;
  employeeLimit: number;
}

export interface NewEmployee {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  role: string;
  practiceArea: string;
  officeLocation: string;
}

export interface Member extends NewEmployee {
  MemberID: string;
  MemberFirstName: string;
  MemberLastName: string;
  MemberRole: string;
  hireDate: string;
  MemberType: string;
  caseStats: {
    totalCases: number;
    successRate: number;
    notableCases: string[];
  };
  clientFeedback: {
    rating: number;
    reviews: string[];
  };
  skills: string[];
  certifications: string[];
  currentProjects: string[];
  availabilityStatus: string;
}

const roles = ['Associate', 'Partner', 'Paralegal', 'Legal Secretary', 'Intern'];
const practiceAreas = ['Corporate Law', 'Criminal Law', 'Family Law', 'Intellectual Property', 'Labor Law', 'Real Estate', 'Environmental Law', 'Tax Law', 'Criminal Defense', 'Immigration'];

const Onboarding: React.FC<OnboardingProps> = ({ addEmployee, employeeCount, employeeLimit }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const userID = getUserID();
  const [newEmployee, setNewEmployee] = useState<NewEmployee>({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    role: '',
    practiceArea: '',
    officeLocation: ''
  });
  const [lawyerLicense, setLawyerLicense] = useState<File | null>(null);


  const formik = useFormik<NewEmployee>({
    initialValues: {
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      role: '',
      practiceArea: '',
      officeLocation: ''
    },
    validationSchema: Yup.object({
      firstName: Yup.string().required(t('First Name is required')),
      lastName: Yup.string().required(t('Last Name is required')),
      email: Yup.string().email(t('Invalid email format')).required(t('Email is required')),
      phone: Yup.string().required(t('Phone is required')),
      role: Yup.string().required(t('Role is required')),
      practiceArea: Yup.string().required(t('Practice Area is required')),
      officeLocation: Yup.string().required(t('Office Location is required'))
    }),
    onSubmit: (values) => {
      dispatch(showPasswordModal(handlePasswordConfirm));
    }
  });

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      setLawyerLicense(e.target.files[0]);
    }
  };

  const handleChange = (field: string, value: string) => {
    setNewEmployee({ ...newEmployee, [field]: value });
  };


  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    dispatch(showPasswordModal(handlePasswordConfirm));
  };

  const handlePasswordConfirm = async (adminPassword: string) => {
    const formData = new FormData();
    formData.append('FirstName', formik.values.firstName);
    formData.append('LastName', formik.values.lastName);
    formData.append('LawfirmID', userID || '');
    formData.append('Email', formik.values.email);
    formData.append('PhoneNumber', formik.values.phone);
    formData.append('StreetAddress', '');
    formData.append('Country', '');
    formData.append('State', '');
    formData.append('City', formik.values.officeLocation);
    formData.append('ZipCode', '');
    formData.append('DateOfBirth', '');
    formData.append('PracticeArea', formik.values.practiceArea);
    formData.append('CountryOfJurisdiction', 'Norway');
    formData.append('DisplayLawfirmName', '');
    formData.append('Password', adminPassword);
    formData.append('CompanyRole', formik.values.role);
    formData.append('AgreeToTerms', 'Yes');
    if (lawyerLicense) {
      formData.append('LawyerLicense', lawyerLicense);
    }

    try {
      await dispatch(SignUp(formData, 'lawFirmLawyer') as any);

      const employee: Member = {
        MemberID: Date.now().toString(),
        MemberFirstName: formik.values.firstName,
        MemberLastName: formik.values.lastName,
        MemberRole: formik.values.role,
        hireDate: new Date().toISOString().split('T')[0],
        MemberType: 'LawfirmLawyer',
        caseStats: {
          totalCases: 0,
          successRate: 0,
          notableCases: []
        },
        clientFeedback: {
          rating: 0,
          reviews: []
        },
        skills: [],
        certifications: [],
        currentProjects: [],
        availabilityStatus: 'Available',
        firstName: formik.values.firstName,
        lastName: formik.values.lastName,
        email: formik.values.email,
        phone: formik.values.phone,
        role: formik.values.role,
        practiceArea: formik.values.practiceArea,
        officeLocation: formik.values.officeLocation,
      };

      addEmployee(employee);
      setNewEmployee({
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        role: '',
        practiceArea: '',
        officeLocation: ''
      });
      setLawyerLicense(null);

      await dispatch(getAllLawyers(userID));
    } catch (error) {
      console.error('Error during onboarding:', error);
    }
  };

  const handleRemoveFile = () => {
    setLawyerLicense(null);
    // Optionally, reset the file input
    (document.getElementById('file-input') as HTMLInputElement).value = '';
  };

  return (
    <div className='onboarding-container'>
      <h2>{t('Onboard New Employee')}</h2>
      <form onSubmit={handleSubmit} className='onboarding-form'>
        <div className='onboarding-form-group__row'>
          <div className='onboarding-form-group'>
            <label>{t('First Name')}</label>
            <input
              type='text'
              value={newEmployee.firstName}
              onChange={(e) => handleChange('firstName', e.target.value)}
            />
          </div>
          <div className='onboarding-form-group'>
            <label>{t('Last Name')}</label>
            <input
              type='text'
              value={newEmployee.lastName}
              onChange={(e) => handleChange('lastName', e.target.value)}
            />
          </div>
        </div>
        <div className='onboarding-form-group__row'>
          <div className='onboarding-form-group'>
            <label>{t('Email')}</label>
            <input
              type='email'
              value={newEmployee.email}
              onChange={(e) => handleChange('email', e.target.value)}
            />
          </div>
          <div className='onboarding-form-group'>
            <label>{t('Phone Number')}</label>
            <input
              type='text'
              value={newEmployee.phone}
              onChange={(e) => handleChange('phone', e.target.value)}
            />
          </div>
        </div>
        <div className='onboarding-form-group'>
        <label>{t('Office Location')}</label>
        <input
              type='text'
              value={newEmployee.officeLocation}
              onChange={(e) => handleChange('officeLocation', e.target.value)}
            />
        </div>
        <div className='onboarding-form-group'>
          <CustomDropdownLCM
            label={t('Role')}
            options={roles}
            value={newEmployee.role}
            onChange={(value) => handleChange('role', value as string)}
          />
        </div>
        <div className='onboarding-form-group'>
          <CustomDropdownLCM
            label={t('Practice Area')}
            options={practiceAreas}
            value={newEmployee.practiceArea}
            onChange={(value) => handleChange('practiceArea', value as string)}
          />
        </div>
        <div className='onboarding-form-group'>
      <label>{t('Lawyer License')}</label>
      <input
        type='file'
        id='file-input'
        style={{ display: 'none' }} 
        onChange={handleFileChange}
      />
      <label htmlFor='file-input' className='custom-file-upload--onboard'>
        {t('Upload Lawyer License')}
      </label>

      {lawyerLicense && (
        <div className='file-details'>
          <span>{lawyerLicense.name}</span>
          <i onClick={handleRemoveFile} className='remove-file-btn fas fa-xmark'>
            
          </i>
        </div>
      )}
    </div>
        <div style={{ width: '100%', justifyContent: 'center', display: 'flex' }}>
          <button type='submit'>{t('Add Employee')}</button>
        </div>
      </form>
      <div className='employee-count'>
        <p>{t('Employee Count')}: {employeeCount} / {employeeLimit}</p>
        {/* <p>{t('Employees Left in Package')}: {employeeLimit - employeeCount}</p> */}
      </div>
    </div>
  );
};

export default Onboarding;
