import { useEffect, useState } from 'react';
import '../Settings.css';
import ConfirmationDialog from '../ConfirmationDialog';
import { useTranslation } from 'react-i18next';
import { RootState, useAppDispatch } from '../../../store';
import { hideModal, showModal } from '../../../store/actions/modalActions';
import { addSignature } from '../../../store/actions/DocumentsAction';
import { getUserID, getUserType } from '../../../store/actions/ChatAction';
import { useSelector } from 'react-redux';
import { disable2FA, generateQr, signOut, verify2faQr } from '../../../store/actions/SignInAction';
import { useHistory } from 'react-router-dom';
import { loadStripe } from '@stripe/stripe-js';
import VerifyButton from './StripeVerificationButton';
import { ClipLoader } from 'react-spinners';
import { userInformation } from '../../../store/actions/DataAction';
import SignatureModal from './DigitalSignature';

const stripePromise = loadStripe('pk_test_51NUAIpCKZInHFLiaoqKLdIpmsng4qX2W0ylQG7C7SvB6jAmLmUw3brzQUbMdBjHWpPTGyGJFfmbEYIYsZjGvARMu0000tqTUgw');


const SecuritySettings = () => {
    const [confirmModalOpen, setConfirmModalOpen] = useState(false);
    const [deviceToSignOut, setDeviceToSignOut] = useState(null);
    const dispatch = useAppDispatch();
    const [inputData, setInputData] = useState('');
    const [signature, setSignature] = useState<string | null>(null);
    const userId = getUserID()
    const userType = getUserType()
    const { t } = useTranslation()
    const userInfo = useSelector((state: RootState) => state.user.userInfo)
    const [isVerified, setIsVerified] = useState(false)
    const [message, setMessage] = useState('');
    const history = useHistory();
    const [loading, setLoading] = useState(false);

    useEffect(() => {

        if (isVerified) {
            const timeoutId = setTimeout(() => {
                dispatch(signOut()).then(() => {
                    history.push('/');
                });
            }, 5000); 

            return () => {
                clearTimeout(timeoutId);
            };
        }

    }, [isVerified, dispatch, history])

    const confirmSignOut = () => {
        if (deviceToSignOut !== null) {
            handleSignOut(deviceToSignOut);
        }
        setConfirmModalOpen(false);
    };

    const cancelSignOut = () => {
        setConfirmModalOpen(false);
    };

    const handleSignOutConfirm = (deviceIndex: any) => {
        setDeviceToSignOut(deviceIndex);

        dispatch(showModal({
            type: 'alert',
            message: 'Are you sure you want to sign out from this device?',
            onConfirm: (index: File | string) => {
                if (index !== null) {
                    handleSignOut(deviceToSignOut);
                }
            },
            onCancel: handleCancel,
            inputData: deviceIndex,
            showModal: true,
        }));
    };


    const [devices, setDevices] = useState([
        { name: 'MacBook Pro', type: 'mac', lastActive: 'Today, 10:00 AM', location: 'Oslo, Norway' },
        { name: 'iPhone 12', type: 'mobile', lastActive: 'Yesterday, 8:00 PM', location: 'Stockholm, Sweden' },
        { name: 'Windows 13', type: 'windows', lastActive: '09.11.2023, 16:00 AM', location: 'Oslo, Norway' },
    ]);

    const handleSignOut = (deviceIndex: any) => {
        setDevices(devices.filter((_, index) => index !== deviceIndex));
    };


    const handleEkyc = async () => {
        setLoading(true)
        try {

            const response: any = await dispatch(generateQr(userId, userType, userInfo.Email))
            
            if (response) {
                setLoading(false)
                dispatch(showModal({
                    type: "2fa-qrcode",
                    message: "Enable Two-Factor Authentication (2FA)",
                    onConfirm: async (verificationCode: File | string) => {
                        if (verificationCode) {
                            const resp: any = await dispatch(verify2faQr(verificationCode, userId, userType))
                            if (resp) {
                                if (resp.code === "E0044") {
                                    setMessage(`${resp.message}! Please ensure you have scan the qr and entered code correctly!`)
                                    setConfirmModalOpen(true);

                                } else {
                                    setIsVerified(true)
                                    dispatch(hideModal())
                                    setMessage(`${resp.message}! You will be automatically signed out in 5 sec !`)
                                    setConfirmModalOpen(true);
                                }
                            }

                        } else {
                            setConfirmModalOpen(true);
                            setMessage(`Please enter verification code to enable two factor authentication (2FA)!`)
                        }
                    },
                    onCancel: handleCancel,
                    showModal: true,
                    inputData: response.QR
                }))
            }

        } catch (err) {
            setLoading(false)
        } finally {
            setLoading(false)
        }

    }


    const handleSignatureClick = () => {
        dispatch(showModal({
            type: 'addSignature',
            message: 'Your signature',
            onConfirm: (signatureFile: File | string) => {
                if (typeof signatureFile === 'object') {
                    dispatch(addSignature(userId, userType, signatureFile));
                }
            },
            onCancel: handleCancel,
            showModal: true,
        }));
    };

    const handleCancel = () => {
        dispatch(hideModal());
    };


    const handleDisable2fa = async () => {
        dispatch(disable2FA(userId,userType))
        dispatch(userInformation(userType))
    }

    
    
      const handleSaveSignature = (signature: string) => {
        setSignature(signature);
      };
    
      const handleConfirm = () => {
            const signatureBlob = new Blob([inputData], { type: 'image/svg+xml' });
            const signatureFile = new File([signatureBlob], 'signature.svg', { type: 'image/svg+xml' });
      }
    

    return (
        <>
            <ConfirmationDialog
                isOpen={confirmModalOpen}
                onClose={cancelSignOut}
                onConfirm={confirmSignOut}
                message={t(`${message}`)}
            />
            <div className="security-settings-container">
                <div className="security-verification-wrapper">
                    <div className="security-container">

                        <h2 style={{ padding: 15 }}>{t('Security')}</h2>

                            {/* <div className="security-option">
                                <div>
                                    <p className="option-title">{t('Face Recognition')}</p>
                                    <span className="option-description">{t('Biometric security for mobile devices. Fast and secure access.')}</span>
                                </div>

                                <Toggle
                                    checked={faceRecognition}
                                    onChange={setFaceRegocnition}
                                    onLabel=''
                                    offLabel=''
                                    colorScheme='custom'
                                />

                            </div> */}
                                <div className='verification-options'>
                                <div className='settings-options--wrapper security'>
                            <div className="security-option">
                                <div>
                                    <p className="option-title">{t('Password')}</p>
                                    <span className="option-description">{t('Create a unique, strong password for account protection.')}</span>
                                </div>

                                <button className="dummy-btn">{t('Reset')}</button>
                            </div>
                            <div className="security-option">
                                <div>
                                    <p className="option-title">{t('Two-factor Authentication [2FA]')}</p>
                                    <span className="option-description">{t('Enhanced security layer with two-step verification process.')}</span>

                                </div>
                                {
                                    userInfo.Enable2FA === "Yes" ? (<button className="dummy-btn" onClick={() => handleDisable2fa()}>{t('Disable')}</button>) : (<button className="dummy-btn" onClick={() => handleEkyc()}>{t('Enable')} {loading && <ClipLoader color={"#64d2ff"} size={10} />}</button>)
                                }
                            </div>
                            </div>
                            <h2 style={{ marginTop: 15, marginBottom: 10}}>{t('Verification')}</h2>
                            <div className='settings-options--wrapper verification'>
                            <div className="verification-option">
                                <div className='data-section'>
                                    <p className='option-title'>{t('Verify your identity')}</p>
                                    <span>{t('Identity verification for secure and compliant platform access.')}</span>
                                </div>
                                <VerifyButton stripePromise={stripePromise} />
                            </div>
                            <div className="verification-option">
                                <div className='data-section'>
                                    <p className='option-title'>{t('Email')}</p>
                                    <span>{t('Confirm your email for secure communication and account recovery.')}</span>
                                </div>
                                <button className="dummy-btn">{t('Verify')}</button>
                            </div>
                            <div className="verification-option">
                                <div className='data-section'>
                                    <p className='option-title'>{t('SMS Verification')}</p>
                                    <span>{t('Link mobile number for additional security and alerts.')}</span>
                                </div>
                                <button className="dummy-btn">{t('Verify')}</button>
                            </div>
                            {/* <div className="verification-option">
                                <div className='data-section'>
                                    <p className='option-title'>{t('Digital Signature')}</p>
                                    <span>{t('Confirm your email for secure communication and account recovery.')}</span>
                                </div>
                                <button onClick={() => handleSignatureClick()} className="dummy-btn">{t('Verify')}</button>
                            </div> */}
                            </div>
                        </div>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', height: 'calc(100vh - 120px)', gap: 10, flex: 0.5 }}>
                    <div className="verification-container">
                    <SignatureModal
                            onSave={handleSaveSignature}
                            userId={userId}
                            userType={userType}
                            onConfirm={handleConfirm}
              />
                    </div>
                <div className="device-management-container">
                <div className="device-management-container--header">
                    <h2>{t('Device Management')}</h2>
                    <button
                                    className="sign-out-btn"
                                    // onClick={() => handleSignOutConfirm(index)}
                                >
                                    {t('Sign out of all devices')}
                                </button>
                                </div>
                    <span style={{ fontSize: '0.9rem' }} className='device-management-container--p'>{t('Devices that have been active on your account in the last 30 days, or are currently signed in.')}</span>
                    <div className='dummy-devices'>
                        {devices.map((device, index) => (
                            <div key={index} className="dummy-device">
                                {/* {device.type === 'mobile' && <img src={iphone} alt="iPhone" className="dummy-device-icon" />}
                                {device.type === 'mac' && <img src={mac} alt="MacBook" className="dummy-device-icon" />}
                                {device.type === 'windows' && <img src={microsoft} alt="Windows Laptop" className="dummy-device-icon" />} */}

                                <div className="dummy-device-info">
                                    <span>{device.name}</span>
                                    <p>{device.lastActive}</p>
                                    <p>{device.location}</p>
                                </div>
                                <button
                                    className="sign-out-btn"
                                    onClick={() => handleSignOutConfirm(index)}
                                >
                                    {t('Sign out')}
                                </button>
                            </div>
                        ))}
                    </div>
                    </div>
                </div>
                </div>


            </div>
        </>
    );
};

export default SecuritySettings;
