import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { Provider } from 'react-redux';
import store from './store/index'
import reportWebVitals from './reportWebVitals';
import { ThemeProvider } from '@mui/material';
import theme from './components/Themes';
import { PublicClientApplication } from '@azure/msal-browser';
import './i18n/i18n'
import ErrorBoundary from './ErrorBoundaryProps';

const msalConfig = {
  auth: {
    clientId: '0bd26934-d21d-4902-af1e-75a5a3513e6e',
    authority: 'https://login.microsoftonline.com/35100afd-c1b4-4a57-86f0-22db19cc815b',
    redirectUri: '/',
  }
};

const pca = new PublicClientApplication(msalConfig);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <React.StrictMode>
    <ErrorBoundary>
      <ThemeProvider theme={theme}>
        <Provider store={store}>

          <App />
        </Provider>
      </ThemeProvider>
    </ErrorBoundary>
  </React.StrictMode >
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
