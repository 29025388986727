
import { useEffect, useState } from 'react';
import './AdminBusiness.css'
import BusinessPieChart from '../../Charts/AdminBusinessCharts/BusinessPieChart';
import SpendingChart, { ChartSeries, SpendingData } from '../../Charts/AdminBusinessCharts/SpendingsChart';
import BudgetOverview from './BusinessComponents.tsx/BudgetOverview';
import { useTranslation } from 'react-i18next';
import SuggestedFilesContainer from '../../Documents/SuggestedFilesContainer';
import { FileProps } from '../../../pages/DocumentsPage';
import { useHistory } from 'react-router-dom';
import { useAppDispatch } from '../../../store';
import { selectDocumentForEditing } from '../../../store/actions/DocumentsAction';

interface CaseStatuses {
    open: number;
    closed: number;
    inProgress: number;
    rejected: number;
    resolved: number
}

interface EmployeePerformance {
    id: number;
    name: string;
    tasksCompleted: number;
    averageResolutionTime: number;
    efficiencyRating: number;
}

interface UtilizationRates {
    consultation: number;
    litigation: number;
    documentation: number;
}

interface Milestone {
    date: string;
    description: string;
}


interface LegalActivityData {
    caseStatuses: CaseStatuses;
    utilizationRates: UtilizationRates;
    recentMilestones: Milestone[];
}




const randomID = () => Math.floor(Math.random() * 1000).toFixed(0);
const randomName = () => `Case ${Math.random().toString(36).substr(2, 5)}`;
const randomCategory = () => ["Civil", "Criminal", "Financial", "Family", "Labor"][Math.floor(Math.random() * 5)];
const randomStatus = () => ["Open", "Closed", "Pending", "Resolved", "Rejected"][Math.floor(Math.random() * 5)];



const generateRandomData = (num: number) => {
    return Array.from({ length: num }, () => ({
        caseID: randomID(),
        caseName: randomName(),
        caseCategory: randomCategory(),
        caseStatus: randomStatus(),
    }));
};

const AdminBusiness = () => {
    const [isPanelOpen, setIsPanelOpen] = useState<boolean>(false);
    const [isServicePanelOpen, setIsServicePanelOpen] = useState<boolean>(false);
    const [isEditPanelOpen, setIsEditPanelOpen] = useState<boolean>(false);
    const dispatch = useAppDispatch()
    const history = useHistory();
    const {t} = useTranslation()

    const employees = [
        { 
            fullName: "Alice Johnson", 
            email: "alice.johnson@example.com", 
            role: "Manager", 
            department: "Sales", 
            phone: "123-456-7890", 
            status: "Active", 
            services: [
                { name: "Legal Advisor Inquiries", usage: 80 },
                { name: "Contract Analysis", usage: 60 },
                { name: "Company Agent Token Usage", usage: 50 }
            ]
        },
        { 
            fullName: "Bob Smith", 
            email: "bob.smith@example.com", 
            role: "Developer", 
            department: "IT", 
            phone: "123-456-7891", 
            status: "Active", 
            services: [
                { name: "Contract Generators", usage: 70 },
                { name: "Legal Case Mappings", usage: 40 }
            ]
        },
        { 
            fullName: "Carol White", 
            email: "carol.white@example.com", 
            role: "Designer", 
            department: "Marketing", 
            phone: "123-456-7892", 
            status: "Inactive", 
            services: [
                { name: "Legal Advisor Inquiries", usage: 30 },
                { name: "Company Agent Token Usage", usage: 20 }
            ]
        },
        { 
            fullName: "David Brown", 
            email: "david.brown@example.com", 
            role: "Tester", 
            department: "Quality Assurance", 
            phone: "123-456-7893", 
            status: "Active", 
            services: [
                { name: "Legal Case Mappings", usage: 90 },
                { name: "Contract Analysis", usage: 50 }
            ]
        },
        { 
            fullName: "Eva Green", 
            email: "eva.green@example.com", 
            role: "HR", 
            department: "Human Resources", 
            phone: "123-456-7894", 
            status: "Active", 
            services: [
                { name: "Legal Advisor Inquiries", usage: 70 },
                { name: "Contract Generators", usage: 60 },
                { name: "Company Agent Token Usage", usage: 40 }
            ]
        },
        { 
            fullName: "Frank Wilson", 
            email: "frank.wilson@example.com", 
            role: "Support", 
            department: "Customer Service", 
            phone: "123-456-7895", 
            status: "Inactive", 
            services: [
                { name: "Contract Analysis", usage: 20 },
                { name: "Legal Case Mappings", usage: 10 }
            ]
        },
        { 
            fullName: "Grace Lee", 
            email: "grace.lee@example.com", 
            role: "Product Manager", 
            department: "Product", 
            phone: "123-456-7896", 
            status: "Active", 
            services: [
                { name: "Contract Generators", usage: 80 },
                { name: "Legal Advisor Inquiries", usage: 60 },
                { name: "Company Agent Token Usage", usage: 30 }
            ]
        },
        { 
            fullName: "Hank Turner", 
            email: "hank.turner@example.com", 
            role: "Sales", 
            department: "Sales", 
            phone: "123-456-7897", 
            status: "Active", 
            services: [
                { name: "Legal Case Mappings", usage: 50 },
                { name: "Contract Analysis", usage: 70 },
                { name: "Company Agent Token Usage", usage: 40 }
            ]
        }
    ];
    
    

    const togglePanel = () => {
        setIsPanelOpen(!isPanelOpen);
    };

    const toggleServicePanel = () => {
        setIsServicePanelOpen(!isServicePanelOpen);
    };

    const toggleEditPanel = () => {
        setIsEditPanelOpen(!isEditPanelOpen);
    };

    const data: LegalActivityData = {
        caseStatuses: {
            open: 120,
            closed: 95,
            inProgress: 50,
            rejected: 30,
            resolved: 10
        },
        utilizationRates: {
            consultation: 75,
            litigation: 60,
            documentation: 85
        },
        recentMilestones: [
            { date: "2024-03-25", description: "Opened new public interest litigation case #789" },
            { date: "2024-04-01", description: "Successfully negotiated settlement in case #123" },
            { date: "2024-03-28", description: "Completed critical discovery phase in case #456" },
            { date: "2024-03-25", description: "Opened new public interest litigation case #789" },
            { date: "2024-03-25", description: "Opened new public interest litigation case #789" },
            { date: "2024-04-01", description: "Successfully negotiated settlement in case #123" },
            { date: "2024-03-28", description: "Completed critical discovery phase in case #456" },
            { date: "2024-03-25", description: "Opened new public interest litigation case #789" },
            { date: "2024-03-28", description: "Completed critical discovery phase in case #456" },
            { date: "2024-03-25", description: "Opened new public interest litigation case #789" },
            { date: "2024-03-25", description: "Opened new public interest litigation case #789" },
            { date: "2024-04-01", description: "Successfully negotiated settlement in case #123" },
            { date: "2024-03-28", description: "Completed critical discovery phase in case #456" },
            { date: "2024-03-25", description: "Opened new public interest litigation case #789" },
            { date: "2024-03-28", description: "Completed critical discovery phase in case #456" },
            { date: "2024-03-25", description: "Opened new public interest litigation case #789" },
            { date: "2024-03-25", description: "Opened new public interest litigation case #789" },
            { date: "2024-04-01", description: "Successfully negotiated settlement in case #123" },
            { date: "2024-03-28", description: "Completed critical discovery phase in case #456" },
            { date: "2024-03-25", description: "Opened new public interest litigation case #789" },
        ]
    };

    const spendingData: SpendingData[] = [
        { month: 'January', spent: 3000, budget: 5000 },
        { month: 'February', spent: 10000, budget: 10000 },
        { month: 'March', spent: 4500, budget: 7000 },
        { month: 'April', spent: 3000, budget: 5000 },
        { month: 'May', spent: 7500, budget: 10000 },
        { month: 'June', spent: 2500, budget: 7000 },
        { month: 'July', spent: 3000, budget: 5000 },
        { month: 'August', spent: 7000, budget: 10000 },
        { month: 'September', spent: 4500, budget: 7000 },
        { month: 'October', spent: 3000, budget: 5000 },
        { month: 'November', spent: 8000, budget: 10000 },
        { month: 'December', spent: 1500, budget: 7000 },
    ];

    const handleDocumentClick = (document: FileProps) => {
        dispatch(selectDocumentForEditing(document));
        history.push('/documents');
    };


    return (
        <div className="business-admin-main__container">
            <div className='business-container--section-row'>
                <div className="business-container" style={{ flex: 1, height: 'calc(100vh / 2.5)' }}>
                    <div className='bussiness-header-container'>
                        <span>{t('Task list')}</span>
                    </div>
                    </div>
                <div style={{ flex: 1, height: 'calc(100vh / 2.5)', padding: 10, backgroundColor: '#333', borderRadius: 8 }}>
                <SuggestedFilesContainer setSelectedFile={handleDocumentClick} />
                </div>
                </div>
                <div className='business-container'>
                <div className='bussiness-header-container'>
                    <span>{t('Service usage and allocation')}</span>
                    <button onClick={toggleServicePanel}>{t('Allocate Services')}</button>
                </div>
                <div className='business-container-body'>
                    <div className='sub-business-container-body'>
                        <div className='sub-business-container-body-chart'>
                            {/* <div className='bussiness-header-container'>
                                <span>{t('Service Utilization Rates')}</span>
                            </div> */}
                            <div className="service-usage-overview">
                                {employees.map((employee) => (
                                    <div key={employee.fullName} className="service-usage-item">
                                        <span>{employee.fullName}</span>
                                        {employee.services.map((service, index) => (
                                            <div key={index} className="service-detail">
                                                <span>{service.name}: {service.usage}</span>
                                            </div>
                                        ))}
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="business-container employees">
                            <div className='bussiness-header-container'>
                                <span>{t('Employee Management')}</span>
                                <button onClick={togglePanel}>{t('Add')}</button>
                            </div>
                            <div className='business-employee--wrapper'>
                                <div className="business-employee--header-row">
                                    <span>{t('Name')}</span>
                                    <span>{t('Role')}</span>
                                    <span>{t('Email')}</span>
                                    <span>{t('Department')}</span>
                                    <span>{t('Phone')}</span>
                                    <span>{t('Status')}</span>
                                </div>
                                <div className='business-employee--column'>
                                    {employees.map((employee, index) => (
                                        <div key={index} className="business-employee--item">
                                            <span>{employee.fullName}</span>
                                            <span>{employee.role}</span>
                                            <span>{employee.email}</span>
                                            <span>{employee.department}</span>
                                            <span>{employee.phone}</span>
                                            <span>{employee.status}</span>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>

            <div className='business-management-container-for-budgets-tracking'>

            </div>

            <div className={`side-panel ${isPanelOpen ? 'open_panel' : ''}`}>
                <button className="close-btn" onClick={() => togglePanel()}>&times;</button>
                <h1 style={{ textAlign: "center", color: "#fff", width: "fit-content", alignSelf: "center" }}>{t('Add Employee')} </h1>
                <div style={{ minHeight: 300, overflowY: "auto", padding: 10, scrollbarWidth: 'none' }}>
                </div>
            </div>

            <div className={`side-panel ${isServicePanelOpen ? 'open_panel' : ''}`}>
                <button className="close-btn" onClick={toggleServicePanel}>&times;</button>
                <h1 style={{ textAlign: "center", color: "#fff", width: "fit-content", alignSelf: "center" }}>{t('Allocate Services')}</h1>
                <div style={{ minHeight: 300, overflowY: "auto", padding: 10, scrollbarWidth: 'none' }}>
                    {/* Service Allocation Form */}
                    <div>
                        <label>{t('Service Name')}</label>
                        <input type="text" placeholder={t('Enter service name')} />
                    </div>
                    <div>
                        <label>{t('Usage Percentage')}</label>
                        <input type="number" placeholder={t('Enter usage percentage')} />
                    </div>
                    <button>{t('Allocate')}</button>
                </div>
            </div>

            <div className={`side-panel ${isEditPanelOpen ? 'open_panel' : ''}`}>
                <button className="close-btn" onClick={() => toggleEditPanel()}>&times;</button>
                <h1 style={{ textAlign: "center", color: "#fff", width: "fit-content", alignSelf: "center" }}>{t('Edit Employee')} </h1>
                <div style={{ minHeight: 300, overflowY: "auto", padding: 10, scrollbarWidth: 'none' }}>
                </div>
            </div>
        </div>
    )


}


export default AdminBusiness;