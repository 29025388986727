import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from "react-i18next";
import { ICaseItem } from "../../Interfaces/ICaseItem";
import { getUserID, getUserType } from "../../../../store/actions/ChatAction";
import { BidDetails, submitBid } from "../../../../store/actions/marketplace/lawyerAction";
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useAppDispatch } from '../../../../store/index';

const PublishedCaseModal: React.FC<{ isOpen: boolean; onClose: () => void; caseDetails: ICaseItem }> = ({ isOpen, onClose, caseDetails }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const userType = getUserType();

  const [isBidFormVisible, setIsBidFormVisible] = useState(false);
  const endOfComponentRef = useRef<HTMLDivElement>(null);

  const formatNumber = (value: string) => {
    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
  };

  const getSchemaValidation = () => {
    return Yup.object().shape({
      Message: Yup.string().required("Message is required"),
      BidType: Yup.string().oneOf(['fullAmount', 'HourlyRates']),
      BidAmount: Yup.number().when('BidType', {
        is: 'fullAmount',
        then: () => Yup.number().required("Full bid amount required"),
        otherwise: () => Yup.number().notRequired(),
      }),
      HourlyRates: Yup.number().when('BidType', {
        is: 'fullAmount',
        then: () => Yup.number().notRequired(),
        otherwise: () => Yup.number().required("Hourly rates required"),
      }),
      MaxHours: Yup.number().when('BidType', {
        is: 'fullAmount',
        then: () => Yup.number().notRequired(),
        otherwise: () => Yup.number().required("Maximum hours required"),
      }),
    });
  };

  const validationSchema = getSchemaValidation();

  const formik = useFormik({
    initialValues: {
      Message: "",
      BidType: "fullAmount",
      BidAmount: "",
      HourlyRates: "",
      MaxHours: "",
      LawyerBudgetCurrency: "USD",
    },
    enableReinitialize: true,
    validationSchema: validationSchema,
    onSubmit: async (values, { resetForm }) => {
      const minAmount = values.BidType === 'fullAmount' ? values.BidAmount : (parseFloat(values.HourlyRates) * parseFloat(values.MaxHours)).toString();
      const maxAmount = values.BidType === 'fullAmount' ? values.BidAmount : values.HourlyRates;
      const bidDetails: BidDetails = {
        CaseID: caseDetails.CaseID,
        UserID: getUserID(),
        UserType: userType,
        Message: values.Message,
        LawyerMinBudget: minAmount,
        LawyerMaxBudget: maxAmount,
        LawyerBudgetCurrency: "USD",
      };
      dispatch(submitBid(bidDetails))
        .then(() => {
          resetForm();
          setIsBidFormVisible(false);  // Close the form after submission
        })
        .catch((error: any) => {
          console.error('Failed to submit bid:', error);
        });
    },
  });

  useEffect(() => {
    if (isBidFormVisible) {
      endOfComponentRef.current?.scrollIntoView({ behavior: 'smooth' });
    }
  }, [isBidFormVisible]);

  const handleBidTypeToggle = () => {
    const newBidType = formik.values.BidType === 'fullAmount' ? 'HourlyRates' : 'fullAmount';
    formik.setFieldValue('BidType', newBidType);
  };

  const handleToggleBidForm = () => {
    setIsBidFormVisible(!isBidFormVisible);
  };

  return (
    <>
    <div style={{ position: 'relative', height: '100%' }}>
    <div className="lcm-case-details">
        <div className='lcm-case-details__client--header published'>
          <h2>{caseDetails.CaseType}</h2>
          <p style={{ marginRight: 10 }} className='lcm-case-details__pricerange-lawyer'>{formatNumber(caseDetails.MinBudget)} - {formatNumber(caseDetails.MaxBudget)} {caseDetails.Currency}</p>
        </div>

        <div className='lcm-case-details--info-wrapper published'>
          <div className='lcm-case-details__row'>
            <p>{t('Urgency')}</p><p className='lcm-case-details__urgency'>{caseDetails.Urgency}</p>
          </div>

          <div className='lcm-case-details__row'>
            <div className='lcm-case-details__row' style={{ marginRight: 10 }}>
              <p>{t('Jurisdiction')}</p>
              <p className='lcm-case-details__div--default'>{caseDetails.CountryOfJurisdiction}</p>
            </div>

            <div className='lcm-case-details__row'>
              <p>{t('Preferred Language')}</p>
              <p className='lcm-case-details__div--default'>{caseDetails.PreferredLanguage}</p>
            </div>
          </div>

          <div className='lcm-case-details__row'>
            <p>{t('Desired Outcome')}</p>
            <p className='lcm-case-details__div--default'>{t(`${caseDetails.DesiredOutcome}`)}</p>
          </div>

          <div className='lcm-case-details__row'>
            <p>{t('Expected Outcome')}</p>
            <p className='lcm-case-details__div--default'>{t(`${caseDetails.ExpectedOutcome}`)}</p>
          </div>

          <div>
            <p>{t('Attachments')}</p>
            <div className='lcm-case-details__row--documents'>
              {caseDetails.AttachmentsLink && caseDetails.AttachmentsLink.length > 0 ? (
                caseDetails.AttachmentsLink.map((file, index) => (
                  <p key={index} className='lcm-case-detailes__attachments'>
                    {file}
                  </p>
                ))
              ) : (
                <p className='lcm-case-detailes__attachments'>{t('No attachments')}</p>
              )}
            </div>
          </div>
        </div>

          <p>{t('Description')}</p>
        <div className='lcm-case-details__description'>
          <p className='lcm-case-details__description--p'>{caseDetails.Description}</p>
        </div>

        <div className='lcm-case-details__actions'>
          <button className='lcm-case-details__actions--btn' onClick={handleToggleBidForm}>
            {isBidFormVisible ? t('Close Bid Form') : t('Propose Bid')}
          </button>
        </div>
      </div>
      </div>

        {isBidFormVisible && (
          <div className="lawyer-bid__section--wrapper">
          <div className="lawyer-bid__section">
            <div className="lawyer-bid__section--header">
              <h2 className="bid-section__header" >{t('Propose Bid')}</h2>
              <div className="toggle-switch__bid-type">
                <button className={`button left ${formik.values.BidType === 'fullAmount' ? 'active' : ''}`} onClick={handleBidTypeToggle}>{t('Full Amount')}</button>
                <button className={`button right ${formik.values.BidType === 'HourlyRates' ? 'active' : ''}`} onClick={handleBidTypeToggle}>{t('Hourly Rate')}</button>
                <div className={`slider-otp ${formik.values.BidType === 'HourlyRates' ? 'right' : ''}`}></div>
              </div>
            </div>
            <form onSubmit={formik.handleSubmit}>
              <label>
                <p>{t('Message')}</p>
                <textarea
                  name='Message'
                  value={formik.values.Message}
                  onChange={formik.handleChange('Message')}
                  onBlur={formik.handleBlur('Message')}
                  placeholder={t("Enter your message here...")}
                />
                {formik.errors?.Message && formik.touched.Message && <div className="error-message-display">{formik.errors?.Message}</div>}
              </label>
              <div className="bid-section__right">
                {formik.values.BidType === 'fullAmount' ? (
                  <label>
                    <p style={{ display: 'flex', justifyContent: 'center' }}>{t('Total Bid Amount')}</p>
                    <div className="custom-number-input" style={{ justifyContent: 'center', alignItems: 'center' }}>
                      <input
                        name="BidAmount"
                        type="number"
                        value={formik.values.BidAmount}
                        onChange={formik.handleChange('BidAmount')}
                        onBlur={formik.handleBlur('BidAmount')}
                        placeholder={t("Enter total bid amount")}
                        style={{ width: 200, textAlign: 'center' }}
                      />
                    </div>
                    {formik.errors?.BidAmount && formik.touched.BidAmount && <div className="error-message-display">{formik.errors?.BidAmount}</div>}
                  </label>
                ) : (
                  <>
                    <div style={{ alignItems: 'center', display: 'flex', gap: 10, width: '100%' }}>
                      <label>
                        <p>{t('Hourly Rate')}</p>
                        <input
                          name="HourlyRates"
                          type="number"
                          value={formik.values.HourlyRates}
                          onChange={formik.handleChange('HourlyRates')}
                          onBlur={formik.handleBlur('HourlyRates')}
                          placeholder={t("Enter hourly rate")}
                          style={{ width: 200 }}
                        />
                        {formik.errors?.HourlyRates && formik.touched.HourlyRates && <div className="error-message-display">{formik.errors?.HourlyRates}</div>}
                      </label>
                      <label>
                        <p>{t('Max Hours')}</p>
                        <input
                          name="MaxHours"
                          type="number"
                          value={formik.values.MaxHours}
                          onChange={formik.handleChange('MaxHours')}
                          onBlur={formik.handleBlur('MaxHours')}
                          placeholder={t("Enter number of hours")}
                          style={{ width: 200 }}
                        />
                        {formik.errors?.MaxHours && formik.touched.MaxHours && <div className="error-message-display">{formik.errors?.MaxHours}</div>}
                      </label>
                    </div>
                  </>
                )}
              </div>
              <button className="submit-casebid__button" type="submit">{t('Place Bid')}</button>
            </form>
          </div>
          </div>
        )}

      <div ref={endOfComponentRef} />
    </>
  );
};

export default PublishedCaseModal;
