import { Route, Switch, useRouteMatch,Redirect  } from 'react-router-dom';
import Account from './AccountSettings/AccountSettings';
import Security from './Security/SecuritySettings';
import Finance from './FinanceSettings';
import './Settings.css';

const Settings = () => {
    let { path } = useRouteMatch();

    return (
        <div className="settings-container-header">

            <Switch>
                <Route exact path={path} render={() => <Redirect to={`${path}/account`} />} />
                <Route exact path={`${path}/account`} component={Account} />
                <Route path={`${path}/security`} component={Security} />
                <Route path={`${path}/finance`} component={Finance} />
            </Switch>
        </div>
    );
}

export default Settings;
