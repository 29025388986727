
export const SET_FONT_SIZE = 'SET_FONT_SIZE';

export interface SetFontSizeAction {
  type: typeof SET_FONT_SIZE;
  payload: { fontSize: string };
}

export const setFontSize = (fontSize: string): SetFontSizeAction => ({
  type: SET_FONT_SIZE,
  payload: { fontSize },
});
