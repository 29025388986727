import React, { useState, useCallback, useEffect} from 'react';
import { useTranslation } from 'react-i18next';
import GlobalDropdown from '../../shared/GlobalDropdown';
import { caseTypeOptions } from './LCMUploadForm';

interface FilterProps {
  applyFilters: (filters: { location: string; expertise: string; rating: number; casesHandled: number; lawyerType: string, hourlyRate: number }) => void;
  toggleDropdown: () => void;
}

  const FilterDropdownLawyer: React.FC<FilterProps> = ({ applyFilters, toggleDropdown }) => {
    const { t } = useTranslation();
    const initialFilters = JSON.parse(localStorage.getItem('lawyerFilters') || '{}');
    const [localFilters, setLocalFilters] = useState({
      location: initialFilters.location || '',
      expertise: initialFilters.expertise || '',
      rating: initialFilters.rating || 0,
      casesHandled: initialFilters.casesHandled || 0,
      hourlyRate: initialFilters.hourlyRate || 0,
      lawyerType: initialFilters.lawyerType || ''
    });

    useEffect(() => {
      updateSliderBackground(localFilters.rating, 5, 'rating');
      updateSliderBackground(localFilters.casesHandled, 300, 'casesHandled');
    }, [localFilters.rating, localFilters.casesHandled]);
    
    useEffect(() => {
      localStorage.setItem('lawyerFilters', JSON.stringify(localFilters));
    }, [localFilters]);
  
    const handleFilterChange = useCallback((name: string, value: string | number) => {
      setLocalFilters(prev => ({ ...prev, [name]: value }));
    }, []);
  
    const handleApplyFilters = useCallback(() => {
      applyFilters(localFilters);
      toggleDropdown();
      localStorage.setItem('lawyerFilters', JSON.stringify(localFilters)); 
    }, [localFilters, applyFilters, toggleDropdown]);


  const handleClearFilters = useCallback(() => {
    const resetFilters = {
      location: '',
      caseType: '',
      expertise: '',
      lawyerType: '',
      rating: 0,
      casesHandled: 0,
      hourlyRate: 0
    };
    setLocalFilters(resetFilters);
    applyFilters(resetFilters);
    localStorage.setItem('lawyerFilters', JSON.stringify(resetFilters));
    toggleDropdown();
  }, [applyFilters, toggleDropdown]);

  const handleRangeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = parseFloat(e.target.value);
    const max = parseFloat(e.target.max);
    const name = e.target.name;
  
    setLocalFilters(prev => ({
      ...prev,
      [name]: value  
    }));
  
    updateSliderBackground(value, max, name);
  };
  
  
  
  const updateSliderBackground = (value: number, max: number, name: string) => {
    const percentage = (value / max) * 100;
    const slider = document.querySelector(`input[name='${name}']`) as HTMLInputElement; // Correct type assertion
    if (slider) {
      slider.style.setProperty('--range-percentage', `${percentage}%`);
    }
  };
  
  

  return (
    <div className="filter-lawyer-dropdown">
      <div className="filter-option">
      <label htmlFor="">{t('Location')}</label>
      <GlobalDropdown 
       options={[{ label: 'Norway', id: 'Norway' }, { label: 'Sweden', id: 'Sweden' }, { label: 'Spain', id: 'Spain' }, { label: 'United Kingdom', id: 'United Kingdom' }, { label: 'United States', id: 'United States' }, { label: 'Danmark', id: 'Danmark' }, { label: 'Finland', id: 'Finland' }, { label: 'India', id: 'India' }]}
        onSelect={id => handleFilterChange('location', id)}
        selectedLabel={localFilters.location}
        placeholder={t('Select Location')}
        style={{ width: '100%' }}
      />
      </div>
      <div className='filter-lawyer__wrap'>
      <div className="filter-option">
      <label htmlFor="">{t('Law Expertise')}</label>
      <GlobalDropdown 
        options={caseTypeOptions.map((caseType: string) => ({ id: caseType, label: caseType }))}
        onSelect={id => handleFilterChange('expertise', id)}
        selectedLabel={localFilters.expertise}
        placeholder={t('Select Expertise')}

      />
      </div>
      <div className="filter-option">
      <label htmlFor="">{t('Lawyer Occupation')}</label>
      <GlobalDropdown 
        options={[{ label: 'Independent', id: 'independent' }, { label: 'Law Firm Affiliated', id: 'lawfirm' }]}
        onSelect={id => handleFilterChange('lawyerType', id)}
        selectedLabel={localFilters.lawyerType}
        placeholder={t('Select Lawyer Type')}
      />
      </div>
      </div>
      <div className="filter-option">
      <label htmlFor="">{t('Hourly Rate')}</label>
      <input 
        type="range"
        name="hourlyRate" 
        min="0" 
        max="500" 
        step="1" 
        value={localFilters.hourlyRate}
        onChange={(e) => {
          handleRangeChange(e);
        }}

      />
      <span>{localFilters.hourlyRate}</span>
      </div>
      <div className="filter-option">
      <label htmlFor="">{t('Rating')}</label>
      <input 
        type="range"
        name="rating" 
        min="0" 
        max="5" 
        step="0.1" 
        value={localFilters.rating}
        onChange={(e) => {
          handleRangeChange(e);
        }}
        style={{ width: '100%', cursor: 'pointer' }}
      />
      <span>{localFilters.rating}</span>
      </div>
      <div className="filter-option">
      <label htmlFor="">{t('Cases Handled')}</label>
      <input 
        type="range" 
        name="casesHandled" 
        min="0" 
        max="300" 
        value={localFilters.casesHandled}
        onChange={(e) => {
          handleRangeChange(e); 
        }}
        style={{ width: '100%', cursor: 'pointer'  }}
      />
      <span>{localFilters.casesHandled}</span>
      </div>
      <div className='filter-lawyer__buttons'>
      <button onClick={handleApplyFilters}>{t('Apply Filters')}</button>
      <button onClick={handleClearFilters} className="clear-filters-button">{t('Clear Filters')}</button>
      </div>
    </div>
  );
};

export default FilterDropdownLawyer;
