
import {  useSelector } from 'react-redux';
import './LawyerIndependent.css'
import { RootState, useAppDispatch } from '../../../store';
import { Task } from '../../Marketplace/Lawyer/LawyerSection';
import { useEffect, useState } from 'react';
import EarningTracker from '../CreditTracker/EarningsTracker';
import FeedbackWidget from './FeedbackWidget';
import { getUserID, getUserType } from '../../../store/actions/ChatAction';
import { ActiveTasks, CompleteTask, DeleteTask, GetAllTasks } from '../../../store/actions/marketplace/TasksActions';
import { useTranslation } from 'react-i18next';
import { ICaseItem } from '../../Marketplace/Interfaces/ICaseItem';
import { ClipLoader } from 'react-spinners';
import { hideModal, showAddTaskModal, showModal } from '../../../store/actions/modalActions';
import { fetchProposals } from '../../../store/actions/marketplace/lawyerAction';
import { fetchCaseList } from '../../../store/actions/marketplace/caseActions';
import { selectCaseForViewing, setActiveView, setDetailsContent } from '../../../store/actions/marketplace/activeViewActions';
import { useHistory } from 'react-router-dom';
import { IAdminCaseItem } from '../../Marketplace/Interfaces/IAdminCaseItem';


const LawyerIndependent = () => {
    const CaseList = useSelector((state: RootState) => state.case.caseList);
    const TodoList = useSelector((state: RootState) => state.toDo.activeTasks)
    const userId = getUserID()
    const [isTodoLoading, setIsTodoLoading] = useState(false);
    const [isCasesLoading, setIsCasesLoading] = useState(false);
    const [isEarningsLoading, setIsEarningsLoading] = useState(false);
    const [isInquiriesLoading, setIsInquiriesLoading] = useState(false);
    const userType = getUserType();
    const dispatch = useAppDispatch()
    const preferences = useSelector((state: RootState) => state.lists.preferences)
    const {t} = useTranslation()
    const proposals = useSelector((state: RootState) => state.lawyers.proposals)
    const [isLoading, setIsLoading] = useState(false);  
    const history = useHistory();
    const [selectedTaskId, setSelectedTaskId] = useState<string | null>(null);
    
    useEffect(() => {
        const fetchData = async () => {
            setIsTodoLoading(true);
            setIsCasesLoading(true);
            setIsEarningsLoading(true);
            setIsInquiriesLoading(true);
            setIsLoading(true);
            try {
                await dispatch(ActiveTasks(userId, userType));
                await dispatch(GetAllTasks(userId, userType));
                await dispatch(fetchCaseList());
                await dispatch(fetchProposals(userId, userType));
            } finally {
                setIsTodoLoading(false);
                setIsCasesLoading(false);
                setIsEarningsLoading(false);
                setIsInquiriesLoading(false);
            }
        };
        fetchData();
    }, [dispatch]);

    // useEffect(() => {
    //     const fetchData = async () => {
    //       setIsInquiriesLoading(true);
    //       try {
    //         await dispatch(fetchProposals(userId, userType));
    //         if (proposals.length > 0) {
    //           proposals.forEach((proposal: any) => {
    //             if (!caseDetails[proposal.CaseID]) {
    //               dispatch(fetchFullCase(proposal.CaseID))
    //                 .then((response) => {
    //                   setCaseDetails((prevDetails) => ({
    //                     ...prevDetails,
    //                     [proposal.CaseID]: response.payload,
    //                   }));
    //                 })
    //                 .catch((error) => {
    //                   console.error(`Error fetching case details for CaseID: ${proposal.CaseID}`, error);
    //                 });
    //             }
    //           });
    //         }
    //       } finally {
    //         setIsInquiriesLoading(false);
    //       }
    //     };
    //     fetchData();
    //   }, [dispatch, proposals, caseDetails, userId, userType]);

    const formatNumber = (value: string) => {
        return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
      };
      
      const handleCompleteTask = async (id: string) => {
        setIsLoading(true);
        await dispatch(CompleteTask(userType, userId, id));
        await dispatch(ActiveTasks(userId, userType));
        await dispatch(GetAllTasks(userId, userType))
        setIsLoading(false);
    };

    const handleRemoveTask = (taskId: string) => {
        setSelectedTaskId(taskId); 
        dispatch(showModal({
          type: 'confirmation',
          message: t('Are you sure you want to delete this task?'),
          onConfirm: () => handleConfirmDelete(taskId),
          onCancel: handleCancel,
          showModal: true
        }));
    };

    const handleMarketClick = () => {
        dispatch(setActiveView('market'));
        history.push('/marketplace');
    };

    const handleCaseClick = (caseItem: ICaseItem | IAdminCaseItem) => {
        dispatch(selectCaseForViewing(caseItem));
        dispatch(setDetailsContent('MY_CASE_DETAILS'))
        dispatch(setActiveView('cases'))
        history.push('/marketplace');
    };

    const handleConfirmDelete = async (taskId?: string) => {
        if (taskId) {
          setIsLoading(true);
          await dispatch(DeleteTask(userType, userId, taskId));
          await dispatch(ActiveTasks(userId, userType));
          setIsLoading(false);
          setSelectedTaskId(null);
          dispatch(hideModal());
        }
      };
      
    
    const handleCancel = () => {
        setSelectedTaskId(null); 
        dispatch(hideModal());
    };


    const showTaskModal = () => {
        dispatch(showAddTaskModal())
    }
      
    return (
        <div className="dashboard-lawyer__container">
        <div className='dashboard-lawyer__top'>
          <div className="dashboard-lawyer__todo-list-container">
            <div className="dashboard-lawyer__services--header">
              <h2>{t('To-Do List')}</h2>
              <button onClick={showTaskModal}>{('Add Task')}</button>
            </div>
            <div className="dashboard-lawyer__todo-list">
              {isTodoLoading ? (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                  <ClipLoader color="#fff" loading={isTodoLoading} size={30} />
                </div>
              ) : (
                <>
                  {TodoList && TodoList.length > 0 ? TodoList.map((task: Task, index: number) => (
                    <div key={index} className="dashboard-lawyer__todo-list--item">
                      <div className='dashboard-lawyer__todo-list--item--info'>
                        <span className="caseName">{task.TaskTitle}</span>
                        <span className='dashboard-lawyer__todo-list--item--desc'>{task.TaskDescription}</span>
                        <span title='Task Deadline' className='dashboard-lawyer__todo-list--item--deadline'>{task.TaskDeadline}</span>
                      </div>
                      <div className="task-actions dashboard">
                        <button className="icon-button complete" onClick={() => handleCompleteTask(task.TaskID)} title="Complete Task">
                          <i className={`fa${task.Status === "Completed" ? 's' : 'r'} fa-check-circle`}></i>
                        </button>
                        <button className="icon-button delete" onClick={() => handleRemoveTask(task.TaskID)} title="Remove Task">
                          <i className="far fa-trash-alt"></i>
                        </button>
                      </div>
                    </div>
                  )) : (
                    <div className="no-tasks">{t('No tasks')}</div>
                  )}
                </>
              )}
            </div>
          </div>

          <div className="dashboard-lawyer__services">
            <div className="dashboard-lawyer__services--header">
              <h2>{t('Active Cases')}</h2>
              <button className='browse-market' onClick={() => handleMarketClick()}>{('Browse Market')}</button>
            </div>
            <div className="todo-list">
              {isCasesLoading ? (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                  <ClipLoader color="#fff" loading={isCasesLoading} size={30} />
                </div>
              ) : (
                CaseList && CaseList.filter((item: ICaseItem) => item.Status === "Ongoing").length > 0 ? CaseList.filter((item: ICaseItem) => item.Status === "Ongoing").map((item: ICaseItem, index: number) => (
                  <div key={index} className="dashboard-lawyer__case-item" onClick={() => handleCaseClick(item)}>
                    <div className='dashboard-lawyer__case-item__row'>
                      <p className="dashboard-lawyer__case-item--casetype">{item.CaseType}</p>
                      <p className="caseUrgency">{item.Urgency}</p>
                    </div>
                    <div className='dashboard-lawyer__case-item__row'>
                      <p className="casePrice"> {formatNumber(item.MinBudget)} - {formatNumber(item.MaxBudget)} {item.Currency}</p>
                      <p className="caseCountry">{item.CountryOfJurisdiction}</p>
                    </div>
                  </div>
                )) : (
                  <p className="no-tasks">{t('No Active Cases')}</p>
                )
              )}
            </div>
          </div>
        </div>

        <div className='dashboard-lawyer__middle'>
          <div className="chart-service">
            <h2>{t('Earnings per Case')}</h2>
            <div className="earning-per-case__list">
              {isEarningsLoading ? (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                  <ClipLoader color="#fff" loading={isEarningsLoading} size={30} />
                </div>
              ) : (
                CaseList && CaseList.filter(((item: ICaseItem) => item.Status === "Completed")).length > 0 ? CaseList.filter((item: ICaseItem) => item.Status === "Completed").map((item: ICaseItem, index: number) => (
                  <div key={index} style={{ backgroundColor: '#222' }} className="dashboard-lawyer__case-item" onClick={() => handleCaseClick(item)}>
                    <div className='dashboard-lawyer__case-item__row'>
                      <p className="dashboard-lawyer__case-item--casetype">{item.CaseType}</p>
                      <p className="casePrice">{formatNumber(item.MaxBudget)} {item.Currency}</p>
                    </div>
                    <div className='dashboard-lawyer__case-item__row'>
                      <p className="caseCountry">{item.CountryOfJurisdiction}</p>
                    </div>
                  </div>
                )) : (
                  <p className="no-tasks">{t('No completed cases')}</p>
                )
              )}
            </div>
          </div>
          <div className="dashboard-lawyer__tracker">
            <EarningTracker totalCredits={0} />
          </div>
        </div>

        <div className='dashboard-lawyer__bottom' style={{ paddingBottom: 20 }}>
          <FeedbackWidget />
          <div className="inquiries-widget">
            <h2>{t('Client Inquiries')}</h2>
            {isInquiriesLoading ? (
              <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                <ClipLoader color="#fff" loading={isInquiriesLoading} size={30} />
              </div>
            ) : (
              <ul>
            {proposals && proposals.length > 0 ? proposals.map((item: any) => (
                        <li key={item.id}>
                            {/* <p>{item.ProposalStatus}</p> */}
                            <div className='inquiries-top'>
                            <span className='inquiry-client__msg'>{item.ClientMessage}</span>
                            <p>{formatNumber(item.ClientMaxBudget)} {preferences.Currency}</p>
                            </div>
                        </li>
                        )) : (
                        <p className="no-tasks">{'No Inquiries'}</p>
                        )}
                    </ul>
                    )}
          </div>
        </div>

        </div>
    )
}


export default LawyerIndependent;