import { useTranslation } from "react-i18next";
import TimeTracker from "../components/TimeTracker/TimeTracker";
import Timekeeping from "../components/TimeTracker/TimeKeeping";

function TimeKeepingPage() {
    const { t } = useTranslation();
    return (
        <>
             <Timekeeping />
        </>
    )
}

export default TimeKeepingPage;