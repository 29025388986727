import React, { useState } from 'react';
import './Header.css'

const RoleDropdown: React.FC<{ userType: string; setUserType: (type: string) => void }> = ({ userType, setUserType }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedRole, setSelectedRole] = useState(userType === "LawfirmAdmin" || userType === "BusinessAdmin" ? "Admin" : "Employee");

  const toggleDropdown = () => setIsOpen(!isOpen);

  const handleRoleChange = (role: string) => {
    setSelectedRole(role);
    setIsOpen(false);
    setUserType(role === "Admin" ? "LawfirmAdmin" : "Employee");
  };

  return (
    <div className="role-dropdown">
      <p className="adminmark--header" onClick={toggleDropdown}>
        {selectedRole} <i className={`fas fa-chevron-${isOpen ? 'up' : 'down'}`}></i>
      </p>
      {isOpen && (
        <div className="dropdown-menu">
          <div className="dropdown-item" onClick={() => handleRoleChange('Admin')}>
            Admin
          </div>
          <div className="dropdown-item" onClick={() => handleRoleChange('Employee')}>
            Employee
          </div>
        </div>
      )}
    </div>
  );
};

export default RoleDropdown;
