import React, { CSSProperties, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, BarElement, CategoryScale, LinearScale, Tooltip, ScriptableContext, Chart, TooltipModel, TooltipItem } from 'chart.js';
import './WorkActivityChart.css';

ChartJS.register(BarElement, CategoryScale, LinearScale, Tooltip);

// Plugin to display text inside each bar with rounded padding
const textOnBarPlugin = {
  id: 'textOnBar',
  afterDatasetsDraw(chart: Chart) {
    const { ctx } = chart;
    chart.data.datasets.forEach((dataset: any, i: any) => {
      if (i === 0) return; // Skip the background dataset (index 0)

      const meta = chart.getDatasetMeta(i);
      meta.data.forEach((bar: any, index: any) => {
        const value = dataset.data[index];
        ctx.save();
        ctx.textAlign = 'center';
        ctx.textBaseline = 'middle';
        ctx.fillStyle = parseFloat(value) < 30 ? '#fa0000' : parseFloat(value) > 90 ? 'rgb(66, 212, 66)' : 'white';
        ctx.font = '14px Poppins';

        const textWidth = ctx.measureText(value + '%').width;
        const padding = 5; // Padding around text
        const rectX = bar.x - textWidth / 2 - padding;
        const rectY = bar.y + bar.height - 20;
        const rectWidth = textWidth + padding * 2;
        const rectHeight = 16;
        const borderRadius = 10;

        ctx.fillStyle = '#272B30';
        ctx.beginPath();
        ctx.moveTo(rectX + borderRadius, rectY);
        ctx.lineTo(rectX + rectWidth - borderRadius, rectY);
        ctx.quadraticCurveTo(rectX + rectWidth, rectY, rectX + rectWidth, rectY + borderRadius);
        ctx.lineTo(rectX + rectWidth, rectY + rectHeight - borderRadius);
        ctx.quadraticCurveTo(rectX + rectWidth, rectY + rectHeight, rectX + rectWidth - borderRadius, rectY + rectHeight);
        ctx.lineTo(rectX + borderRadius, rectY + rectHeight);
        ctx.quadraticCurveTo(rectX, rectY + rectHeight, rectX, rectY + rectHeight - borderRadius);
        ctx.lineTo(rectX, rectY + borderRadius);
        ctx.quadraticCurveTo(rectX, rectY, rectX + borderRadius, rectY);
        ctx.closePath();
        ctx.fill();

        ctx.fillStyle = parseFloat(value) < 30 ? '#fa0000' : parseFloat(value) > 90 ? 'rgb(66, 212, 66)' : 'white';
        ctx.fillText(value + '%', bar.x, bar.y + bar.height - 10);
        ctx.restore();
      });
    });
  }
};

// Plugin to draw a dynamic line and display information on hover
// const dynamicLineTooltipPlugin = {
//   id: 'dynamicLineTooltip',
//   afterEvent(chart: Chart, args: { tooltip: TooltipModel<'bar'> }) {
//     const { ctx } = chart;
//     const tooltip = chart.tooltip;
//     const data = chart.data.datasets[1].data as number[];

//     ctx.clearRect(0, 0, chart.width, chart.height);

//     // Only draw if hovering over a bar
//     if (tooltip && tooltip.dataPoints && tooltip.dataPoints.length) {
//       const activePoint = tooltip.dataPoints[0];
//       const { x, y } = activePoint.element.tooltipPosition(true); // Pass a boolean value

//       // Draw a vertical line at the hovered position
//       ctx.save();
//       ctx.beginPath();
//       ctx.moveTo(x, chart.chartArea.top);
//       ctx.lineTo(x, chart.chartArea.bottom);
//       ctx.lineWidth = 1;
//       ctx.strokeStyle = '#ffcc00'; // Customize line color
//       ctx.stroke();
//       ctx.restore();

//       // Display information next to the line
//       ctx.font = '12px Arial';
//       ctx.fillStyle = 'white';
//       const dataIndex = activePoint.dataIndex; // Correctly get the data index
//       const isFilledBar = tooltip.dataPoints[0].datasetIndex === 1; // Check if hovering over filled bar
//       const infoText = isFilledBar
//         ? `Tasks Completed: ${data[dataIndex]}`
//         : 'Remaining time to complete';

//       ctx.fillText(infoText, x + 10, y); // Display text on the right side of the line
//     }
//   }
// };

const WorkActivityChart: React.FC<({ height?: number })> = ({ height }) => {
  const [viewMode, setViewMode] = useState<'weekly' | 'monthly'>('weekly');

  const weeklyData = [6, 8, 5, 2, 4]; // Represents Monday to Friday
  const monthlyData = [
    weeklyData.reduce((acc, val) => acc + val, 0),  // Sum of each week's hours
    weeklyData.reduce((acc, val) => acc + val, 0) * 0.9, // Example for Week 2
    weeklyData.reduce((acc, val) => acc + val, 0) * 1.1, // Example for Week 3
    weeklyData.reduce((acc, val) => acc + val, 0) * 0.95 // Example for Week 4
  ];

  const maxDailyHours = 8;
  const maxWeeklyHours = 37.5; // 37.5 hours per week as 100%
  const data = viewMode === 'weekly' ? weeklyData : monthlyData;
  const percentageData = data.map((value) => {
    const maxHours = viewMode === 'weekly' ? maxDailyHours : maxWeeklyHours;
    return Math.round((value / maxHours) * 100);
  });

  const remainingPercentageData = percentageData.map(value => 100 - value);

  const chartData = {
    labels: viewMode === 'weekly' ? ['Mon', 'Tue', 'Wed', 'Thu', 'Fri'] : ['Week 1', 'Week 2', 'Week 3', 'Week 4'],
    datasets: [
      {
        data: remainingPercentageData,
        backgroundColor: (context: ScriptableContext<'bar'>) => {
          const ctx = context.chart.ctx;
          const patternCanvas = document.createElement('canvas');
          patternCanvas.width = 10;
          patternCanvas.height = 10;
          const patternCtx = patternCanvas.getContext('2d');

          if (patternCtx) {
            patternCtx.fillStyle = 'rgba(27, 27, 27, 0.3)';
            patternCtx.fillRect(0, 0, 10, 10);
            patternCtx.strokeStyle = 'rgba(255, 255, 255, 0.4)';
            patternCtx.lineWidth = 1;
            patternCtx.beginPath();
            patternCtx.moveTo(0, 10);
            patternCtx.lineTo(10, 0);
            patternCtx.stroke();

            return ctx.createPattern(patternCanvas, 'repeat')!;
          }

          return '#1b1b1b';
        },
        hoverBackgroundColor: '#191919',
        barPercentage: 1.0,
        categoryPercentage: 1.0,
        barThickness: 55,
        borderSkipped: false,
        borderRadius: 10,
        order: 2,
      },
      {
        data: percentageData,
        backgroundColor: '#272B30',
        hoverBackgroundColor: '#34495e',
        borderWidth: 1,
        borderRadius: 10,
        barThickness: 55,
        borderSkipped: false,
        barPercentage: 1.0,
        categoryPercentage: 1.0,
        order: 1,
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      tooltip: {
        enabled: true, // Disable default tooltip
        // external: (context: any) => dynamicLineTooltipPlugin.afterEvent(context.chart, { tooltip: context.tooltip }), 
      },
      legend: {
        display: false,
      },
    },
    scales: {
      y: {
        beginAtZero: true,
        stacked: true,
        display: false,
      },
      x: {
        grid: {
          color: 'transparent',
        },
        ticks: {
          color: '#ccc',
        },
        stacked: true,
      },
    },
  };

  const totalPercentageWorked = Math.round(
    (data.reduce((acc, val) => acc + val, 0) / (viewMode === 'weekly' ? maxWeeklyHours : maxWeeklyHours * 4)) * 100
  );

  return (
    <div className='work-activity-chart'>
      <div className='work-activity-chart__header'>
        <h2>{('My Activity')}</h2>
        <div className='my-activity--togggle'>
          <button onClick={() => setViewMode('weekly')} className={`toggle-btn ${viewMode === 'weekly' ? 'active' : ''}`}>
            Weekly
          </button>
          <button onClick={() => setViewMode('monthly')} className={`toggle-btn ${viewMode === 'monthly' ? 'active' : ''}`}>
            Monthly
          </button>
        </div>
      </div>
        <h3>{totalPercentageWorked}%</h3>
      <div className='work-activity-chart__chart' style={{ height: height}}>
        <Bar data={chartData} options={options} plugins={[textOnBarPlugin]} />
      </div>
    </div>
  );
};

export default WorkActivityChart;
