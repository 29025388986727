import React, { createContext, useContext, useMemo, useState } from 'react'

interface PeerContextType {
    peer: RTCPeerConnection;
    createOffer: () => Promise<RTCSessionDescriptionInit>;
    createAnswer: (offer: any) => Promise<RTCSessionDescriptionInit>;
    remoteAnswer: (ans: any) => void;
    remoteStream: MediaStream | null,
    setRemoteStream: (stream: any) => void
}

const PeerContext = createContext<PeerContextType | null>(null);


export const usePeer = () => {
    const context = useContext(PeerContext);
    if (!context) {
        throw new Error('usePeer must be used within a PeerProvider');
    }
    return context;
};
export const PeerProvider = (props: any) => {
    const [remoteStream, setRemoteStream] = useState<MediaStream | null>(null)
    const peer = useMemo(() => new RTCPeerConnection({
        iceServers: [
            {
                urls: [
                    "stun:stun.l.google.com:19302",
                    "stun:global.stun.twilio.com:3478",
                ],
            }
        ]
    }), [])

    const createOffer = async () => {
        const offer = await peer.createOffer()
        await peer.setLocalDescription(new RTCSessionDescription(offer))
        return offer
    }

    const createAnswer = async (offer: RTCSessionDescriptionInit) => {
        if (!offer) {
            throw new Error('Invalid offer');
        }
        await peer.setRemoteDescription(offer)
        const answer = await peer.createAnswer()
        await peer.setLocalDescription(new RTCSessionDescription(answer))
        return answer

    }


    const remoteAnswer = async (ans: RTCSessionDescriptionInit) => {
        await peer.setRemoteDescription(new RTCSessionDescription(ans))
    }

    return (
        <PeerContext.Provider value={{ peer, createOffer, createAnswer, remoteAnswer,remoteStream,setRemoteStream}}>{props.children}</PeerContext.Provider>
    )
}


