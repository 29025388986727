import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

const CaseDocumentation = () => {
    const { t } = useTranslation()
    const files = [
        { id: 1, name: 'Document 1.pdf' },
        { id: 2, name: 'Document 2.pdf' },
        { id: 3, name: 'Document 3.pdf' },
        { id: 4, name: 'Document 4.pdf' },
        { id: 5, name: 'Document 5.pdf' },
    ];

    const [rating, setRating] = useState(0);
    const [feedback, setFeedback] = useState('');

    const downloadFile = (fileName: string) => {
    };

    const submitFeedback = () => {
    };

    return (
        <div className="case-documentation-container">
            <div className="files-list">

                {files.map((file) => (
                    <div key={file.id} className="file-row">
                        <span className="file-name">{file.name}</span>
                        <button onClick={() => downloadFile(file.name)} className="download-button">
                            <i style={{ fontSize: 24, color: '#64d2ff' }} className='fa-regular fa-arrow-down-to-line'></i>
                        </button>
                    </div>
                ))}
            </div>

            <div className="rating-container">
                <h2 className="subtitle">{t('Rate your experience')}</h2>
                <div className="stars-container">
                    {[...Array(5)].map((_, index) => (
                        <button key={index} onClick={() => setRating(index + 1)} className="star-button">
                            {/* Placeholder for FontAwesomeIcon */}
                            {index < rating ? <i style={{ fontSize: 24 }} className='fa-solid fa-star'></i> : <i style={{ fontSize: 24 }} className='fa-regular fa-star'></i>}
                        </button>
                    ))}
                </div>
                <textarea
                    className="feedback-input"
                    onChange={(e) => setFeedback(e.target.value)}
                    value={feedback}
                    placeholder={t("Leave your feedback here...")}

                />
                <button onClick={submitFeedback} className="submit-button">
                    {t('Submit Rating')}
                </button>
            </div>
        </div>
    );
};

export default CaseDocumentation;
