import React, { useEffect, useState, DragEvent } from 'react';
import './NewFileModal.css';
import { RootState, useAppDispatch } from '../../store';
import { fetchFileTypes } from '../../store/actions/FolderAction';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import GlobalDropdown, { DropdownOption } from '../shared/GlobalDropdown';
import { hideModal } from '../../store/actions/modalActions';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { fetchCategories } from '../../store/actions/DocumentsAction';
import { FolderProps } from './Files';
import { ICaseItem } from '../Marketplace/Interfaces/ICaseItem';
import EmailTagsInput from './EmailTagInput';
import { LocalizationProvider, DateTimePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import dayjs, { Dayjs } from 'dayjs';

export interface NewFileData {
  folderId: string;
  fileName: string;
  fileType: 'Contract' | 'Legal Document' | 'General' | 'Template';
  file: File | undefined;
  additionalFields: {
    PartiesInvolved?: string[];
    StartDate?: string;
    EndDate?: string;
    Approvers?: string[];
    Assignees?: string[];
    SharedWith?: string[];
    LegalType?: string;
    CaseID?: string;
    Expiry?: string;
  };
}

interface NewFileModalProps {
  folders: FolderProps[];
  onSave: (data: NewFileData) => void;
  currentUserId: string;
}

const NewFileModal: React.FC<NewFileModalProps> = ({ folders, onSave, currentUserId }) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const fileTypes = useSelector((state: RootState) => state.folders.fileTypes);
  const structure = useSelector((state: RootState) => state.folders.structure);
  const [selectedFileType, setSelectedFileType] = useState<'Contract' | 'Legal Document' | 'General' | 'Template'>('General');
  const caseList = useSelector((root: RootState) => root.case.caseList);
  const [file, setFile] = useState<File | null>(null);

  useEffect(() => {
    dispatch(fetchCategories());
    dispatch(fetchFileTypes());
  }, [dispatch]);

  const formattedCaseOptions = caseList.filter((caseItem: ICaseItem) => caseItem.Status === "Ongoing").map((caseItem: ICaseItem) => ({
    label: `${caseItem.CaseType} - ${caseItem.MaxBudget}`,
    id: caseItem.CaseID
  }));

  const getValidationSchema = () => {
    let schemaFields = {
      FolderID: Yup.string().required(t('Please select a folder')),
      Title: Yup.string().required(t('File title/name required')),
    };

    return Yup.object().shape(schemaFields);
  };
  const validationSchema = getValidationSchema();

  const formik = useFormik({
    initialValues: {
      FolderID: '',
      FolderName: '',
      Title: '',
      Content: '',
      FileType: 'General' as 'Contract' | 'Legal Document' | 'General' | 'Template',
      PartiesInvolved: [],
      StartDate: '',
      EndDate: '',
      Approvers: [],
      Assignees: [],
      SharedWith: [],
      LegalType: '',
      CaseID: '',
      Expiry: '',
    },
    validationSchema,
    enableReinitialize: true,
    onSubmit: async (values) => {
      const additionalFields = {
        PartiesInvolved: values.PartiesInvolved,
        StartDate: values.StartDate,
        EndDate: values.EndDate,
        Approvers: values.Approvers || [],
        Assignees: values.Assignees || [],
        SharedWith: values.SharedWith || [],
        LegalType: values.LegalType,
        CaseID: values.CaseID,
        Expiry: values.Expiry || "",
      };

      const newFileData: NewFileData = {
        folderId: values.FolderID,
        fileName: values.Title,
        fileType: values.FileType,
        file: file || undefined,
        additionalFields,
      };
      console.log(newFileData);

      onSave(newFileData);
      // dispatch(hideModal());


    },
  });

  const buildFolderOptions = (folders: FolderProps[]): DropdownOption[] => {
    return folders.map(folder => ({
      id: folder.FolderID,
      label: folder.FolderName,
      subOptions: folder.SubFolders && folder.SubFolders.length > 0 ? buildFolderOptions(folder.SubFolders) : undefined
    }));
  };

  const folderOptions: DropdownOption[] = buildFolderOptions(structure);

  const handleFolderChange = (selectedId: string) => {
    const findFolder = (folders: FolderProps[], id: string): FolderProps | undefined => {
      for (let folder of folders) {
        if (folder.FolderID === id) return folder;
        if (folder.SubFolders) {
          const found = findFolder(folder.SubFolders, id);
          if (found) return found;
        }
      }
      return undefined;
    };

    const selectedFolder = findFolder(structure, selectedId);
    if (selectedFolder) {
      formik.setFieldValue('FolderName', selectedFolder.FolderName);
      formik.setFieldValue('FolderID', selectedFolder.FolderID);
    }
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    event.stopPropagation();
    if (event.currentTarget.files && event.currentTarget.files[0]) {
      setFile(event.currentTarget.files[0]);
    }
  };

  const handleDrop = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();
    if (event.dataTransfer.files && event.dataTransfer.files[0]) {
      setFile(event.dataTransfer.files[0]);
    }
  };

  const handleDragOver = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();
  };

  const handleFileTypeSelect = (fileType: 'Contract' | 'Legal Document' | 'General' | 'Template') => {
    setSelectedFileType(fileType);
    formik.setFieldValue('FileType', fileType);
  };

  const darkTheme = createTheme({
    palette: {
      mode: 'dark',
    },
  });

  const fileTypeIcons = {
    Contract: 'fas fa-file-contract',
    Legal_Document: 'fas fa-gavel',
    General: 'fas fa-file-alt',
    Template: 'fas fa-file-invoice',
  };

  return (
    <div className='form-wrapper--document'>
      <form onSubmit={formik.handleSubmit} className="modal__form">
        <h2 className="modal__title">{t('Create New File')}</h2>
        <div className="file-type-buttons">
          {fileTypes.map((type: any) => (
            <button
              type="button"
              key={type.FileType}
              className={`file-type-button ${selectedFileType === type.FileType ? 'selected' : ''}`}
              onClick={() => handleFileTypeSelect(type.FileType as 'Contract' | 'Legal Document' | 'General' | 'Template')}
            >
              <i className={fileTypeIcons[type.FileType as keyof typeof fileTypeIcons]}></i>
              <span>{t(`${type.FileTypeName}`)}</span>
            </button>
          ))}
        </div>
        <label className="modal__label">
          <p>{t('File Name')}</p>
          <input className="modal__input" type="text" value={formik.values.Title} onChange={formik.handleChange('Title')} />
          {formik.errors.Title && formik.touched.Title ? <span style={{ color: 'red' }}>{formik.errors.Title}</span> : ''}
        </label>
        {formik.values.FileType === 'Contract' && (
          <>
            <label className="modal__label">
              <div className='label-icon--row'>
                <p>{t('Parties Involved')}</p>
                <i title={t('Please enter emails and separate by comma.')} className='fa fa-exclamation-circle'></i>
              </div>
              <EmailTagsInput
                value={formik.values.PartiesInvolved}
                placeholder={t('Enter parties involved')}
                onChange={(tags) => formik.setFieldValue('PartiesInvolved', tags)}
              />
            </label>
            <div className='document-form--row'>
              <label className="modal__label">
                <p>{t('Start Date')}</p>
                <ThemeProvider theme={darkTheme}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DateTimePicker
                      label=""
                      ampm={false}
                      value={formik.values.StartDate ? dayjs(formik.values.StartDate) : null}
                      name="StartDate"
                      onChange={(newValue: Dayjs | null) => {
                        if (newValue) {
                          formik.setFieldValue('StartDate', newValue.format("YYYY-MM-DD HH:mm:ss"));
                        }
                      }}
                    />
                  </LocalizationProvider>
                </ThemeProvider>
              </label>
              <label className="modal__label">
                <p>{t('End Date')}</p>
                <ThemeProvider theme={darkTheme}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DateTimePicker
                      label=""
                      ampm={false}
                      value={formik.values.EndDate ? dayjs(formik.values.EndDate) : null}
                      name="EndDate"
                      onChange={(newValue: Dayjs | null) => {
                        if (newValue) {
                          formik.setFieldValue('EndDate', newValue.format("DD.MM.YYYY"));
                        }
                      }}
                    />
                  </LocalizationProvider>
                </ThemeProvider>
              </label>
            </div>
            <label className="modal__label">
              <div className='label-icon--row'>
                <p>{t('Approvers')}</p>
                <i title={t('Please enter emails and separate by comma.')} className='fa fa-exclamation-circle'></i>
              </div>
              <EmailTagsInput
                value={formik.values.Approvers}
                placeholder={t('Enter approvers')}
                onChange={(tags) => formik.setFieldValue('Approvers', tags)}
              />
            </label>
            <label className="modal__label">
              <div className='label-icon--row'>
                <p>{t('Assignees')}</p>
                <i title={t('Please enter emails and separate by comma.')} className='fa fa-exclamation-circle'></i>
              </div>
              <EmailTagsInput
                value={formik.values.Assignees}
                placeholder={t('Enter assignees')}
                onChange={(tags) => formik.setFieldValue('Assignees', tags)}
              />
            </label>
            <label className="modal__label">
              <div className='label-icon--row'>
                <p>{t('Shared With')}</p>
                <i title={t('Please enter emails and separate by comma.')} className='fa fa-exclamation-circle'></i>
              </div>
              <EmailTagsInput
                value={formik.values.SharedWith}
                placeholder={t('Enter emails to share with')}
                onChange={(tags) => formik.setFieldValue('SharedWith', tags)}
              />
            </label>
          </>
        )}
        {formik.values.FileType === 'Legal Document' && (
          <>
            <label className="modal__label">
              <p>{t('Legal Type')}</p>
              <input
                className="modal__input"
                type="text"
                value={formik.values.LegalType}
                onChange={formik.handleChange('LegalType')}
              />
            </label>
            <label className="modal__label">
              <p>{t('Case')}</p>
              <GlobalDropdown
                options={formattedCaseOptions}
                onSelect={(id) => formik.setFieldValue("CaseID", id)}
                selectedLabel={formattedCaseOptions.find((option: any) => option.value === formik.values.CaseID)?.label || ''}
                style={{ width: '100%' }}
                name='CaseID'
              />
            </label>
            <label className="modal__label">
              <div className='label-icon--row'>
                <p>{t('Shared With')}</p> <i title={t('Please enter emails and separate by comma.')} className='fa fa-exclamation-circle'></i>
              </div>
              <EmailTagsInput
                value={formik.values.SharedWith}
                placeholder={t('Enter emails to share with')}
                onChange={(tags) => formik.setFieldValue('SharedWith', tags)}
              />
            </label>
          </>
        )}
        <label className="modal__label">
          <p>{t('Deadline')}</p>
          <ThemeProvider theme={darkTheme}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DateTimePicker
                label=""
                ampm={false}
                value={formik.values.Expiry ? dayjs(formik.values.Expiry) : null}
                name="Expiry"
                onChange={(newValue: Dayjs | null) => {
                  if (newValue) {
                    formik.setFieldValue('Expiry', newValue.format("YYYY-MM-DD HH:mm:ss"));
                  }
                }}
              />
            </LocalizationProvider>
          </ThemeProvider>
        </label>
        <label className="modal__label">
          <p>{t('Folder')}</p>
          <div className="newfile-dropdown">
            <GlobalDropdown
              options={folderOptions}
              onSelect={handleFolderChange}
              selectedLabel={formik.values.FolderName}
              placeholder={t('Select a folder')}
              style={{ width: '100%' }}
            />
            {formik.errors.FolderName && formik.touched.FolderName ? <span style={{ color: 'red' }}>{formik.errors.FolderName}</span> : ''}
          </div>
        </label>
        <label className="modal__label">
          <p>{t('Import File Content')}</p>
          <input id="fileUploadInput" type="file" onDrop={handleDrop} onDragOver={handleDragOver} onChange={handleFileChange} style={{ display: 'none' }} />
          <div
            className="file-upload-dropzone"
            onDrop={handleDrop}
            onDragOver={handleDragOver}
          >
            {file ? (
              <div className="uploaded-file--docs">
                <span>{file.name}</span>
                <button type="button" onClick={() => setFile(null)}>
                  <i className="fas fa-times"></i>
                </button>
              </div>
            ) : (
              <p>{t('Drag and drop a file here or click to upload')}</p>
            )}
          </div>
        </label>
        <div className="modal__buttons">
          <button type="submit" className="modal__button modal__button--create">
            {t('Create')}
          </button>
        </div>
      </form>
    </div>
  );
};

export default NewFileModal;
