import {
  FETCH_HELP_REQUEST, FETCH_HELP_SUCCESS, FETCH_HELP_FAILURE,
  FETCH_USER_HELP_REQUEST, FETCH_USER_HELP_SUCCESS, FETCH_USER_HELP_FAILURE,
  FETCH_CATEGORIES_REQUEST, FETCH_CATEGORIES_SUCCESS, FETCH_CATEGORIES_FAILURE,
  CREATE_TICKET_REQUEST, CREATE_TICKET_SUCCESS, CREATE_TICKET_FAILURE,
  FETCH_TICKET_REQUEST, FETCH_TICKET_SUCCESS, FETCH_TICKET_FAILURE,
  FETCH_ALL_TICKETS_REQUEST, FETCH_ALL_TICKETS_SUCCESS, FETCH_ALL_TICKETS_FAILURE,
  FETCH_RESOLVED_TICKETS_REQUEST, FETCH_RESOLVED_TICKETS_SUCCESS, FETCH_RESOLVED_TICKETS_FAILURE,
  FETCH_UNRESOLVED_TICKETS_REQUEST, FETCH_UNRESOLVED_TICKETS_SUCCESS, FETCH_UNRESOLVED_TICKETS_FAILURE,
  RESOLVE_TICKET_REQUEST, RESOLVE_TICKET_SUCCESS, RESOLVE_TICKET_FAILURE,
  FETCH_ALL_USER_TICKETS_REQUEST,
  FETCH_ALL_USER_TICKETS_SUCCESS,
  FETCH_ALL_USER_TICKETS_FAILURE,
  RECEIVE_MESSAGE,
  SEND_MESSAGE_FAILURE,
  SEND_MESSAGE_REQUEST,
  SEND_MESSAGE_SUCCESS
} from "../actions/HelpAction";

interface Action {
  type: string;
  payload?: any; 
}

interface HelpState {
  isLoading: boolean;
  allHelp: any;
  userHelp: any;
  categories: any;
  ticket: any;
  allTickets: any;
  messages: { text: string; sender: 'user' | 'bot', animationComplete?: boolean }[];
  loadingChat: boolean;
  allUserTickets: any;
  resolvedTickets: any;
  unresolvedTickets: any;
  error: string | null;
}

const initialState: HelpState = {
  isLoading: false,
  allHelp: null,
  messages: [],
  loadingChat: false,
  userHelp: null,
  categories: null,
  
  ticket: null,
  allTickets: null,
  allUserTickets: null,
  resolvedTickets: null,
  unresolvedTickets: null,
  error: null,
};

const helpReducer = (state: HelpState = initialState, action: Action): HelpState => {
  switch (action.type) {
    case FETCH_HELP_REQUEST:
    case FETCH_USER_HELP_REQUEST:
    case FETCH_CATEGORIES_REQUEST:
    case CREATE_TICKET_REQUEST:
    case FETCH_TICKET_REQUEST:
    case FETCH_ALL_TICKETS_REQUEST:
    case FETCH_ALL_USER_TICKETS_REQUEST:
    case FETCH_RESOLVED_TICKETS_REQUEST:
    case FETCH_UNRESOLVED_TICKETS_REQUEST:
    case RESOLVE_TICKET_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: null
      };
    
    case FETCH_HELP_SUCCESS:
      return {
        ...state,
        isLoading: false,
        allHelp: action.payload,
        error: null
      };
    
    case FETCH_USER_HELP_SUCCESS:
      return {
        ...state,
        isLoading: false,
        userHelp: action.payload,
        error: null
      };
    
    case FETCH_CATEGORIES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        categories: action.payload.SupportCategories,
        error: null
      };
    
    case CREATE_TICKET_SUCCESS:
      return {
        ...state,
        isLoading: false,
        ticket: action.payload,
        error: null
      };
    
    case FETCH_TICKET_SUCCESS:
      return {
        ...state,
        isLoading: false,
        ticket: action.payload,
        error: null
      };
    
    case FETCH_ALL_TICKETS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        allTickets: action.payload,
        error: null
      };
    case FETCH_ALL_USER_TICKETS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        allUserTickets: action.payload,
        error: null
      };
    
    case FETCH_RESOLVED_TICKETS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        resolvedTickets: action.payload,
        error: null
      };
    
    case FETCH_UNRESOLVED_TICKETS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        unresolvedTickets: action.payload,
        error: null
      };
    
    case RESOLVE_TICKET_SUCCESS:
      return {
        ...state,
        isLoading: false,
        resolvedTickets: action.payload,
        error: null
      };
      case SEND_MESSAGE_REQUEST:
        return {
            ...state,
            messages: [...state.messages, { text: action.payload, sender: 'user' }],
            loadingChat: true,
            error: null
        };
    case SEND_MESSAGE_SUCCESS:
        return {
            ...state,
            loadingChat: false,
            error: null
        };
    case SEND_MESSAGE_FAILURE:
        return {
            ...state,
            loadingChat: false,
            error: action.payload
        };
    case RECEIVE_MESSAGE:
        return {
            ...state,
            messages: [...state.messages, { text: action.payload, sender: 'bot' }]
        };
    
    case FETCH_HELP_FAILURE:
    case FETCH_USER_HELP_FAILURE:
    case FETCH_CATEGORIES_FAILURE:
    case CREATE_TICKET_FAILURE:
    case FETCH_TICKET_FAILURE:
    case FETCH_ALL_TICKETS_FAILURE:
    case FETCH_ALL_USER_TICKETS_FAILURE:
    case FETCH_RESOLVED_TICKETS_FAILURE:
    case FETCH_UNRESOLVED_TICKETS_FAILURE:
    case RESOLVE_TICKET_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload
      };
    
    default:
      return state;
  }
};

export default helpReducer;
