import PerformanceSection from "../components/Dashboard/AdminFirm/PerformanceSection";
function FinancePage() {
    return (
        <div style={{ overflowY: 'auto', height: 'calc(100vh - 100px)', scrollbarWidth: 'thin', scrollbarColor: '#64d2ff #111' }}>

            <PerformanceSection />
        </div>
    )
}

export default FinancePage;