import React, { useState } from 'react';
import '../../Marketplace.css'
import { useTranslation } from 'react-i18next';

interface Documents {
    caseSummary: number;
    evidence: number;
    legalBriefs: number;
    contracts: number;
    affidavits: number;
    witnessStatements: number;
    otherDocuments: number;
}

interface DocumentSectionProps {
    title: string;
    docType: keyof Documents;
}

const ClientDocumentation: React.FC = () => {
    const [confirmModalVisible, setConfirmModalVisible] = useState<boolean>(false);
    const [successModalVisible, setSuccessModalVisible] = useState<boolean>(false);
    const [documents, setDocuments] = useState<Documents>({
        caseSummary: 0,
        evidence: 0,
        legalBriefs: 0,
        contracts: 0,
        affidavits: 0,
        witnessStatements: 0,
        otherDocuments: 0,
    });
    const {t} = useTranslation()

    const handleConfirmSubmit = () => {
        setConfirmModalVisible(false);
        setTimeout(() => {
            setSuccessModalVisible(true);
        }, 1000);
    };

    const handleUploadDocument = (docType: keyof Documents) => {
        setDocuments((prevDocs) => ({
            ...prevDocs,
            [docType]: prevDocs[docType] + 1,
        }));
    };

    const handleSubmitDocuments = () => {
        setConfirmModalVisible(true);
    };

    const DocumentSection: React.FC<DocumentSectionProps> = ({ title, docType }) => {
        const isUploaded = documents[docType] > 0;
        return (
            <div className="client-doc-section">
                <span className="client-doc__title">{title}</span>
                <button
                    className={`client-doc__upload-button ${isUploaded ? 'uploaded' : ''}`}
                    onClick={() => handleUploadDocument(docType)}
                >
                    {isUploaded ? `${documents[docType]} ${t('File(s) Uploaded')}` : t('Upload')}<i className='fa-solid fa-plus'></i>
                </button>
            </div>
        );
    };

    return (
        <div className="client-doc__container">
            <div>

            <h1>{t('Upload and Submit Documents')}</h1>
            <p>{t('Ensure all necessary documents are uploaded before submission.')}</p>
            <div className="client-doc__container__list">
                <DocumentSection title="Case Summary" docType="caseSummary" />
                <DocumentSection title="Evidence" docType="evidence" />
                
            </div>
            </div>
            <button className="client-doc__submit-button" onClick={handleSubmitDocuments}>
                {t('Submit Documents')}
            </button>

            {confirmModalVisible && (
                <div className="client-doc__modal">
                    <div className="client-doc__modal-content">
                        <p>{t('Are you sure you want to submit the client case documents?')}</p>
                        <div>
                            <button onClick={() => setConfirmModalVisible(false)}>Cancel</button>
                            <button onClick={handleConfirmSubmit}>Confirm</button>
                        </div>
                    </div>
                </div>
            )}

            {successModalVisible && (
                <div className="client-doc__modal">
                    <div className="client-doc__modal-content">
                        <p>{t('Client Documentation successfully submitted.')}</p>
                        <button onClick={() => setSuccessModalVisible(false)}>{t('Close')}</button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default ClientDocumentation;
