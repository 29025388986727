import React, { useRef } from 'react';
import SignatureCanvas from 'react-signature-canvas';
import './DigitalSignature.css'; 
import { addSignature } from '../../../store/actions/DocumentsAction';
import { useAppDispatch } from '../../../store';

interface SignatureModalProps {
  // isOpen: boolean;
  // onClose: () => void;
  onSave: (signature: string) => void;
  onConfirm: (signatureFile: File) => void; 
  userId: string;
  userType: string;
}

const SignatureModal: React.FC<SignatureModalProps> = ({  onSave, onConfirm, userId, userType }) => {
  const signatureCanvasRef = useRef<SignatureCanvas>(null);
  const dispatch = useAppDispatch();

  const handleClear = () => {
    signatureCanvasRef.current?.clear();
  };

  const handleSave = async () => {
    if (!signatureCanvasRef.current) {
      alert("Signature pad not initialized.");
    } else if (signatureCanvasRef.current.isEmpty()) {
      alert("Please provide a signature first.");
    } else {
      const canvas = signatureCanvasRef.current.getCanvas();
      const canvasDataUrl = canvas.toDataURL('image/png');
      const img = new Image();
      img.src = canvasDataUrl;

      img.onload = () => {
        const svgNamespace = "http://www.w3.org/2000/svg";
        const svgElement = document.createElementNS(svgNamespace, "svg");
        svgElement.setAttribute("width", canvas.width.toString());
        svgElement.setAttribute("height", canvas.height.toString());

        const foreignObject = document.createElementNS(svgNamespace, "foreignObject");
        foreignObject.setAttribute("width", "100%");
        foreignObject.setAttribute("height", "100%");

        const xhtmlNamespace = "http://www.w3.org/1999/xhtml";
        const div = document.createElementNS(xhtmlNamespace, "div");
        div.innerHTML = `<img src="${canvasDataUrl}" width="${canvas.width}" height="${canvas.height}"/>`;

        foreignObject.appendChild(div);
        svgElement.appendChild(foreignObject);

        const serializer = new XMLSerializer();
        const svgString = serializer.serializeToString(svgElement);
        const blob = new Blob([svgString], { type: 'image/svg+xml' });
        const signatureFile = new File([blob], `${userId}_signature.svg`, { type: 'image/svg+xml' });


        onSave(svgString);
        onConfirm(signatureFile);

        localStorage.setItem('userSignature', svgString);

        try {
          dispatch(addSignature(userId, userType, signatureFile));
        } catch (error) {
          console.error('Dispatch Error:', error); 
        }

        // onClose();
      };

      img.onerror = (error) => {
        console.error('Image Load Error:', error); 
      };
    }
  };

  // if (!isOpen) return null;

  return (
    <div className="signature__modal-content">
      <div className='signature__modal-header'>
        <h2>Digital Signature</h2>
          <div className="signature__modal-actions">
            <button className='digiclear' onClick={handleClear}>Clear</button>
            <button className='digisave' onClick={handleSave}>Save</button>
            {/* <button className='digiCancel' onClick={onClose}>Cancel</button> */}
          </div>
          </div>
      <div style={{ alignSelf: 'center', width: '100%', position: 'relative', height: '100%', display: 'flex', justifyContent: 'center' , alignItems: 'center'}}>
        <SignatureCanvas 
          penColor="white"
          ref={signatureCanvasRef}
          canvasProps={{
            height: 200,
            className: 'signature-canvas'
          }}
        />
      </div>
    </div>
  );
};

export default SignatureModal;
