import React, { useEffect, useState } from 'react';
import './ContractGenerator.css';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../../store';
import GlobalDropdown from '../../shared/GlobalDropdown';
import { hideModal, showModal } from '../../../store/actions/modalActions';

export interface ContractType {
  Attributes: string;
  ContractType: string;
  ContractTypeID: number;
  Description: string;
  Country: string;
  Language: string;
}

interface ContractGeneratorProps {
  initiateContractGeneration: (contractType: ContractType) => void;
}

const ContractGenerator: React.FC<ContractGeneratorProps> = ({ initiateContractGeneration }) => {
  const { t } = useTranslation();
  const contractTypes = useSelector((state: RootState) => state.model.contracts);
  const dispatch = useDispatch();
  const [sortedContracts, setSortedContracts] = useState<ContractType[]>([]);
  const [sortOrder, setSortOrder] = useState<string>('');
  const [jurisdiction, setJurisdiction] = useState<string>(t(''));
  const [searchQuery, setSearchQuery] = useState<string>('');

  useEffect(() => {
    const savedSortOrder = localStorage.getItem('contractSortOrder');
    if (savedSortOrder) {
      setSortOrder(savedSortOrder);
    }
  }, []);

  useEffect(() => {
    let filteredContracts = contractTypes;

    if (searchQuery) {
      filteredContracts = filteredContracts.filter((contract: ContractType) =>
        contract.ContractType.toLowerCase().includes(searchQuery.toLowerCase()) ||
        contract.Description.toLowerCase().includes(searchQuery.toLowerCase())
      );
    }

    if (jurisdiction) {
      filteredContracts = filteredContracts.filter((contract: ContractType) =>
        contract.Country.toLowerCase() === jurisdiction.toLowerCase()
      );
    }

    const sorted = [...filteredContracts].sort((a, b) => {
      if (sortOrder === 'a-z') {
        return a.ContractType.localeCompare(b.ContractType);
      } else if (sortOrder === 'z-a') {
        return b.ContractType.localeCompare(a.ContractType);
      }
      return 0;
    });

    setSortedContracts(sorted);
    localStorage.setItem('contractSortOrder', sortOrder);
  }, [contractTypes, sortOrder, searchQuery, jurisdiction]);

  const handleSortChange = (order: string) => {
    setSortOrder(order);
  };

  const handleJurisdictionChange = (jurisdiction: string) => {
    setJurisdiction(jurisdiction);
  };

  const handleContractTypeSelection = (contract: ContractType) => {
    dispatch(showModal({
      type: 'confirmation',
      message: `Do you want to initiate the ${contract.ContractType} generation process?`,
      onConfirm: () => {
        initiateContractGeneration(contract);
        dispatch(hideModal());
      },
      onCancel: () => {
        dispatch(hideModal());
      },
      showModal: true
    }));
  };

  const renderContracts = (sortedContracts: ContractType[]): JSX.Element => (
    <div className="contract-column">
      {sortedContracts.map((contract, index) => (
        <div
          title={contract.Attributes}
          key={index}
          className='contract-prompt'
          onClick={() => handleContractTypeSelection(contract)}
        >
          <h3>{t(contract.ContractType)}</h3>
          <p>{t(contract.Description)}</p>
          <div className='contract-prompt--details'>
          <span style={{ padding: '3px 8px', backgroundColor: '#1e1f21', color: '#fff', borderRadius: 4, fontSize: '0.8rem' }}>{t(contract.Country)}</span>
          <span style={{ padding: '3px 8px', backgroundColor: '#1e1f21', color: '#fff', borderRadius: 4, fontSize: '0.8rem' }}>{t(contract.Language)}</span>
          </div>
        </div>
      ))}
    </div>
  );

  return (
    <div style={{ width: '100%' }}>
      <div className='contract-generator__header--wrapper'>
        <div className='contract-generator__header--input-wrapper'>
          <i className='fas fa-magnifying-glass'></i>
          <input
            type="text"
            className='input'
            placeholder={t('Search for any contract type...')}
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
        </div>
        <div className='contract-generator__header--dropdown-wrapper'>
          <GlobalDropdown
            options={[{ id: 'a-z', label: 'A - Z' }, { id: 'z-a', label: 'Z - A' }]}
            onSelect={handleSortChange}
            selectedLabel={sortOrder}
            placeholder={t('Select sorting order')}
            style={{ width: 250 }}
          />
          <GlobalDropdown
            options={[{ id: 'Norway', label: t('Norway') }, { id: 'United States', label: t('United States') }, { id: '', label: 'All' }]}
            onSelect={handleJurisdictionChange}
            selectedLabel={jurisdiction}
            placeholder={t('Select jurisdiction')}
            style={{ width: 250 }}
          />
        </div>
      </div>
      <div className='contract-generator-prompts'>
        {renderContracts(sortedContracts)}
      </div>
    </div>
  );
};

export default ContractGenerator;
