import React, { ReactNode } from "react";
import Sidebar from "../components/Sidebar/Sidebar";
import Header from "../components/Header/Header";
import { RootState } from "../store";
import { useSelector } from "react-redux";

interface PrivateLayoutProps {
  children: ReactNode;
  activeView: string;
  setActiveView: (activeView: string) => void; 
}

const PrivateLayout: React.FC<PrivateLayoutProps> = ({ children, activeView, setActiveView }) => {
  const isSidebarOpen = useSelector((state: RootState) => state.sidebar.isOpen);

  return (
    <div style={{ position: 'relative', width: '100%', height: '100%', overflow: 'hidden', background: '#0D0D12' }}>
      <div style={{ display: 'flex', overflow: 'hidden', position: 'relative', justifyContent: 'space-between' }}>
        <Sidebar />
        <div style={{ width: isSidebarOpen ? 'calc(100vw - 15rem)' : 'calc(100vw - 80px)', padding: '0px', position: 'relative', overflow: 'hidden' }}>
          <Header activeView={activeView} setActiveView={setActiveView} />
          {children}
        </div>
      </div>
    </div>
  );
};

export default PrivateLayout;
