import { SET_ACTIVE_ITEM } from "../actions/AdminAction";

interface AdminViewState {
    activeItem: string;
}

const initialState: AdminViewState = {
    activeItem: 'Management',
};

const adminViewReducer = (state = initialState, action: any): AdminViewState => {
    switch (action.type) {
        case SET_ACTIVE_ITEM:
            return {
                ...state,
                activeItem: action.payload,
            };
        default:
            return state;
    }
};

export default adminViewReducer;
