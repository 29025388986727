import { useEffect } from "react"
import './Dashboard.css'
import ClientDashboard from "./Client/ClientDashboard"
import LawyerIndependent from "./LawyerIndependent/LawyerIndependent"
import ClientBusiness from "./ClientBusiness/ClientBusiness"
import LawyerFirm from "./LawyerFirm/LawyerFirm"
import AdminBusiness from "./AdminBusiness/AdminBusiness"
import AdminFirm from "./AdminFirm/AdminFirm"
import { getUserID, getUserType } from "../../store/actions/ChatAction"
import { useDispatch } from "react-redux"
import { ThunkDispatch } from "redux-thunk"
import { RootState } from "../../store"
import { AnyAction } from "redux"
import { ActiveTasks, GetAllTasks } from "../../store/actions/marketplace/TasksActions"
import SocketServices from "../../utils/SocketServices"

const Dashboard = () => {
    const isActiveUser = getUserType()
    const userId = getUserID()
    const dispatch = useDispatch<ThunkDispatch<RootState, undefined, AnyAction>>();
    useEffect(() => {
        if (isActiveUser === "Lawyer") {
            dispatch(ActiveTasks(userId, isActiveUser))
            dispatch(GetAllTasks(userId, isActiveUser))

        }
    }, [isActiveUser, dispatch, userId])

   

    return (
        <div className="dashboard-main__container">

            {isActiveUser === 'Client' && (
                <ClientDashboard />
            )}

            {isActiveUser === 'Lawyer' && (
                <LawyerIndependent />
            )}

            {isActiveUser === 'Employee' && (
                <ClientBusiness />
            )}

            {isActiveUser === 'Lawfirmlawyer' && (
                <LawyerFirm />
            )}

            {isActiveUser === 'BusinessAdmin' && (
                <AdminBusiness />

            )}

            {isActiveUser === 'LawfirmAdmin' && (
                <AdminFirm />

            )}
        </div>
    )


}


export default Dashboard