import { AnyAction } from "redux";
export interface FolderOption {
  id: string;
  label: string;
}

export interface ModalState {
  showModal: boolean;
  type: 'confirmation' | 'feedback' | 'custom' | 'success' | 'error' | 'warning' | 'share' | 'sign' | 'create' | 'addFile' | 'resetPassword' | 'password' | 'payment-options' | "tips" | 'info' | 'receiver-options' | "addSignature" | 'promptfeedback' | "2fa-qrcode" | 'alert' | 'otp-verification' | 'addTask' | 'confirm-upload' | 'subscription-modal';
  message: string;
  onConfirm?: (data: string | File) => void;
  onCancel?: () => void;
  duration?: number; 
  folderOptions?: FolderOption[];
  selectedModel?: any;
  inputData?: string; 
  info?: string;
  title?: string;
  date?: string;
}


  const initialState: ModalState = {
    showModal: false,
    type: 'custom',
    message: '',
    info: '',
    title: '',
  };
  

  export const modalReducer = (state = initialState, action: AnyAction): ModalState => {
    switch (action.type) {
        case 'SHOW_MODAL':
            return {
              ...state,
              ...action.payload,
              showModal: true
            };
          case 'HIDE_MODAL':
            return {
              ...state,
              showModal: false 
            };
        default:
          return state;
      }
    };