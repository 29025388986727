import React, { useEffect, useState, CSSProperties, useRef } from 'react';
import './GlobalDropdown.css';
import { useTranslation } from 'react-i18next';

export interface DropdownOption {
  id: string;
  label: string;
  subOptions?: DropdownOption[];
}

interface GlobalDropdownProps {
  options: DropdownOption[];
  onSelect: (id: string) => void;
  selectedLabel: string;
  placeholder?: string;
  style?: CSSProperties;
  reverseHeader?: boolean;
  name?: string;
}

const GlobalDropdown: React.FC<GlobalDropdownProps> = ({
  options,
  onSelect,
  selectedLabel,
  placeholder,
  style = {},
  reverseHeader = false,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [shouldDropUp, setShouldDropUp] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const dropdownListRef = useRef<HTMLDivElement>(null);
  const { t } = useTranslation();
  const newPlaceHolder = placeholder || t('Select an option');
  const [openFolders, setOpenFolders] = useState<{ [key: string]: boolean }>({});
  const toggleFolder = (id: string) => {
    setOpenFolders(prevState => ({ ...prevState, [id]: !prevState[id] }));
  };

  const handleSelect = (option: DropdownOption) => {
    console.log(option);
    
    onSelect(option.id);
    setIsOpen(false);
  };

  const [selectedOption, setSelectedOption] = useState<DropdownOption | null>(null);

  useEffect(() => {
    if (selectedLabel) {
      const foundOption = options.find(option => option.label === selectedLabel);
      setSelectedOption(foundOption || null);
    }
  }, [selectedLabel, options]);

  useEffect(() => {
    const checkDropdownPosition = () => {
      if (dropdownRef.current && dropdownListRef.current) {
        const dropdownRect = dropdownRef.current.getBoundingClientRect();
        const dropdownListHeight = dropdownListRef.current.offsetHeight;
        const viewportHeight = window.innerHeight || document.documentElement.clientHeight;

        // Check if the dropdown list would go beyond the viewport's bottom
        if (dropdownRect.bottom + dropdownListHeight > viewportHeight) {
          setShouldDropUp(true);
        } else {
          setShouldDropUp(false);
        }
      }
    };

    if (isOpen) {
      checkDropdownPosition();
      window.addEventListener('resize', checkDropdownPosition);
    } else {
      window.removeEventListener('resize', checkDropdownPosition);
    }

    return () => {
      window.removeEventListener('resize', checkDropdownPosition);
    };
  }, [isOpen]);

  const renderOptions = (options: DropdownOption[]) => {
    return options.map(option => (
      <div key={option.id}>
        <div className="global-dropdown-item">
          <span onClick={() => handleSelect(option)}>{option.label}</span>
          {option.subOptions && option.subOptions.length > 0 && (
            <i
              className={`fas fa-chevron-${openFolders[option.id] ? 'down' : 'right'}`}
              onClick={() => toggleFolder(option.id)}
            ></i>
          )}
        </div>
        {option.subOptions && openFolders[option.id] && (
          <div className="global-dropdown-suboptions">
            {renderOptions(option.subOptions)}
          </div>
        )}
      </div>
    ));
  };

  return (
    <div className={`global-dropdown ${shouldDropUp ? 'drop-up' : ''}`} style={style} ref={dropdownRef}>
      <div className={`global-dropdown-header ${reverseHeader ? 'reverse' : ''} ${isOpen ? 'active' : ''}`} onClick={() => setIsOpen(!isOpen)}>
        <span style={style ? { fontSize: '0.8rem', color: '#fff' } : { fontSize: '0.8rem', color: '#fff' }}>
          {selectedOption ? selectedOption.label : selectedLabel ? selectedLabel : newPlaceHolder}
        </span>
        <i className={`fas fa-chevron-${isOpen ? 'up' : 'down'}`} style={style ? { fontSize: '14px', color: '#64d2ff' } : { fontSize: '14px', color: '#64d2ff' }}></i>
      </div>
      {isOpen && (
        <div className="global-dropdown-list" ref={dropdownListRef}>
          {renderOptions(options)}
        </div>
      )}
    </div>
  );
};

export default GlobalDropdown;
