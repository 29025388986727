import { SET_CHAT_THEME, SetChatThemeAction } from "../actions/ChatThemeAction";



export interface ChatThemeState {
  chatTheme: string;
}

const initialState: ChatThemeState = {
  chatTheme: "dark", 
};

export const chatThemeReducer = (state = initialState, action: SetChatThemeAction): ChatThemeState => {
  switch (action.type) {
    case SET_CHAT_THEME:
      return {
        ...state,
        chatTheme: action.payload.chatTheme,
      };
    default:
      return state;
  }
};
