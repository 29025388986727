import CreditTracker from '../CreditTracker/CreditTracker';
import { RootState, useAppDispatch } from '../../../store';
import { setSelectedModel } from '../../../store/actions/ChatAction';
import { useHistory } from 'react-router-dom';
import './ClientBusiness.css';
import { useSelector } from 'react-redux';
import { Task } from '../../Marketplace/Lawyer/LawyerSection';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';

interface LegalDeadline {
    id: number;
    title: string;
    dueDate: Date;
    description: string;
}

const ClientBusiness = () => {
    const {t} = useTranslation()
    const dispatch = useAppDispatch()
    const history = useHistory();
    const TodoList = useSelector((state: RootState) => state.toDo.activeTasks)

    const handleServiceClick = (selectedModel: string) => {
        dispatch(setSelectedModel(selectedModel));
        history.push("/ai-assistant");
    };

    const deadlines: LegalDeadline[] = [
        { id: 1, title: 'Contract Renewal ABC Corp.', dueDate: new Date('2024-05-01'), description: 'Renewal of service contract with ABC Corporation.' },
        { id: 2, title: 'Submit Patent Application', dueDate: new Date('2024-06-15'), description: 'Submission deadline for patent application XYZ.' },
        { id: 3, title: 'Contract Renewal ABC Corp.', dueDate: new Date('2024-05-01'), description: 'Renewal of service contract with ABC Corporation.' },
        { id: 4, title: 'Submit Patent Application', dueDate: new Date('2024-06-15'), description: 'Submission deadline for patent application XYZ.' },
    ];

    const sortedDeadlines = deadlines.sort((a, b) => a.dueDate.getTime() - b.dueDate.getTime());
    return (
        <div className="client-business-main__container">
            <div className="client-business-container">
                <div className="business-client-container">
                    <div className='bussiness-client-header-container'>
                        <span>{t('Upcoming Legal Deadlines')}</span>
                    </div>
                    <div className="client-business-services--list">
                        <button onClick={() => handleServiceClick("Legal Case Mapping")}>{t('Legal Case Mapping')}</button>
                        <button onClick={() => handleServiceClick("Legal Advisor")}>{t('Legal Advisor')}</button>
                        <button onClick={() => handleServiceClick("Contract Generation")}>{t('Contract Generation')}</button>
                        <button onClick={() => handleServiceClick("Contract Analysis")}>{t('Contract Analysis')}</button>
                    </div>
                </div >
                <div className="business-client-container">
                    <CreditTracker totalCredits={0} />
                </div>
            </div >

            <div className="client-business-container">
                <div className="business-client-container">
                    <div className='bussiness-client-header-container'>
                        <span>{t('Upcoming Legal Deadlines')}</span>
                    </div>
                    <ul style={{ overflow: 'auto', scrollbarWidth: 'none', maxHeight: 300 }}>
                        {sortedDeadlines.map((deadline, index) => (
                            <>
                                <div key={index} className="dashboard-client__marketplace--item">
                                    <div className="dashboard-client__marketplace--item--row">
                                        <div>
                                            <p className="dashboard-client__marketplace--tag casetype">{deadline.title}</p>
                                            <p className="dashboard-client__marketplace--tag jurisdiction">{t('Due by')}: {format(deadline.dueDate, 'PP')}</p>
                                            <p className="dashboard-client__marketplace--tag budget">{t('Description')}: {deadline.description}</p>
                                        </div>
                                    </div>

                                </div>
                            </>
                        ))}
                    </ul>
                </div>

                <div className="business-client-container">
                    <div className='bussiness-client-header-container'>
                        <span>{t('ToDo List')}</span>
                    </div>
                    <div className="todo-list">
                        {
                            TodoList.length > 0 ? TodoList.map((task: Task, index: number) => (
                                <div key={index} className="case">
                                    <div className="caseName">{task.TaskTitle}</div>
                                </div>
                            )) : (
                                <p className="no-documents">-- {t('No Active Tasks')} --</p>
                            )
                        }
                    </div>
                </div>
            </div>
        </div>
    )


}


export default ClientBusiness;